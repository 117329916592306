<template>
  <div>
    <div class="w-[261px] h-full bg-blue-200">
      <div class="">
        <button
          class="block text-[22px] text-[#232323] font-bold"
          @click="showCategory = !showCategory"
        >
          카테고리
        </button>
        <button
          class="w-full mt-[11px] text-[18px] text-[#232323] text-end"
          @click="pageDataOrganizer('')"
        >
          전체상품보기
        </button>
      </div>
      <!-- 카테고리 리스트 -->
      <div
        class="mt-[3px] md:mt-[15px] w-[6em] md:w-[230px]"
        v-for="(cat, i) in catParengtGroup"
        :key="i"
      >
        <div class="flex">
          <button @click="catSearchOrg(cat.catTag, cat.catName)" class="flex items-center">
            <img class="w-[56px] h-[56px] mr-[16px] mb-[11px]" :src="cat.catImg" alt="" />
            <p class="md:text-[18px] text-[#373737]" style="font-size: 16px">{{ cat.catName }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect } from 'vue'
import axios from 'axios'

const catData = ref([])
const catParengtGroup = ref([])
const pageReady = ref(false)

onMounted(() => {
  pageReady.value = true
})

const catOgernizer = () => {
  const paraentGroup = catData.value.filter((parent) => parent.catParent === '')
  catParengtGroup.value = paraentGroup
}

const getCat = async () => {
  try {
    const getData = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/category/getCat`)
    const result = getData.data
    console.log(result)
    // console.log(result)
    catData.value = result.data[0].catArray
    catOgernizer()
  } catch (error) {
    console.error(error)
    return
  }
}

watchEffect(() => {
  if (pageReady.value === true) {
    getCat()
  }
})
</script>

<style lang="scss" scoped></style>
