<template>
  <div>
    <div class="lg:w-[1180px] md:mx-5 lg:mx-auto" :key="searchKeyword">
      <!-- <homePostAndCat class="ml-[1px]" /> -->
      <p
        class="flex items-center mt-[13.5px] lg:mt-[37px] text-[18px] lg:text-[26px] text-[#404040] font-nanumsquareEB ml-[13px] lg:pl-[10px]"
      >
        {{ searchKeyword }}
        <span class="text-[13px] lg:text-[16px] font-notosansCJKmd ml-[13px] lg:ml-2"
          >검색 결과</span
        >
        <!-- {{ mainCatDatas }} -->
      </p>
      <shopMain :isSearch="isSearch" :searchKeyword="searchKeyword" />
    </div>
  </div>
</template>

<script setup>
import shopMain from '../../components/shop/shopMain.vue'
import homePostAndCat from '../../components/home/homePostAndCat.vue'
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const searchKeyword = ref(route.query.keyword)

const isSearch = ref(true)

watchEffect(() => {
  if (route.query.keyword) {
    searchKeyword.value = route.query.keyword
  }
})
</script>

<style lang="scss" scoped></style>
