<template>
  <div>
    <hr class="mx-3 md:mx-11" />
    <section class="ml-3 mr-2 md:mr-0 md:ml-[37px]">
      <p class="text-[9px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D] mt-[11px]">
        ＊ 교환 비용은 수거 비용과 재발송 비용, 반품 비용은 구매시 배송비와 반품시 배송비를 합산하
        여 청구됩니다. 단, 고객과실이 아닌 경우는 제외합니다.
      </p>
      <p class="text-[9px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D] mt-[11px]">
        ＊ 반품/교환배송비는 같은 주문번호 내 요청된 상품들이 묶어서 청구됩니다. 설치비용 혹은
        화물배송인 경우에는 적용되지 않습니다.
      </p>
      <p class="text-[9px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D] mt-[11px]">
        ＊ 판매자 과실로 반품/교환을 선택을 하였으나 판매자 과실이 아닌 경우 추가 반품 비용이 청구될
        수 있습니다.
      </p>
      <p
        class="text-[9px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D] mt-[11px] mb-[10.5px] md:mb-[19.5px]"
      >
        ＊ 단순 변심으로 반품시 사용 흔적 등으로 상품 가치가 하락한 경우 반품이 거부될 수 있습니다.
      </p>
    </section>
    <br />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
