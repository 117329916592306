<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-10 overflow-hidden"
  >
    <div
      class="w-[365px] md:w-[626px] h-auto max-h-[80vh] bg-white rounded-xl overflow-y-clip hide-scrollbar"
    >
      <!-- 공통헤더 -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          리뷰 작성하기
        </p>
        <button class="absolute right-[15px] md:right-[31px]">
          <i
            class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
            @click="system.reviewModal = false"
          ></i>
        </button>
      </div>
      <hr class="mx-2" />
      <section class="md:hidden">
        <div>
          <section>
            <div class="rounded-xl flex flex-col justify-center mx-2 bg-white">
              <section class="">
                <div class="flex justify-between ml-[11px] mt-[12px]">
                  <div class="flex items-center">
                    <!-- 날짜  -->
                    <p class="text-[13px] font-nanumsquareEB text-[#171717]">
                      <!-- {{ dayjs(days).format('YYYY.MM.DD') }} -->
                    </p>
                    <!-- 태그  -->
                  </div>
                </div>
              </section>
              <div class="flex justify-between items-start ml-[11px] mt-[8.9px]">
                <!-- 우측 데이터 -->
                <div class="flex justify-between items-center w-full">
                  <div class="flex w-full mb-[20px]">
                    <img :src="item.thumbImg" alt="" class="w-[59px] h-[74px] rounded-md" />
                    <div class="mx-[10px] mt-[2px] w-full">
                      <div class="flex justify-between">
                        <p
                          class="text-[12px] md:text-[16px] text-[rgb(109,109,109)] font-notosansCJKmd mb-[3px] md:mb-[2px]"
                        >
                          {{ item.company }} ({{ item.orderNumber }})
                        </p>
                      </div>
                      <p class="text-[14px] md:text-[18px] text-[#171717] font-notosansCJKmd">
                        {{ item.title }}
                      </p>
                      <p
                        class="text-[12px] md:text-[15px] text-[#6D6D6D] mb-[3px] md:mb-[2px] font-notosansCJKmd"
                      >
                        {{ item.options.types }}
                        <span class="font-notosansCJR">- {{ item.options.color }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 좌측 제어  -->
              </div>
            </div>
          </section>
          <hr class="mx-3" />
          <!-- 별점 -->
          <section class="mt-[31px]">
            <p class="text-[14px] font-notosansCJKmd text-[#232323] text-center">
              상품은 어떠셨나요?
            </p>
            <div class="">
              <div class="">
                <!-- 별 점수 -->
                <div class="flex justify-center">
                  <p class="text-[16px] font-notosansCJKmd text-[#389033] mt-[15px]">
                    {{ payload.reviewData.reviewRate || 0 }}.0
                  </p>
                </div>
                <div class="flex justify-center">
                  <Rating
                    v-model="payload.reviewData.reviewRate"
                    class="flex text-[#389033] text-[23px] space-x-1 custom-rating"
                    :cancel="false"
                    :onIcon="'pi pi-star-fill text-[23px]'"
                    :offIcon="'pi pi-star text-[23px]'"
                  />
                </div>
              </div>
            </div>
          </section>
          <section class="mt-[28px] mx-3">
            <div class="flex justify-center">
              <div class="h-[213px] w-full">
                <textarea
                  ref="textarea"
                  @input="limitLines"
                  v-model="payload.reviewData.reviewDetail"
                  class="resize-none w-full outline-none pt-[13px] text-[16px] pl-[10px] h-full placeholder:text-gray-400 placeholder:text-[12px] border rounded-md"
                  placeholder="사용하시면서 느낀 만족도에 대한 리뷰를 작성해주세요. 
작성해주신 리뷰는 다른 분들께 큰 도움이 됩니다 :)
(최소 10자 이상)"
                  maxlength="1000"
                />
              </div>
            </div>
          </section>
          <div class="flex justify-center">
            <reviewModalImgAddr
              @imgList="system.reviewImgArray"
              @updateReviewImg="handleUpdateImages"
              class="w-full"
            />
          </div>
          <section class="mt-[8px] mb-[11.5px]">
            <div
              class="flex justify-end mr-5 cursor-pointer"
              @click="cautionStatus = !cautionStatus"
            >
              <p class="text-[9px] font-notosansCJKmd text-[#6D6D6D]">리뷰 작성시 유의사항</p>
              <i
                class="pi pi-angle-down ml-[4px] text-[9px] pt-[1px] text-[#6D6D6D] -rotate-90"
                :class="`${reloate}`"
              ></i>
            </div>
          </section>

          <section>
            <reviewCation v-if="cautionStatus" />
          </section>
          <section>
            <hr class="mx-3" />
            <div class="flex justify-center mb-[16px] mt-[8.3px]">
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#D5D5D5] rounded-lg md:rounded-md"
                @click="system.reviewModal = false"
              >
                <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
              </button>
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
                @click="handleReview()"
              >
                <p
                  v-if="!item.reviewed"
                  class="text-[13px] md:text-[18px] text-white font-notosansCJKmd"
                >
                  작성하기
                </p>
                <p v-else class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">
                  수정하기
                </p>
              </button>
            </div>
          </section>
        </div>
      </section>
      <section class="hidden md:block">
        <div class="mt-[29px] ml-[45px]">
          <div class="flex">
            <img :src="item.thumbImg" alt="" class="w-[81px] h-[101px] rounded-md" />
            <div class="ml-[26px] mt-[10px]">
              <div class="flex">
                <p class="text-[16px] text-[#6D6D6D] font-notosansCJKmd mb-[2px]">
                  {{ item.company }} ({{ item.orderNumber }})
                </p>
              </div>
              <p class="text-[18px] text-[#171717] font-notosansCJKmd">{{ item.title }}</p>
              <p class="text-[15px] text-[#6D6D6D] mb-[2px] font-notosansCJKmd">
                {{ item.options.types }}
                <span class="font-notosansCJR">- {{ item.options.color }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 별점 -->
        <div class="flex justify-center mt-[31px]">
          <div class="">
            <p class="text-[18px] font-notosansCJKmd text-[#232323]">상품은 어떠셨나요?</p>
            <!-- 별 점수 -->
            <div class="flex justify-center">
              <p class="text-[20px] font-notosansCJKmd text-[#389033] mt-[15px]">
                {{ payload.reviewData.reviewRate || 0 }}.0
              </p>
            </div>
            <div class="flex justify-center">
              <Rating
                v-model="payload.reviewData.reviewRate"
                class="flex text-[#389033] text-[29px] space-x-1 custom-rating"
                :cancel="false"
                :onIcon="'pi pi-star-fill text-[29px]'"
                :offIcon="'pi pi-star text-[29px]'"
              />
            </div>
          </div>
        </div>
        <div class="mt-[39px] flex justify-center">
          <div class="h-[233px] w-[545px]">
            <textarea
              ref="textarea"
              @input="limitLines"
              v-model="payload.reviewData.reviewDetail"
              class="resize-none w-full outline-none pt-[13px] text-[16px] pl-[14px] h-full placeholder:text-gray-400 placeholder:text-[15.5px] border rounded-md"
              placeholder="사용하시면서 느낀 만족도에 대한 리뷰를 작성해주세요. 작성해주신 리뷰는 다른
분들께 큰 도움이 됩니다 :)
(최소 10자 이상)"
              maxlength="1000"
            />
          </div>
        </div>
        <!-- 사진 첨부 -->
        <div class="flex justify-center">
          <reviewModalImgAddr
            @imgList="system.reviewImgArray"
            @updateReviewImg="handleUpdateImages"
            class="w-full"
          />
        </div>
        <!-- 버튼 제어 -->
        <div class="flex justify-center mt-[57px]">
          <button
            class="w-[226px] h-[51px] border border-[#D5D5D5] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
            @click="system.reviewModal = false"
          >
            취소하기
          </button>
          <button
            class="w-[226px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[40px]"
            @click="handleReview()"
          >
            <p v-if="!item.reviewed">작성하기</p>
            <p v-else>수정하기</p>
          </button>
        </div>
        <!-- 하단 -->
        <hr class="mt-[31px] mx-2" />
        <div class="flex justify-end items-center mt-[20px] mb-[25px] mr-[21.5px]">
          <button class="text-[14px] text-[#6D6D6D] font-notosansCJKmd">
            리뷰 작성시 유의사항
          </button>
          <i class="pi pi-chevron-right text-[12px] ml-[6px]"></i>
        </div>
      </section>
      <!-- 주문 카드  -->
    </div>
  </div>
</template>

<script setup>
import 'primeicons/primeicons.css'
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useUserStore } from '../../../../stores/useUser'
import { useSystemStore } from '../../../../stores/useSystem'
import { sendRequest } from '../../../../api/serviceApi'
import { useOrderStore } from '../../../../stores/userOrder'
import reviewModalImgAddr from './reviewModalImgAddr.vue'
import reviewCation from './reviewCation.vue'
import Rating from 'primevue/rating'
import dayjs from 'dayjs'

const order = useOrderStore()
const item = order.orderDetailData.orderDetailData

const system = useSystemStore()
const user = useUserStore()

const cautionStatus = ref(false)

const reloate = ref('')

const props = defineProps({
  item: Object
})

// const reviewImages = ref([])
const handleUpdateImages = (updatedImages) => {
  system.reviewImgArray = updatedImages
}

watchEffect(() => {
  if (!cautionStatus.value) {
    reloate.value = 'animate-rotateRevers'
  } else {
    reloate.value = 'animate-rotate'
  }
})

// const handleData = (data) => {
//   payload.value.reviewImgs = data
// }

// const { textarea, input } = useTextareaAutosize({ styleProp: 'minHeight' })

// const day = order.orderDetailData.orderData.orderDate

const maxLines = 8

const payload = ref({
  orderDate: order.orderDetailData.orderData.orderDate,
  reviewDate: system.serverTime,
  productId: order.orderDetailData.orderDetailData._id,
  orderData: {
    company: order.orderDetailData.orderDetailData.company,
    title: order.orderDetailData.orderDetailData.title,
    orderNumber: order.orderDetailData.orderDetailData.orderNumber,
    orderDetailNumber: order.orderDetailData.orderDetailData.orderDetailNumber,
    options: order.orderDetailData.orderDetailData.options,
    productImg: order.orderDetailData.orderDetailData.thumbImg,
    price: order.orderDetailData.orderDetailData.price
  },
  userData: {
    meId: user.me._id,
    names: user.me.name,
    userAvatar: user.me.avatar
  },
  reviewData: {
    reviewDetail: '',
    reviewRate: 5,
    reviewImg: system.reviewImgArray
  }
})

const limitLines = (event) => {
  const textArea = event.target
  const lines = textArea.value.split('\n')
  if (lines.length > maxLines) {
    textArea.value = lines.slice(0, maxLines).join('\n')
  }
}

const orderStatusPatch = async (status) => {
  try {
    // 주문 상태 패치
    await order.patchOrders(
      order.orderDetailData.orderDetailData.orderNumber,
      order.orderDetailData.orderDetailData.orderDetailNumber,
      status
    )
    console.log('주문 상태 패치 성공')
  } catch (error) {
    console.error('주문 상태 패치 실패:', error)
  }
}

const handleReview = async () => {
  // payload.value.reviewData.reviewImg = 'null' // 이미지 땜빵 CDN복구후
  const confirmMessage = item.reviewed ? '리뷰를 수정하시겠어요?' : '리뷰를 작성하시겠어요?' // true이면 작성됨

  const postConfirm = confirm(confirmMessage)
  if (!postConfirm) {
    return
  }

  const method = item.reviewed ? 'patch' : 'post'
  const path = item.reviewed ? '/review/patchRivew' : '/review/postReview' // patch 경로 확인
  const successMessage = item.reviewed
    ? '고객님의 소중한 리뷰가 수정되었습니다.'
    : '고객님의 소중한 리뷰가 등록되었습니다.'

  try {
    const postReview = await sendRequest({
      method,
      path,
      data: payload.value
    })

    if (item.status === 'exchangeShipping' && !item.reviewed) {
      orderStatusPatch('exchangeDone')
    }
    // 리뷰 작성시 완료 변경
    if (!item.reviewed) {
      orderStatusPatch('done')
    }

    const result = postReview.result
    // console.log(result)
    if (result === 'ok') {
      // if (item.reviewed === false) {
      //   // 리뷰 작성일 경우
      //   const orderPatchData = {
      //     orderNumber: item.orderNumber,
      //     orderDetailNumber: item.orderDetailNumber,
      //     orderDoneDate: system.serverTime
      //   }
      //   const patcher = await order.orderMultiPatcher(orderPatchData)

      //   if (patcher === 'ok') {
      //     // alert(successMessage)
      //     system.reviewModal = false
      //     location.reload()
      //   }
      // }
      alert(successMessage)
      system.reviewModal = false // 모달 닫기
      location.reload()
    }
  } catch (error) {
    console.error('리뷰 처리 중 오류 발생:', error)
  }
}

const getReviewDatas = async () => {
  const sendData = {
    orderDetailNumber: order.orderFilterData.odn
  }

  const getReviewPatch = await sendRequest({
    method: 'post',
    path: '/review/getOneReviewByOdn',
    data: sendData
  })
  const result = getReviewPatch.result
  console.log(result)
}

// onMounted에서 수정 모드일 때 데이터 미리 로드
onMounted(() => {
  if (system.reviewEdit === true) {
    payload.value.reviewData = system.reviewData.reviewData
    console.log(payload.value.reviewData.reviewImg)
  }
  if (item.reviewed) {
    getReviewDatas()
  }
})

onUnmounted(() => {
  system.reviewData = null
  system.reviewEdit = false
  order.orderFilterData = {
    on: '',
    odn: '',
    company: '',
    shipMethod: '',
    request: ''
  }
})
</script>

<style scoped>
.custom-rating .p-rating-icon {
  font-size: 24px !important; /* 중요도를 높여 강제로 적용 */
}
.p-rating-icon {
  @apply text-4xl; /* Tailwind의 text-4xl 클래스를 사용해 아이콘 크기를 설정 */
  --p-rating-icon-size: 2rem; /* 추가로 Design Tokens를 사용해 크기를 조정 */
}

.hide-scrollbar {
  overflow-y: scroll; /* 세로 스크롤은 가능하지만 */
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>
