<template>
  <div class="md:w-[292px] md:h-[370px] border border-[#D5D5D5] rounded-md">
    <section class="mt-[10px] md:mt-[21px] mx-[13px] md:mx-[15px]">
      <p class="text-[13px] md:text-[18px] font-notosansCJKmd text-[#232323] md:text-[#6D6D6D]">
        고객센터
      </p>
      <hr class="mt-[14px] hidden md:block" />
    </section>
    <!-- 모바일 상단 -->
    <section class="mx-3 md:hidden">
      <div class="mt-[14px] flex items-start justify-between">
        <!-- 이미지 어레이 -->
        <div class="flex items-center">
          <img class="w-[56px] h-[56px]" src="/img/support/furniture.webp" alt="furniture" />
          <img
            class="ml-[10px] w-[52px] h-[52px]"
            src="/img/support/wooden-chair.webp"
            alt="wooden"
          />
          <img
            class="ml-[10px] w-[52px] h-[52px]"
            src="/img/support/cupboard.webp"
            alt="cupboard"
          />
        </div>
        <!-- 정보 표시 어레이 -->
        <div class="ml-[17px]">
          <p class="text-[16px] font-notosansCJKmd text-[#6D6D6D]">10:30 ~ 18:00</p>
          <p class="text-[14px] font-notosansCJKmd text-[#232323] ml-[5px]">063-278-7781</p>
          <div class="ml-[5px] mt-[4px]">
            <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D]">상담가능시간 : 평일</p>
            <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D]">주말/공휴일 : 휴무</p>
          </div>
        </div>
      </div>
    </section>
    <!-- 모바일 상담 버튼 -->
    <section class="md:hidden mx-2 mb-[18px] mt-[24px]">
      <button
        class="w-full h-[36px] rounded-md border border-[#389033] mb-[8px]"
        @click="goToContact()"
      >
        <p class="ml-[12px] text-[12px] font-notosansCJKmd text-[#389033]">1:1 문의하기</p>
      </button>
      <button
        class="flex justify-center rounded-md items-center w-full h-[36px] bg-[#FFE97A]"
        @click="openKakaoinq()"
      >
        <img src="/img/support/pngwing.com.webp" alt="pngwing" class="h-[17px]" />
        <p class="ml-[9px] text-[12px] font-notosansCJKmd text-[#232323]">카카오톡 1:1 문의</p>
      </button>
    </section>

    <section class="mt-[18px] mr-[30px] hidden md:block">
      <div class="flex justify-end text-right">
        <div>
          <div class="flex items-center justify-end">
            <img src="/img/support/customer-service-headset.webp" class="w-[24px]" alt="" />
            <p class="text-[22px] font-notosansCJKmd text-[#232323] ml-[7px]">063-278-7781</p>
          </div>
          <p class="text-[20px] font-notosansCJKmd text-[#6D6D6D]">10:30 ~ 18:00</p>
          <p class="mt-[11px] text-[15px] font-notosansCJKmd text-[#6D6D6D]">상담가능시간 : 평일</p>
          <p class="text-[15px] font-notosansCJKmd text-[#6D6D6D]">주말/공휴일 : 휴무</p>
        </div>
      </div>
    </section>
    <section class="mt-[46px] hidden md:block">
      <div class="w-[247px] mx-auto">
        <button
          class="w-[247px] h-[45px] rounded-md border border-[#389033]"
          @click="goToContact()"
        >
          <p>1:1 문의하기</p>
        </button>
      </div>
      <div class="w-[247px] mt-[13px] mx-auto">
        <button
          class="flex justify-center rounded-md items-center w-[247px] h-[45px] bg-[#FFE97A]"
          @click="openKakaoinq()"
        >
          <img src="/img/support/pngwing.com.webp" alt="" />
          <p class="ml-[12px] text-[15px] font-notosansCJKmd text-[#232323]">카카오톡 1:1 문의</p>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

const goToContact = () => {
  router.push('/account/contact')
}

const openKakaoinq = () => {
  const chatConfirm = confirm('카카오톡 1:1 문의를 시작합니다')
  if (!chatConfirm) {
    return
  }
  const url = 'http://pf.kakao.com/_wxhkxhn/chat'
  window.open(url) // 새 창으로 열기
}
</script>

<style lang="scss" scoped></style>
