<template>
  <div class="border-t-[1px] lg:border-t-0 lg:border-b-[1px] border-[#D9D9D9]">
    <!-- 상품 데이터 -->
    <div class="flex my-[18.8px]">
      <!-- 이미지 -->
      <div class="flex lg:items-center ml-[12.5px] lg:ml-[17px]">
        <router-link :to="`/product/${item.products._id}`">
          <img
            :src="item.products.thumb_img"
            class="w-[76.39px] h-[95.49px] lg:w-[94px] lg:h-[117px] object-cover object-center"
            alt=""
          />
        </router-link>
      </div>
      <!-- 상세 -->
      <div class="ml-[18.5px] w-full">
        <!-- 상품 이름 -->
        <p class="text-[15px] lg:text-[18px] font-notosansCJKmd text-[#171717] mb-[14px]">
          <router-link :to="`/product/${item.products._id}`">
            {{ item.products.title }}
          </router-link>
        </p>
        <!-- 상품 옵션 -->
        <div
          v-for="(options, e) in item.itemOptions"
          :key="'options' + e"
          class="mb-[18.8px] w-full"
        >
          <cartItemOptions :options="options" :isOrder="isOrder" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import cartItemOptions from './cartItemOptions.vue'

const router = useRouter()
const props = defineProps({
  item: Object,
  isOrder: Boolean
})

const goDetail = (linkId) => {
  router.push(`/product/${linkId}`)
}
</script>

<style lang="scss" scoped></style>
