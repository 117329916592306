<template>
  <div v-if="order" class="lg:w-[1180px] lg:mx-auto h-full mt-0 lg:mt-[58px]">
    <reviewModal v-if="system.reviewModal" />
    <cancleModalArea v-if="system.cancleModal" />
    <refundModalArea v-if="system.refundModal" />
    <exchangeModalArea v-if="system.exchangeModal" />
    <changePasswordModalMain v-if="system.changePasswordModal" />
    <orderInquirieModalArea
      v-if="system.orderInquirieModal"
      style="max-height: calc(); overflow-x: hidden; overflow-y: auto"
    />
    <productInquiryUserModal v-if="system.inquiryModals" :isEdit="true" />
    <changeMobileModal v-if="system.changeMobileModal" />
    <div class="mx-auto">
      <!-- 플렉스 박스 -->
      <div class="md:flex">
        <!-- 좌측 메뉴 -->
        <div v-if="!system.isPrint" class="hidden lg:block">
          <accountMenuBox class="menuBox" />
        </div>
        <!-- 오른쪽 컨테이너 -->
        <div class="lg:ml-[30px] w-full md:mx-3 lg:mx-0">
          <accountUserInfo
            v-if="route.path === '/account/main' || route.path === '/account/support'"
          />

          <div>
            <div class="mb-[50px]">
              <component
                v-if="isComponentLoaded && currentComponent"
                :is="currentComponent"
                class="ease-in-out"
              />
              <div v-else class="text-center mt-52">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"> </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="route.params.id !== 'main'" class="lg:hidden mr-3">
        <accountMenuBox v-if="!system.isPrint" class="menuBox" />
      </div>
    </div>
    <!-- 컴포넌트가 로딩 중인지 확인하여 처리 -->
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, shallowRef, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import accountMenuBox from '../../components/account/accountMenuBox.vue'
import accountUserInfo from '../../components/account/accountUserInfo.vue'
import { useOrderStore } from '../../stores/userOrder'
import { useSystemStore } from '../../stores/useSystem'
import { useUserStore } from '../../stores/useUser'

// 모달창
import cancleModalArea from '../../components/account/modal/cancleModal/cancleModalArea.vue'
import refundModalArea from '../../components/account/modal/refundModal/refundModalArea.vue'
import reviewModal from '../../components/account/modal/reviewModal/reviewModal.vue'
import exchangeModalArea from '../../components/account/modal/exchangeModal/exchangeModalArea.vue'
import orderInquirieModalArea from '../../components/account/modal/orderInquirieModal/orderInquirieModalArea.vue'
import changePasswordModalMain from '../../components/account/modal/changPasswordModal/changePasswordModalMain.vue'
import productInquiryUserModal from '../../components/prodcut/productDescription/productInquiry/productInquiryUserModal.vue'
import changeMobileModal from '../../components/account/modal/changeMobileModal/changeMobileModal.vue'

const user = useUserStore()
const system = useSystemStore()
const router = useRouter()
const route = useRoute()
const order = useOrderStore()
const pageReady = ref(false)

const isComponentLoaded = shallowRef(false)
const currentComponent = shallowRef(null)

// 컴포넌트 맵핑
const componentsMap = {
  // 기본페이지
  main: () => import('../../components/account/main/accountMainTest.vue'),
  orderDetails: () => import('../../components/account/orderDetails/orderDetailsMain.vue'), // 주문 상세
  noticeDetail: () => import('../../components/account/noticeDetail/noticeDetailMain.vue'), // 공지 상세
  qna: () => import('../../components/account/qnaBoard/accountQnaBoardMain.vue'),
  billing: () => import('../../components/account/billing/accountBillingMain.vue'),

  // 주문정보
  orders: () => import('../../components/account/orders/accountOrdersMain.vue'), // 주문내역·배송조회
  returns: () => import('../../components/account/returns/accountReturnsMain.vue'), // 주문취소·반품·교환내역
  //나의 활동
  writeReview: () => import('../../components/account/reviews/accountWriteReview.vue'), //  리뷰작성
  myReviews: () => import('../../components/account/reviews/accountMyReview.vue'), //  내가 쓴 리뷰
  inquiries: () => import('../../components/account/inquiries/accountInquiries.vue'), // 상품 문의
  // 내 정보

  address: () => import('../../components/account/address/accountAddressMain.vue'), // 배송지 관리
  profile: () => import('../../components/account/profile/profileMain.vue'), // 회원정보 관리
  points: () => import('../../components/account/points/accountPointsMain.vue'), //포인트 내역
  // 고객센터
  contact: () => import('../../components/account/contact/accountContactMain.vue'), // 1:1문의하기
  history: () => import('../../components/account/history/accountHistoryMain.vue'), //문의내역
  notices: () => import('../../components/account/notices/accountNoticesMain.vue'), // 공지사항
  support: () => import('../../components/account/support/supportMain.vue') // 고객센터
}

// 현재 경로 및 컴포넌트 동적 로딩 처리
watchEffect(async () => {
  isComponentLoaded.value = false
  const loadComponent = componentsMap[route.params.id]

  if (loadComponent) {
    try {
      const componentModule = await loadComponent()
      currentComponent.value = componentModule.default || componentModule
      isComponentLoaded.value = true
      // setTimeout(() => {}, 150)
    } catch (error) {
      console.error('컴포넌트를 로드하는 중 오류가 발생했습니다:', error)
      router.push('/error')
    }
  } else {
    router.push('/error')
  }
})

onMounted(() => {
  pageReady.value = true
})

onUnmounted(() => {
  // order = null
})

watchEffect(() => {
  if (pageReady.value) {
    order.getOrder('getOrderByUserIds')
  }
})

watch(
  () => user.auth,
  (auth) => {
    if (auth === null) {
      const redirectPath = router.currentRoute.value.fullPath // 현재 경로 가져오기
      router.push({
        path: '/login',
        query: { redirect: redirectPath } // 쿼리 파라미터로 전달
      })
    }
  }
)
</script>

<style lang="scss" scoped>
.modal {
  display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}
.modal-body {
  height: 80vh;
  overflow-y: auto;
}

@media print {
  .menuBox {
    display: none; /* 프린트 시 네비게이션 바와 푸터 숨기기 */
  }

  /* 프린트 시 본문 내용이 페이지 전체를 차지하도록 */
  main {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
