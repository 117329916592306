<template>
  <div v-if="pageReady">
    <div class="lg:w-[1180px] lg:mx-auto h-full mt-0 lg:mt-[36px]">
      <billingMain :orderData="orderDatas" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import { sendRequest } from '../../api/serviceApi'
import billingMain from '../../components/billing/billingMain.vue'

const pageReady = ref(false)
const route = useRoute()
const orderDatas = ref()

const getOrderDatas = async () => {
  const payload = {
    orderNumber: route.query.keyword
  }
  const getData = await sendRequest({
    method: 'post',
    path: '/order/getOrderByOrderNumber',
    data: payload
  })
  console.log(getData)
  orderDatas.value = getData.data[0]
}

onMounted(() => {
  pageReady.value = true
})

watchEffect(() => {
  if (pageReady.value) {
    getOrderDatas()
  }
})
</script>

<style lang="scss" scoped></style>
