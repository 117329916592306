<template>
  <div class="w-full h-full">
    <div class="w-[510px] hidden md:block">
      <div class="w-[510px] h-[585px] flex items-top justify-end flex-shrink-0">
        <img
          :src="currentPic.img"
          :key="currentPic.img"
          alt=""
          class="w-full h-full object-cover rounded-md"
        />
      </div>
      <div class="flex mt-[18px] overflow-x-auto">
        <div
          v-for="(imgs, index) in reviewImgArray"
          :key="index"
          class="w-[66px] h-[66px] mr-[10px] flex-shrink-0"
          @click="chngCurrentPic(imgs, index)"
        >
          <img :src="imgs" alt="" class="w-full h-full object-cover rounded-sm" />
        </div>
      </div>
    </div>
    <!-- mobileSwiper -->
    <div class="md:hidden w-full h-full aspect-375/429">
      <swiper
        :pagination="{
          clickable: true
        }"
        :modules="modules"
        class="mySwiper h-full"
        :grabCursor="true"
      >
        <swiper-slide
          v-for="(item, index) in reviewImgArray"
          :key="'img' + index"
          class="w-full h-full"
        >
          <img :src="item" alt="" class="w-full h-full object-cover object-center" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
const modules = [Pagination]

const props = defineProps({
  imgArray: Object
})

const currentPic = ref({
  img: '',
  index: 0
})
const reviewImgArray = ref([])

const chngCurrentPic = (imgs, index) => {
  currentPic.value.img = imgs
  currentPic.value.index = index
}

watchEffect(() => {
  if (props.imgArray) {
    currentPic.value.img = props.imgArray.img[0]
    reviewImgArray.value = props.imgArray.img
  }
})
</script>

<style scoped>
.swiper-pagination {
  bottom: 10px; /* 페이지네이션 위치 조정 */
}

.swiper-pagination-bullet .swiper-pagination-bullet-active {
  background: blueviolet;
}
</style>
