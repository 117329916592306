<template>
  <div>
    <div class="h-auto sm:flex justify-start items-center flex mt-[6px]">
      <div
        v-for="menu in menuList"
        :key="menu.title"
        class="cursor-pointer"
        @click="modalController(menu.logic, menu.route)"
      >
        <!-- <svg class="w-[20px] h-[20px] menuIcon"></svg> -->
        <div v-if="menu.auth === isLogin" class="mr-[59px] relative">
          <div class="mx-auto"></div>
          <p class="font-notosansCJK text-[13px] text-[#3A3A3A] mt-[2px]"></p>
          <div class="flex justify-center">
            <i :class="menu.icon" style="font-size: 19px"></i>
          </div>
          <p class="text-[10px] font-notosansCJK text-[#3A3A3A] mt-[5.3px]">
            {{ menu.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '../../stores/useUser'
import { useSystemStore } from '../../stores/useSystem'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'

const cookies = useCookies() // 쿠키 초기화
const system = useSystemStore()
const router = useRouter()
const user = useUserStore()

const isLogin = ref(false)

const purgeCookie = () => {
  system.mobileMenu = false
  cookies.remove('yeondeul', { path: '/' })
  router.push('/login').then(() => {
    location.reload()
  })
}

const modalController = (logic, route) => {
  if (!route) {
    console.log('Route is empty, defaulting to home')
    route = '/login' // 기본 경로로 설정
  }
  system.mobileMenu = false
  switch (logic) {
    case 'login':
      return router.push('/login')
    case 'signUp':
      return router.push('/register')
    case 'logout':
      purgeCookie()
      break // 여기에 break 추가
    case 'goCart':
      return router.push('/cart')
    case 'goHome':
      return router.push('/')
    case 'account':
      if (user?.me?._id) {
        return router.push(`${route}/main`)
      } else {
        alert('마이페이지는 로그인 후 사용이 가능합니다.')
        router.push('/')
        return
      }
    case 'support':
      if (user?.me?._id) {
        return router.push('/account/support')
      } else {
        return console.log('userNotFound')
      }
    default:
      return router.push(`${route}`)
  }
}

const menuList = [
  {
    title: '로그인',
    route: '',
    icon: 'pi pi-sign-in',
    auth: false,
    logic: 'login'
  },

  {
    title: '회원가입',
    route: '',
    icon: 'pi pi-user-plus',
    auth: false,
    logic: 'signUp'
  },
  {
    title: '로그아웃',
    route: '',
    icon: 'pi pi-sign-out',
    auth: true,
    logic: 'logout'
  },
  {
    title: '연들홈',
    route: '',
    icon: 'pi pi-home',
    auth: true,
    logic: 'goHome'
  },
  {
    title: '장바구니',
    route: '',
    icon: 'pi pi-shopping-cart',
    auth: true,
    logic: 'goCart'
  },
  {
    title: '장바구니',
    route: '',
    icon: 'pi pi-shopping-cart',
    auth: false,
    logic: 'goCart'
  },
  {
    title: '마이페이지',
    route: '/account',
    icon: 'pi pi-user-edit',
    auth: true,
    logic: 'account'
  },
  {
    title: '마이페이지',
    route: '/account',
    icon: 'pi pi-user-edit ',
    auth: false,
    logic: 'account'
  }
]

watchEffect(() => {
  if (user.token) {
    isLogin.value = true
  } else if (user.token === null || user.token === '') {
    isLogin.value = false
  }
})
</script>

<style lang="scss" scoped></style>
