<template>
  <div>
    <section class="hidden md:block">
      <div
        class="h-[370px] lg:h-[430px] w-full lg:w-[1200px] mx-auto bg-[url('/img/main/main_bottm_banner.webp')] bg-cover bg-top relative"
      >
        <p
          class="font-Unggungqui hidden lg:block text-white text-right text-[48px] absolute top-[10px] right-[20px] lg:right-[39px] lg:top-[34px]"
        >
          Yeondeul Signiture
        </p>
        <p
          class="font-Unggungqui lg:hidden text-white text-right text-[48px] absolute top-[10px] right-[45px] lg:right-[39px] lg:top-[34px]"
        >
          Yeondeul
        </p>
        <p
          class="font-Unggungqui lg:hidden text-white text-right text-[48px] absolute top-[50px] right-[22px] lg:right-[39px] lg:top-[34px]"
        >
          Signiture
        </p>
        <p
          class="text-white text-right text-[20px] absolute right-[39px] top-[104px] hidden lg:block"
        >
          격을 높히다, 연들 시그니처 라인업
        </p>
        <p class="text-white text-right text-[20px] absolute right-[25px] bottom-[80px] lg:hidden">
          격을 높히다,
        </p>
        <p class="text-white text-right text-[20px] absolute right-[30px] bottom-[55px] lg:hidden">
          연들 시그니처 라인업
        </p>
        <p
          class="text-white text-right text-[16px] lg:text-[18px] absolute right-[30px] lg:right-[39px] bottom-[20px]"
        >
          2024 SS 시즌 고재의자 신상품
        </p>
      </div>
    </section>
    <!-- mobile -->
    <section class="md:hidden mx-[10px]">
      <div>
        <img
          src="/img/main/newGenMainBottomMobile.webp"
          alt=""
          class="aspect-356/475 w-full rounded-lg"
        />
      </div>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
