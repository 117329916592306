<template>
  <div class="border-t border-[#D23F3F] bg-white">
    <section class="">
      <div class="w-full">
        <div
          v-for="(listItem, index) in visibleItems"
          :key="listItem._id + index"
          class="border-b h-[59px] bg-white flex"
        >
          <section class="w-full">
            <div class="flex items-center justify-between mt-[15px] mx-3">
              <div class="">
                <p class="text-[12px] text-[#707070] font-notosansCJR">
                  {{ dayjs(listItem.createAt).format('YYYY/MM/DD') }}
                </p>
                <router-link
                  :to="{
                    path: `${pathSelector}`,
                    query: { ad: listItem._id, type: `${type}` }
                  }"
                >
                  <p class="text-[13px] text-[#232323] font-notosansCJKmd">
                    {{ listItem.boardTitle }}
                  </p>
                </router-link>
              </div>
              <div>
                <i class="pi pi-angle-right text-[#707070]"></i>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <!-- 더보기 버튼 -->
    <section v-if="!allItemsRendered">
      <div class="flex justify-center">
        <button
          @click="loadMore"
          class="w-[175px] h-[28px] border border-[#D5D5D5] rounded-md mt-[10px]"
        >
          <p class="text-[11px]">더보기</p>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { ref, computed } from 'vue'

// props로 받은 item 배열
const props = defineProps({
  item: {
    type: Array,
    default: () => [] // 기본값을 빈 배열로 설정하여 null 방지
  },
  readonly: Boolean,
  type: String,
  public: Boolean
})

const pathSelector = computed(() => {
  if (props.public) {
    return '/noticeDetail'
  } else {
    return '/account/noticeDetail'
  }
})

// 기본 10개 렌더링, 더보기 클릭 시 5개씩 추가
const itemsToShow = ref(10) // 처음 보여줄 item의 개수
const visibleItems = computed(() => props.item?.slice(0, itemsToShow.value) || []) // item이 null일 때 빈 배열 반환

// 모든 아이템을 렌더링 했는지 여부
const allItemsRendered = computed(
  () => Array.isArray(props.item) && itemsToShow.value >= props.item.length
)

// 더보기 버튼 클릭 시 5개씩 추가로 보여줌
const loadMore = () => {
  if (!allItemsRendered.value) {
    itemsToShow.value += 5
  }
}
</script>

<style lang="scss" scoped></style>
