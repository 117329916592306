<template>
  <div v-if="product.productItem" class="mx-2 lg:ml-[9px]">
    <p class="text-[18px] font-notosansCJKbold text-[#3D3D3D] hidden lg:block">배송·환불</p>
    <!-- 1. 배송비 안내 -->
    <hr class="lg:w-[813px] mt-[16px]" />

    <section>
      <!-- 1-1. 배송비 안내 -->
      <div class="flex lg:ml-[17px] mt-[24px]">
        <!-- 분류 -->
        <div>
          <p :class="leftTitle">배송</p>
          <p :class="leftTitle">배송비</p>
          <p :class="leftTitle" class="lg:pb-0">묶음배송가능여부</p>
          <p :class="leftTitle">도서 산간지역</p>
        </div>
        <!-- 내용 -->
        <div class="ml-[27px] lg:ml-[71px]">
          <p :class="detailText">{{ product.productItem.ship_method || 'SHIPPING_METHOD_NULL' }}</p>
          <p :class="detailText">
            선불 {{ product?.productItem?.ship_ammount.toLocaleString('kr-KO') || 0 }}원
          </p>
          <p :class="detailText">
            {{ product.productItem.company || 'SHIPPING_COMPANY_NULL' }} 택배배송 상품과 묶음배송
          </p>
          <p :class="detailText">도서 산간지역 추가배송비 4,000원 · 제주지역 추가배송비 4,000원</p>
        </div>
      </div>
      <hr class="lg:w-[813px]" />
      <div class="flex lg:ml-[15px] my-[24px]">
        <!-- 분류 -->
        <div>
          <p :class="leftTitle" class="">반품·교환배송비</p>
        </div>
        <!-- 내용 -->
        <div class="ml-[19px] lg:ml-[78px]">
          <p :class="detailText">
            반품배송비
            {{
              product.productItem.extrafee?.refundPrice.toLocaleString('kr-KO') ||
              product.productItem.ship_ammount.toLocaleString('kr-KO')
            }}원 · 교환배송비
            {{
              product.productItem.extrafee?.changePrcie.toLocaleString('kr-KO') ||
              (product.productItem.ship_ammount * 2).toLocaleString('kr-KO')
            }}원
          </p>
        </div>
      </div>
    </section>
    <!-- 2.  교환 반품 요창 가능 기간 안내 -->
    <section class="lg:ml-[14px]">
      <section>
        <div v-for="(guide, index) in refundGuide" :key="'guide' + index" class="mb-[53px]">
          <p :class="guideTitle">{{ guide.title }}</p>
          <div v-for="detail in guide.des" :key="detail.left">
            <p :class="`${guideDes} ${detail.style}`" class="mb-[2px]">
              {{ detail.left }}
              <span :class="guideDesLittle"> {{ detail.right }} </span>
            </p>
          </div>
        </div>
      </section>
    </section>
    <!-- 3. 시공안내 -->
    <section class="lg:ml-[9px]">
      <div>
        <p
          class="text-[14px] lg:text-[18px] font-notosansCJKbold text-[#3D3D3D] mb-[12.6px] lg:mb-[15px]"
        >
          시공안내
        </p>
        <hr class="lg:w-[813px] mb-[16px]" />
        <p :class="guideTitle" class="mb-[15.2px] ml-[18px] font-notosansCJKmd text-[#232323]">
          ※ 시공 상품의 경우 구매자께서 미리 정리 후 공간을 확보 해주셔야합니다
        </p>
        <div class="lg:ml-[12.5px]">
          <p :class="`${guideDes}`" class="">
            전문가가 직접 배송 및 시공해드립니다. 시공이 어렵거나 거절하실 경우 반품 비용이 발생할
            수 있습니다.
          </p>
          <p :class="`${guideDes}`" class="mb-[10px]">
            정확한 확인이 어려운 경우 연들 고객센터로 연락주시면 도움을 드리도록 하겠습니다.
          </p>
          <p :class="`${guideDes}`" class="">
            엘리베이터 이용이 어려운 경우 사다리차 비용이 추가로 발생할 수 있으며 엘리베이터가 없는
            3층 이상의 경우
          </p>
          <p :class="`${guideDes}`" class="">계단운반 비용이 발생할 수 있습니다.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import refundGuide from '../../../data/refundGuide'
import { useProductStore } from '../../../stores/useProduct'
const product = useProductStore()

const leftTitle =
  'font-notosansCJR text-[14px] lg:text-[16px] text-[#A5A5A5] mb-[22.3px] lg:mb-[24.2px]'
const detailText =
  'font-notosansCJR text-[14px] lg:text-[16px] text-[#6D6D6D] mb-[22.3px] lg:mb-[24.2px]'
const guideTitle = 'font-notosansCJKmd text-[13px] lg:text-[16px] text-[#232323] lg:mb-[7.5px]'
const guideDes = 'font-notosansCJR text-[12px] lg:text-[14px] text-[#6D6D6D] '
const guideDesLittle = 'font-notosansCJR text-[10px] lg:text-[12px] text-[#232323]'
</script>

<style lang="scss" scoped></style>
