<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div
      class="w-[365px] md:w-[800px] h-auto max-h-[80vh] bg-white rounded-xl overflow-y-clip hide-scrollbar"
    >
      <!-- 공통헤더 -->
      <section>
        <div
          class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
        >
          <p
            class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
          >
            반품 정보
          </p>
          <button class="absolute right-[15px] md:right-[31px]">
            <i
              class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
              @click="system.adminRefundModal = false"
            ></i>
          </button>
        </div>
        <hr class="mx-2" />
      </section>

      <section>
        <hr class="mx-2" />
        <!-- 버튼 제어 -->
        <div class="flex justify-center mb-[16px] mt-[16.5px]">
          <button
            class="w-[167px] md:w-[310px] h-[48px] md:h-[51px] border border-[#0C370A] rounded-md text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd"
            @click="system.adminRefundModal = false"
          >
            취소하기
          </button>
          <button
            class="w-[167px] md:w-[310px] h-[48px] md:h-[51px] bg-[#389033] border rounded-md text-[13px] md:text-[18px] text-white font-notosansCJKmd ml-2 md:ml-[40px]"
          >
            반품승인
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useOrderStore } from '../../../stores/userOrder'
import { useSystemStore } from '../../../stores/useSystem'
const order = useOrderStore()
const system = useSystemStore()
</script>

<style lang="scss" scoped></style>
