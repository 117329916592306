<template>
  <div>
    <div class="flex justify-center mt-[15.5px] md:mt-[59px]">
      <section>
        <div class="flex justify-center">
          <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">회원 정보 입력</p>
        </div>
        <div class="mt-[16px] md:mt-[17px]">
          <registerInfoBox />
        </div>
      </section>
    </div>
    <!-- 모바일 버튼 -->
    <section class="md:hidden">
      <div class="mt-[23.5px] md:mt-[42px] mb-0 md:mb-[191px]">
        <section class="">
          <div class="flex justify-center">
            <button
              class="border border-[#389033] md:border-[#0C370A] w-[320px] md:w-[356px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033] md:text-white bg-transparent md:bg-[#389033]"
              @click="emailChecker()"
            >
              다음 단계
            </button>
          </div>
          <p class="text-center text-[10px] font-notosansCJR text-[#6D6D6D] my-[27px]">
            ⓒ ruruci.inc All rights reserved.
          </p>
        </section>
      </div>
    </section>
    <section class="hidden md:block">
      <div class="mt-[23.5px] mb-[191px]">
        <section class="">
          <div class="flex justify-center">
            <button
              class="border border-[#D5D5D5] w-[226px] h-[51px] rounded-md text-[18px] font-notosansCJKmd text-[#6D6D6D] bg-transparent"
              @click="register.registStage = 0"
            >
              이전단계
            </button>
            <button
              class="border border-[#0C370A] bg-[#389033] w-[226px] h-[51px] rounded-md text-[18px] font-notosansCJKmd text-white ml-[40px]"
              @click="emailChecker()"
            >
              다음단계
            </button>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script setup>
import registerInfoBox from './registerInfoBox.vue'
import { useRegisterStore } from '../../stores/useRegister'
import axios from 'axios'
import { sendRequest } from '../../api/serviceApi'
const register = useRegisterStore()

const emailChecker = async () => {
  if (!register.validateEmail) {
    alert('올바른 이메일 형식이 아닙니다.')
    return
  }
  console.log('설정 이메일 주소', register.registerStage.stage1.userInfo.email)
  const payload = {
    email: register.registerStage.stage1.userInfo.email
  }
  const requestDupCheck = await sendRequest({
    method: 'post',
    path: '/users/emailChecker',
    data: payload
  })
  console.log('requestDupCheck', requestDupCheck)
  if (requestDupCheck.data === 'bad') {
    alert('이미 사용된 이메일 주소 입니다.')
    return
  } else if (requestDupCheck.data === 'isDelete') {
    alert('사용 불가능한 이메일 주소 입니다.')
    return
  } else {
    stepToStage()
  }
}

const stepToStage = () => {
  // 필수 입력 항목 확인
  const requiredFields = ['name', 'email', 'mobile', 'password', 'passwordCheck']

  // 누락된 항목이 있는지 확인
  let missingFields = requiredFields.filter(
    (field) => !register.registerStage.stage1.userInfo[field]
  )

  // 비밀번호 일치 여부 확인
  if (
    register.registerStage.stage1.userInfo.password !==
    register.registerStage.stage1.userInfo.passwordCheck
  ) {
    missingFields.push('passwordCheck')
  }

  // 필수 항목 검증
  if (missingFields.length > 0) {
    alert('필수 항목이 누락되었습니다. 다시 확인해주세요.')
    return
  }

  // 휴대폰 인증 여부 확인
  const isSmsAuthMissing = !register.smsAuth
  if (isSmsAuthMissing) {
    alert('휴대폰 인증이 필요합니다.')
    return
  }

  // 모든 검증 통과 시 다음 단계로 이동
  register.registStage = 2
}
</script>

<style lang="scss" scoped></style>
