<template>
  <div class="lg:w-[804px]">
    <!-- 1. 질문 -->
    <section class="flex">
      <!-- 1-1.질문 배너 왼쪽 -->
      <div
        class="w-[25px] lg:w-[40px] h-auto bg-[#F6F6F6] flex items-center justify-center rounded-md"
      >
        <p class="font-notosansCJR text-[13px] text-[#7D7D7D] vertical-rl lg:horizontal">질문</p>
      </div>
      <!-- 1-2. 오른쪽 -->
      <div class="ml-[9px] lg:ml-[15px] w-full">
        <!-- 1-2-1. 오른쪽 상단, 닉네임, 날짜, 답변여부 -->
        <div v-if="!isMypage" class="flex items-center justify-between lg:justify-start">
          <p class="text-[15px] font-notosansCJKlight text-[#1C1C1C]">
            {{ maskName(inquirys.userData.name) }}** 님
            <span class="ml-[13px] lg:ml-[33px] text-[#707070] font-notosansCJR">
              {{ dayjs(`${inquirys.createAt}`).format('YYYY.MM.DD') || 'date Null' }}
            </span>
          </p>
          <div
            v-if="inquirys.inquiryData.isAnswer"
            class="lg:ml-[21px] w-[65px] lg:w-[73px] h-[23px] bg-[#F6F6F6] flex items-center justify-center rounded-md"
          >
            <p class="text-[12px] lg:text-[13px] font-notosansCJKmd text-[#232323] rounded-md">
              답변완료
            </p>
          </div>
        </div>
        <!-- 1-2-2. 오른쪽 하단, 질문제목, 질문내용 -->
        <div class="mt-[9px] lg:w-[736px]">
          <p class="font-notosansCJKmd text-[15px] text-[#505050]">
            Q. {{ inquirys.inquiryData.inquiryTitle || 'inquiryTitle Null' }}
          </p>
          <p class="lg:ml-0 mt-2 mb-[32.8px] text-[15px] font-notosansCJKlight text-[#232323]">
            {{ inquirys.inquiryData.inquiryDetails || 'inquiryDetails Null' }}
          </p>
        </div>
      </div>
    </section>
    <!-- 2. 답변 -->
    <section v-if="inquirys.inquiryData.isAnswer" class="flex mt-[6px]">
      <!-- 2-1.답변 배너 왼쪽 -->
      <div
        class="w-[25px] lg:w-[37px] h-auto bg-[#F3EAEA] flex items-center justify-center rounded-md"
      >
        <p class="font-notosansCJR text-[13px] text-[#D23F3F] vertical-rl lg:horizontal">답변</p>
      </div>
      <!-- 2-2. 오른쪽 -->
      <div class="ml-[12px] w-[736px]">
        <!-- 2-2-1. 오른쪽 하단, 답변내용-->
        <div class="mt-[9px]">
          <p class="font-notosansCJKlight text-[15px] text-[#232323] mb-[16px]">
            {{ inquirys.inquiryData.answerDetails || 'answerDetails Null' }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import dayjs from 'dayjs'

const props = defineProps({
  inquirys: Object,
  isMypage: Boolean
})

function maskName(name) {
  if (name.length < 3) {
    return name.slice(0, 2) + '*'.repeat(2)
  } else if (name.length === 3) {
    return name.slice(0, 2) + '*'.repeat(1)
  } else {
    return name.slice(0, 3) + '*'.repeat(name.length - 3)
  }
}
// const inquirys = ref({
//   userName: '사용자',
//   createAt: '2023-07-05T07:35:41.344+0000',
//   inquiryTitle: '공구 필요한가요?',
//   inquiryDetails: '제품 구매하려고 하는데요. 따로 공구가 있어야만 설치가 가능한가요?',
//   isAnswer: true,
//   answerDetails:
//     '안녕하세요 고객님! 연들을 이용해주셔서 감사드립니다.저희는 미관상 더 예쁘면서 더욱 튼튼하기도 한 논프라피스를 제공해드리고 있습니다. 제품 구성품에 들어있는 나사 (논프라피스)를 벽에 부착하기 위해서는 구멍을 뚫을 수 있는 해머드릴이 필요합니다. 일반적인 6mm가 아닌 3.5mm 구멍을 내기에 일반적인 12v 드릴로도 충분히 작업이 가능합니다. 공구가 없으신 경우에는 공구를 구매하시거나 시공 서비스를 이용하셔야 한다는 점 양해 부탁드립니다. 감사합니다.'
// })
</script>

<style lang="scss" scoped></style>
