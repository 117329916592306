<template>
  <div>
    <section class="hidden md:block">
      <div class="border rounded-lg" :class="`${style.border}`">
        <div class="flex justify-between items-center mt-[26px] mx-[24px] mb-[28px]">
          <div class="flex">
            <router-link :to="`/product/${item._id}`">
              <img :src="item.thumbImg" alt="" class="w-[94px] h-[117px] rounded-md" />
            </router-link>
            <div class="ml-[13px]">
              <div class="flex">
                <p class="text-[16px] text-[#171717 font-notosansCJKmd mb-[2px]">
                  {{ item.company }} ({{ item.orderNumber }})
                </p>
              </div>
              <div>
                <p class="text-[18px] text-[#171717] font-notosansCJKmd">{{ item.title }}</p>
              </div>
              <p class="text-[15px] text-[#6D6D6D] mb-[2px] font-notosansCJKmd">
                {{ item.options.types }} - {{ item.options.color }}
              </p>
              <p class="text-[18px] text-[#171717] font-notosansCJKmd">
                {{ item.totalPrice.toLocaleString() }}원
              </p>
            </div>
          </div>
          <div class="mr-[18px]">
            <div class="text-right">
              <button>
                <i class="pi pi-check text-[24px] text-[#A8A8A8]" :class="`${style.text}`"></i>
              </button>
            </div>
            <!-- <modalEaCounter :ea="item.ea" @update:ea="handleEaUpdate" class="pt-10" /> -->
          </div>
        </div>
      </div>
    </section>
    <!-- 모바일 -->
    <section class="md:hidden">
      <div
        class="rounded-xl border flex flex-col justify-center mx-2 mb-[7px] bg-white"
        :class="`${style.border}`"
      >
        <div class="flex justify-between items-start ml-[11px] mt-[19px]">
          <!-- 우측 데이터 -->
          <div class="flex justify-between items-center w-full">
            <div class="flex w-full mb-[20px]">
              <img :src="item.thumbImg" alt="" class="w-[59px] h-[74px] rounded-md" />
              <div class="mx-[10px] mt-[2px] w-full">
                <div class="flex justify-between">
                  <p
                    class="text-[12px] md:text-[16px] text-[#6D6D6D] font-notosansCJKmd mb-[3px] md:mb-[2px]"
                  >
                    {{ item.company }} ({{ item.orderNumber }})
                  </p>
                  <i class="pi pi-check pr-3" :class="style.text"></i>
                </div>
                <div class="flex justify-between">
                  <div>
                    <p class="text-[14px] md:text-[18px] text-[#171717] font-notosansCJKmd">
                      {{ item.title }}
                    </p>
                    <p
                      class="text-[12px] md:text-[15px] text-[#6D6D6D] mb-[3px] md:mb-[2px] font-notosansCJKmd"
                    >
                      {{ item.options.types }}
                      <span class="font-notosansCJR">- {{ item.options.color }}</span>
                    </p>
                    <p class="text-[14px] text-[#171717] font-notosansCJKmd">
                      {{ item.totalPrice.toLocaleString() }}원
                    </p>
                  </div>
                  <!-- <modalEaCounter :ea="item.ea" @update:ea="handleEaUpdate" class="pt-10" /> -->
                </div>
              </div>
            </div>
          </div>
          <!-- 좌측 제어  -->
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { useOrderStore } from '../../../stores/userOrder'
import modalEaCounter from './modalEaCounter.vue'
import { computed, ref } from 'vue'
const order = useOrderStore()
const days = order.orderDetailData.orderData.orderDate

const props = defineProps({
  item: Object,
  style: Object
})

const currentEa = ref(1)

const handleEaUpdate = (newEa) => {
  console.log('자식으로 부터 받은 수량:', newEa)

  currentEa.value = newEa // 부모 컴포넌트의 수량을 업데이트
  console.log('부모에서 업데이트된 수량:', currentEa.value)
}

const resultPrice = computed(() => {
  return props.item.price * currentEa.value
})
</script>

<style lang="scss" scoped></style>
