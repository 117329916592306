<template>
  <div>
    <section>
      <p class="text-center text-[13px] md:text-[18px] font-notosansCJKmd text-[#232323]">
        새로운 비밀번호를 입력해주세요
      </p>
      <div class="mt-[19px] md:mt-[22px] flex justify-center">
        <section class="">
          <div>
            <input
              type="password"
              class="border w-[343.22px] md:w-[492px] h-[43px] md:h-[51px] rounded-md pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
              placeholder="새로운 비밀번호"
              v-model="password.passData.stage2.password"
            />
            <p v-if="passwordError.status" class="text-red-500 text-[11px] ml-2 mb-2">
              {{ passwordError.message }}
            </p>
            <input
              type="password"
              class="block border w-[343.22px] md:w-[492px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
              placeholder="새로운 비밀번호 확인"
              v-model="password.passData.stage2.passwordCheck"
            />
            <p v-if="passwordCheckError.status" class="text-red-500 text-[11px] ml-2 mb-2">
              {{ passwordCheckError.message }}
            </p>
          </div>
          <p class="mt-[9px] text-center md:text-right text-[10px] font-notosansCJR text-[#6D6D6D]">
            영문, 숫자 조합의 8자 이상의 비밀번호를 입력해주세요
          </p>
        </section>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { usePasswordStore } from '../../../../stores/usePassword'
const password = usePasswordStore()
const passwordError = ref({
  message: '',
  status: false
})

const passwordCheckError = ref({
  message: '',
  status: false
})

// 비밀번호 검증 함수
const validatePassword = async () => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
  if (!passwordPattern.test(password.passData.stage2.password)) {
    passwordError.value.message = '영문, 숫자 조합의 8자 이상의 비밀번호를 입력해주세요.'
    passwordError.value.status = true
  } else {
    passwordError.value.message = ''
    passwordError.value.status = false
  }
}

const validatePasswordCheck = async () => {
  const data = password.passData.stage2
  if (data.password !== data.passwordCheck) {
    passwordCheckError.value.message = '비밀번호가 일치하지 않습니다.'
    passwordCheckError.value.status = true
  } else {
    passwordCheckError.value.message = ''
    passwordCheckError.value.status = false
  }
}

// 비밀번호 입력 감시
watchEffect(() => {
  if (password.passData.stage2.password) {
    validatePassword()
  }
})

watchEffect(() => {
  if (password.passData.stage2.passwordCheck) {
    validatePasswordCheck()
  }
})

watchEffect(() => {
  if (passwordError.value.status || passwordCheckError.value.status) {
    password.validatePassword = false
  } else {
    password.validatePassword = true
  }
})
</script>

<style lang="scss" scoped></style>
