<template>
  <div class="">
    <!-- 1. 윗줄 아바타 닉네임 별점 -->
    <div class="flex items-center justify-between">
      <!-- 아바타 닉네암 -->
      <div class="flex items-center">
        <img
          :src="reviewDatas.userData.userAvatar"
          alt=""
          class="rounded-full w-[39.5px] h-[39.5px] bg-center bg-cover"
        />
        <p class="text-left font-notosansCJR text-[15px] text-[#1C1C1C] ml-[10.6px]">
          {{ reviewDatas.userData.names || 'userName Null' }}
        </p>
      </div>
      <div class="w-[92px] h-[14.5px] ml-[24px]">
        <div>
          <Rating
            v-model="reviewDatas.reviewData.reviewRate"
            :cancel="false"
            class="flex text-[#389033] text-[14.5px] space-x-[2.5px] custom-rating"
            :onIcon="'pi pi-star-fill text-[14.5px]'"
            :offIcon="'pi pi-star text-[14.5px] hidden'"
            readonly
          />
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-2">
      <section class="pl-[17px] w-[90%] mr-[8px]">
        <div class="bg-[#F8F8F8] h-[35px] flex items-center rounded-md">
          <!-- 2-1. 날짜, 옵션 -->
          <p class="font-notosansCJR text-[13px] text-[#707070] ml-[15px]">
            {{ dayjs(`${reviewDatas.reviewDate}`).format('YYYY.MM.DD') || 'date Null' }}
            {{ reviewDatas.orderData.options.color || 'options Null' }}
            {{ reviewDatas.orderData.options.types || 'options Null' }}
          </p>
        </div>
      </section>
    </div>
    <!-- 1-2. 리뷰 이미지 -->

    <div class="mt-[9.2px] -ml-[5px] mr-[11px] relative">
      <img
        v-if="reviewDatas.reviewData.reviewImg.length > 0"
        :src="reviewDatas.reviewData.reviewImg[0]"
        alt=""
        class="w-[100vw] h-[345px] objcet-center object-cover rounded-md cursor-pointer"
        @click="imgModal()"
      />
      <div
        class="h-[29.4px] w-[46.4px] bg-[#2E2E2E] absolute right-0 bottom-0 rounded-md flex justify-center items-center opacity-[93%]"
      >
        <p class="text-[16px] text-white">{{ reviewDatas.reviewData.reviewImg.length }}</p>
      </div>
    </div>
    <!-- 리뷰 내용 -->
    <section>
      <div class="mr-[25px]">
        <div class="mt-[9.2px]">
          <p class="text-[14px] text-[#232323] break-words">
            {{ reviewDatas.reviewData.reviewDetail }}
          </p>
        </div>

        <!-- 2. 오른쪽 날짜, 옵션, 별점, 상세 내용 -->
      </div>
    </section>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import Rating from 'primevue/rating'
import { useSystemStore } from '../../../../stores/useSystem'

const system = useSystemStore()
const props = defineProps({
  reviewDatas: Object
})

const imgModal = () => {
  system.reviewImgArray = props.reviewDatas.reviewData.reviewImg
  system.reviewImgModal = true
}

console.log('리뷰 모바일 로딩')
</script>

<style lang="scss" scoped></style>
