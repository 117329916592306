<template>
  <div v-if="item" class="w-[271px] max-h-[232px]">
    <img :src="item.mainImg" :alt="item.mainImg" class="h-[179px] rounded-md" />
    <p
      class="mt-[10px] md:mt-[8px] text-center font-notosansCJKmd text-[14px] md:text-[15px] text-[#232323]"
    >
      {{ item.title }}
    </p>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  item: Object
})
</script>

<style lang="scss" scoped></style>
