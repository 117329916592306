<template>
  <div class="border border-[#D5D5D5] rounded-lg w-[365px] md:w-[737px]">
    <div class="mt-[20.5px] md:mt-[36px] ml-[10px] md:ml-[83px] mb-[19px] md:mb-[53.5px]">
      <!-- 아이디 -->
      <section>
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">ID (이메일)</p>
          <input
            type="text"
            class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            placeholder="ID (이메일)"
            v-model="register.registerStage.stage1.userInfo.email"
          />
          <p v-if="emailError.status" class="text-red-500 text-[11px] ml-2 mb-2 mt-2">
            {{ emailError.message }}
          </p>
        </div>
      </section>
      <!-- 패스워드 -->
      <section class="mt-[23.5px] md:mt-[31.5px]">
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            비밀번호
            <span class="hidden ml-1 md:inline text-[13px] font-notosansCJR text-[#6D6D6D]"
              >영문, 숫자 조합의 8자 이상의 비밀번호를 입력해주세요</span
            >
          </p>
          <input
            type="password"
            class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            placeholder="비밀번호"
            v-model="register.registerStage.stage1.userInfo.password"
          />
          <p v-if="passwordError.status" class="text-red-500 text-[11px] ml-2 mb-2 mt-2">
            {{ passwordError.message }}
          </p>
          <input
            type="password"
            class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            placeholder="비밀번호 확인"
            v-model="register.registerStage.stage1.userInfo.passwordCheck"
          />
          <p v-if="passwordCheckError.status" class="text-red-500 text-[11px] ml-2 mb-2 mt-2">
            {{ passwordCheckError.message }}
          </p>
        </div>
      </section>
      <!-- 성함 -->
      <section class="mt-[23.5px] md:mt-[31.5px]">
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">성함</p>
          <input
            type="text"
            class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            placeholder="이름"
            v-model="register.registerStage.stage1.userInfo.name"
          />
        </div>
      </section>
      <!-- 성별, 생년월일 (선택) -->
      <section class="mt-[23.5px] md:mt-[66.5px] flex items-center">
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            성별
            <span class="text-[10px] md:text-[13px] font-notosansCJR text-[#6D6D6D]">(선택)</span>
          </p>
          <div class="mt-[6.5px] md:mt-[10.5px]">
            <button
              class="w-[55px] h-[38px] md:h-[51px] md:w-[96px] border border-[#D5D5D5] rounded-md"
              @click="gederSelector(0)"
              :class="genderSelectData[0].class"
            >
              <p
                class="text-[12px] text-[#707070] font-notosansCJR md:text-[18px] md:font-notosansCJKmd md:text-[#1C1C1C]"
              >
                남성
              </p>
            </button>
            <button
              class="ml-[10px] md:ml-[13px] w-[55px] h-[38px] md:h-[51px] md:w-[96px] border border-[#D5D5D5] rounded-md"
              @click="gederSelector(1)"
              :class="genderSelectData[1].class"
            >
              <p
                class="text-[12px] text-[#707070] font-notosansCJR md:text-[18px] md:font-notosansCJKmd md:text-[#1C1C1C]"
              >
                여성
              </p>
            </button>
          </div>
        </div>
        <div class="ml-[19.5px] md:ml-[40px]">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            생년월일
            <span class="text-[10px] md:text-[13px] font-notosansCJR text-[#6D6D6D]">(선택)</span>
          </p>
          <div class="mt-[6.5px] md:mt-[10.5px]">
            <input
              type="date"
              class="border px-3 w-[200px] md:w-[323px] h-[38px] md:h-[51px] rounded-md pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] border-[#D5D5D5]"
              placeholder="1988 / 02 / 22"
              v-model="register.registerStage.stage1.userInfo.birth"
            />
          </div>
        </div>
      </section>
      <!-- 인증 -->
      <section class="mt-[23.5px] md:mt-[31.5px]">
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            연락처 (휴대폰)
          </p>
          <div class="flex mt-[6.5px] md:mt-[13.5px]">
            <input
              type="text"
              class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] no-arrow"
              placeholder="- 을 제외한 휴대폰 번호 입력"
              v-model="register.registerStage.stage1.userInfo.mobile"
              @input="validatePhoneInput"
            />
            <button
              class="border border-[#389033] w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] text-[#389033] md:text-[18px] font-notosansCJKmd"
              @click="sendAuth()"
            >
              휴대폰 인증
            </button>
          </div>
        </div>
        <div v-if="register.sendSms" class="">
          <p
            class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323] md:hidden mt-[13.5px]"
          >
            휴대폰 인증번호 입력
          </p>
          <div class="flex mt-[6.5px] md:mt-[22.5px]">
            <input
              type="text"
              class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] no-arrow"
              placeholder="인증번호 입력"
              v-model="authInput"
              @input="validateOtpInput"
            />
            <button
              class="border w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] md:text-[18px] font-notosansCJKmd"
              :class="smsAuthBtn.class"
              @click="authDone()"
              :disabled="register.smsAuth"
            >
              {{ smsAuthBtn.text }}
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref, watchEffect } from 'vue'
import { useRegisterStore } from '../../stores/useRegister'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const register = useRegisterStore()

const genderSelectData = ref([
  {
    class: '',
    type: 'male'
  },
  {
    class: '',
    type: 'female'
  }
])

const gederSelector = (i) => {
  genderSelectData.value.forEach((selData) => {
    selData.class = ''
  })
  // selecteData.value = resetSelData
  genderSelectData.value[i].class = 'border-[2px] border-green-700'
  // genderData.value = genderSelectData.value[i].type
  register.registerStage.stage1.userInfo.gender = genderSelectData.value[i].type
}

// 입력 검증기
const emailError = ref({
  message: '',
  status: false
})

const passwordError = ref({
  message: '',
  status: false
})

const passwordCheckError = ref({
  message: '',
  status: false
})

const validateEmail = async () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!emailPattern.test(register.registerStage.stage1.userInfo.email)) {
    emailError.value.message = '올바른 이메일 형식이 아닙니다.'
    emailError.value.status = true
    register.validateEmail = false
  } else {
    emailError.value.message = ''
    emailError.value.status = false
    register.validateEmail = true
  }
}
// 비밀번호 검증 함수
const validatePassword = async () => {
  // 영문 소문자 또는 대문자, 숫자 필수. 특수문자는 선택, 8자 이상
  const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#]{8,}$/

  if (!passwordPattern.test(register.registerStage.stage1.userInfo.password)) {
    passwordError.value.message = '영문과 숫자를 포함한 8자 이상의 비밀번호를 입력해주세요.'
    passwordError.value.status = true
  } else {
    passwordError.value.message = ''
    passwordError.value.status = false
  }
}

const validatePasswordCheck = async () => {
  const data = register.registerStage.stage1.userInfo
  if (data.password !== data.passwordCheck) {
    passwordCheckError.value.message = '비밀번호가 일치하지 않습니다.'
    passwordCheckError.value.status = true
  } else {
    passwordCheckError.value.message = ''
    passwordCheckError.value.status = false
  }
}

watchEffect(() => {
  if (register.registerStage.stage1.userInfo.email) {
    validateEmail()
  }
})

// 비밀번호 입력 감시
watchEffect(() => {
  if (register.registerStage.stage1.userInfo.password) {
    validatePassword()
  }
})

watchEffect(() => {
  if (register.registerStage.stage1.userInfo.passwordCheck) {
    validatePasswordCheck()
  }
})

// 모바일
const validatePhoneInput = (event) => {
  // 숫자만 남기기 위해 입력 값에서 숫자 이외의 모든 문자 제거
  let onlyNumbers = event.target.value.replace(/[^0-9]/g, '')

  // 최대 11자리로 제한
  if (onlyNumbers.length > 11) {
    onlyNumbers = onlyNumbers.slice(0, 11)
  }

  register.registerStage.stage1.userInfo.mobile = onlyNumbers
}

const validateOtpInput = (event) => {
  // 숫자만 남기기 위해 입력 값에서 숫자 이외의 모든 문자 제거
  let onlyNumbers = event.target.value.replace(/[^0-9]/g, '')

  // 최대 11자리로 제한
  if (onlyNumbers.length > 6) {
    onlyNumbers = onlyNumbers.slice(0, 6)
  }

  authInput.value = onlyNumbers
}

// 인증기
const authInput = ref()
const smsAuthBtn = ref({ text: '확인', class: 'border-[#389033] text-[#389033] ' })

const sendAuth = async () => {
  if (register.smsAuth) {
    alert('인증이 완료된 전화번호 입니다.')
    return
  }
  try {
    const sendAuth = await axios({
      // url: 'https://yeondeul.com/servers/smsAuth',
      url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth`,
      method: 'POST',
      data: {
        mobile: register.registerStage.stage1.userInfo.mobile
      }
    })
    const result = sendAuth.data
    if (result === 'isDuplicate') {
      // console.log(sendAuth)
      alert('전송된 인증번호가 존재 합니다')
      return
    } else if (result === 'sendVerify') {
      alert('인증번호가 고객님의 휴대전화로 발송 되었습니다.')
      register.sendSms = true
    } else {
      alert(result)
      return
    }
  } catch (error) {}
}

const authDone = async () => {
  const confirmSmsAuth = await axios({
    // url: 'https://rurucidev2.com/servers/smsAuth/verifyAuth',
    url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth/verifyAuth`,
    method: 'POST',
    data: {
      mobile: register.registerStage.stage1.userInfo.mobile,
      otp: authInput.value
    }
  })
  // alert(confirmSmsAuth)
  if (confirmSmsAuth.data === true) {
    register.smsAuth = true
    smsAuthBtn.value.class = ''
    smsAuthBtn.value.text = '완료'
    alert('휴대폰 인증이 완료 되었습니다.')
  } else {
    alert('인증 번호가 틀렸습니다.')
  }
}
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
