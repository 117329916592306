<template>
  <div>
    <!-- 모바일 타이틀 -->
    <section class="lg:hidden mt-[25.3px] ml-[5px]">
      <div class="flex">
        <div>
          <img src="/img/main/storytelling.webp" class="w-[24px] h-[24px]" alt="" />
        </div>
        <div class="ml-[9px]">
          <p class="text-[17px] font-notosansCJKbold text-[#404040]">
            MAGAZINE, 연들 인테리어 이야기
          </p>
        </div>
      </div>
    </section>
    <!-- pc 타이틀 -->
    <section class="hidden lg:block">
      <!-- 상단타이틀 -->
      <section>
        <div class="">
          <p class="text-[16px] text-[#232323] font-notosansCJK ml-[7px]">연들 매거진</p>
          <hr class="border-[1px] border-[#E2E2E2] w-[150px] mt-[7px]" />
        </div>
      </section>
      <!-- 중간 영역 -->
      <section class="ml-[7px]">
        <div>
          <div class="w-[208px] h-[56px] flex items-center justify-center mt-[26px]">
            <p class="text-[38px] text-[#232323] font-notosansCJKlight tracking-widest">MAGAZINE</p>
          </div>
          <div class="mt-[40px] w-[171px] h-[98px]">
            <p
              v-for="(script, index) in middleScript"
              :key="index + 'mdscript'"
              class="text-[14px] font-notosansCJKlight mb-[4px] tracking-tight"
            >
              {{ script }}
            </p>
          </div>
        </div>
      </section>
      <!-- 바닥영역 -->
      <section class="mt-[59px] ml-[7px]">
        <button class="">
          <router-link to="/post">
            <p class="text-[13px] font-notosansCJKmd">MAGAZINE 바로가기</p>
          </router-link>
        </button>
      </section>
    </section>
  </div>
</template>

<script setup>
const middleScript = [
  '연들이 시공, 설계한 인테리어,',
  '새로 제작한 가구와 오브제,',
  '인테리어에 대한 팁까지',
  '연들 매거진에서 만나보세요'
]
</script>

<style lang="scss" scoped></style>
