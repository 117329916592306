<template>
  <div id="app" ref="appMain" class="bg-white">
    <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>
    </component>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { RouterView } from 'vue-router'
import { useSystemStore } from './stores/useSystem'
import { useUserStore } from './stores/useUser'
import { useCookies } from '@vueuse/integrations/useCookies'

const cookies = useCookies()
const user = useUserStore()
const system = useSystemStore()
const myJwt = cookies.get('yeondeul')

function openModal() {
  document.body.classList.add('overflow')
}

function closeModal() {
  document.body.classList.remove('overflow')
}

watchEffect(() => {
  if (
    system.registerModals ||
    system.loginModals ||
    system.inquiryModals ||
    system.reviewModal ||
    system.reviewImgModal ||
    system.cancleModal ||
    system.refundModal ||
    system.myAddrModal ||
    system.historyModal ||
    system.orderInquirieModal ||
    system.changePasswordModal ||
    system.mobileMenu ||
    system.adminShippingInputModal ||
    system.adminExchangeModal ||
    system.adminRefundModal ||
    system.exchangeModal ||
    system.addressListModal ||
    system.changeMobileModal
  ) {
    openModal()
  } else {
    closeModal()
  }
})
</script>

<style>
.background-fixed {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.overflow {
  overflow: hidden;
}
</style>
