<template>
  <div v-if="dataReady" class="" ref="shopMains">
    <div v-if="isSearch">
      <div class="ml-[21px] mt-[14px] lg:ml-[15px] lg:mt-[36px]">
        <searchNavBarVue @update-cmd="selectedCmd" :cmdChange="searchCmd" />
        <!-- {{ searchCmd }} -->
      </div>
      <div v-if="searchCmd === 'combine' || searchCmd === 'store'">
        <hr class="lg:w-[1180px]" />
        <div class="flex items-center mt-[46px] ml-[15px] mb-[27px]">
          <p class="text-[16px] lg:text-[20px] text-[#404040] font-nanumsquareEB">스토어</p>
          <p class="ml-[13px] text-[16px] lg:text-[18px] text-[#92CE8F] font-notosansCJKmd">
            {{ dataCount }}건
          </p>
        </div>
      </div>
    </div>
    <shopFilter
      v-if="searchCmd === 'combine' || searchCmd === 'store'"
      class="mb-[22px] ml-[13px] lg:ml-[20px] hidden lg:block"
      :noCategory="isSearch"
    />
    <!-- 상점 하단 -->
    <div v-if="dataCount === 0" class="">
      <p class="text-center my-[10em]">검색 결과가 없습니다</p>
    </div>
    <div
      v-if="searchCmd === 'combine' || searchCmd === 'store'"
      class="lg:ml-[21px] mt-[20px] lg:mt-0"
    >
      <div
        class="grid grid-cols-2 md:grid-cols-4 gap-y-[44px] gap-x-[10px] sm:gap-x-[22px] sm:gap-y-0 px-[13px] lg:px-5 lg:gap-y-0 lg:gap-x-0 mb-[74px]"
      >
        <div v-for="item in sortedData" :key="item._id" class="lg:mb-11">
          <shopCardGrid
            class="cursor-pointer lg:w-[251px] lg:h-[434px] md:mb-[64px] lg:mb-[32px]"
            :item="item"
            @click="router.push(`/product/${item._id}`)"
          ></shopCardGrid>
        </div>
      </div>
      <!-- <div v-for="items in sortedData" :key="items.title">
        {{ items.title }}
      </div> -->

      <!-- 페이징  -->
      <pagination
        v-show="width > 991"
        :numberOfData="6"
        :data="pageData"
        @update-data="handleData"
        slot=""
        class="mb-[144px]"
      />
      <!-- 검색 페이지에서 참조시 블로그 영역  -->
      <section v-if="isSearch && dataCount > 0" class="flex justify-center mb-[39px]">
        <button class="w-[253px] h-[51px] border border-solid-1 border-[#AAAAAA] rounded-md">
          <p class="text-[#7D7D7D] text-[15px] font-notosansCJKmd" @click="readMoreEvent('store')">
            상품 더보기
          </p>
        </button>
      </section>
    </div>
    <shopPostArea
      v-if="(dataCount > 0 && isSearch && searchCmd === 'combine') || searchCmd === 'post'"
      class="mb-[113px]"
      :keyword="searchKeyword"
    />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, watchEffect, onMounted, watch, computed } from 'vue'
import shopFilter from './shopFilter.vue'
import shopCard from './shopCard.vue'
import shopCardGrid from './shopCardGrid.vue'
import pagination from '../util/pagination.vue'
import { useProductStore } from '../../stores/useProduct'
import shopPostArea from './shopPost/shopPostArea.vue'
import searchNavBarVue from './searchNavBar.vue'

import { useElementSize } from '@vueuse/core'
const shopMains = ref(null)
const { width } = useElementSize(shopMains)

const props = defineProps({
  isSearch: Boolean,
  keyword: String,
  searchKeyword: String
})
const router = useRouter()
const dataReady = ref(false)
const pageData = ref([])
const dataCount = ref(0)
const product = useProductStore()
const childData = ref([])
const searchCmd = ref('combine') // 검색 유형 디폴트 값

const changeStore = ref('')
const changePost = ref('')

const selectedCmd = (data) => {
  searchCmd.value = data
}
// const system = useSystemStore()

const handleData = (data) => {
  childData.value = data
}

const readMoreEvent = (cmd) => {
  searchCmd.value = cmd
}

// watchEffect(async () => {
//   if (product.filteredProducts) {
//     dataCount.value = product.filteredProducts.length
//     pageData.value = product.filteredProducts
//   }
// })

// 정렬 로직
const sortedData = computed(() => {
  switch (product.shopShortFilter) {
    case '인기순':
      return [...childData.value].sort(
        (a, b) => Number(b.reviewCount * b.rating) - Number(a.reviewCount * a.rating)
      )
    case '낮은가격순':
      return [...childData.value].sort((a, b) => a.price - b.price)
    case '높은가격순':
      return [...childData.value].sort((a, b) => b.price - a.price)
    case '리뷰순':
      return [...childData.value].sort((a, b) => b.rating - a.rating)
    default:
      return childData.value
  }
})

watch(
  () => product.filteredProducts, // product.filteredProducts를 감시
  (newFilteredProducts) => {
    if (newFilteredProducts) {
      pageData.value = newFilteredProducts
      dataCount.value = newFilteredProducts.length

      // childData도 업데이트 필요하다면
      childData.value = pageData.value // 필요에 따라 childData 업데이트
    }
  },
  { immediate: true } // 컴포넌트가 마운트될 때도 즉시 실행
)

watchEffect(() => {
  if (props.keyword) {
    pageData.value = product.products || []
    dataCount.value = pageData.value.length
  }
})

onMounted(async () => {
  if (props.keyword) {
    const { fetchProduct } = product
    await fetchProduct('cateGory', null, props.keyword)
    if (product.filteredProducts) {
      dataCount.value = product.filteredProducts.length
      pageData.value = product.filteredProducts
    }
    dataReady.value = true
  } else if (props.searchKeyword) {
    const { fetchProduct } = product
    await fetchProduct('search', null, props.searchKeyword)
    if (product.filteredProducts) {
      dataCount.value = product.filteredProducts.length
      pageData.value = product.filteredProducts
    }
    dataReady.value = true
  }
})
</script>

<style lang="scss" scoped></style>
