<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div class="w-[365px] md:w-[550px] h-auto bg-white rounded-xl overflow-y-clip">
      <!-- 헤더 -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          휴대폰 번호 변경
        </p>
        <button class="absolute right-[15px] md:right-[31px]">
          <i
            class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
            @click="system.changeMobileModal = false"
          ></i>
        </button>
      </div>
      <!-- 헤더 -->
      <hr class="mx-2" />
      <section class="md:hidden">
        <div>
          <section class="mt-[31.5px]">
            <div class="flex justify-center">
              <img
                src="../../../../../public/img/password/padlock.webp"
                alt=""
                class="w-[48px] h-[48px] object-cover"
              />
            </div>
            <div class="mt-[24px]">
              <p class="text-center text-[12px] font-notosansCJKmd text-[#232323]">
                변경하실 휴대전화를 인증해주세요
              </p>
            </div>
          </section>
          <section class="flex justify-center mt-[38px] mb-[19px]">
            <mobileAuth />
          </section>
          <section class="mt-[61px]">
            <hr class="mx-3" />
            <div class="flex justify-center mb-[16px] mt-[10.5px]">
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#D5D5D5] rounded-lg md:rounded-md"
                @click="system.changeMobileModal = false"
              >
                <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
              </button>
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
                @click="changeMobile()"
              >
                <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">변경하기</p>
              </button>
            </div>
          </section>
        </div>
      </section>
      <section class="hidden md:block">
        <div>
          <section class="mt-[54px]">
            <div class="flex justify-center">
              <img src="../../../../../public/img/password/padlock.webp" alt="" />
            </div>
            <div class="mt-[43px]">
              <p class="text-center text-[18px] font-notosansCJKmd text-[#232323]">
                변경하실 휴대전화를 인증해주세요
              </p>
            </div>
          </section>
          <section class="mt-[56px] mb-[86px]">
            <mobileAuth class="mx-5" />
          </section>
          <hr class="mx-2" />
          <!-- 버튼 제어 -->
          <div class="flex justify-center mb-[16px] mt-[16.5px]">
            <button
              class="w-[226px] h-[51px] border border-[#D5D5D5] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
              @click="system.changeMobileModal = false"
            >
              취소하기
            </button>
            <button
              class="w-[226px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[40px]"
              @click="changeMobile()"
            >
              변경하기
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { useRegisterStore } from '../../../../stores/useRegister'
import mobileAuth from '../../../util/mobileAuth.vue'
import { useSystemStore } from '../../../../stores/useSystem'
import { useUserStore } from '../../../../stores/useUser'
import { sendRequest } from '../../../../api/serviceApi'
import { onUnmounted, ref, watch } from 'vue'

const register = useRegisterStore()
const system = useSystemStore()
const user = useUserStore()

const isAuth = ref(false)

const changeMobile = async () => {
  if (isAuth.value === false) {
    alert('변경하실 휴대전화를 인증해 주세요.')
    return
  }
  const payload = {
    id: user.me._id,
    mobile: register.registerStage.stage1.userInfo.mobile
  }

  const patchUserMobile = await sendRequest({
    method: 'patch',
    path: '/users/userMultiPatcher',
    data: payload
  })

  const result = patchUserMobile.result
  if (result === 'ok') {
    alert('휴대폰 번호가 변경 되었습니다.')
    system.changeMobileModal = false
    location.reload()
  }
}

watch(
  () => register.smsAuth,
  (newValue) => {
    isAuth.value = newValue
    console.log(isAuth.value)
  }
)
</script>

<style lang="scss" scoped></style>
