<template>
  <div class="flex flex-col min-h-screen">
    <mobileMenu v-if="system.mobileMenu" />
    <mainHeader v-if="!system.isPrint" class="sticky top-0 w-full z-50 navbar" />
    <div class="lg:w-[1200px] w-auto md:mx-5 lg:mx-auto hidden lg:block navbar">
      <homePostAndCat class="sm:ml-[23.8px] mx-3" />
    </div>
    <div class="flex-grow">
      <slot />
    </div>
    <!-- <mainFooter class="w-full" /> -->
    <div v-if="!system.isPrint" class="w-full h-full bg-[#FBFBFB] footer">
      <renewalFooter class="mx-auto" />
    </div>
  </div>
</template>

<script setup>
import { useSystemStore } from '../stores/useSystem'
import mainHeader from '../layouts/header/mainHeader.vue'
import homePostAndCat from '../components/home/homePostAndCat.vue'
import renewalFooter from './footer/renewalFooter.vue'
import mobileMenu from './mobile/mobileMenu.vue'

const system = useSystemStore()
</script>

<style lang="scss" scoped>
@media print {
  .navbar,
  .footer {
    display: none; /* 프린트 시 네비게이션 바와 푸터 숨기기 */
  }

  /* 프린트 시 본문 내용이 페이지 전체를 차지하도록 */
  main {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
