<template>
  <!-- 공용메뉴 박스 PC -->
  <div class="w-[233px]">
    <!-- 마이페이지 상단 -->
    <div
      class="hidden lg:flex lg:h-[102px] lg:mb-[19px] justify-center border-b-[1px] border-[#6D6D6D]"
    >
      <router-link to="/admin/main" class="h-[30px] mt-[28px]">
        <p class="text-[26px] font-notosansCJKmd text-[#171717]">관리자 페이지</p>
      </router-link>
    </div>
    <!-- 하단 메뉴 데이터 반복-->
    <div class="pl-[16px] lg:pl-0">
      <div v-for="(menus, index) in adminMenuList" :key="index" class="mb-[16px] lg:mb-[45px]">
        <!-- 타이틀 -->
        <div class="border-b-[0.5px] border-[#B2B2B2]">
          <p class="hidden lg:block lg:mb-[19px] lg:text-[16px] font-notosansCJKmd text-[#171717]">
            {{ menus.title }}
          </p>
        </div>
        <!-- 메뉴 -->
        <div v-for="menu in menus.menu" :key="menu.name">
          <router-link :to="{ path: `${menu.route}`, query: { type: menu.params } }">
            <p
              class="text-[13px] lg:text-[16px] font-notosansCJKmd text-[#232323] lg:text-[#6D6D6D] mt-[20px] lg:mt-[17px]"
              :class="menu.class"
            >
              {{ menu.name }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const adminMenuList = ref([
  {
    title: '상품관리',
    menu: [
      {
        name: '상품 등록',
        route: '/admin/productNew',
        class: ''
      },
      {
        name: '상품 조회/수정',
        route: '/admin/productManage',
        class: ''
      },
      {
        name: '추가상품 등록',
        route: '/admin/productExtraNew',
        class: ''
      },
      {
        name: '추가상품 관리',
        route: '/admin/productExtraManage',
        class: ''
      }
    ]
  },
  {
    title: '결제관리',
    menu: [
      {
        name: '결제 통계',
        route: '/admin/payStatistics',
        class: ''
      }
    ]
  },
  {
    title: '판매관리',
    menu: [
      {
        name: '주문 관리',
        route: '/admin/orders',
        class: '',
        params: 'orders'
      },
      {
        name: '발송 관리',
        route: '/admin/shipping',
        class: '',
        params: 'shipping'
      },
      {
        name: '취소 관리',
        route: '/admin/cancel',
        class: '',
        params: 'cancel'
      },
      {
        name: '반품 관리',
        route: '/admin/refund',
        class: '',
        params: 'refund'
      },
      {
        name: '교환 관리',
        route: '/admin/exchange',
        class: '',
        params: 'exchange'
      },
      {
        name: '구매 확정',
        route: '/admin/payment',
        class: '',
        params: 'payment'
      }
    ]
  },
  {
    title: '문의·리뷰관리',
    menu: [
      {
        name: '제품문의',
        route: '/admin/productReport',
        class: ''
      },
      {
        name: '주문문의',
        route: '/admin/orderReport',
        class: ''
      },
      {
        name: '1:1문의',
        route: '/admin/contactReport',
        class: ''
      },
      {
        name: '리뷰 조회/수정',
        route: '/admin/reviewManage',
        class: ''
      }
    ]
  },
  {
    title: '회원관리',
    menu: [
      {
        name: '회원 조회/수정',
        route: '/admin/userManage',
        class: ''
      }
    ]
  },
  {
    title: '게시글 관리',
    menu: [
      {
        name: '게시글 작성',
        route: '/admin/postNew',
        class: ''
      },
      {
        name: '연들 소식',
        route: '/admin/news',
        class: ''
      },
      {
        name: '칼럼',
        route: '/admin/cases',
        class: ''
      },
      {
        name: '인테리어 · 시공사례',
        route: '/admin/interior',
        class: ''
      }
    ]
  },
  {
    title: '시스템관리',
    menu: [
      {
        name: '공지사항',
        route: '/admin/board',
        class: ''
      },
      {
        name: '자주묻는질문',
        route: '/admin/qna',
        class: ''
      },
      {
        name: '메인배너 수정',
        route: '/admin/banner',
        class: ''
      },
      {
        name: '상품카테고리 수정',
        route: '/admin/category',
        class: ''
      },
      {
        name: '배송비 설정',
        route: '/admin/shippingAmount',
        class: ''
      }
    ]
  }
])

watchEffect(() => {
  if (route.path) {
    adminMenuList.value.forEach((element) => {
      element.menu.forEach((menuItem) => {
        menuItem.class = 'text-[#6D6D6D]'
        if (menuItem.route === route.path) {
          menuItem.class = 'text-black lg:text-black'
        }
      })
      return
    })
  }
})
</script>

<style lang="scss" scoped></style>
