<template>
  <div>
    <div>
      <hr class="lg:w-[1180px]" />
      <div class="flex items-center mt-[28px] ml-[13px] md:ml-[15px] mb-[27px]">
        <p class="text-[16px] md:text-[20px] text-[#404040] font-nanumsquareEB">블로그</p>
        <p
          class="ml-[11px] md:ml-[13px] text-[16px] md:text-[18px] text-[#92CE8F] font-notosansCJKmd"
        >
          {{ blogDatas.length }}건
        </p>
      </div>
    </div>
    <section class="max-w-[1151px] hidden md:flex justify-between ml-[15px]">
      <div v-for="(post, index) in blogDatas" :key="index">
        <router-link :to="`/post/details/${post._id}`">
          <postSearchCard v-if="index < dataCount" :data="post" class="mr-[34px]" />
        </router-link>
      </div>
    </section>
    <section class="md:hidden ml-[13px]">
      <div class="swiper-container mt-[16px] md:mt-[19px]">
        <swiper
          :slides-per-view="'auto'"
          :space-between="spaces"
          :loop="false"
          class="swiper-wrapper"
        >
          <swiper-slide v-for="(item, index) in blogDatas" :key="'swiper' + index">
            <router-link :to="`/post/details/${item._id}`">
              <productPostCard :item="item" />
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section class="flex justify-center mt-[58px]">
      <router-link to="/post/blog">
        <button
          class="w-[175px] h-[33px] md:w-[253px] md:h-[51px] border border-solid-1 border-[#AAAAAA] rounded-md"
        >
          <p class="text-[#7D7D7D] text-[14px] md:text-[15px] font-notosansCJKmd">블로그 더보기</p>
        </button>
      </router-link>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import postSearchCard from '../../post/postSearchCard.vue'
import productPostCard from '../../prodcut/productDescription/productPostCard.vue'
import { sendRequest } from '../../../api/serviceApi'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

const props = defineProps({
  keyword: String
})

const spaces = ref(10)
const dataCount = ref(3)
const blogDatas = ref([])

const getPost = async () => {
  const payload = {
    keyword: props.keyword
  }
  const getBlogData = await sendRequest({
    method: 'post',
    path: '/post/postTitleSearch',
    data: payload
  })
  blogDatas.value = getBlogData.data.sort(() => Math.random() - 0.5)
}

onMounted(() => {
  getPost()
})
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  position: relative;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
