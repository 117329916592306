<template>
  <div
    v-if="system.loginModals === true"
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-gray-800 z-[1000] bg-opacity-50 overflow-auto"
  >
    <div
      class="w-[360px] h-auto fixed bg-white rounded-lg text-black text-[16px] p-[16px]"
      v-motion-slide-top
    >
      <div class="flex items-center justify-between">
        <p>로그인</p>
        <button @click="system.loginModals = false">X</button>
      </div>
      <div class="pt-[24px] mb-[20px]">
        <button class="third-party-btn">구글 계정으로 로그인</button>
        <button class="third-party-btn">네이버 계정으로 로그인</button>
        <button class="third-party-btn">카카오 계정으로 로그인</button>
      </div>
      <div class="border-t-2 border-solid py-[20px]">
        <input
          class="modal-input my-[4px]"
          type="text"
          placeholder="이메일 주소"
          v-model="payload.email"
          @blur="validateEmail"
        />
        <p v-if="emailError.status" class="text-red-500 text-[11px] ml-2 mb-2">
          {{ emailError.message }}
        </p>
        <input
          class="modal-input my-[4px]"
          type="password"
          placeholder="비밀번호"
          v-model="payload.password"
          @keyup.enter="fetchProduct"
        />
      </div>
      <button
        @click="fetchProduct"
        class="third-party-btn"
        style="background-color: #389033; color: white"
      >
        로그인
      </button>
      <div class="flex p-[20px] justify-center">
        <button class="text-[14px] w-[143px] leading-[18px] text-center cursor-pointer">
          비밀번호 찾기
        </button>
        <button
          class="border-l-2 border-solid w-[143px] border-[#d5d5d5d5] text-[14px] leading-[18px] text-center cursor-pointer"
        >
          회원 가입
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref, watchEffect } from 'vue'
import { useSystemStore } from '../../stores/useSystem'
import { useCookies } from '@vueuse/integrations/useCookies'

const cookies = useCookies()

const system = useSystemStore()
let emailError = {
  message: '',
  status: false
}
const payload = ref({
  email: '',
  password: ''
})

const validateEmail = async () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!emailPattern.test(payload.value.email)) {
    emailError.message = '올바른 이메일 형식이 아닙니다.'
    emailError.status = true
  } else {
    emailError.message = ''
    emailError.status = false
  }
}

const fetchProduct = async () => {
  try {
    const getToken = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/loginUsers`,
      payload.value
    )
    const token = getToken.data
    if (token.status === 400) {
      alert(token.message)
    } else if (token.status === 200) {
      // console.log('result', token.message)
      cookies.set('yeondeul', token.message, { maxAge: 100 * 180 }) // 유효 기간을 60분으로 설정
      // console.log('Cookie set: ', cookies.get('yeondeul'))
      alert('login')
      location.reload()
    }
  } catch (error) {
    console.error(error)
  }
}

watchEffect(() => {
  if (payload.value.email) {
    validateEmail()
  }
})
</script>

<style lang="scss" scoped></style>
