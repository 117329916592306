<template>
  <div>
    <div class="md:w-[517px] md:h-[38px] mx-[8px] md:mx-0">
      <p class="md:text-[12px] font-notosansCJKmd text-[#6D6D6D] tracking-tight hidden md:block">
        ＊ 주문/배송과 관련하여 문의사항이 있으실 경우 상품 페이지 내 상품문의 또는 1:1 문의 를
        이용해주세요.
      </p>
      <div class="flex md:hidden text-[10px] text-[#6D6D6D]">
        <p class="font-notosansCJKmd">*</p>
        <p class="font-notosansCJKmd ml-2">
          주문/배송과 관련하여 문의사항이 있으실 경우 상품 페이지 내 상품문의 또는 1:1 문의 를
          이용해주세요.
        </p>
      </div>
      <p class="mt-[14px] md:mt-[5px] text-[10px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D]">
        ＊ 해당 주문에 대한 정보는 카카오톡 또는 휴대폰 문자메세지로도 발송됩니다.
      </p>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
