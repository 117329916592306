<template>
  <div v-if="pageReady" class="md:w-[516px]">
    <section>
      <div class="flex justify-between ml-[15px] md:ml-[6px] mr-[9px]">
        <div class="flex items-center mb-[5px]">
          <p
            class="text-[15px] md:text-[18px] font-nanumsquareEB md:font-notosansCJKmd text-[#171717]"
          >
            자주 묻는 질문
          </p>
          <i class="pi pi-angle-right text-[#707070] text-[19px] ml-[9px] hidden md:inline"></i>
        </div>
        <button
          class="hidden md:inline text-[15px] font-notosansCJKmd text-[#7D7D7D]"
          @click="goQna()"
        >
          더보기
        </button>
      </div>
      <hr class="border border-[#6D6D6D] md:border-[#DCDCDC]" />
    </section>
    <section>
      <div v-for="(faqs, i) in faqDataList" :key="i" class="flex items-center h-[45px] border-b">
        <div class="flex justify-between md:block w-full">
          <router-link
            :to="{
              path: `${detailpath}`,
              query: { ad: faqs._id, type: 'qna' }
            }"
          >
            <p class="ml-[14px] text-[13px] md:text-[14.5px] font-notosansCJKmd text-[#232323]">
              Q. {{ faqs.boardTitle }}
            </p>
          </router-link>
          <i class="pi pi-angle-right text-[#707070] text-[15px] mr-[9px] md:hidden"></i>
        </div>
      </div>
    </section>
    <section class="mt-[10px] md:hidden">
      <div class="flex justify-center">
        <button class="w-[175px] h-[28px] border border-[#D5D5D5] rounded-md" @click="goQna()">
          <p class="text-[11px]">더보기</p>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useBoardStore } from '../../../stores/useBoard'

const props = defineProps({
  public: Boolean
})
const router = useRouter()

const detailpath = computed(() => {
  if (props.public) {
    return '/noticeDetail'
  } else {
    return '/account/noticeDetail'
  }
})

const pageReady = ref(false)
const goQna = () => {
  // router.push('/account/qna')

  if (props.public) {
    router.push('/qna')
  } else {
    router.push('/account/qna')
  }
}

const board = useBoardStore()
const faqDataList = ref([])
onMounted(async () => {
  await board.getQnaBoard()
  faqDataList.value = Array.isArray(board.qnaData) ? [...board.qnaData].reverse() : [] // 배열인지 체크하고 처리
  pageReady.value = true
})
</script>

<style lang="scss" scoped></style>
