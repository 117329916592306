<template>
  <div class="relative inline-block text-left">
    <div>
      <button
        type="button"
        class="inline-flex justify-center w-[123px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-notosansCJR text-gray-700 hover:bg-gray-50 focus:ring-offset-2"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        @click="toggleDropdown"
      >
        {{ selectedOption }}
        <svg
          class="-mr-1 ml-7 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.293l3.72-4.063a.75.75 0 111.08 1.04l-4.25 4.635a.75.75 0 01-1.08 0L5.23 8.27a.75.75 0 01.02-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <div
      v-if="isOpen"
      class="origin-top-right absolute right-0 mt-2 w-[123px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      v-motion
      :initial="{ opacity: 0, y: -20 }"
      :enter="{ opacity: 1, y: 5 }"
      :leave="{ opacity: 0, y: 0 }"
      :delay="50"
      :duration="100"
    >
      <div class="" role="none">
        <a
          v-for="option in options"
          :key="option"
          href="#"
          class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
          role="menuitem"
          tabindex="-1"
          @click.prevent="selectOption(option)"
          :class="{ 'bg-gray-100': option === selectedOption }"
        >
          {{ option }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useProductStore } from '../../../stores/useProduct'

const product = useProductStore()
// 드롭다운 옵션 목록
const options = ref(['신상품순', '인기순', '낮은가격순', '높은가격순', '리뷰순'])

// 선택된 옵션과 드롭다운의 열림 상태
const selectedOption = ref('신상품순')
const isOpen = ref(false)

// 드롭다운 토글 함수
const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

// 옵션 선택 함수
const selectOption = (option) => {
  selectedOption.value = option
  product.shopShortFilter = option
  isOpen.value = false
}
</script>
