<template>
  <div class="">
    <div class="flex justify-center">
      <div>
        <div>
          <p class="text-center text-[18px]">브랜드 소개</p>
          <div class="flex justify-center items-center">
            <img src="/img/about_img/about_top_left.png" alt="" class="mt-[14px]" />
          </div>
          <div class="text-right mt-[17px] text-[17px] text-[#373737] mx-7 relative">
            <p class="font-chnM">억지로 꾸미지 않다.</p>
            <p class="font-chnM">누가 봐도 이상함이 없다.</p>
            <p class="text-[24px] font-chnM">자연스럽다.</p>
            <hr class="absolute border-1 border-solid border-[#707070] mt-[14px] w-[80%] right-0" />
          </div>
        </div>
        <div class="mt-[63px] text-center text-[17px] text-[#373737]">
          <p class="font-chnM">연들은 단순한 가구를 만드려고 하지 않습니다.</p>
          <p class="font-chnM mt-[1.7px]">자연스러운 가구, 건강한 가구를 넘어</p>
          <p class="font-chnM mt-[1.7px]">가족 뿐 아니라 사람과 사람 사이,</p>
          <p class="font-chnM mt-[1.7px]">이야기가 담긴 공간을 만드려고 합니다.</p>
        </div>
        <div class="flex justify-center items-center mt-[62.5px]">
          <img src="/img/aboutMobile/text_1.webp" alt="" class="w-[233px] h-[71.47] bg-cover" />
        </div>
        <div class="mt-[71px] text-center text-[17px] text-[#373737]">
          <p class="font-chnM">필름을 이용해 원목의 느낌을 흉내내거나</p>
          <p class="font-chnM">억지로 자연스러움을 끌어내려고 하지 않습니다.</p>
        </div>
        <div class="mt-[51px] flex h-[354px] relative">
          <div class="-left-[95px] absolute">
            <img src="/img/aboutMobile/left_male.webp" alt="" />
          </div>
          <div class="absolute right-[20px] -bottom-[25px] text-[17px] text-right">
            <p class="font-chnM text-[#0D0D0D]">순리에 맞는,</p>
            <p class="font-chnM text-[#0D0D0D] mt-[5px]">재료가 가진,</p>
            <p class="font-chnM text-[#0D0D0D] mt-[5px]">삶이 담긴,</p>
            <p class="font-chnM text-[26px] text-[#389033] mt-[5px]">자연스러움</p>
          </div>
          <hr
            class="absolute border-1 border-solid border-[#707070] mt-[14px] w-[70%] right-0 -bottom-[38px]"
          />
        </div>
        <div class="flex justify-center items-center mt-[96.5px]">
          <img src="/img/aboutMobile/text_2.webp" alt="" class="w-[335.5px] h-auyo bg-cover" />
        </div>
        <div class="flex justify-center items-center mt-[124.5px] mb-[155px]">
          <img src="/img/aboutMobile/logo.webp" alt="" class="w-[175px] h-[75px] bg-cover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
