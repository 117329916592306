<template>
  <div>
    <homeHeroBanner v-if="banners" :data="banners" class="lg:mt-[17px]" />
    <div class="lg:w-[1200px] w-auto lg:mx-auto">
      <section class="mx-[10px] lg:mx-auto mt-[13.5px] lg:mt-[68px]">
        <homePostMain />
      </section>
      <!-- BEST -->
      <section
        class="block lg:flex lg:w-[1200px] mx-[10px] lg:mx-auto lg:justify-between mt-[31px] lg:mt-[78px]"
      >
        <!-- 모바일 타이틀 -->
        <section class="lg:hidden ml-[5px]">
          <div class="flex">
            <div>
              <img src="/img/main/main_best_mobile_icon.webp" class="w-[24px] h-[24px]" alt="" />
            </div>
            <div class="ml-[9px]">
              <p class="text-[17px] font-notosansCJKbold text-[#404040]">
                지금 <span class="font-notosansCJKbold text-[#389033]">가장 인기있는</span> 상품
              </p>
            </div>
          </div>
        </section>
        <homeBestProductTitle class="lg:mt-[21px]" />
        <homeSellProduct :data="bestItem" class="lg:ml-[25px]" />
      </section>
      <!-- NEW -->
      <section
        class="block lg:flex lg:w-[1200px] mx-[10px] lg:mx-auto lg:justify-between mt-[31px] lg:mt-[77px]"
      >
        <!-- 모바일 타이틀 -->
        <section class="lg:hidden ml-[5px]">
          <div class="flex">
            <div>
              <img src="/img/main/sofa.webp" class="w-[24px] h-[24px]" alt="" />
            </div>
            <div class="ml-[9px]">
              <p class="text-[17px] font-notosansCJKbold text-[#404040]">
                이달의 <span class="font-notosansCJKbold text-[#D23F3F]">신상품</span>
              </p>
            </div>
          </div>
        </section>
        <homeNewProductTitle class="mt-[20px]" />
        <homeSellProduct :data="newItem" class="lg:ml-[25px]" />
      </section>
      <homeMiddleBannerVue class="mb-[42.7px] md:mt-[68px] md:mb-[103px]" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import homePostAndCat from './homePostAndCat.vue'
import homePostMain from './homePost/homePostMain.vue'
import homeSellProduct from './homeSellProduct..vue'
import homeHeroBanner from './homeHeroBanner.vue'
import homeBestProductTitle from './homeBestProductTitle.vue'
import homeNewProductTitle from './homeNewProductTitle.vue'
import homeSellNew from './homeSellNew.vue'
import { sendRequest } from '../../api/serviceApi'
import homeMiddleBannerVue from './homeMiddleBanner.vue'

const systemDatas = ref(null)
const postBGC = ref('')
const banners = ref(null)

const bestItem = ref(null)
const newItem = ref(null)

const getSystem = async () => {
  const result = await sendRequest({
    method: 'post',
    path: '/system/getSystem'
  })
  if (result) {
    const datas = result.data[0]
    systemDatas.value = datas
    postBGC.value = datas.postBgColor
    banners.value = datas.mainBanner
    if (Array.isArray(datas.mainBanner) && datas.mainBanner.length > 0) {
      banners.value = Object.values(datas.mainBanner[0])
    } else {
      banners.value = []
    }
  } else {
    console.error(result.message)
  }
}

const getBestProduct = async () => {
  const getBestDatas = await sendRequest({ method: 'post', path: '/product/getBestProduct' })
  const results = getBestDatas.result
  if (results === 'ok') {
    bestItem.value = getBestDatas.data
  } else {
    return
  }
}

const getNewProduct = async () => {
  const getNewProdcut = await sendRequest({ method: 'post', path: '/product/getNewproduct' })
  const results = getNewProdcut.result
  if (results === 'ok') {
    newItem.value = getNewProdcut.data
  } else {
    return
  }
}

// const getPickProduct = async () => {
//   const result = await sendRequest({ method: 'post', path: '/product/getProductAll' })
//   const randomData = result.data.sort(() => Math.random() - 0.5) // 랜덤 섞기
//   combineProduct.value.pickProduct.item = randomData.slice(0, 4) // 첫 4개
//   combineProduct.value.pickProduct.swiperItem = randomData.slice(0, 12) // 12개
// }

onMounted(() => {
  getSystem()
  getNewProduct()
  getBestProduct()
})
</script>

<style lang="scss" scoped></style>
