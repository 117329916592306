<template>
  <div v-if="params">
    <!-- <smallBanner /> -->
    <div class="lg:w-[1180px] lg:mx-auto lg:flex">
      <postIdMain class="" :params="params" :key="route.params.id" />
      <!-- <postMenu class="pt-[50px] ml-[98px]" :item="params" /> -->
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import smallBanner from '../../components/heroBanner/smallBanner.vue'
import postIdMain from '../../components/post/postIdMain.vue'
import postMenu from '../../components/post/postMenu.vue'
const router = useRouter()
const route = useRoute()
const params = ref('')
watchEffect(() => {
  if (route.params.id !== 'blog' && route.params.id !== 'interior' && route.params.id !== 'case') {
    router.push('/404')
    // alert('뭔가 잘못 됨')
    // console.log(route.params.id)
  }
  if (route.path) {
    params.value = route.params.id
  }
})
</script>

<style lang="scss" scoped></style>
