<template>
  <div>
    <!-- 인증 -->
    <section class="mt-[23.5px] md:mt-[31.5px]">
      <div class="">
        <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">연락처 (휴대폰)</p>
        <div class="flex mt-[6.5px] md:mt-[13.5px]">
          <input
            type="text"
            class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] no-arrow"
            placeholder="- 을 제외한 휴대폰 번호 입력"
            v-model="register.registerStage.stage1.userInfo.mobile"
            @input="validatePhoneInput"
          />
          <button
            class="border border-[#389033] w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] text-[#389033] md:text-[18px] font-notosansCJKmd"
            @click="sendAuth()"
          >
            휴대폰 인증
          </button>
        </div>
      </div>
      <div v-if="register.sendSms" class="">
        <p
          class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323] md:hidden mt-[13.5px]"
        >
          휴대폰 인증번호 입력
        </p>
        <div class="flex mt-[6.5px] md:mt-[22.5px]">
          <input
            type="text"
            class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] no-arrow"
            placeholder="인증번호 입력"
            v-model="authInput"
            @input="validateOtpInput"
          />
          <button
            class="border w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] md:text-[18px] font-notosansCJKmd"
            :class="smsAuthBtn.class"
            @click="authDone()"
            :disabled="register.smsAuth"
          >
            {{ smsAuthBtn.text }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { useRegisterStore } from '../../stores/useRegister'

const register = useRegisterStore()
const authInput = ref()
const smsAuthBtn = ref({ text: '확인', class: 'border-[#389033] text-[#389033] ' })

const sendAuth = async () => {
  if (register.smsAuth) {
    alert('인증이 완료된 전화번호 입니다.')
    return
  }
  try {
    const sendAuth = await axios({
      // url: 'https://yeondeul.com/servers/smsAuth',
      url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth`,
      method: 'POST',
      data: {
        mobile: register.registerStage.stage1.userInfo.mobile
      }
    })
    const result = sendAuth.data
    if (result === 'isDuplicate') {
      // console.log(sendAuth)
      alert('전송된 인증번호가 존재 합니다')
      return
    } else if (result === 'sendVerify') {
      alert('인증번호가 고객님의 휴대전화로 발송 되었습니다.')
      register.sendSms = true
    } else {
      alert(result)
      return
    }
  } catch (error) {}
}

const authDone = async () => {
  const confirmSmsAuth = await axios({
    // url: 'https://rurucidev2.com/servers/smsAuth/verifyAuth',
    url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth/verifyAuth`,
    method: 'POST',
    data: {
      mobile: register.registerStage.stage1.userInfo.mobile,
      otp: authInput.value
    }
  })
  // alert(confirmSmsAuth)
  if (confirmSmsAuth.data === true) {
    register.smsAuth = true
    smsAuthBtn.value.class = ''
    smsAuthBtn.value.text = '완료'
    alert('휴대폰 인증이 완료 되었습니다.')
  } else {
    alert('인증 번호가 틀렸습니다.')
  }
}
</script>

<style lang="scss" scoped></style>
