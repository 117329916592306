<template>
  <div v-if="data && type" class="w-[263px] lg:w-[376px]">
    <div class="bg-gray-400 rounded-md">
      <img
        :src="data.mainImg"
        alt="homePostMini"
        class="w-full h-[174px] lg:h-[248px] rounded-lg object-cover object-center"
      />
    </div>
    <div class="mt-[7px] lg:mt-[10px]">
      <p
        v-if="type === '최신글'"
        class="ml-[4px] font-notosansCJKmd text-[14px] text-[#278022] mb-[5.5px] lg:mb-[7px]"
      >
        {{ types }}
      </p>
      <p
        class="font-notosansCJK lg:text-[19px] text-[#232323] leading-[1.25rem] ml-[4px] line-clamp-1 lg:line-clamp-2"
      >
        {{ data.title }}
      </p>
      <p class="mt-[7px] lg:mt-[8px] lg:ml-[4px] text-[12px] font-notosansCJR text-[#707070]">
        {{ dayjs(`${data.date}`).format('YYYY/MM/DD/') }} By {{ data.author }}
      </p>
      <p
        class="mt-[10px] lg:mt-[17px] ml-[2px] text-[13px] font-notosansCJKlight text-[#707070] leading-[1.2rem] text-left line-clamp-2 lg:line-clamp-3"
      >
        {{ data.des }}
      </p>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { computed, ref } from 'vue'

const props = defineProps({
  data: Object,
  type: String
})

const types = computed(() => {
  const propsType = props.data.type
  if (propsType === 'case') {
    return '칼럼'
  } else if (propsType === 'interior') {
    return '인테리어 ·시공사례'
  } else {
    return '연들소식'
  }
})
</script>

<style lang="scss" scoped></style>
