const mainCatData = [
  {
    title: '원목가구',
    link: 'wood',
    class: ''
  },
  {
    title: '조명',
    link: 'light',
    class: ''
  },
  {
    title: '소품/오브제',
    link: 'obj',
    class: ''
  },
  // {
  //   title: '아웃도어/캠핑',
  //   link: 'camp',
  //   class: ''
  // },
  {
    title: '도자기',
    link: 'pot',
    class: ''
  },
  {
    title: '공간인테리어',
    link: 'interior',
    class: ''
  },
  {
    title: '반려동물',
    link: 'pet',
    class: ''
  }
]

export default mainCatData
