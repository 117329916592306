<template>
  <div>
    <section class="">
      <div class="flex justify-center">
        <button class="border rounded-md" :class="buttonClassArray[0]" @click="selectedButton(0)">
          <p class="text-[15px] font-notosansCJKmd text-[#232323] px-[55.9px] py-[17.4px]">
            신용카드
          </p>
        </button>
        <button
          class="border rounded-md ml-[15.1px]"
          :class="buttonClassArray[1]"
          @click="selectedButton(1)"
        >
          <!-- <p class="text-[15px] font-notosansCJKmd text-[#232323] px-[55.9px] py-[17.4px]">
            네이버페이
          </p> -->
          <img src="/img/billing/npay.png" alt="mobile_npay" class="px-[55.9px] py-[17.4px]" />
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useCartStore } from '../../stores/useCart'

const cart = useCartStore()

const defaultButton = ref('')
const selectedButtonClass = ref('border-3 border-[#232323]')

const buttonClassArray = ref(['border-[#CFCFCF]', 'border-[#CFCFCF]'])
const selectedButton = (index) => {
  if (index === 0) {
    buttonClassArray.value[index] = selectedButtonClass.value
    buttonClassArray.value[1] = defaultButton.value
    cart.payUserInfo.checkoutMethod = 'card'
  } else {
    buttonClassArray.value[index] = selectedButtonClass.value
    buttonClassArray.value[0] = defaultButton.value
    cart.payUserInfo.checkoutMethod = 'naver'
  }
}
</script>

<style lang="scss" scoped></style>
