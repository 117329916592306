<template>
  <div>
    <findMyEamil v-if="password.passStage === 0" />
    <resetSmsVue v-if="password.passStage === 1" />
    <setNewPassword v-if="password.passStage === 2" />
    <passwordResetDone v-if="password.passStage === 3" />
  </div>
</template>

<script setup>
import { usePasswordStore } from '../../../stores/usePassword'
import resetSmsVue from './resetSms.vue'
import setNewPassword from './setNewPassword.vue'
import passwordResetDone from './passwordResetDone.vue'
import findMyEamil from './findMyEamil.vue'
import { onUnmounted } from 'vue'

const password = usePasswordStore()

onUnmounted(() => {
  password.validateEmail = false
  password.emailAuth = false
  password.sendSms = false
  password.smsAuth = false
  password.passStage = 0
  password.passData = {
    stage0: {
      emaill: '',
      name: ''
    },
    stage1: {
      userInfo: {}
    },
    stage2: {
      receiverInfo: {}
    }
  }
})
</script>

<style lang="scss" scoped></style>
