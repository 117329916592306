<template>
  <div class="lg:w-[1180px] lg:mx-auto h-full mt-0 lg:mt-[36px]">
    <!-- 1. 타이틀 -->
    <div class="flex justify-center items-center h-[48.5px] lg:h-auto mt-[28.5px] lg:mt-3">
      <p class="text-[16px] lg:text-[26px] text-[#171717] font-bold flex">주문완료</p>
    </div>
    <!-- 2. 결제완료 안내 -->
    <billingDone class="mt-[31px] lg:mt-0" />
    <!-- 3-1. 결제 안내 사항 -->
    <div class="md:flex justify-center mt-[70px] hidden">
      <billingAnnouce />
    </div>
    <!--4. 빌링 데이터 -->
    <div v-if="billData" class="mt-[56px] lg:mt-[107px] flex justify-center">
      <billingBill :data="billData" />
    </div>
    <!--3-2. 결제 안내 사항 모바일 -->
    <div class="mt-[41.4px] flex justify-center md:hidden">
      <billingAnnouce />
    </div>
    <div class="mt-[42px] lg:mt-[100px] mb-[60px] md:mb-[130px]">
      <billingRecomnd />
    </div>
  </div>
</template>

<script setup>
import billingAnnouce from './billingAnnouce.vue'
import billingDone from './billingDone.vue'
import billingBill from './billingBill.vue'
import billingRecomnd from './billingRecomnd.vue'
import { ref, watchEffect } from 'vue'
import { usePaymentStore } from '../../stores/usePayment'

const payment = usePaymentStore()
const paymethod = ref('')
const props = defineProps({
  orderData: Object
})

const billData = ref(null)

watchEffect(() => {
  if (props.orderData) {
    switch (props.orderData.payUserInfo.checkoutMethod) {
      case 'card':
        paymethod.value = '신용카드'
        break
      case 'naver':
        paymethod.value = '네이버페이'
        break
      default:
        break
    }
    // if (props.orderData.payUserInfo.checkoutMethod === 'card') {
    //   paymethod.value = '신용카드'
    // } else if (props.orderData.payUserInfo.checkoutMethod === 'naver') {
    //   paymethod.value = '네이버페이'
    // }
    billData.value = [
      props.orderData.orderNumber,
      props.orderData.payUserInfo.receiverInfo.name,
      props.orderData.payUserInfo.receiverInfo.mobile,
      props.orderData.payUserInfo.receiverInfo.address,
      props.orderData.payUserInfo.receiverInfo.addressDetail,
      props.orderData.payUserInfo.receiverInfo.request || '',
      '',
      paymethod.value,
      `${props.orderData.priceInfo.totalPayPrice.toLocaleString('ko-KR')}원`
    ]
  }
})
</script>

<style lang="scss" scoped></style>
