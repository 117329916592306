<template>
  <!-- <div class="w-[1180px] mx-auto">
    <div class="h-[550px] w-full bg-gray-100">상단 compo</div>
    <dropdownTestMain />
  </div> -->

  <div class="lg:w-full">
    <div class="lg:w-[1120px] lg:mx-auto mx-2">
      <section>
        <div class="mx-2 lg:mx-auto w-full lg:w-[800px] bg-green-200 text-white"></div>
      </section>
      <div class="">
        <!-- <p class="text-[23px]">Response Test</p> -->
        <!-- <doomsDay /> -->
        <!-- <payTestVue /> -->
        <!-- <swiperpageVue /> -->
        <!-- <InterMain /> -->
        <mypage />
        <!-- <Rating
          v-model="value"
          class="flex text-[#389033] space-x-1"
          :cancel="false"
          :onIcon="'pi pi-star-fill text-[50px]'"
          :offIcon="'pi pi-star text-[50px]'"
        /> -->
      </div>
      <!-- <adminGraph /> -->
    </div>
  </div>
</template>

<script setup>
import testMain from '../../components/test/testMain.vue'
import dropdownTestMain from '../../components/test/dropdownTestMain.vue'
import payTestVue from '../../components/test/payTest.vue'
import swiperpageVue from '../../components/test/swiperpage.vue'
import adminGraph from '../../components/test/adminGraph.vue'
import doomsDay from '../../components/test/doomsDay.vue'
import Rating from 'primevue/rating'
import { ref } from 'vue'
// import InterMain from '../../components/interactive/InterMain.vue'
// import mypage from '../mypage.vue'
const value = ref(3)
</script>

<style lang="scss" scoped>
.custom-rating {
  font-size: 50px !important;
}

.pi-star-fill {
  font-size: 50px;
}
</style>
