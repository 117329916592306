<template>
  <div
    v-if="pageReady"
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div
      class="w-[365px] md:w-[800px] h-auto max-h-[80vh] bg-white rounded-xl overflow-y-clip hide-scrollbar"
    >
      <!-- 공통헤더 -->
      <section>
        <div
          class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
        >
          <p
            class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
          >
            교환 배송정보 입력
          </p>
          <button class="absolute right-[15px] md:right-[31px]">
            <i
              class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
              @click="system.adminExchangeModal = false"
            ></i>
          </button>
        </div>
        <hr class="mx-2" />
      </section>
      <section class="my-10 mx-2 md:mx-20">
        <!-- 중간 로직 -->
        <div class="mb-3">
          <p>배송 방법: {{ shippingData.shippeingMethod || 'SHIPPING_METHOD' }}</p>
        </div>
        <!-- 자체설치상품 -->
        <div v-if="shippingData.shippeingMethod === '자체설치상품'">
          <p>수령 가능 날짜</p>
          <input
            type="date"
            class="full h-10 w-full border border-gray-300 rounded-md px-3"
            v-model="payload.pickupDate"
          />
        </div>
        <!-- 배송지 정보 -->
        <div v-if="shippingData.shippeingMethod !== '자체설치상품'" class="mb-3">
          <p>성함: {{ shippingData.reciverInfo.name || 'RECIVER_NAME_FILED' }}</p>
          <p>연락처 : {{ shippingData.reciverInfo.mobile || 'RECIVER_MOBILE_FILED' }}</p>
          <p>
            주소 : {{ shippingData.reciverInfo.address || 'RECIVER_ADDRESS' }}({{
              shippingData.reciverInfo.postcode
            }}) {{ shippingData.reciverInfo.addressDetail }}
          </p>
        </div>
        <!-- 화물 배송 -->
        <div v-if="shippingData.shippeingMethod === '화물배송'">
          <p>배송예정일 입력</p>
          <input
            type="date"
            class="full h-10 w-full border border-gray-300 rounded-md px-3"
            v-model="reciveDate"
          />
        </div>
        <!-- 택배 배송 -->
        <div
          v-if="
            shippingData.shippeingMethod !== '화물배송' &&
            shippingData.shippeingMethod !== '자체설치상품'
          "
        >
          <p class="mb-1">택배사 입력</p>
          <input
            type="text"
            class="full h-10 w-full border border-gray-300 rounded-md mb-2 pl-3"
            v-model="payload.deliverCompany"
          />
          <p class="mb-1">송장번호 입력</p>
          <input
            type="text"
            class="full h-10 w-full border border-gray-300 rounded-md pl-3"
            v-model="payload.deliverNumber"
          />
        </div>
        <div class="text-center mt-10">
          <p>{{ shippingData.on }}</p>
          <p>{{ shippingData.odn }}</p>
        </div>
      </section>
      <section>
        <hr class="mx-2" />
        <!-- 버튼 제어 -->
        <div class="flex justify-center mb-[16px] mt-[16.5px]">
          <button
            class="w-[167px] md:w-[310px] h-[48px] md:h-[51px] border border-[#0C370A] rounded-md text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd"
            @click="system.adminExchangeModal = false"
          >
            취소하기
          </button>
          <button
            class="w-[167px] md:w-[310px] h-[48px] md:h-[51px] bg-[#389033] border rounded-md text-[13px] md:text-[18px] text-white font-notosansCJKmd ml-2 md:ml-[40px]"
            @click="updateShipping()"
          >
            배송지입력 완료
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue'
import { useOrderStore } from '../../../stores/userOrder'
import { useSystemStore } from '../../../stores/useSystem'
const order = useOrderStore()
const system = useSystemStore()

console.log('서버시간', system.serverTime)
const pageReady = ref(false)
const shippingData = ref({})
const isEdit = ref(false)
const payload = ref({
  exchangeDate: system.serverTime,
  reciveDate: '',
  pickupDate: '',
  deliverCompany: '',
  deliverNumber: '',
  delieverAmmount: 0,
  shippedAt: system.serverTime
})

// info, on, odn, status
const validateShippingInfo = () => {
  // 배송 방법에 따라 필요한 값 검증
  if (shippingData.value.shippeingMethod === '자체설치상품') {
    if (!payload.value.pickupDate) {
      alert('수령 가능 날짜를 입력해주세요.')
      return false
    }
  } else if (shippingData.value.shippeingMethod === '화물배송') {
    if (!payload.value.reciveDate) {
      alert('배송 예정일을 입력해주세요.')
      return false
    }
  } else if (shippingData.value.shippeingMethod === '택배배송') {
    if (!payload.value.deliverCompany || !payload.value.deliverNumber) {
      alert('택배사 및 송장번호를 입력해주세요.')
      return false
    }
  }

  return true // 모든 검증을 통과했을 경우 true 반환
}

const updateShipping = async () => {
  if (!isEdit.value) {
    payload.value.exchangeDate = system.serverTime
  }
  payload.value.shippedAt = system.serverTime
  // 검증 실패 시 중단
  if (!validateShippingInfo()) {
    return
  }

  try {
    await order.patchExchangeInfo(
      payload.value, // info
      shippingData.value.on,
      shippingData.value.odn,
      shippingData.value.newStatus,
      isEdit.value
    )
    location.reload()
  } catch (error) {
    console.log()
    ;('배송 정보 업데이트에 실패했습니다.')
  }
}

onMounted(() => {
  shippingData.value = order.exchangeInputData
  if (order.exchangeInputData.shippingInfo) {
    payload.value = order.exchangeInputData.shippingInfo
    isEdit.value = true
  }
  pageReady.value = true
})

onUnmounted(() => {
  order.exchangeInputData = {}
})
</script>

<style lang="scss" scoped></style>
