<template>
  <div
    v-show="cart.cartCount > 0 || types !== ''"
    class="z-30 h-[56px] w-full bg-white bg-opacity-95 border-t-[0.5px] shadow-2xl"
  >
    <div class="flex justify-between">
      <div class="flex ml-[12.5px] mt-[19px]">
        <p class="text-[14px] text-[#232323]">총 {{ cart.cartCount || cart.cartData?.length }}개</p>
        <p class="ml-[13px] text-[15px] text-[#389033]">
          {{ cart.calculator.totalPayPrice.toLocaleString('ko-KR') || 'NULL_PRICE' }}원
        </p>
      </div>
      <div class="mt-[7px]">
        <button
          class="mr-[9px] w-[175px] h-[43px] bg-[#389033] rounded-md text-white font-notosansCJKmd text-[15px]"
          @click="procCheckOut()"
        >
          {{ btnName }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { orderNumber, detailNumberGenerator, resetCounters } from '../../utils/orderNumberGenerator'
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { sendRequest } from '../../api/serviceApi'
import { useUserStore } from '../../stores/useUser'
import { useSystemStore } from '../../stores/useSystem'
import { useCartStore } from '../../stores/useCart'
import axios from 'axios'

const props = defineProps({
  types: String
})
const cart = useCartStore()
const user = useUserStore()
const system = useSystemStore()

const router = useRouter()
const route = useRoute()
const btnName = ref('')

watchEffect(() => {
  if (route.path === '/cart') {
    btnName.value = '구매하기'
  } else {
    btnName.value = '결제하기'
  }
})

const orderUserInfoCheck = () => {
  const nullChecks = Object.values(cart.payUserInfo.orderUserInfo).filter((element) => {
    return element === ''
  })
  return nullChecks || []
}

const receiverInfoCheck = () => {
  const nullChecks = Object.entries(cart.payUserInfo.receiverInfo)
    .filter(([key, value]) => key !== 'title' && key !== 'request' && value === '') // title 필드를 제외하고 값이 빈 문자열인 경우만 필터링
    .map(([key, value]) => value) // 값만 반환

  return nullChecks || []
}

const productName = computed(() => {
  const companyCount = cart.cartData.length
  if (companyCount === 0) {
    return '장바구니가 비어 있습니다'
  } else if (companyCount === 1) {
    let firstItemTitle = cart.cartMaker[0].items[0].products.title
    return firstItemTitle ? firstItemTitle : '상품 이름 없음'
  } else if (companyCount > 1) {
    let firstItemTitle = cart.cartMaker[0].items[0].products.title
    return firstItemTitle ? `${firstItemTitle} 외` : '상품 이름 없음'
  }
})

const addresCombiner = ref('')
const newOrderNum = ref('A' + orderNumber(system.serverTime))
// 초기화 끝 아래로 결제 로직

// 포인트 계산
const pointCacluator = (price, pointPer, ea) => {
  const eaTimePrice = price * ea
  const points = eaTimePrice * (pointPer / 100)
  const totalPointsRounded = Math.floor(points)
  return totalPointsRounded
}

const pointCollector = () => {
  const datas = cart.cartData
  const totalPoint = datas.reduce((accumulator, item, i) => {
    console.log('data', item.options.name)
    console.log(
      '결제가격 :',
      item.options.price,
      ', 포인트 적립 퍼센티지 :',
      item.products.pointPercentage,
      ', 갯수',
      item.options.ea
    )
    const points = pointCacluator(
      item.options.price,
      item.products.pointPercentage,
      item.options.ea
    )
    console.log('i번째 계산 결과', i, ' = ', points)
    console.log('\n')

    return accumulator + points
  }, 0)
  console.log('총합', totalPoint)
}

// 임시 주문번호
const tempOrderNumber = ref()

// 주문 생성
const orderDetailGenerator = (orderNumber) => {
  const datas = cart.cartData
  const newArr = datas.map((item) => ({
    _id: item.products._id,
    title: item.products.title,
    company: item.products.company,
    options: {
      types: item.options.types,
      color: item.options.color
    },
    price: item.options.price,
    ea: item.options.ea,
    totalPrice: item.options.price * item.options.ea,
    status: 'wating',
    reviewed: false,
    thumbImg: item.products.thumb_img,
    // orderNumber: 'A' + orderNumber(system.serverTime),
    orderNumber: orderNumber,
    orderDetailNumber: 'D' + detailNumberGenerator(system.serverTime),
    point: pointCacluator(item.options.price, item.products.pointPercentage, item.options.ea),
    pointPercentage: item.products.pointPercentage,
    ship_method: item.products.ship_method,
    ship_ammount: item.products.ship_ammount,
    extreafee: item.products.extrafee,
    salePrice: item.products.sale_of_per
  }))
  resetCounters()
  console.log(newArr)
  return newArr
}

const orderGenerator = (orderNumber) => {
  // const orderNumberGet = 'D' + orderNumber(system.serverTime)

  // tempOrderNumber.value = orderNumberGet
  tempOrderNumber.value = orderNumber
  const orderDetailData = orderDetailGenerator(orderNumber)
  const orderData = {
    // orderNumber: orderNumberGet,
    orderName: productName.value,
    orderNumber: orderNumber,
    payUserInfo: {
      orderUserId: user.me._id,
      orderUserInfo: cart.payUserInfo.orderUserInfo,
      receiverInfo: cart.payUserInfo.receiverInfo,
      checkoutMethod: cart.payUserInfo.checkoutMethod
    },
    orderDate: system.serverTime,
    orderDetail: orderDetailData,
    priceInfo: cart.calculator,
    buyPriceInfos: cart.buyPriceInfos
  }
  // console.log(orderData)
  cart.buyPriceInfos = {}
  return orderData
}
// order끝

// 결제 생성
const paymentGenerator = () => {
  const paymenetData = {
    payUserId: user.me._id,
    paymentDate: system.serverTime,
    orderNumber: tempOrderNumber.value,
    pgResultData: {
      success: true,
      imp_uid: 'imp_188887034524',
      pay_method: cart.payUserInfo.checkoutMethod,
      merchant_uid: 'IMPA311241217570',
      name: productName.value,
      paid_amount: cart.calculator.totalPayPrice,
      currency: 'KRW',
      pg_provider: 'danal_tpay',
      pg_type: 'payment',
      pg_tid: '202311241218070923573400',
      apply_num: '00078807',
      buyer_name: cart.payUserInfo.orderUserInfo.name,
      buyer_email: cart.payUserInfo.orderUserInfo.email,
      buyer_tel: cart.payUserInfo.orderUserInfo.mobile,
      buyer_addr:
        cart.payUserInfo.receiverInfo.address + ' ' + cart.payUserInfo.receiverInfo.addressDetail,
      buyer_postcode: cart.payUserInfo.receiverInfo.postcode,
      custom_data: null,
      status: 'paid',
      paid_at: 1700795937,
      receipt_url:
        'https://www.danalpay.com/receipt/creditcard/view.aspx?dataType=receipt&cpid=9810030929&data=MWuQGsxz0RlhmJuPkdTpW9Vz%2BwrBii%2FBVGcKp1%2F3hU7K6D27jR%2FKXizNmHwa0LM8',
      card_name: '현대카드',
      bank_name: null,
      card_quota: 0,
      card_number: '543333******8424'
    }
  }
  console.log(paymenetData)
  return paymenetData
}

const paymethodSelector = computed(() => {
  const CPID = `${import.meta.env.PORT_ONE_MID}` // 상점 아이디

  if (cart.payUserInfo.checkoutMethod === 'card') {
    return `danal_tpay.${CPID}`
  } else if (cart.payUserInfo.checkoutMethod === 'naver') {
    // return `naverpay.np_tuzla307103`
    // return `nice.nictest00m`
    // alert('네이버 페이는 준비중 입니다. \n빠른 시일내로 더 좋은 서비스로 보답 드리겠습니다.')
    return
  }
})

// PG사 요청
const pgRequest = () => {
  return new Promise((resolve, reject) => {
    try {
      const IMP = window.IMP
      IMP.init('imp61876687') // 고객사 코드 -> 연동정보 -> 식별코드
      const CPID = `${import.meta.env.PORT_ONE_MID}` // 상점 아이디
      // const CPID = '9810030929' // 상점 아이디
      const names = productName.value

      IMP.request_pay(
        {
          pg: paymethodSelector.value, // PG사 설정
          pay_method: 'card',
          merchant_uid: 'IMP' + newOrderNum.value, // 주문 고유 번호 (중복되지 않도록 해야 함)
          name: names, // 상품 정보
          amount: cart.calculator.totalPayPrice, //1000, // 결제 금액
          buyer_email: cart.payUserInfo.orderUserInfo.email, // 구매자 이메일
          buyer_name: cart.payUserInfo.orderUserInfo.name, // 구매자 이름
          buyer_tel: cart.payUserInfo.orderUserInfo.mobile, // 구매자 전화번호
          buyer_addr: addresCombiner.value, // 구매자 주소
          buyer_postcode: cart.payUserInfo.orderUserInfo.postcode // 우편번호
        },
        (rsp) => {
          if (rsp.success) {
            console.log('결제 완료', rsp)

            // 결제 성공 후 받은 데이터를 서버에 맞는 형식으로 가공
            const formattedPaymentData = {
              payUserId: user.me._id, // 사용자 ID
              paymentDate: system.serverTime, // 결제 날짜
              orderNumber: tempOrderNumber.value, // 주문 번호
              pgResultData: {
                success: rsp.success, // 결제 성공 여부
                imp_uid: rsp.imp_uid, // 아임포트 결제 고유 ID
                pay_method: rsp.pay_method, // 결제 수단
                merchant_uid: rsp.merchant_uid, // 주문 고유 번호
                name: rsp.name, // 상품명
                paid_amount: rsp.paid_amount, // 결제 금액
                currency: 'KRW', // 통화
                pg_provider: rsp.pg_provider, // 결제 PG사
                pg_type: 'payment', // 결제 타입
                pg_tid: rsp.pg_tid, // PG사 거래 ID
                apply_num: rsp.apply_num, // 승인 번호
                buyer_name: rsp.buyer_name, // 구매자 이름
                buyer_email: rsp.buyer_email, // 구매자 이메일
                buyer_tel: rsp.buyer_tel, // 구매자 전화번호
                buyer_addr: rsp.buyer_addr, // 구매자 주소
                buyer_postcode: rsp.buyer_postcode, // 우편번호
                status: rsp.status, // 결제 상태
                receipt_url: rsp.receipt_url, // 영수증 URL
                card_name: rsp.card_name, // 카드 이름
                card_number: rsp.card_number, // 카드 번호 (마스킹 처리됨)
                card_quota: rsp.card_quota // 할부 개월 수
              }
            }

            resolve(formattedPaymentData) // 가공된 결제 데이터를 resolve로 반환
          } else {
            console.error('결제 실패:', rsp.error_msg)
            alert(`결제 실패: ${rsp.error_msg}`)
            reject(new Error(rsp.error_msg)) // 결제 실패 시 reject 처리
          }
        }
      )
    } catch (error) {
      console.error('결제 요청 중 오류 발생', error)
      reject(error) // 예외 발생 시 reject 처리
    }
  })
}

const createPaymentData = ref(null)

const cartDbHandller = async (cmnd) => {
  const data = {
    params: cmnd,
    payload: {
      userId: user.me._id
    }
  }
  await sendRequest({ method: 'patch', path: '/cart/cartPatch', data })
}

const sendBizTalk = async () => {
  const paylaod = {
    status: 'waiting',
    on: tempOrderNumber.value,
    odn: ''
  }
  const sendbiztalk = await sendRequest({
    method: 'post',
    path: '/message/sendPlusMessage',
    data: paylaod
  })
  const result = sendbiztalk
  console.log(result)
}

const processCheckOut = async () => {
  // 0. 주문 번호 작성
  const orderNumbers = 'A' + orderNumber(system.serverTime)
  if (orderNumbers) {
    // console.log(1)

    // 1. 주문 정보를 작성
    const createOrderData = orderGenerator(orderNumbers)
    // console.log(2)

    try {
      // 2. 결제 요청 (PG사에 통신)
      if (user.auth === 'admin') {
        createPaymentData.value = paymentGenerator() //테스트 결제
      } else if (user.auth === 'user') {
        createPaymentData.value = await pgRequest() //실결제
      }
      // createPaymentData.value = await pgRequest()
      // console.log('결제요청 결과: ', createPaymentData.value)

      // 3. PG사 승인이 완료되었을 때 주문 정보가 넘어옴
      if (createOrderData && createPaymentData.value) {
        // console.log(3)
        // console.log('orderData', createOrderData)
        // console.log(4)
        // console.log('paymentData', createPaymentData.value)

        // 4. 서버에 주문 정보 및 결제 정보 전송
        const requestCreateOrder = await sendRequest({
          method: 'post',
          path: '/order/createOrder',
          data: createOrderData
        })

        const requestCreatePayment = await sendRequest({
          method: 'post',
          path: '/payment/createPayment',
          data: createPaymentData.value
        })

        // const pointPatcher = user.pointPatcher() 구매시 포인트 적립 제거
        const orderResult = requestCreateOrder.result
        const paymentResult = requestCreatePayment.result

        // console.log('pointPatcher', pointPatcher) 구매시 포인트 적립 제거
        const patchPoint = await user.pointUse(cart.calculator.pointToUse)
        if (!patchPoint) {
          console.log('user patcher fail')
          return alert('결제 실패! 관리자 페이지에 문의해 주세요!')
        }

        // console.log('requestCreateOrder', orderResult)
        // console.log('requestCreatePayment', paymentResult)
        // console.log(6)

        // 5. 결제 및 주문이 성공적으로 완료된 경우
        if (orderResult === 'ok' && paymentResult === 'ok') {
          // console.log(7)
          cart.cartData = [] // 장바구니 초기화
          await cartDbHandller('deleteAll')
          await sendBizTalk()
          router.push({ path: '/billing', query: { keyword: tempOrderNumber.value } })
        } else {
          alert('결제 실패! 관리자 페이지에 문의해 주세요!')
        }
      } else {
        alert('결제 정보 생성 실패! 관리자 페이지에 문의해 주세요!')
      }
    } catch (error) {
      console.error('결제 처리 중 오류 발생', error)
      alert(
        '결제 처리 중 오류가 발생했습니다. 자세한 사항은 마이페이지 -> 1:1 문의하기를 이용해 주세요.'
      )
    }
  } else {
    console.error('cannot generate order Number')
  }
}

const saveDefaultAddr = async () => {
  const payload = {
    id: user.me._id,
    body: {
      address: cart.payUserInfo.receiverInfo.address,
      addressDetail: cart.payUserInfo.receiverInfo.addressDetail,
      mobile: cart.payUserInfo.receiverInfo.mobile,
      name: cart.payUserInfo.receiverInfo.name,
      postcode: cart.payUserInfo.receiverInfo.postcode
    }
  }
  const patchData = await axios.patch(
    `${import.meta.env.VITE_API_BASE_URL}/users/setUserDefautlAddr`,
    payload
  )
  console.log(patchData.data)
}

// 결제 버튼 동작
const procCheckOut = async () => {
  if (route.path === '/cart') {
    router.push('/checkout')
    return
  }
  if (cart.payUserInfo.checkoutMethod === 'naver') {
    alert('네이버 페이는 준비중 입니다. \n빠른 시일내로 더 좋은 서비스로 보답 드리겠습니다.')
    return
  }
  if (orderUserInfoCheck().length > 0) {
    return alert('주문하시는 분 정보가 누락되어 있습니다')
  }
  if (receiverInfoCheck().length > 0) {
    return alert('받으시는 분 정보가 누락되어 있습니다.')
  }
  if (cart.payUserInfo.checkoutMethod === '') {
    return alert('결제 수단을 선택해 주세요.')
  }
  if (cart.payUserInfo.setToDefault) {
    saveDefaultAddr()
  }
  processCheckOut()
}

// 위로 결제 로직
watchEffect(() => {
  if (cart.payUserInfo.receiverInfo) {
    addresCombiner.value = `${cart.payUserInfo.receiverInfo.address} ${cart.payUserInfo.receiverInfo.addressDetail} `
  }
})

onUnmounted(() => {
  cart.usePoint = 0
  cart.payUserInfo.checkoutMethod = ''
})
</script>

<style lang="scss" scoped></style>
