<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-80 overflow-hidden"
  >
    <div class="w-[365px] md:w-[626px] h-auto bg-white rounded-xl overflow-y-clip">
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          문의하기
        </p>
        <button class="absolute right-[31px]">
          <i
            class="pi pi-times text-[19px] text-[#A8A8A8]"
            @click="system.inquiryModals = false"
          ></i>
        </button>
      </div>
      <hr class="mx-2" />
      <div class="ml-[6px] mt-[14.5px] md:ml-[26px] md:mt-[42px]">
        <section>
          <div class="md:flex md:items-center">
            <p
              class="text-[12px] text-[#6D6D6D] md:text-[15px] font-notosansCJKmd md:text-[#232323]"
            >
              문의 제목
            </p>
            <input
              type="text"
              maxlength="50"
              v-model="payload.inquiryData.inquiryTitle"
              placeholder="문의 제목을 입력해 주세요"
              class="pl-3 placeholder:text-[12px] md:placeholder:text-[15px] border border-[#CFCFCF] w-[354px] h-[35px] md:w-[471px] md:h-[48px] md:ml-[44px] rounded-md mt-[9.5px] md:mt-0 font-notosansCJR text-[14px] md:text-[17px]"
            />
          </div>
        </section>
        <section class="mt-[22.5px] md:mt-[24px">
          <div class="md:flex md:items-start">
            <p
              class="md:mt-[15px] text-[12px] text-[#6D6D6D] md:text-[15px] font-notosansCJKmd md:text-[#232323]"
            >
              문의 내용
            </p>
            <textarea
              v-model="payload.inquiryData.inquiryDetails"
              type="text"
              class="pl-3 pt-2 placeholder:text-[12px] placeholder:md:text-[15px] border border-[#CFCFCF] w-[354px] h-[225.96px] md:w-[471px] md:h-[319px] md:ml-[44px] rounded-md mt-[9.5px] md:mt-0 resize-none font-notosansCJR text-[14px] md:text-[17px]"
              placeholder="문의 내용을 입력해 주세요"
              @input="limitLines"
              maxlength="1000"
            />
          </div>
        </section>
      </div>
      <div
        class="text-left md:text-center mt-[19.5px] md:mt-[22px] mb-[37px] md:mb-[16px] ml-2 md:ml-0"
      >
        <section>
          <p
            class="text-[11px] md:text-[14px] text-[#6D6D6D] font-notosansCJR md:font-notosansCJKmd"
          >
            ＊ 작성하신 상품 문의는
            <span
              class="tracking-tight text-[11px] md:text-[14px] text-[#D23F3F] font-notosansCJR md:font-notosansCJKmd"
              >마이페이지의 상품 문의에서도 확인</span
            >하실 수 있습니다.
          </p>
        </section>
        <section>
          <p
            class="tracking-tight text-[11px] md:text-[12x] font-notosansCJR md:font-notosansCJKmd mt-[8.5px] md:mt-[45px] text-[#6D6D6D]"
          >
            ＊ 욕설, 중복 질문, 상품과 무관한 문의 작성시 통보 없이 삭제될 수 있습니다.
          </p>
          <p
            class="tracking-tight text-[11px] md:text-[12x] font-notosansCJR md:font-notosansCJKmd mt-[8.5px] md:mt-[11px] text-[#6D6D6D]"
          >
            ＊ 개인정보 보호를 위해 연락처, 성함, 주소 등의 개인정보를 기재하지 말아주세요.
          </p>
        </section>
      </div>

      <hr class="mx-2" />
      <div class="flex justify-center mb-[16px] mt-[16.5px]">
        <button
          class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#D5D5D5] rounded-lg md:rounded-md"
          @click="system.inquiryModals = false"
        >
          <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
        </button>
        <button
          v-if="!report.reportEdit"
          class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
          @click="saveOrderInqury()"
        >
          <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">문의하기</p>
        </button>
        <button
          v-else
          class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
          @click="updateOrderInqury()"
        >
          <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">수정하기</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { useTextareaAutosize } from '@vueuse/core'
import { useUserStore } from '../../../../stores/useUser'
import { useSystemStore } from '../../../../stores/useSystem'
import { useProductStore } from '../../../../stores/useProduct'
import 'primeicons/primeicons.css'
import { sendRequest } from '../../../../api/serviceApi'
import { useReportStore } from '../../../../stores/useReport'
import { onMounted, onUnmounted, ref } from 'vue'

// const props = defineProps({
//   isEdit: Boolean
// })
const report = useReportStore()
const system = useSystemStore()
const product = useProductStore()
const user = useUserStore()
// const { textarea, input } = useTextareaAutosize({ styleProp: 'minHeight' })

const maxLines = 5

const payload = ref({
  userData: {
    id: user.me._id, // 사용자 ID
    name: user.me.name // 사용자 이름
  },
  type: 'product', // 문의 타입: 상품 문의
  productId: '', // 제품 ID
  productTitle: '', // 제품 제목
  orderData: {
    orderNumber: '', // 주문 번호
    orderDetailNumber: '', // 주문 상세 번호
    title: '', // 상품 제목 (주문 관련)
    thumbImg: '', // 상품 이미지 URL
    company: '', // 상품 회사 이름
    options: {
      color: '', // 색상 옵션
      types: '' // 옵션 유형
    }
  },
  inquiryData: {
    inquiryTitle: '', // 문의 제목
    inquiryDetails: '', // 문의 상세 내용
    inquiryImg: [], // 문의 이미지 URL 배열
    tag: '', // 문의 태그 (예: '상품 관련 문의')
    isAnswer: false, // 초기 상태: 답변 없음
    answerDetails: null // 초기 상태: 답변 없음
  },
  answerImg: [], // 답변 이미지 배열 (초기 상태: 빈 배열)
  createAt: system.serverTime, // 생성일 (자동으로 현재 날짜)
  editAt: null // 수정일 (초기 상태: null)
})

const limitLines = (event) => {
  const textArea = event.target
  const lines = textArea.value.split('\n')
  if (lines.length > maxLines) {
    textArea.value = lines.slice(0, maxLines).join('\n')
  }
}

const saveOrderInqury = async () => {
  if (
    payload.value.inquiryData.inquiryTitle === '' ||
    payload.value.inquiryData.inquiryDetails === ''
  ) {
    alert('누락된 내용이 존재합니다.')
    return
  }
  const rusure = confirm('문의 내용을 저장합니다.')
  if (!rusure) {
    return
  }

  const sendData = {
    data: payload.value
  }
  const patchSave = await sendRequest({
    method: 'post',
    path: '/report/createReport',
    data: sendData
  })

  const result = patchSave.result
  console.log(result)
  if (result === 'ok') {
    alert('문의가 작성 되었습니다.')
    system.inquiryModals = false
    location.reload()
  }
}

const updateOrderInqury = async () => {
  if (
    payload.value.inquiryData.inquiryTitle === '' ||
    payload.value.inquiryData.inquiryDetails === ''
  ) {
    alert('누락된 내용이 존재합니다.')
    return
  }
  const rusure = confirm('문의 내용을 수정하시겠어요?')
  if (!rusure) {
    return
  }

  const sendData = {
    reportid: report.myReportData._id,
    data: payload.value
  }
  const patchSave = await sendRequest({
    method: 'patch',
    path: '/report/updateReport',
    data: sendData
  })

  const result = patchSave.result
  console.log(result)
  if (result === 'ok') {
    alert('문의가 수정 되었습니다.')
    system.inquiryModals = false
    location.reload()
  }
}

onUnmounted(() => {
  report.myReportData = null
  report.reportEdit = false
})

onMounted(() => {
  if (!report.reportEdit) {
    payload.value.productTitle = product.productItem.title
    payload.value.productId = product.productItem._id
    payload.value.orderData.thumbImg = product.productItem.thumb_img
    payload.value.orderData.company = product.productItem.company
  } else {
    payload.value = report.myReportData
    payload.value.type = 'product'
  }
})
</script>

<style lang="scss" scoped></style>
