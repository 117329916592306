import { defineStore } from 'pinia'
import { useUserStore } from './useUser'
import { ref, computed, watch } from 'vue'
import axios from 'axios'

export const useMenualCartStore = defineStore(
  'useMenualCart',
  () => {
    console.log('menual Cart running')
    //상태 정의
    const user = useUserStore()
    const currentUser = computed(() => user.me._id)
    const cartCount = ref(0)
    const cartData = ref(null)
    const selectItem = ref(null)
    const updater = ref(true)
    const isExtra = ref(false)

    // 게터, 불변 속성을 계산해서 리턴
    const clacluator = computed(() => {
      let priceTable = {
        totalPrice: 0,
        totalDiscount: 0,
        totalPayPrice: 0
      }
      if (cartData.value !== null) {
        const datas = cartData.value

        const originalPrices = (datas) => {
          return datas.reduce((sum, item) => {
            return sum + item.options.fixPrice * item.options.ea
          }, 0)
        }

        const discoutPrice = (datas) => {
          return datas.reduce((sum, item) => {
            return sum + item.products.sale_of_per * item.options.ea
          }, 0)
        }

        priceTable.totalDiscount = discoutPrice(datas)
        priceTable.totalPrice = originalPrices(datas) + priceTable.totalDiscount
        priceTable.totalPayPrice = priceTable.totalPrice - priceTable.totalDiscount
      }
      return priceTable
    })

    // 액션, 가변적 실행 함수 모음
    const insertField = (idex) => {
      if (cartData.value === null) {
        return
      }
      const isExtras = cartData.value[idex].options.color
      // 컬러 값이 null이면 extra이므로 true를 리턴
      if (isExtras === null) {
        return true
      } else {
        // 컬러 값이 null이 아니면 일반 상품이므로 false를 리턴
        return false
      }
    }

    const quantityChecks = (name, index) => {
      if (cartData.value !== null) {
        // isExtra.value = insertField(index)
        // 추가 상품이 아닌 경우
        if (isExtra.value === false) {
          const quantityRef = cartData.value[index].products.options
          const findMatch = quantityRef.find((item) => item.optionName === name)
          return findMatch.quantity
        } else {
          const quantityRef = cartData.value[index].products.extra
          const findMatch = quantityRef.find((item) => item.title === name)
          return findMatch.quantity
        }
      }
    }

    const checkStock = (item, quantity) => {
      const product = item.products
      const option = product.options.find(
        (opt) => opt.optionName === item.options.color && opt.parentOption === item.options.types
      )

      // 기본형 전체 물량 체크
      const totalQuantity = cartData.value
        .filter((cartItem) => cartItem.options.types === item.options.types)
        .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

      // 개별 옵션 재고 체크
      const optionQuantity = cartData.value
        .filter((cartItem) => cartItem.options._id === item.options._id)
        .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

      return option.quantity >= quantity && totalQuantity + quantity <= option.quantity
    }

    const getCartData = async () => {
      if (currentUser.value) {
        // console.log('user has found')
        const payload = { userid: currentUser.value }
        const getData = await axios({
          url: `${import.meta.env.VITE_API_BASE_URL}/cart/cartGetByUser`,
          method: 'POST',
          data: {
            payload: payload
          }
        })
        if (getData === null) {
          return
        }
        const result = getData.data
        cartData.value = result
        cartCount.value = result.length
        return
      } else {
        console.warn('No user found')
        return
      }
    }

    // user.me가 설정된 후 getCartData를 호출하기 위한 watch 추가
    watch(
      () => user.me,
      (newVal) => {
        if (newVal && newVal._id) {
          getCartData()
        }
      },
      { immediate: true }
    )

    return {
      cartData,
      cartCount,
      selectItem,
      clacluator,
      quantityChecks,
      currentUser,
      updater,
      isExtra,
      getCartData
    }
  },
  {
    // persist: true
  }
)
