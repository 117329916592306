<template>
  <div>
    <div class="flex justify-center mt-[32.5px] md:mt-[91px]">
      <section>
        <div class="flex justify-center">
          <p class="hidden md:block text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
            비밀번호 변경
          </p>
          <p class="md:hidden text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
            비밀번호 변경 완료
          </p>
        </div>
        <!-- pc -->
        <section class="hidden md:block mt-[91px]">
          <div class="flex justify-center">
            <img src="/img/password/password-set-done.webp" alt="" />
          </div>
          <p class="mt-[48px] text-[26px] text-[#389033] font-notosansCJKmd">
            비밀번호 변경이 완료되었습니다
          </p>
        </section>
        <!-- mobile -->
        <section class="md:hidden">
          <div class="flex justify-center mt-[155px]">
            <img src="/img/password/padlock.webp" alt="" />
          </div>
          <p class="mt-[24.4px] text-[12px] text-[#2323233] font-notosansCJKmd">
            비밀번호 변경이 완료되었습니다
          </p>
        </section>
      </section>
    </div>
    <div class="mt-[56px] md:mt-[85px] mb-0 md:mb-[191px]">
      <section class="">
        <div class="flex justify-center">
          <router-link to="/">
            <button
              class="border border-[#CFCFCF] w-[320px] md:w-[569px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#6D6D6D] bg-transparent"
              @click="stepToStage()"
            >
              홈으로
            </button>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
