import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { MotionPlugin } from '@vueuse/motion'
import PrimeVue from 'primevue/config'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue'
import Sticky from 'vue3-sticky-directive'
import App from './App.vue'
import router from './router'
import './index.css'
import './assets/css/main.css'
import './assets/css/font.css'

import Vue3Toasity from 'vue3-toastify'
import Vueform from '@vueform/vueform'
import vueformConfig from '.././vueform'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import { useUserStore } from './stores/useUser'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import axios from 'axios'

// import * as Sentry from '@sentry/vue'

const app = createApp(App)
app.use(createPinia())

const user = useUserStore()
const cookies = useCookies()

const setToAuth = async (token) => {
  try {
    const tokenCheck = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/getAuth`,
      {},
      {
        headers: {
          authentication: token
        }
      }
    )
    const setMeData = tokenCheck.data
    user.status = true
    user.me = setMeData
    user.auth = setMeData.auth
  } catch (e) {
    console.error('Token validation failed:', e)
    user.status = false
    user.me = null
    user.auth = null
  }
}

const setAuth = async () => {
  const myJwt = cookies.get('yeondeul')
  if (myJwt) {
    user.token = myJwt
    await setToAuth(myJwt)
  } else {
    console.warn('JWT token is missing.')
    return
  }
}

// Top-level await을 함수 내부로 옮기기
async function initializeApp() {
  await setAuth() // Top-level await 해결
  app.use(router)
  app.mount('#app') // mount 위치를 여기로 이동
}

initializeApp()

// Sentry.init({
//   app,
//   dsn: 'https://17efb9e61800eda1b8bf5d14c8b0d4c0@o4507338170302464.ingest.us.sentry.io/4507338175021056',
//   integrations: [
//     Sentry.browserTracingIntegration({ router }),
//     Sentry.replayIntegration(),
//     Sentry.browserProfilingIntegration()
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   // Profiling
//   profilesSampleRate: 1.0 // Profile 100% of the transactions. This value is relative to tracesSampleRate
// })

app
  .use(PrimeVue, {
    unstyled: true // 기본 스타일 활성화
  })
  .use(CkeditorPlugin)
app.use(MotionPlugin)
app.use(Vueform, vueformConfig)
app.use(Sticky)
app.use(Vue3Toasity, { limit: 3, hideProgressBar: true })

app.component('VueDatePicker', VueDatePicker)

console.log('자연에 물들다 - 연들 stable')
