<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <!-- <mainBanner class="w-auto" /> -->
    <homeMain />
    <!-- <div class="h-[1500px] bg-red-100">0</div> -->
  </div>
</template>

<script setup>
import { useSystemStore } from '../stores/useSystem'
import { onUnmounted } from 'vue'

// import mainBanner from '../components/heroBanner/mainBanner.vue'
import homeMain from '../components/home/homeMain.vue'

const system = useSystemStore()
system.isMainPage = true

onUnmounted(() => {
  system.isMainPage = false
})
</script>

<style lang="scss" scoped></style>
