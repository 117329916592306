<template>
  <div v-if="pageReady" class="md:w-[1180px] mx-auto">
    <!-- <homePostAndCat class="ml-[4px]" /> -->
    <productReviewImgModalVue v-if="system.reviewImgModal" />
    <hr class="hidden lg:block mt-[0px] md:mt-[16px] h-[1pt] bg-[#E2E2E2]" />
    <productMain :item="pageData" class="md:ml-[15px] mt-[7.5px] md:mt-[28px]" />
  </div>
</template>

<script setup>
import { useProductStore } from '../../stores/useProduct'
import productMain from '../../components/prodcut/productMain.vue'
import { sendRequest } from '../../api/serviceApi'
// import homePostAndCat from '../../components/home/homePostAndCat.vue'
import { useRoute } from 'vue-router'
import { ref, onMounted, onUnmounted } from 'vue'
import productReviewImgModalVue from '../../components/modal/productReviewImgModal.vue'
import { useSystemStore } from '../../stores/useSystem'

const system = useSystemStore()
const product = useProductStore()
const productid = ref('')
const pageData = ref(null)
const reviewData = ref(null)
const pageReady = ref(false)

const fetchProduct = async () => {
  const payload = {
    _id: productid.value
  }
  const getProduct = await sendRequest({
    method: 'post',
    path: '/product/productGetByid',
    data: payload
  })

  const result = getProduct.result
  if (result === 'ok') {
    pageData.value = getProduct.data
    product.productItem = getProduct.data
  } else {
    alert('현재 상품은 이용할 수 없습니다.')
    return
  }
}

const fetchReview = async () => {
  const payload = {
    productId: productid.value
  }
  const result = await sendRequest({
    method: 'post',
    path: '/review/getProductReiew',
    data: payload
  })
  if (result) {
    // console.log(result)
    reviewData.value = result.data
  } else {
    console.error(result.message)
  }
}

onMounted(() => {
  window.scrollTo(0, 0)
  const route = useRoute()
  productid.value = route.params.id
  fetchProduct()
  pageReady.value = true

  // fetchReview()
})

onUnmounted(() => {
  product.productItem = null
})
</script>

<style lang="scss" scoped></style>
