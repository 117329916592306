<template>
  <div class="w-full relative" ref="serachfield">
    <div>
      <input
        type="text"
        class="w-[170px] sm:w-[260px] h-[33px] lg:w-[260px] lg:h-[41px] pl-3 pr-[40px] md:pr-[15px] md:pl-[40px] outline-none bg-[#F8F8F8] lg:bg-[#F8F8F8] rounded-lg relative"
        style="border: none"
        v-model="keywords"
        @keyup.enter="goSearchPage()"
      />
      <!-- <div
        v-if="serachController"
        class="absolute bg-white w-[600px] -ml-[180px] border rounded-md mt-3"
      >
        <div class="mx-3 overflow-auto h-auto max-h-[500px]">
          <div v-if="productData !== null" class="text-center my-14">
            <p v-if="productData.length === 0">검색 결과가 존재하지 않습니다</p>
            <p v-else>'{{ resultkeyWord }}' 에 대한 검색결과 입니다</p>
          </div>
          <div
            v-for="data in productData"
            :key="data._id"
            class="my-3 flex justify-between items-center"
          >
            <searchProductCard
              :data="data"
              class="mb-3 shadow-md w-full py-3 px-2 mx-3 cursor-pointer"
              @click="selecteFunction(data)"
            />
          </div>
        </div>
      </div> -->
    </div>
    <div
      class="absolute right-2 top-1/2 lg:right-0 lg:left-2 transform -translate-y-1/2 w-[21px] h-[21px]"
      @click="goSearchPage()"
    >
      <svg
        class="w-[21px] h-[21px] cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.3"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        ></path>
      </svg>
    </div>
    <!-- <div class="hidden md:inline absolute top-1/2 right-12 transform -translate-y-1/2">
      <button v-if="keywords" @click="reset()">x</button>
    </div> -->
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
// import badgeDropdown from '../../components/img/badgeDropdown.vue'
// import { sendRequest } from '../../api/serviceApi'
// import searchProductCard from '../../components/img/searchProductCard.vue'
import { useRouter } from 'vue-router'
import { onClickOutside } from '@vueuse/core'

const serachfield = ref(null)
const router = useRouter()
const keywords = ref('')
// const resultkeyWord = ref('')
const productData = ref(null)
const serachController = ref(false)

onClickOutside(serachfield, () => (serachController.value = false))

const goSearchPage = () => {
  if (keywords.value === '') {
    alert('검색어를 입력해 주세요')
    return
  }
  router.push({ path: '/search', query: { keyword: keywords.value } })
  keywords.value = ''
}

const reset = () => {
  keywords.value = ''
  productData.value = null
  serachController.value = false
}
</script>

<style lang="scss" scoped></style>
