<template>
  <div>
    <div class="flex">
      <button
        class="border w-[260px] h-[51px] rounded-md text-[15px] font-notosansCJKmd text-[#232323] ease-in-out hover:border-2 hover:border-[#a1a1a1]"
        :class="buttonClassArray[0]"
        @click="selectedButton(0)"
      >
        신용카드
      </button>
      <button
        class="border w-[260px] h-[51px] rounded-md text-[15px] font-notosansCJKmd text-[#232323] ease-in-out hover:border-2 hover:border-[#a1a1a1 ml-[14.7px]"
        :class="buttonClassArray[1]"
        @click="selectedButton(1)"
      >
        <img src="/img/billing/npay.png" alt="" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useCartStore } from '../../stores/useCart'

const cart = useCartStore()

const defaultButton = ref('')
const selectedButtonClass = ref('border-2 border-[#232323]')

const buttonClassArray = ref(['', ''])
const selectedButton = (index) => {
  if (index === 0) {
    buttonClassArray.value[index] = selectedButtonClass.value
    buttonClassArray.value[1] = defaultButton.value
    cart.payUserInfo.checkoutMethod = 'card'
  } else {
    buttonClassArray.value[index] = selectedButtonClass.value
    buttonClassArray.value[0] = defaultButton.value
    cart.payUserInfo.checkoutMethod = 'naver'
  }
}

// const clicked =
</script>

<style lang="scss" scoped></style>
