// src/api/apiService.js
import axios from 'axios'

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  timeout: 10000
})

export const sendRequest = async ({ method, path, data }) => {
  // console.log('API is running')
  // console.log('Handlling parmas', method, path, data)
  try {
    const response = await apiClient({
      method,
      url: path,
      data
    })
    // 예상된 응답 형식에 따라 결과 처리
    const { result, message, data: responseData } = response.data

    if (result === 'ok') {
      // 성공적인 응답
      return { result: result, message, data: responseData }
    } else {
      // API 오류 처리
      return { result: result, message, data: null }
    }
  } catch (error) {
    // 네트워크 오류나 기타 예외 처리
    console.error('API request failed:', error)
    return { result: result, message: error.message, data: null }
  }
}
