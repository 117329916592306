<template>
  <div v-if="item" class="nanumBold">
    <productMobilePopMenu class="md:hidden bg-red-100" :item="item" />
    <div class="">
      <div class="ml-[19px] md:ml-0">
        <p class="text-[14px] md:text-[17px] font-notosansCJR text-[#707070]">
          {{ item.company }}
        </p>
        <!-- 상품 이름 -->
        <p
          class="text-[17px] md:text-[24px] font-notosansCJKmd mt-[3px] md:mt-[3.5px] text-[#171717]"
        >
          {{ item.title }}
        </p>
        <!-- 기획전 -->
        <div class="hidden md:block">
          <productTag :item="item" />
        </div>
      </div>
      <!-- 바인딩 필요 -->
      <div class="mt-[11.8px]">
        <!-- !리뷰 별  review-->
        <div class="flex items-center ml-[19px] md:ml-0">
          <section class="flex items-center" v-if="item.reviewCount > 0">
            <div>
              <!-- <Rating v-model="startRating" :cancel="false" class="flex text-[#389033]" /> -->
              <Rating
                v-model="item.rating"
                :cancel="false"
                class="flex text-[#389033] text-[13px] md:text-[17px] space-x-[2px] custom-rating"
                :onIcon="'pi pi-star-fill text-[13px] md:text-[17px]'"
                :offIcon="'pi pi-star text-[13px] md:text-[17px] font-light hidden'"
                readonly
              />
            </div>
            <p class="text-[13px] md:text-[16px] ml-[11.5px] md:ml-[16px] text-[#707070]">
              리뷰 {{ item.reviewCount || 0 }}개
            </p>
          </section>
          <!-- <p>별별별별별</p> -->

          <!-- 공유하기 추가 -->
          <i
            class="md:hidden pi pi-share-alt text-[17px] h-[25px] cursor-pointer delay-75 hover:text-gray-500 transition ease-in-out hover:drop-shadow-lg absolute right-0 pr-[22px]"
            @click="copy(), notify()"
          ></i>
        </div>
        <div class="mt-[16px] md:mt-[27.3px] ml-[16.6px] md:ml-0">
          <!-- 세일을 한다면 -->
          <div v-if="item.sale_of_per > 0" class="flex">
            <!-- 세일 % -->
            <p class="font-notosansCJKmd text-[20px] md:text-[21px] text-[#D23F3F]">
              {{ salePer().toFixed(0) }}%
            </p>
            <!-- 세일전 원가 -->
            <p
              class="text-[20px] md:text-[21px] text-[#6B6868] line-through font-notosansCJR ml-[12px]"
            >
              {{ item.price.toLocaleString('ko-KR') || '세일 없음' }} 원
            </p>
          </div>
          <!-- 정상가 -->
          <p
            v-if="item.sale_of_per === 0"
            class="text-[20px] md:text-[26px] font-notosansCJKmd text-[#232323]"
          >
            {{ item.price.toLocaleString('ko-KR') || 'null' }} 원
          </p>
          <!-- 세일 적용가 -->
          <p
            v-if="item.sale_of_per > 0"
            class="text-[20px] md:text-[26px] font-notosansCJKmd text-[#232323]"
          >
            {{ (item.price - item.sale_of_per).toLocaleString('ko-KR') || 'null' }} 원
          </p>
        </div>
      </div>
      <!-- 모바일 상품 뱃지 긴거 -->
      <div
        class="mx-[7px] md:hidden h-[43px] bg-[#F9F8E1] flex justify-between items-center rounded-md mt-[18px]"
      >
        <p class="ml-[24px] font-notosansCJKmd text-[15px]">{{ item.company }}</p>
        <button class="mr-[17px] text-[13px] text-[#707070]">
          브랜드 상품 더보기 <span class="ml-[15px]">></span>
        </button>
      </div>
      <!-- 라인 -->
      <div class="flex mt-[25px] md:mt-[38.5px] ml-[31.6px] md:ml-0 text-[13px] md:text-[16px]">
        <div class="text-[#A5A5A5]">
          <p class="font-notosansCJR">혜택</p>
          <p class="mt-[17px] md:mt-[27.3px] font-notosansCJR">배송</p>
        </div>
        <div class="ml-[45px] md:ml-[60px]">
          <p class="text-[#232323] font-notosansCJR">
            {{ item.point.toLocaleString('ko-KR') || 0 }}
            p 적립
          </p>
          <div class="mt-[17px] md:mt-[27.3px]">
            <p v-if="item.ship_ammount > 0" class="text-[#232323] font-notosansCJR">
              {{ item.ship_ammount.toLocaleString('ko-KR') || 0 }}원 선불 ({{
                item.ship_method || 'SHIP_METHOD_NULL'
              }})
              <span class="text-[12px] md:text-[12px] mr-2 md:mr-0 text-[#ACACAC] font-notosansCJR"
                >({{ product.vendorAmount.toLocaleString('ko-KR') }}원 이상 구매시 무료배송)</span
              >
            </p>
            <p class="text-[#232323] font-notosansCJR mt-[1px]">
              <span class="text-[#D23F3F] font-notosansCJR">{{ dateToShipped || '5/29' }}일</span>
              발송예정, 주문 후 제작상품
            </p>
            <p class="text-[#6D6D6D] font-notosansCJR mt-[1px]">
              도서 산간지역 {{ cart.islandPriceSetup.toLocaleString('ko-KR') }}원
            </p>
            <div
              class="h-[38px] w-[403px] bg-[#ECF9E1] rounded-md mt-[25.5px] hidden md:flex items-center justify-between cursor-pointer -ml-[5px]"
            >
              <p class="ml-[16px] text-[15px] font-notosansCJKmd">
                {{ item.company }}
                <span class="ml-[8px] text-[#707070] font-notosansCJKmd">상품 더 보기</span>
              </p>
              <i class="pi pi-angle-right mr-[13.7px] text-[#707070]"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- <hr class="h-0.5 w-full bg-[#B2B2B2] mt-[14px]" /> -->
      <hr class="w-full mt-[14px] border-1 bg-[#B2B2B2]" />
      <!-- 옵션 선택 -->
      <div class="mt-[25.5px] hidden md:block">
        <productDetailOption :item="item" @update-data="handleData" />
      </div>
      <!-- !구매 환불 위치 selector-->
      <div class="hidden md:block mt-[20px]">
        <productDetailselector :item="childData" :isSide="false" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, useAttrs, computed } from 'vue'
import { useProductStore } from '../../stores/useProduct'
import { useSystemStore } from '../../stores/useSystem'
import productTag from './productTag.vue'
import Rating from 'primevue/rating'
import productDetailOption from './productDetail/productDetailOption.vue'
import productDetailselector from './productDetail/productDetailselector.vue'
import productMobilePopMenu from './productDetail/productMobilePopMenu.vue'
import productDetailReviewStar from './productDetail/productDetailReviewStar.vue'
import { useCartStore } from '../../stores/useCart'
import { toast } from 'vue3-toastify'
import { useRoute } from 'vue-router'

import 'vue3-toastify/dist/index.css'

import { useClipboard } from '@vueuse/core'
import dayjs from 'dayjs'

const props = defineProps({
  item: Object,
  review: Object
})

const system = useSystemStore()

const dateToShipped = computed(() => {
  const serverTime = new Date(system.serverTime)
  const daysToAdd = product.productItem.deliverDelay // 예시: 5일 추가

  return dayjs(`${addBusinessDays(serverTime, daysToAdd)}`).format('MM/DD')
})

const addBusinessDays = (date, days) => {
  let result = new Date(date)
  let addedDays = 0

  while (addedDays < days) {
    result.setDate(result.getDate() + 1)
    const dayOfWeek = result.getDay()
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0: 일요일, 6: 토요일
      addedDays++
    }
  }

  return result
}

const copyToastid = 'copyToastIsAlive'
const cart = useCartStore()
const route = useRoute()
const product = useProductStore()
const lineFont = ref('')
// const dateToShipped = ref('5/29')
const shippedMethod = ref('택배배송')
const point = ref(120)
const rate = ref(15)
const shippedPay = ref(3000)
const childData = ref('')

const handleData = (data) => {
  childData.value = data
}

const salePer = () => {
  if (props.item.sale_of_per > 0) {
    const item = props.item
    return (item.sale_of_per / item.price) * 100
  }
}
const shareLink = ref('')

const doMainCombine = () => {
  const path = route.fullPath
  const domain = 'https://yeondeul.com'
  // const domain = 'localhost:3000'
  shareLink.value = domain + path
}

const { text, copy, copied, isSupported } = useClipboard({ source: shareLink })

const notify = () => {
  toast.success('링크 주소가 복사 되었습니다.', {
    toastId: copyToastid,
    transition: toast.TRANSITIONS.FLIP,
    position: toast.POSITION.TOP_RIGHT
  })
}

const vendorAmount = ref(0)

const vendorAmountSetter = () => {
  switch (props.item.company) {
    case '연들':
      vendorAmount.value = cart.freeShipAmmount.yeondeul
      break
    case '자기다움':
      vendorAmount.value = cart.freeShipAmmount.jagidaum
      break
    default:
      break
  }
}

const pageReady = ref(false)
onMounted(() => {
  if (props.item) {
    pageReady.value = true
    doMainCombine()
    vendorAmountSetter()
  }
})
</script>

<style lang="scss" scoped>
.fonts {
  font-family: 'notosansCJKmd', sans-serif !important;
}
</style>
