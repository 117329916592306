<template>
  <div>
    <!-- helpCenter -->
    <section class="mt-[16px] md:mt-[75px] flex justify-center mx-2 md:mx-0">
      <helpCenter :public="props.public" />
    </section>
    <section class="mt-[8px] md:mt-[69px]">
      <div class="md:flex justify-center">
        <supportRequest class="md:hidden mx-2 md:mx-0" />
        <faqBoard class="mt-[31.5px] md:mt-0 md:mr-[23px]" :public="props.public" />
        <supportRequest class="hidden md:block" />
      </div>
    </section>
    <section class="mt-[46px] md:mt-[64px]">
      <div class="flex justify-center">
        <noticeBoard :public="props.public" />
      </div>
    </section>
  </div>
</template>

<script setup>
import faqBoard from './faqBoard.vue'
import helpCenter from './helpCenter.vue'
import noticeBoard from './noticeBoard.vue'
import supportRequest from './supportRequest.vue'

const props = defineProps({
  public: Boolean
})
</script>

<style lang="scss" scoped></style>
