<template>
  <div v-if="item && pageRady" class="">
    <section class="flex justify-center">
      <img
        :src="item.mainImg"
        alt="mainImg"
        class="md:w-[1060px] aspect-375/280 md:aspect-900/580 lg:rounded-md object-cover object-center"
      />
    </section>
    <!-- title -->
    <section class="ml-[12px] md:mx-auto w-auto md:w-[800px] leading-tight">
      <div class="mt-[14px] md:mt-[21px]">
        <p class="text-[20px] md:text-[30px] font-notosansCJKmd text-[#232323]">
          {{ item.title }}
        </p>
      </div>
      <div class="mt-[7px] md:mt-[18px]">
        <p class="text-[10px] md:text-[13px] font-notosansCJR text-[#707070]">
          {{ dayjs(`${item.date}`).format('YYYY년 MM월 DD일') }} By {{ item.author }}
        </p>
      </div>
    </section>
    <section class="hidden w-[800px] mx-auto md:flex justify-end mb-[5px]">
      <div>
        <i
          class="pi pi-share-alt text-[#707070] cursor-pointer text-[18px]"
          @click="copy(), notify()"
        >
        </i>
        <i
          class="pi pi-print ml-[34px] text-[#707070] cursor-pointer text-[18px]"
          @click="printPage()"
        >
        </i>
      </div>
    </section>
    <section class="mx-2 md:mx-auto w-auto md:w-[800px] mt-[14px] md:mt-[1px]">
      <hr class="border-1 border-whuite" />
    </section>
    <!-- 에디터 리더 -->
    <section class="w-full md:w-[800px] mt-[14px] md:mt-[23px] md:mx-auto">
      <div class="w-full h-auto">
        <!-- <editor_core class="w-full" /> -->
        <editorReadOnly :editors="item.editor" class="w-full" />
      </div>
    </section>
    <section class="mx-2 md:mx-auto w-auto md:w-[800px] mt-[36px]">
      <hr class="border-1 border-whuite" />
    </section>
    <!-- 하단 아이콘 -->
    <section class="w-auto md:w-[800px] mx-auto flex justify-end mt-[18px] md:mt-[20px]">
      <div class="flex pr-3 md:pr-0">
        <i
          class="pi pi-share-alt text-[#707070] cursor-pointer md:text-[18px]"
          @click="copy(), notify()"
        ></i>
        <i
          class="pi pi-print ml-8 text-[#707070] cursor-pointer md:text-[18px]"
          @click="printPage()"
        ></i>
      </div>
    </section>
    <section v-if="item.img !== ''" class="md:w-[1182px] mx-auto mt-[80px]">
      <p class="ml-[12px] md:ml-0 text-[17px] md:text-[18px] font-notosansCJKbold text-[#389033]">
        이번 포스팅에 사용된 소품이에요!
      </p>
    </section>
    <section class="w-full flex justify-center mt-[11px] md:mt-[22px]">
      <div class="w-full md:w-[1060px]">
        <div class="mx-auto">
          <postImgUtil
            v-if="item.img"
            :data="item.img"
            :index="selectIndex"
            :badgeList="item.badgeList.batchList"
            :main="true"
            :key="selectIndex"
          />
        </div>
      </div>
    </section>
    <section class="lg:w-[1182px] mx-auto mt-[87px]">
      <p
        class="ml-[12px] lg:ml-[9px] text-[17px] lg:text-[18px] font-notosansCJKbold text-[#389033]"
      >
        이런 매거진은 어떠세요?
      </p>
    </section>
    <section class="mx-2 hidden lg:block lg:mx-auto w-auto lg:w-[1182px] mt-[6px]">
      <hr class="border-1 border-whuite" />
    </section>
    <section
      v-if="dataReady"
      class="mx-0 lg:mx-auto w-auto lg:w-[1182px] mt-[6px] mb-[44px] lg:mb-[70px]"
    >
      <!-- <postMain /> -->
      <postListMain :data="randomPost" :type="'최신글'" />
    </section>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import editorReadOnly from '../test/editor-readOnly.vue'
import postImgUtil from '../util/postImgUtill/postImgUtil.vue'
import postListMain from './postList.vue/postListMain.vue'
import { sendRequest } from '../../api/serviceApi'
import { ref, onMounted, watchEffect, watch } from 'vue'
import { useClipboard } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { toast } from 'vue3-toastify'
import { useSystemStore } from '../../stores/useSystem'

const route = useRoute()
const system = useSystemStore()

// 링크 공유
const shareLink = ref('')
const doMainCombine = () => {
  const path = route.fullPath
  const domain = 'https://yeondeul.com'
  // const domain = 'http://localhost:3000'
  shareLink.value = domain + path
}
const copyToastid = 'copyToastIsAlive'
const notify = () => {
  toast.success('링크 주소가 복사 되었습니다.', {
    toastId: copyToastid,
    transition: toast.TRANSITIONS.FLIP,
    position: toast.POSITION.TOP_RIGHT
  })
}

const { text, copy, copied, isSupported } = useClipboard({ source: shareLink })

const props = defineProps({
  item: Object
})

const pageRady = ref(false)
const dataReady = ref(false)
const selectIndex = ref({})

const randomPost = ref(null)

const getAllBlogs = async () => {
  const getPost = await sendRequest({ method: 'post', path: '/post/getPostMain' })
  const result = getPost.data
  if (result) {
    // console.log(result)
    randomPost.value = shuffleArray(result.new)
    dataReady.value = true
  } else {
    console.error(result.message)
  }
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // j는 0과 i 사이의 무작위 정수
    // 배열의 요소를 서로 교환
    ;[array[i], array[j]] = [array[j], array[i]] // 여기에서 배열을 교환할 때 세미콜론 추가
  }
  return array
}

const printPage = () => {
  window.print() // 현재 페이지를 프린터로 출력
}

// 게시글 데이터를 로드하는 함수
const fetchPostDetails = (id) => {
  // id를 사용해 데이터를 가져오는 로직을 여기에 작성
  console.log(`Fetching post with ID: ${id}`)
}

onMounted(() => {
  pageRady.value = true
  fetchPostDetails(route.params.id)
  // system.isPrint = true
  doMainCombine()
})

watch(
  () => route.params.id,
  (newId) => {
    pageRady.value = false
    fetchPostDetails(newId)
    location.reload()
    pageRady.value = true
  }
)

watchEffect(() => {
  if (pageRady.value === true) {
    getAllBlogs()
  }
})
</script>

<style lang="scss" scoped></style>
