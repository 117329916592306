<template>
  <div>
    <div>
      <div class="flex justify-center mt-[32.5px] md:mt-[91px]">
        <section>
          <div class="flex justify-center">
            <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
              비밀번호 변경
            </p>
          </div>
          <!-- pc -->
          <div class="hidden md:block mt-[20px] text-center">
            <p class="text-[16px] font-notosansCJR text-[#6D6D6D]">
              비밀번호는 암호화되어 저장되어 연들 고객센터에서도
            </p>
            <p class="text-[16px] font-notosansCJR text-[#6D6D6D]">
              기존 비밀번호를 확인해드릴 수 있는 방법이 없습니다.
            </p>
            <p class="text-[16px] font-notosansCJR text-[#6D6D6D] mt-[15px]">
              본인확인을 통해 새로운 비밀번호로 변경해드리도록 하겠습니다.
            </p>
          </div>
          <!-- mobile -->
          <section class="md:hidden">
            <div class="flex justify-center my-[17px]">
              <img src="/img/password/padlock.webp" alt="" />
            </div>
            <p class="text-[12px] font-notosansCJR text-[#6D6D6D] text-center">
              비밀번호는 암호화되어 저장되어 연들 고객센터에서도
            </p>
            <p class="text-[12px] font-notosansCJR text-[#6D6D6D] mt-[1px] text-center">
              기존 비밀번호를 확인해드릴 수 있는 방법이 없습니다.
            </p>
            <p class="text-[12px] font-notosansCJR text-[#6D6D6D] mt-[8px] text-center">
              본인확인을 통해 새로운 비밀번호로 변경해드리도록 하겠습니다.
            </p>
          </section>
        </section>
      </div>
      <!-- 인증 -->
      <div class="flex justify-center mt-[37.5px] md:mt-[91px]">
        <section class="">
          <div class="">
            <p
              class="text-[12px] md:text-[18px] font-notosansCJKmd md:font-notosansCJR text-[#232323] md:text-[#6D6D6D]"
            >
              ID (이메일)
            </p>
            <div class="flex mt-[6.5px] md:mt-[13.5px]">
              <input
                type="text"
                class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
                placeholder="가입하신 이메일을 입력해주세요"
                v-model="password.passData.stage0.emaill"
              />
            </div>
            <p v-if="emailError.status" class="text-red-500 text-[11px] ml-2 mb-2">
              {{ emailError.message }}
            </p>
          </div>
          <div class="mt-[23.5px] md:mt-[31.5px]">
            <p
              class="text-[12px] md:text-[18px] font-notosansCJKmd md:font-notosansCJR text-[#232323] md:text-[#6D6D6D]"
            >
              성함
            </p>
            <div class="flex mt-[6.5px] md:mt-[13.5px]">
              <input
                type="text"
                class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
                placeholder="성함을 입력해주세요"
                v-model="password.passData.stage0.name"
              />
            </div>
          </div>
        </section>
      </div>
      <div class="mt-[56px] md:mt-[85px] mb-0 md:mb-[191px]">
        <section class="">
          <div class="flex justify-center">
            <button
              class="border border-[#389033] md:border-[#0C370A] w-[320px] md:w-[569px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033] bg-transparent"
              @click="stepToStage()"
            >
              다음 단계
            </button>
          </div>
          <div class="hidden md:block mt-[31.5px]">
            <p class="text-[14px] font-notosansCJR text-[#D23F3F] mt-[8px] text-center">
              회원가입시 입력한 정보가 기억나지 않는다면 고객센터 (이메일, 전화 등)를 통해
              문의해주세요
            </p>
          </div>
          <section class="md:hidden text-center mt-[64.5px]">
            <div class="">
              <p class="text-[11px] font-notosansCJR text-[#D23F3F] mt-[8px] text-center">
                회원가입시 입력한 정보가 기억나지 않는다면
              </p>
              <p class="text-[11px] font-notosansCJR text-[#D23F3F] text-center">
                고객센터 (이메일, 전화 등)를 통해 문의해주세요
              </p>
            </div>
          </section>
          <section class="mt-[13.5px] md:mt-[9px]">
            <div class="flex justify-center">
              <p class="text-[14px] md:text-[16px] font-notosansCJKmd text-[#6D6D6D]">
                고객센터 가기
              </p>
            </div>
          </section>
        </section>
      </div>
      <div class="mt-[86px] md:hidden">
        <p class="text-center text-[10px] font-notosansCJR text-[#6D6D6D] my-[27px]">
          ⓒ ruruci.inc All rights reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { sendRequest } from '../../../api/serviceApi'
import { usePasswordStore } from '../../../stores/usePassword'
const password = usePasswordStore()
// 입력 검증기

const emailError = ref({
  message: '',
  status: false
})
const validateEmail = async () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!emailPattern.test(password.passData.stage0.emaill)) {
    emailError.value.message = '올바른 이메일 형식이 아닙니다.'
    emailError.value.status = true
    password.validateEmail = false
  } else {
    emailError.value.message = ''
    emailError.value.status = false
    password.validateEmail = true
  }
}

watchEffect(() => {
  if (password.passData.stage0.emaill) {
    validateEmail()
  }
})
const stepToStage = async () => {
  const payload = {
    email: password.passData.stage0.emaill,
    name: password.passData.stage0.name
  }
  if (password.validateEmail === false) {
    alert('올바른 형식의 이메일 주소를 입력해 주세요.')
    return
  }
  if (payload.email === '' || payload.name === '') {
    alert('필수 정보를 모두 입력해 주세요.')
    return
  }
  const reqData = await sendRequest({
    method: 'post',
    path: '/users/findUerByEmailAndName',
    data: payload
  })
  const result = reqData.data
  if (result === 'good') {
    password.emailAuth = true
    password.passStage = 1
  } else {
    alert('일치하는 유저를 찾을 수 없습니다.')
    return
  }
}
</script>

<style lang="scss" scoped></style>
