<template>
  <div class="swiper-container">
    <swiper :slides-per-view="'auto'" :space-between="spaces" :loop="false" class="swiper-wrapper">
      <swiper-slide v-for="menu in menuDatas" :key="menu.route">
        <div :class="menu.class" class="cursor-pointer" @click="routerToLink(menu.route)">
          {{ menu.title }}
        </div>
      </swiper-slide>
      <swiper-slide v-for="list in categoryDatas" :key="list.link">
        <div class="text-center text-[15px] cursor-pointer font-notosansCJKmd">
          <router-link :to="{ path: `/shop/${list.link}`, query: { type: list.title } }">
            {{ list.title }}
          </router-link>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, watch } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useRouter } from 'vue-router'
import mainCatData from '../../data/mainCatData'

const router = useRouter()
const slideView = ref('auto')
const spaces = ref(20)

const onSlideChange = () => {
  console.log('slide change')
}

const postPush = (path) => {
  router.push(`${path}`)
}

const categoryDatas = {
  ...mainCatData
}

const data = ref([
  {
    title: '브랜드소개',
    link: '',
    route: '/about'
  }
])

const menuDatas = [
  {
    title: '브랜드',
    route: '/about',
    class: 'text-center text-[15px] text-[#404040] font-notosansCJKmd cursor-pointer'
  },
  {
    title: '매거진',
    route: '/post',
    class: 'text-center text-[15px] text-[#404040] font-notosansCJKmd cursor-pointer'
  },
  {
    title: '│',
    route: '',
    class: 'text-center text-[15px] text-[#404040] font-notosansCJKmd cursor-pointer'
  }
]

const routerToLink = (link) => {
  router.push(link)
}
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 95%;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
