<template>
  <div v-if="pageRady" class="w-full" ref="conttainers">
    <!-- title -->

    <!-- pc -->
    <div class="hidden lg:flex justify-start items-start mt-[28px] ml-[64px]">
      <div class="" v-for="(data, index) in postData" :key="'mini' + index">
        <div class="mr-[25px]">
          <router-link v-if="index < 3" :to="{ path: `/post/details/${data._id}` }">
            <homePostSmallCard v-if="index < 4" :data="data" class="" />
          </router-link>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="swiper-container lg:hidden mt-[11.5px]">
      <swiper
        :slides-per-view="'auto'"
        :space-between="spaces"
        :loop="false"
        class="swiper-wrapper"
      >
        <swiper-slide v-for="(data, index) in postData" :key="'swiper' + index">
          <router-link :to="{ path: `/post/details/${data._id}` }">
            <homePostSmallCard :data="data" class="" />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <!-- w: {{ width }}px, h: {{ height }}px -->
  </div>
</template>

<script setup>
import homePostSmallCard from './homePostSmallCard.vue'
import { useElementSize } from '@vueuse/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

import { onMounted, ref, watchEffect } from 'vue'
import { sendRequest } from '../../../api/serviceApi'
const pageRady = ref(false)

const conttainers = ref(null)
const { width, height } = useElementSize(conttainers)
const spaces = ref(10)
const postData = ref(null)

const getPostData = async () => {
  const result = await sendRequest({ method: 'post', path: '/post/getPostAll' })
  if (result) {
    // console.log(result)
    postData.value = result.data
  } else {
    console.error(result.message)
  }
}

onMounted(() => {
  pageRady.value = true
})

watchEffect(() => {
  if (pageRady.value === true) {
    getPostData()
  }
})
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  position: relative;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}

.swiper-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px; /* 그라디언트의 너비, 필요에 따라 조절 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  ); /* 왼쪽에서 오른쪽으로 투명에서 완전 
    불투명으로 */
  z-index: 2;
}
</style>
