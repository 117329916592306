import { defineStore } from 'pinia'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

export const useSystemStore = defineStore(
  'system',
  () => {
    // defineStore
    const compoReady = ref({
      saleArea: false,
      bsetArea: false
    })
    const herf = ref()
    const payAgree = ref(false)
    const imgModal = ref(false)
    const isEdit = ref(false)
    const editDetails = ref(null)
    const productOptions = ref(null)
    const postEditor = ref(false)
    const pricefilter = ref([0, 300000])
    const maincattag = ref()
    const adminPostStatus = ref(false)
    const adminPostEndPoint = ref('')
    const adminPostItems = ref()
    const adminRefresh = ref(false)
    const productAdd = ref(false)
    const userEditData = ref()
    const userEdit = ref(false)
    const reviewPhoto = ref([])
    const tempReviewData = ref(null)
    const reviewDetailModal = ref(false)
    const extraProductEdit = ref(false)
    const editorDatas = ref('')
    const loginModals = ref(false)
    const registerModals = ref(false)
    const inquiryModals = ref(false)
    const productEditPayload = ref(null)
    const reviewImgModal = ref(false)
    const reviewImgArray = ref([])
    const reviewModal = ref(false)
    const cancleModal = ref(false)
    const refundModal = ref(false)
    const exchangeModal = ref(false)
    const myAddrModal = ref(false)
    const historyModal = ref(false)
    const orderInquirieModal = ref(false)
    const changePasswordModal = ref(false)
    const mobileMenu = ref(false)
    const adminShippingInputModal = ref(false)
    const reviewEdit = ref(false)
    const reviewData = ref(null)
    const boardEdit = ref(false)
    const isPrint = ref(false)
    const adminExchangeModal = ref(false)
    const adminRefundModal = ref(false)
    const autoLogin = ref(false)
    const changeMobileModal = ref(false)
    const addressListModal = ref(false)
    function $postEditDone() {
      postEditor.value = false
    }

    function $closeEditor() {
      isEdit.value = false
    }

    function $restOptions() {
      productOptions.value = null
    }

    const infoData = ref({
      name: '',
      address: '',
      subAddress: '',
      email: '',
      mobile: ''
    })

    const payUserInfoChecker = computed(() => {
      let condition = {
        name: infoData.value.name,
        address: infoData.value.address,
        subAddress: infoData.value.subAddress,
        email: infoData.value.email,
        mobile: infoData.value.mobile
      }

      // Object.values()를 사용하여 condition 객체의 값들을 배열로 변환
      const values = Object.values(condition)

      // values 배열의 각 요소를 검사하여 빈 문자열이 있는지 확인
      const blankChecker = values.some((value) => value === '')

      // console.log('blank checker is : ', blankChecker)
      return blankChecker
    })

    const serverTime = ref(null)

    function updateServerTime() {
      if (typeof window === 'undefined') {
        return // window 객체가 없으면 아무 것도 하지 않음
      }

      let xmlHttpRequest
      if (window.XMLHttpRequest) {
        // code for Firefox, Mozilla, IE7, etc.
        xmlHttpRequest = new XMLHttpRequest()
      } else if (window.ActiveXObject) {
        // code for IE5, IE6
        xmlHttpRequest = new ActiveXObject('Microsoft.XMLHTTP')
      } else {
        return
      }

      xmlHttpRequest.open('HEAD', window.location.href.toString(), false)
      xmlHttpRequest.setRequestHeader('ContentType', 'text/html')
      xmlHttpRequest.send('')

      const serverDate = xmlHttpRequest.getResponseHeader('Date')
      serverTime.value = new Date(serverDate)
    }

    // Vue 컴포넌트의 라이프사이클 훅을 사용하여 타이머 설정
    onMounted(() => {
      updateServerTime() // 초기 로드 시 시간 업데이트
      const intervalId = setInterval(updateServerTime, 1000) // 1분마다 시간 업데이트

      onUnmounted(() => {
        clearInterval(intervalId) // 컴포넌트가 언마운트되면 타이머 해제
      })
    })

    return {
      herf,
      payAgree,
      imgModal,
      isEdit,
      editDetails,
      $closeEditor,
      productOptions,
      $restOptions,
      postEditor,
      pricefilter,
      $postEditDone,
      serverTime,
      adminPostStatus,
      adminPostEndPoint,
      adminPostItems,
      adminRefresh,
      productAdd,
      maincattag,
      compoReady,
      userEditData,
      userEdit,
      reviewPhoto,
      tempReviewData,
      reviewDetailModal,
      extraProductEdit,
      payUserInfoChecker,
      infoData,
      editorDatas,
      loginModals,
      registerModals,
      inquiryModals,
      productEditPayload,
      reviewImgModal,
      reviewImgArray,
      reviewModal,
      cancleModal,
      refundModal,
      exchangeModal,
      myAddrModal,
      historyModal,
      orderInquirieModal,
      changePasswordModal,
      mobileMenu,
      adminShippingInputModal,
      reviewEdit,
      reviewData,
      boardEdit,
      isPrint,
      adminExchangeModal,
      adminRefundModal,
      autoLogin,
      changeMobileModal,
      addressListModal
    }
  },
  {
    // persist: true
  }
)
