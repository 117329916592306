let serial = 0
let defineDuplicate = 0
export const orderNumber = (serverTime) => {
  const today = serverTime
  const year = today.getFullYear().toString().slice(3) // 년
  const month = String(today.getMonth() + 1).padStart(2, '0') // 월
  const day = String(today.getDate()).padStart(2, '0') // 일
  const time = today.toTimeString().slice(0, 8).replace(/:/g, '') // 시분초
  return `${year}${month}${day}${time}${defineDuplicate++}`
}

export const detailNumberGenerator = (serverTime) => {
  const today = serverTime
  const year = today.getFullYear().toString().slice(3) // 년
  const month = String(today.getMonth() + 1).padStart(2, '0') // 월
  const day = String(today.getDate()).padStart(2, '0') // 일
  const time = today.toTimeString().slice(0, 8).replace(/:/g, '') // 시분초
  return `${year}${month}${day}${time}S${serial++}`
}

export const resetCounters = () => {
  serial = 0
  defineDuplicate = 0
}
