<template>
  <div>
    <section class="bg-[#FBFBFB]">
      <div class="pt-[24.5px] pb-[21px] ml-[13px]">
        <section>
          <div>
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">현금영수증 자진발급제 도입</p>
            <div class="flex">
              <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D] mt-[7.5px]">＊</p>
              <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D] mt-[7.5px]">
                현금영수증 미발행 고객에 한하여 국세청 임의번호 (010-000-1234)로 자동 발행 (미발행
                고객의 경우 홈택스에서 번호 수정)
              </p>
            </div>
          </div>
        </section>
        <section class="mt-[26.5px]">
          <div>
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">사업자 고객 세금계산서 발행</p>
            <div class="flex">
              <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D] mt-[7.5px]">＊</p>
              <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D] mt-[7.5px]">
                무통장 입금 고객 중 세금계산서가 필요한 사업자 고객의 경우 당사 고객센터 연락 혹은
                배송요청사항에 사업자 사항 기재시 세금계산서 발행 가능합니다.
              </p>
            </div>
          </div>
        </section>
        <section class="mt-[26.5px]">
          <div>
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">무이자 할부 안내</p>
            <div class="flex">
              <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D] mt-[7.5px]">＊</p>
              <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D] mt-[7.5px]">
                무이자 할부는 카드사 별로 혜택이 상이할 수 있으며 결제 전 무이자 혜택을 꼭 확인 하신
                후 결제를 부탁드립니다.
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
