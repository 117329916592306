<template>
  <div>
    <!-- 배송지 관리 -->
    <!-- mobile -->
    <myAddrListModal v-if="sysetem.addressListModal === true" />
    <section class="md:hidden">
      <div class="mx-3">
        <section
          class="cursor-pointer border border-[#D5D5D5] rounded-lg"
          :class="selecteData[0].class"
          @click="selectBoxCommander(0)"
        >
          <div class="">
            <div class="flex justify-center mt-[11px]">
              <p class="text-[12px] font-notosansCJKmd text-[#232323]">
                배송받은 택배사에서 수거해주세요
              </p>
            </div>
            <hr class="mt-[10px]" />
            <section class="mt-[11px] mb-[15px] ml-[18px]">
              <div class="flex justify-between">
                <div v-if="myaddr">
                  <div>
                    <p class="text-[12px] text-[#6D6D6D] font-notosansCJKmd mb-[3px]">
                      {{ myaddr.name }}
                    </p>
                    <!-- <button class="border ">
                    <p></p>
                  </button> -->
                  </div>
                  <p class="text-[12px] text-[#6D6D6D] font-notosansCJKmd mb-[3px]">
                    {{ myaddr.mobile }}
                  </p>
                  <p class="text-[12px] text-[#6D6D6D] font-notosansCJKmd mb-[3px]">
                    {{ myaddr.address }}
                  </p>
                  <p class="text-[12px] text-[#6D6D6D] font-notosansCJKmd">
                    {{ myaddr.addressDetail }}
                  </p>
                </div>
                <div class="mr-[13px]">
                  <button
                    class="w-[58px] h-[20px] border border-[#AAAAAA] rounded-md text-[10px] text-[#7D7D7D] font-notosansCJR"
                    @click="sysetem.addressListModal = true"
                  >
                    주소 변경
                  </button>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section
          class="h-[33px] border border-[#D5D5D5] rounded-lg mt-[11px] mb-[28.5px] cursor-pointer"
          :class="selecteData[1].class"
          @click="selectBoxCommander(1)"
        >
          <div class="flex justify-center mt-[7px]">
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">직접 발송 할게요</p>
          </div>
        </section>
      </div>
    </section>

    <!-- desktop -->
    <section class="hidden md:block">
      <div>
        <section
          class="cursor-pointer w-[737px] border border-[#D5D5D5] rounded-md"
          :class="selecteData[0].class"
          @click="selectBoxCommander(0)"
        >
          <div class="">
            <div class="flex justify-center mt-[14px]">
              <p class="text-[16px] font-notosansCJKmd text-[#232323]">
                배송받은 택배사에서 수거해주세요
              </p>
            </div>
            <hr class="mx-2 mt-[11px]" />
            <section>
              <div class="flex justify-between">
                <div class="mt-[18px] mb-[22px] ml-[20px]">
                  <div v-if="myaddr">
                    <p class="text-[15px] text-[#6D6D6D] font-notosansCJKmd">{{ myaddr.name }}</p>
                    <p class="text-[15px] text-[#6D6D6D] font-notosansCJKmd">{{ myaddr.mobile }}</p>
                    <p class="text-[15px] text-[#6D6D6D] font-notosansCJKmd">
                      {{ myaddr.address }}
                    </p>
                    <p class="text-[15px] text-[#6D6D6D] font-notosansCJKmd">
                      {{ myaddr.addressDetail }}
                    </p>
                  </div>
                </div>
                <div class="mt-[43px] mr-[25px]">
                  <button
                    class="w-[100px] h-[29px] border border-[#AAAAAA] rounded-md text-[14px] text-[#7D7D7D] font-notosansCJR"
                    @click="sysetem.addressListModal = true"
                  >
                    주소 변경
                  </button>
                </div>
              </div>
            </section>
          </div>
        </section>
        <section
          class="w-[737px] h-[48px] border border-[#D5D5D5] rounded-md mt-[11px] mb-[28.5px] cursor-pointer"
          :class="selecteData[1].class"
          @click="selectBoxCommander(1)"
        >
          <div class="flex justify-center mt-[14px]">
            <p class="text-[16px] font-notosansCJKmd text-[#232323]">직접 발송 할게요</p>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue'
import { useOrderStore } from '../../../../stores/userOrder'
import { useAddressStore } from '../../../../stores/useAddress'
import { useSystemStore } from '../../../../stores/useSystem'
import myAddrListModal from '../myAddrModal/myAddrListModal.vue'
import { deepCopy } from '../../../../utils/deepCopy'

// setup
const order = useOrderStore()
const address = useAddressStore()
const sysetem = useSystemStore()

// state
let myaddr = ref(order.orderDetailData.orderData.payUserInfo.receiverInfo)

const selecteData = ref([
  {
    value: 'pickUp',
    class: ''
  },
  {
    value: 'send',
    class: ''
  }
])

// action
const selectBoxCommander = (i) => {
  order.refundDats.stage1.refundMethod = selecteData.value[i].value
  if (i === 0) {
    order.refundDats.stage1.refundDetaild = myaddr.value
  }
  // if (i === 1) {
  //   order.refundDats.stage1.refundDetaild = {}
  // }
  selecteData.value.forEach((selData) => {
    selData.class = ''
  })
  selecteData.value[i].class = 'border-[1px] border-green-700'
}

watchEffect(() => {
  if (order.refundDats.stage1.refundDetaild) {
    myaddr.value = deepCopy(order.refundDats.stage1.refundDetaild)
  }
})

// lifeCycle
onMounted(async () => {
  await address.getAddressByUser()
  myaddr.value = order.orderDetailData.orderData.payUserInfo.receiverInfo // 참조
})
</script>

<style lang="scss" scoped></style>
