import { ref, computed, watchEffect, watch } from 'vue'
import { defineStore } from 'pinia'
import { sendRequest } from '../api/serviceApi'

export const useProductStore = defineStore('product', () => {
  // 상태정의

  const products = ref(null) // 제품목록
  const productItem = ref(null)
  const modalEditData = ref(null)
  const vendorAmount = ref(0)
  const product_option_selected = ref({
    done: [],
    parnet: []
  })
  const minPrice = ref(0)
  const maxPrice = ref(25)
  const setMaxPrice = ref(0)
  const shopShortFilter = ref('신상품순')

  const filterList = ref({
    priceFilter: {
      check: false,
      price: computed(() => [minPrice.value * 10000, maxPrice.value * 10000]),
      class: 'w-[75px] text-[#7D7D7D]'
    },
    brandFilter: {
      check: false,
      list: [],
      class: 'w-[97px] text-[#7D7D7D]'
    },
    groupFilter: {
      check: false,
      list: [],
      class: 'w-[109px] text-[#7D7D7D]'
    },
    productFilter: {
      check: false,
      list: [],
      class: 'w-[109px] text-[#7D7D7D]'
    },
    defaultFIlter: {
      check: false,
      list: '',
      class: ''
    }
  })

  // watch([minPrice, maxPrice], () => {
  //   filterList.value.priceFilter.price = [minPrice.value * 10000, maxPrice.value * 10000]
  // })

  const resetFilter = () => {
    filterList.value = {
      priceFilter: {
        check: false,
        price: computed(() => [minPrice.value * 10000, maxPrice.value * 10000]),
        class: 'w-[75px] text-[#7D7D7D]'
      },
      brandFilter: {
        check: false,
        list: [],
        class: 'w-[97px] text-[#7D7D7D]'
      },
      groupFilter: {
        check: false,
        list: [],
        class: 'w-[109px] text-[#7D7D7D]'
      },
      productFilter: {
        check: false,
        list: [],
        class: 'w-[109px] text-[#7D7D7D]'
      },
      defaultFIlter: {
        check: false,
        list: '',
        class: ''
      }
    }
  }
  // 게터, 불변 속성을 계산해서 리턴

  const calculateTotalPrice = computed(() => {
    if (productItem.value === null || product_option_selected.value.done.length === 0) {
      return
    }
    const salePrice = productItem.value.sale_of_per
    let totalPrice = 0

    product_option_selected.value.done.forEach((option) => {
      const { ea, fixPrice } = option
      let itemPrice = fixPrice * ea

      // Apply discount if available
      // if (salePrice) {
      //   itemPrice -= salePrice * ea
      // }

      totalPrice += itemPrice
    })

    return totalPrice
  })

  const productSorter = computed(() => {
    if (products.value) {
      return products.value.reduce(
        (acc, product) => {
          const { category, company } = product

          if (category.parent.catName && !acc.catParent.includes(category.parent.catName)) {
            acc.catParent.push(category.parent.catName)
          }

          if (category.child.catName && !acc.catChild.includes(category.child.catName)) {
            acc.catChild.push(category.child.catName)
          }

          if (company && !acc.company.includes(company)) {
            acc.company.push(company)
          }

          return acc
        },
        {
          catParent: [],
          catChild: [],
          company: []
        }
      )
    }
  })

  // const filteredProducts = computed(() => {
  //   if (!products.value) {
  //     return
  //   }
  //   return products.value.filter((product) => {
  //     // 1. 필터 정보가 없거나 필터가 활성화되지 않았으면 모든 제품 출력
  //     if (
  //       !filterList.value.priceFilter.check &&
  //       !filterList.value.brandFilter.check &&
  //       !filterList.value.groupFilter.check &&
  //       !filterList.value.productFilter.check
  //     ) {
  //       return true
  //     }

  //     // 2. 가격 필터링
  //     const matchPrice =
  //       !filterList.value.priceFilter.check ||
  //       (product.price >= filterList.value.priceFilter.price[0] &&
  //         product.price <= filterList.value.priceFilter.price[1])

  //     // 3. 브랜드 필터링
  //     const matchBrand =
  //       !filterList.value.brandFilter.check ||
  //       filterList.value.brandFilter.list.includes(product.company)

  //     // 4. 카테고리 필터링 (부모 카테고리 및 자식 카테고리)
  //     const matchCategory =
  //       !filterList.value.groupFilter.check ||
  //       filterList.value.groupFilter.list.includes(product.category.parent.catName)

  //     // 5. 제품 필터링 (특정 제품 카테고리 필터링)
  //     const matchProduct =
  //       !filterList.value.productFilter.check ||
  //       filterList.value.productFilter.list.includes(product.category.child.catName)

  //     // 모든 조건을 만족하는 제품만 반환
  //     return matchPrice && matchBrand && matchCategory && matchProduct
  //   })
  // })

  const filteredProducts = computed(() => {
    if (!products.value || products.value.length === 0) {
      return [] // 데이터를 아직 로드하지 않았을 때 빈 배열 반환
    }

    return products.value.filter((product) => {
      // 필터가 활성화되지 않은 경우 모든 제품 반환
      if (
        !filterList.value.priceFilter.check &&
        !filterList.value.brandFilter.check &&
        !filterList.value.groupFilter.check &&
        !filterList.value.productFilter.check
      ) {
        return true
      }

      // 가격 필터링
      const matchPrice =
        !filterList.value.priceFilter.check ||
        (product.price - product.sale_of_per >= filterList.value.priceFilter.price[0] &&
          product.price - product.sale_of_per <= filterList.value.priceFilter.price[1])

      // 브랜드 필터링
      const matchBrand =
        !filterList.value.brandFilter.check ||
        filterList.value.brandFilter.list.includes(product.company)

      // 카테고리 필터링
      const matchCategory =
        !filterList.value.groupFilter.check ||
        filterList.value.groupFilter.list.includes(product.category.parent.catName)

      // 제품 필터링
      const matchProduct =
        !filterList.value.productFilter.check ||
        filterList.value.productFilter.list.includes(product.category.child.catName)

      // 모든 조건을 만족하는 경우만 반환
      return matchPrice && matchBrand && matchCategory && matchProduct
    })
  })

  // const filterResult = computed(() => {
  //   return product.value.filter(() => {})
  // })

  // 액션, 가변적 실행 함수 모음
  const getVendor = async () => {
    const getShippingData = await sendRequest({
      method: 'post',
      path: '/system/getSystem'
    })

    const result = getShippingData.result
    if (result === 'ok') {
      // console.log(getShippingData.data[0].vendors)
      let vedorInfo = []
      getShippingData.data[0].vendors.forEach((item) => {
        vedorInfo[item.vendor] = item.amount
      })
      // console.log('밴더 인포', vedorInfo)
      switch (productItem.value.company) {
        case '연들':
          vendorAmount.value = vedorInfo.yeondeul
          break
        case '자기다움':
          vendorAmount.value = vedorInfo.jagidaum
          break
        default:
          break
      }

      return vedorInfo
    } else {
      console.error(result)
    }
  }

  watchEffect(() => {
    if (productItem.value) {
      getVendor()
    }
  })

  // watch(products.value, (newVal) => {
  //   const priceList = newVal.map((item) => {
  //     const sellprice = item.price - item.sale_of_per
  //     return sellprice
  //   })
  //   const maxPrices = Math.max(...priceList) / 10000
  //   console.log('제품 스토어 상품 가격 리스트', maxPrices)
  //   setMaxPrice.value = maxPrices
  // })

  watchEffect(() => {
    if (products.value) {
      const priceList = products.value.map((item) => {
        const sellprice = item.price - item.sale_of_per
        return sellprice
      })
      const maxPrices = Math.max(...priceList) / 10000
      console.log('제품 스토어 상품 가격 리스트', maxPrices)
      setMaxPrice.value = maxPrices
      maxPrice.value = maxPrices
    }
  })

  const fetchProduct = async (cmd, cat, words) => {
    switch (cmd) {
      case 'getAll':
        const result = await sendRequest({
          method: 'post',
          path: '/product/getProductAll'
        })
        if (result) {
          products.value = result.data
          return
        } else {
          console.error(result.message)
        }
      case 'cateGory':
        const data = { words }
        const categoryResult = await sendRequest({
          method: 'post',
          path: '/product/productGetByCategory',
          data: data
        })
        if (categoryResult) {
          products.value = categoryResult.data
          return
        } else {
          console.error(categoryResult.message)
        }
        return console.log(words)
      case 'search':
        const payload = {
          keyword: words
        }
        const keywordSearch = await sendRequest({
          method: 'post',
          path: '/product/productGetByKeyword',
          data: payload
        })
        products.value = keywordSearch.data
        return
      case 'getById':
        const ids = { words }
        const getProductIds = await sendRequest({
          method: 'post',
          path: '/product/productGetByCategory',
          data: ids
        })
        if (categoryResult) {
          products.value = getProductIds.data
          return
        } else {
          console.error(getProductIds.message)
        }
        return console.log(words)
      default:
        break
    }
  }

  return {
    // setter
    products,
    fetchProduct,
    minPrice,
    maxPrice,
    setMaxPrice,
    productItem,
    modalEditData,
    vendorAmount,
    shopShortFilter,
    //getter
    filteredProducts,
    product_option_selected,
    calculateTotalPrice,
    productSorter,
    filterList,
    // action
    resetFilter
  }
})
