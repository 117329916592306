<template>
  <div class="w-auto lg:w-[1180px] lg:mx-auto h-[70px] lg:mt-[15px] mb-[10px] lg:mb-[15px]">
    <hr />
    <div class="pl-[5px] md:ml-0 md:flex items-center w-full justify-between mt-[12px]">
      <!-- 로고 섹션 -->
      <router-link to="/post">
        <section class="flex items-center">
          <p class="font-outfitBold text-[18px] md:text-[23px] mt-2 text-[#389033]">YEONDEUL</p>
          <p class="font-outfitMd text-[26px] md:text-[32px] ml-[4px] md:ml-[6px] text-[#232323]">
            MAGAZINE
          </p>
        </section>
      </router-link>
      <!-- 메뉴콤보 -->
      <section class="mt-[10px] md:mt-0 md:ml-[107px] flex justify-end md:justify-start w-full">
        <div class="flex items-center">
          <p
            v-for="(menu, i) in menuCombo"
            :key="'postCombo' + i"
            :class="[
              isActive(menu.path, menu.type)
                ? 'text-[#D23F3F]'
                : 'text-[#707070] lg:text-[#232323]',
              menu.class
            ]"
            class="mr-[22px] md:mr-[50px] font-notosansCJKbold text-[14px] lg:text-[16px] cursor-pointer"
            @click="goToDetails(menu.path)"
          >
            {{ menu.text }}
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const props = defineProps({
  isDetail: Boolean,
  type: String,
  isDetail: Boolean
})

const router = useRouter()
const route = useRoute()

const menuCombo = ref([
  {
    text: '매거진 홈',
    path: '/post',
    class: ' hidden md:block'
  },
  {
    text: '홈',
    path: '/post',
    class: 'md:hidden'
  },
  {
    text: '연들소식',
    path: '/post/blog',
    type: 'blog'
  },
  {
    text: '칼럼',
    path: '/post/case',
    type: 'case'
  },
  {
    text: '인테리어 ·시공사례',
    path: '/post/interior',
    type: 'interior'
  }
])

const isActive = (path, type) => {
  if (props.isDetail) {
    return type === props.type
  }
  return route.path === path
}

const goToDetails = (path) => {
  router.push(path)
}
</script>

<style lang="scss" scoped></style>
