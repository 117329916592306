<template>
  <div>
    <div class="editor-custom-container">
      <div class="editor-container editor-container_classic-editor" ref="editorContainerElement">
        <div class="editor-container__editor">
          <div ref="editorElement " class="font-notosansCJKlight">
            <ckeditor
              v-if="isLayoutReady"
              v-model="editorData"
              :editor="editor"
              :config="config"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect } from 'vue'
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  SimpleUploadAdapter,
  SpecialCharacters,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  TodoList,
  Underline,
  Undo
} from 'ckeditor5'

import translations from 'ckeditor5/translations/ko.js'
import 'ckeditor5/ckeditor5.css'

const props = defineProps({
  editors: String
})

const editor = ClassicEditor
const isLayoutReady = ref(false)
const config = ref(null)

const editorData = ref('')

const dataLoad = () => {
  editorData.value = props.editors
}

watchEffect(() => {
  if (isLayoutReady.value) {
    dataLoad()
  }
})

onMounted(() => {
  config.value = {
    toolbar: false,
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      BlockQuote,
      Bold,
      Code,
      CodeBlock,
      Essentials,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      HorizontalLine,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      SimpleUploadAdapter,
      SpecialCharacters,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      TodoList,
      Underline,
      Undo
    ],
    fontFamily: {
      supportAllValues: true
    },
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph'
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1'
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2'
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3'
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4'
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5'
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6'
        }
      ]
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true
        }
      ]
    },
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage'
      ]
    },
    initialData: '',
    language: 'ko',
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true
      }
    },
    menuBar: {
      isVisible: false
    },
    placeholder: '',
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties'
      ]
    },
    translations: [translations]
  }
  isLayoutReady.value = true
})
</script>

<style lang="scss" scoped>
::v-deep .ck-content p,
.ck-content span {
  // text-align: justify !important;
  // text-align: justify !important;
  // text-align-last: left !important;
  // max-width: 100%; /* 너비를 최대 100%로 제한 */
  line-height: 30px; /* 기본 PC용 줄간격 설정 */
  font-size: 16px !important;
  // word-spacing: 2px;
  font-family: 'notosansCJR' !important;
}

// ::v-deep .ck-widget_selected figure {
//   outline: none !important;
// }

// ::v-deep(.ck-widget_selected:hover figure) {
//   outline: none !important;
// }

::v-deep(.image.ck-widget.ck-widget_selected) {
  outline: none !important;
}
::v-deep(.image.ck-widget.ck-widget_selected:hover) {
  outline: none !important;
}

@media (max-width: 768px) {
  ::v-deep .ck-content p,
  .ck-content span {
    text-align: justify !important;
    overflow-wrap: break-word !important;
    text-size-adjust: 100%;
    line-height: 28px !important; /* 모바일용 줄간격 설정 */
    letter-spacing: -0.5px;
    word-spacing: 0em; /* 단어 간격 */
    font-size: 17px !important;
    font-family: 'notosansCJKlight' !important;
    margin-block-end: 0px !important;
    word-break: normal !important;
  }
}
</style>
