<template>
  <div>
    <passwrodResetMain />
  </div>
</template>

<script setup>
import { usePasswordStore } from '../../../stores/usePassword'
import passwrodResetMain from '../../../components/passwrod/reset/passwrodResetMain.vue'
import { onUnmounted } from 'vue'

const password = usePasswordStore()

onUnmounted(() => {
  password.passStage = 0
})
</script>

<style lang="scss" scoped></style>
