<template>
  <div class="w-auto mx-3 md:w-[1180px] md:mx-auto">
    <section class="hidden md:block">
      <div class="mt-[66px] border-b border-[#B2B2B2]">
        <p class="text-[20px] font-notosansCJKmd text-[#171717] ml-[10px] pb-[5px]">공지사항</p>
      </div>
      <div v-if="childData" class="mt-[16px]">
        <noticeBoard :item="childData" :type="types" :public="true" />
      </div>
      <pagination
        class="mt-[60px]"
        :numberOfData="6"
        :data="board.announceData"
        @update-data="handleData"
      />
    </section>

    <div class="md:hidden pb-[58px]">
      <!-- 모바일 헤더 -->
      <section class="py-[15.5px] bg-[#FBFBFB]">
        <div>
          <p class="text-[16px] font-notosansCJKmd text-[#171717] text-center">공지사항</p>
        </div>
      </section>
      <!-- 모바일 공지 바디 -->
      <section>
        <div class="">
          <mobileBoard :item="childData" :type="types" :public="true" />
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import noticeBoard from '../../components/account/notices/noticeBoard.vue'
import pagination from '../../components/util/pagination.vue'
import mobileBoard from '../../components/account/notices/mobileBoard.vue'

import { useBoardStore } from '../../stores/useBoard'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
const types = route.params.id
const getAnnounceData = ref(null)
const board = useBoardStore()
const childData = ref('')
const handleData = (data) => {
  childData.value = data
}

onMounted(() => {
  board.getAnnounceData()
})
</script>

<style lang="scss" scoped></style>
