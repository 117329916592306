<template>
  <div v-if="props && props.item && tempCategory.parent && tempCategory.child">
    <productInquiryUserModal
      v-if="system.inquiryModals === true"
      class="transition ease-in-out delay-100"
    />
    <div class="ml-[19px] md:ml-0 flex items-center text-[13px] md:text-[16px] font-notosansCJKmd">
      <router-link
        :to="{
          path: `/shop/${tempCategory.parent.catTag}`,
          query: { type: tempCategory.parent.catName }
        }"
      >
        <button class="text-[#A9A9A9]">{{ tempCategory.parent.catName }}</button>
      </router-link>
      <div v-if="tempCategory.child.catName" class="flex items-center">
        <i class="pi pi-angle-right text-[#707070] text-[16px] mx-[8px]"></i>
        <button class="text-[#707070]">{{ tempCategory.child.catName }}</button>
      </div>
    </div>
    <div class="w-full h-full md:w-[1108px] mt-[10px] md:mt-[20px]">
      <div class="md:flex">
        <productDetailImg :imgArray="item" />
        <div class="ml-0 mt-[17.5px] md:mt-0 md:ml-[37.9px] w-full md:w-[493px]">
          <productDetail :item="props.item" class="md:mx-auto" />
        </div>
        <i
          class="hidden md:block pi pi-share-alt text-[22px] h-[22px] cursor-pointer delay-75 hover:text-gray-500 transition ease-in-out hover:drop-shadow-lg"
          @click="copy(), notify()"
        ></i>
      </div>
    </div>
    <div class="md:mt-[60px]">
      <prodcutDesMainVue />
    </div>
  </div>
</template>

<script setup>
import { useSystemStore } from '../../stores/useSystem'
import { ref, onMounted, watchEffect } from 'vue'
import { toast } from 'vue3-toastify'
import { useRoute } from 'vue-router'
import { useClipboard } from '@vueuse/core'

import productDetail from './productDetail.vue'
import productDetailImg from './productDetail/productDetailImg.vue'
import productInquiryUserModal from './productDescription/productInquiry/productInquiryUserModal.vue'
import reviewDetail from '../review/reviewDetail.vue'
import prodcutDesMainVue from './prodcutDesMain.vue'

import 'primeicons/primeicons.css'
import 'vue3-toastify/dist/index.css'

const system = useSystemStore()
const route = useRoute()

const props = defineProps({
  item: Object,
  review: Object
})

const tempCategory = ref({})

const shareLink = ref('')

const doMainCombine = () => {
  const path = route.fullPath
  const domain = 'https://yeondeul.com'
  // const domain = 'http://localhost:3000'
  shareLink.value = domain + path
}

const copyToastid = 'copyToastIsAlive'

const notify = () => {
  toast.success('링크 주소가 복사 되었습니다.', {
    toastId: copyToastid,
    transition: toast.TRANSITIONS.FLIP,
    position: toast.POSITION.TOP_RIGHT
  })
}

const { text, copy, copied, isSupported } = useClipboard({ source: shareLink })

onMounted(() => {
  doMainCombine()
  setTimeout(() => {
    tempCategory.value = {
      parent: {
        catName: props.item?.category?.parent?.catName || '카테고리 없음',
        catParent: '',
        catTag: props.item?.category?.parent?.catTag
      },
      child: {
        catName: props.item?.category?.child?.catName || null,
        catParent: props.item?.category?.parent?.catTag,
        catTag: props.item?.category?.child?.catTag
      }
    }
  }, 100)
})
</script>

<style lang="scss" scoped></style>
