<template>
  <div>
    <div class="">
      <table class="w-full">
        <thead class="border-b-[1px] border-[#D23F3F]">
          <tr class="">
            <th class="py-[12px] w-[12%] text-[15px] font-notosansCJKmd text-[#0D0D0D]">No</th>
            <th class="py-[12px] w-[60%] text-[15px] font-notosansCJKmd text-[#0D0D0D]">제목</th>
            <th class="py-[12px] text-[15px] font-notosansCJKmd text-[#0D0D0D]">등록일</th>
          </tr>
        </thead>
        <tbody class="w-full">
          <tr
            v-for="(items, index) in reversedItems"
            :key="index + 'board'"
            class="border-b h-[52px]"
          >
            <td class="w-[12%] text-center text-[15px] font-notosansCJKmd text-[#232323]">
              {{ items.boardNumber }}
            </td>
            <td
              v-if="!readonly"
              class="w-[60%] text-left text-[15px] font-notosansCJKmd text-[#232323] pl-[45px]"
            >
              <router-link
                :to="{
                  path: `${pathSelector}`,
                  query: { ad: items._id, type: `${type}` }
                }"
              >
                {{ items.boardTitle }}
              </router-link>
            </td>
            <td
              v-else
              class="w-[60%] text-left text-[15px] font-notosansCJKmd text-[#232323] pl-[45px]"
            >
              {{ items.boardTitle }}
            </td>
            <td class="text-center text-[15px] font-notosansCJKmd text-[#232323]">
              {{ dayjs(items.createAt).format('YYYY/MM/DD') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { computed } from 'vue'

const props = defineProps({
  item: Array,
  readonly: Boolean,
  type: String,
  public: Boolean
})

const pathSelector = computed(() => {
  if (props.public) {
    return '/noticeDetail'
  } else {
    return '/account/noticeDetail'
  }
})

// item이 배열인지 확인 후 처리
const reversedItems = computed(() => {
  if (Array.isArray(props.item)) {
    return [...props.item].reverse()
  } else {
    return []
  }
})
</script>

<style lang="scss" scoped></style>
