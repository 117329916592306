<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div class="w-[365px] md:w-[550px] h-auto bg-white rounded-xl overflow-y-clip">
      <!-- 헤더 -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          비밀번호 변경
        </p>
        <button class="absolute right-[15px] md:right-[31px]">
          <i
            class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
            @click="system.changePasswordModal = false"
          ></i>
        </button>
      </div>
      <!-- 헤더 -->
      <hr class="mx-2" />
      <section class="md:hidden">
        <div>
          <section class="mt-[31.5px]">
            <div class="flex justify-center">
              <img
                src="../../../../../public/img/password/padlock.webp"
                alt=""
                class="w-[48px] h-[48px] object-cover"
              />
            </div>
            <div class="mt-[24px]">
              <p class="text-center text-[12px] font-notosansCJKmd text-[#232323]">
                기존 비밀번호를 입력해주세요
              </p>
            </div>
          </section>
          <section>
            <div class="flex justify-center">
              <section>
                <input
                  type="password"
                  class="border w-[343.22px] md:w-[492px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
                  placeholder="기존 비밀번호"
                  v-model="password.nowPassword"
                />
              </section>
            </div>
          </section>
          <section class="mt-[38px] mb-[19px]">
            <passwordChangeForm />
          </section>
          <section class="mt-[61px]">
            <hr class="mx-3" />
            <div class="flex justify-center mb-[16px] mt-[10.5px]">
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#D5D5D5] rounded-lg md:rounded-md"
                @click="system.changePasswordModal = false"
              >
                <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
              </button>
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
                @click="resetPassword()"
              >
                <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">변경하기</p>
              </button>
            </div>
          </section>
        </div>
      </section>
      <section class="hidden md:block">
        <div>
          <section class="mt-[54px]">
            <div class="flex justify-center">
              <img src="../../../../../public/img/password/padlock.webp" alt="" />
            </div>
            <div class="mt-[43px]">
              <p class="text-center text-[18px] font-notosansCJKmd text-[#232323]">
                기존 비밀번호를 입력해주세요
              </p>
            </div>
          </section>
          <section>
            <div class="flex justify-center">
              <section>
                <input
                  type="password"
                  class="border w-[343.22px] md:w-[492px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
                  placeholder="기존 비밀번호"
                  v-model="password.nowPassword"
                />
              </section>
            </div>
          </section>
          <section class="mt-[56px] mb-[86px]">
            <passwordChangeForm />
          </section>
          <hr class="mx-2" />
          <!-- 버튼 제어 -->
          <div class="flex justify-center mb-[16px] mt-[16.5px]">
            <button
              class="w-[226px] h-[51px] border border-[#D5D5D5] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
              @click="system.changePasswordModal = false"
            >
              취소하기
            </button>
            <button
              class="w-[226px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[40px]"
              @click="resetPassword()"
            >
              변경하기
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import passwordChangeForm from './passwordChangeForm.vue'
import { useSystemStore } from '../../../../stores/useSystem'
import { usePasswordStore } from '../../../../stores/usePassword'
import { useUserStore } from '../../../../stores/useUser'
import { sendRequest } from '../../../../api/serviceApi'
import { onUnmounted } from 'vue'

const system = useSystemStore()
const password = usePasswordStore()
const user = useUserStore()

const checkOldPassword = async () => {
  const payload = {
    email: user.me.email,
    password: password.nowPassword
  }
  console.log(payload)
  if (payload.password === '') {
    alert('기본 비밀번호를 입력해 주세요', payload)
    return
  }
  const patchNewPass = await sendRequest({
    method: 'post',
    path: '/users/passwordCheck',
    data: payload
  })
  const result = patchNewPass.data
  return result
}

const resetPassword = async () => {
  const compareOldPassword = await checkOldPassword()
  if (!compareOldPassword) {
    alert('기존 비밀번호가 일치하지 않습니다.')
    return
  }
  if (
    !password.validatePassword ||
    password.passData.stage2.password === '' ||
    password.passData.stage2.passwordCheck === ''
  ) {
    alert('형식에 맞게 비밀번호를 입력해 주세요.')
    return
  }
  const payload = {
    email: user.me.email,
    password: password.passData.stage2.password
  }
  const patchNewPass = await sendRequest({
    method: 'patch',
    path: '/users/resetPassword',
    data: payload
  })
  const result = patchNewPass.data
  console.log(result)
  if (!result) {
    alert(
      '비밀번호 변경에 실패 했습니다. 자세한 사항은 고객센터에 문의 바랍니다. 불편을 드려 대단히 죄송합니다.'
    )
    return
  } else {
    alert('비밀 번호가 성공적으로 변경되었습니다.')
    system.changePasswordModal = false
  }
}

onUnmounted(() => {
  password.passData.stage2 = {
    password: '',
    passwordCheck: ''
  }
  password.nowPassword = ''
})
</script>

<style lang="scss" scoped></style>
