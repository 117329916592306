<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="h-[500px]">
		<h1>you successfully land mypage</h1>
		<p>user info below</p>
		<p>{{ user.me }}</p>
		<div>
			<h1>change My Name</h1>
			<input type="text" name="chgName" id="chgName" v-model="payload.name" />
			<h1>change My Password</h1>
			<input
				type="password"
				name="chgPwd"
				id="chgPwd"
				v-model="payload.password"
			/>
			<div>
				<button @click="updateMe">submit</button>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useUserStore } from '../stores/useUser';
import axios from 'axios';

const user = useUserStore();
const payload = {
	name: '',
	password: '',
};

const updateMe = async () => {
	try {
		const upload = await axios({
			url: 'http://localhost:8001/api/user/updateUser',
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			data: {
				id: user.me.id,
				payload: payload,
			},
		});
		const result = upload.data;
		return alert(result);
	} catch (error) {
		console.log(error);
	}
};
</script>

<style lang="scss" scoped></style>
