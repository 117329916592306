<template>
  <div v-if="pageReady" class="border border-[#D5D5D5] rounded-md w-[365px] md:w-[745px]">
    <div class="mt-[13px] md:mt-[69.5px] ml-[10.5px] md:ml-[83.2px] mb-[42px] md:mb-[74px]">
      <div class="">
        <section class="md:hidden">
          <div class="flex justify-center items-center">
            <img src="/img/mypage/mapPoint.webp" alt="" class="w-[21px]" />
            <p class="ml-[6px] text-[12px] font-notosansCJKmd text-[#232323]">
              기본 배송지 입력 <span class="text-[10px] text-[#6D6D6D]">(선택)</span>
            </p>
          </div>
        </section>
        <section class="mt-[15px] md:mt-0">
          <input
            type="text"
            class="bg-[#F6F6F6] w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            :disabled="true"
            v-model="register.registerStage.stage1.userInfo.name"
          />
          <input
            type="text"
            class="bg-[#F6F6F6] w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[9px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            v-model="mobileWithHypen"
            :disabled="true"
          />
        </section>
      </div>
      <div>
        <section class="mt-[23.5px] md:mt-[31.5px]">
          <div class="">
            <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
              기본 배송지 입력
              <span class="hidden md:inline text-[15px] text-[#707070]"> (선택) </span>
            </p>
            <div class="flex mt-[6.5px] md:mt-[13.5px]">
              <input
                type="text"
                class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
                v-model="register.registerStage.stage2.receiverInfo.postcode"
                placeholder="우편번호"
              />
              <button
                class="border border-[#389033] w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] text-[#389033] md:text-[18px] font-notosansCJKmd"
                @click="openPostCode()"
              >
                우편번호 검색
              </button>
            </div>
            <input
              type="text"
              class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] mt-[9px] md:mt-[13.5px]"
              v-model="register.registerStage.stage2.receiverInfo.address"
              placeholder="배송주소"
            />
            <input
              type="text"
              class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] mt-[9px] md:mt-[13.5px]"
              v-model="register.registerStage.stage2.receiverInfo.addressDetail"
              placeholder="상세주소"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue'
import { useRegisterStore } from '../../stores/useRegister'
const register = useRegisterStore()

const pageReady = ref(false)
const mobileWithHypen = ref('')
const autoHypen = (input) => {
  if (!input) return ''
  const inputs = input.replace(/[^0-9]/g, '')
  return inputs.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
}

const openPostCode = () => {
  new window.daum.Postcode({
    oncomplete: (data) => {
      register.registerStage.stage2.receiverInfo.postcode = data.zonecode
      register.registerStage.stage2.receiverInfo.address = data.roadAddress // payload 객체의 주소 필드 업데이트
    }
  }).open()
}

onMounted(() => {
  pageReady.value = true
})

watchEffect(() => {
  if (pageReady.value) {
    mobileWithHypen.value = autoHypen(register.registerStage.stage1.userInfo.mobile)
    register.registerStage.stage2.receiverInfo.name = register.registerStage.stage1.userInfo.name
    register.registerStage.stage2.receiverInfo.mobile = mobileWithHypen.value
  }
})
</script>

<style lang="scss" scoped></style>
