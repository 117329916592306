import { defineStore } from 'pinia'
import { sendRequest } from '../api/serviceApi'
import { ref, watch, computed, watchEffect } from 'vue'
import { useSystemStore } from './useSystem'
import { useUserStore } from './useUser'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export const useOrderStore = defineStore(
  'order',
  () => {
    // State
    const system = useSystemStore()
    const user = useUserStore()
    const allOrderData = ref(null)
    const userOrderData = ref(null) // 유저 오더 데이터
    const orderFilterData = ref({
      on: '',
      odn: '',
      company: '',
      shipMethod: '', // 같은 배송유형
      state: '',
      request: ''
    })

    const mappers = ref({
      on: '',
      odn: ''
    })
    const refundDats = ref({
      fullRefund: false,
      request: '',
      stage0: {
        selectedItems: {},
        selectedReason: {}
      },
      stage1: {
        refundMethod: '',
        refundDetaild: {}
      },
      stage2: {
        refundPricePayMethod: '',
        chargePrice: 0
      }
    })

    const orederPatchData = ref({
      on: '',
      odn: ''
    })

    const shippingInputData = ref({})
    const exchangeInputData = ref({})
    const mapperData = ref({
      orderData: {},
      orderDetailData: {}
    })

    // Getter
    const orderCountCaclulator = computed(() => {
      const allOrderDetails = userOrderData?.value?.flatMap((order) => order.orderDetail)
      const counts = {}
      allOrderDetails?.forEach((detail) => {
        const status = detail.status
        if (counts[status]) {
          counts[status]++
        } else {
          counts[status] = 1
        }
      })

      return counts
    })

    const orderDetailData = computed(() => {
      if (orderFilterData.value.on && orderFilterData.value.odn) {
        const findByOn = userOrderData.value.filter((order) => {
          return order.orderNumber === orderFilterData.value.on
        })

        const findByOdn = findByOn.flatMap((orderItem) =>
          orderItem.orderDetail.filter(
            (details) => details.orderDetailNumber === orderFilterData.value.odn
          )
        )

        return {
          orderData: findByOn[0],
          orderDetailData: findByOdn[0]
        }
      } else {
        return null
      }
    })

    // const mapperData = computed(async () => {
    //   if (mappers.value.on && mappers.value.odn) {
    //     // console.log(mappers.value.on, mappers.value.odn)

    //     await getOrder('getAllOrder')

    //     const findByOn = allOrderData.value.filter((order) => {
    //       return order.orderNumber === mappers.value.on
    //     })

    //     console.log('mappers.findByOn: ', findByOn[0])

    //     const findByOdn = findByOn.flatMap((orderItem) =>
    //       orderItem.orderDetail.filter((details) => details.orderDetailNumber === mappers.value.odn)
    //     )

    //     console.log('mappers.findByOdn: ', findByOn[0])

    //     return {
    //       orderData: findByOn[0],
    //       orderDetailData: findByOdn[0]
    //     }
    //   } else {
    //     return null
    //   }
    // })

    const fetchMapperData = async () => {
      if (mappers.value.on && mappers.value.odn) {
        try {
          await getOrder('getAllOrder')

          const findByOn = allOrderData.value.filter((order) => {
            return order.orderNumber === mappers.value.on
          })

          const findByOdn = findByOn.flatMap((orderItem) =>
            orderItem.orderDetail.filter(
              (details) => details.orderDetailNumber === mappers.value.odn
            )
          )
          mapperData.value.orderData = findByOn[0]
          mapperData.value.orderDetailData = findByOdn[0]
          return
        } catch (error) {
          console.error('Error fetching order data: ', error)
          return null
        }
      } else {
        return null
      }
    }

    const cancleListByOdn = computed(() => {
      if (orderDetailData.value && orderDetailData.value.orderData) {
        const cancleEnable = orderDetailData.value.orderData.orderDetail.filter(
          (orderDetailList) => {
            return orderDetailList.status === 'wating'
          }
        )
        // console.log('cancleEnable', cancleEnable)

        // console.log(
        //   '오더필터데이터',
        //   orderFilterData.value.shipMethod,
        //   orderFilterData.value.company
        // )

        const sameShipMethod = cancleEnable.filter((cancleEnableData) => {
          return cancleEnableData.ship_method === orderFilterData.value.shipMethod
        })
        // console.log('sameShipMethod', sameShipMethod)

        const sameCompany = sameShipMethod.filter((sameShipMethodData) => {
          return sameShipMethodData.company === orderFilterData.value.company
        })
        // console.log('sameCompany', sameCompany)

        return sameCompany.length > 0 ? sameCompany : null
      }
      return null
    })

    const totalOrderDetailCount = computed(() => {
      if (!orderFilterData.value.on) {
        return
      }
      const nowOrder = userOrderData.value.filter((order) => {
        return order.orderNumber === orderFilterData.value.on
      })
      return nowOrder[0].orderDetail.length
    })

    const cancleEanbleCount = computed(() => {
      if (!orderFilterData.value.on) {
        return
      }
      const nowOrder = userOrderData.value.filter((order) => {
        return order.orderNumber === orderFilterData.value.on
      })

      const datas = nowOrder[0].orderDetail.filter((detail) => {
        return detail.status === 'wating'
      })
      // console.log('nowOrder', nowOrder.orderDetail)
      return datas.length
      const statusFinder = nowOrder.filter((od) => {
        return od.orderDetail.status
      })
      // const lengthOfEnable = statusFinder.length
      // return lengthOfEnable
      // return statusFinder
    })

    const refundEnableCount = computed(() =>
      refundListByOdn.value && refundListByOdn.value.length > 0 ? refundListByOdn.value.length : 0
    )

    const refundListByOdn = computed(() => {
      if (orderDetailData.value && orderDetailData.value.orderData) {
        // 반품이 가능한 경우로 필터링
        const refundEnable = orderDetailData.value.orderData.orderDetail.filter(
          (orderDetailList) => {
            return (
              orderDetailList.status === 'shipping' || orderDetailList.status === 'exchangeShipping'
            )
          }
        )
        // 화물이나 자체설치면 회사 다 필요없이 하나만 리턴
        if (
          orderFilterData.value.shipMethod === '자체설치상품' ||
          orderFilterData.value.shipMethod === '화물배송'
        ) {
          const findNowData = refundEnable.filter((odData) => {
            return odData.orderDetailNumber === orderFilterData.value.odn
          })
          return findNowData.length > 0 ? findNowData : null
        }

        // 아닐 경우 무료 배송이나 택배배송일 경우 현재 누른 제품의 회사로 필터링
        const compnayFilter = refundEnable.filter((refundEnable) => {
          // 두 조건을 모두 만족하는 경우만 반환
          return (
            (refundEnable.ship_method === '택배배송' || refundEnable.ship_method === '무료배송') &&
            refundEnable.company === orderFilterData.value.company
          )
        })

        return compnayFilter.length > 0 ? compnayFilter : null
      }

      return null
    })
    //  업체로 일단 가르고 -> 업체 들어왔고 orderFilterData.value.compnay
    //  택배 상품을 눌렀으면 택배 전체 가져오고
    //  화물이, 직접배송이면 하나만 띄우고 => 화물은 가격, 직접 배송은 0원 계산하고
    //  업체로 일단 가르고 -> 업체 들어왔고 orderFilterData.value.compnay
    //  택배 상품을 눌렀으면 택배 전체 가져오고
    //  화물이, 직접배송이면 하나만 띄우고 => 화물은 가격, 직접 배송은 0원 계산하고

    const exchangeListByOdn = computed(() => {})

    // Action
    const orderStatusHandller = async (cmd) => {
      const payload = {
        status: cmd
      }
      const getData = await sendRequest({
        method: 'patch',
        path: '/oredr/orderGet',
        data: payload
      })
      if (getData) {
        userOrderData.value = getData
      } else {
        console.error(result.message)
      }
    }

    const getOrder = async (cmd) => {
      switch (cmd) {
        case 'getOrderByUserIds':
          const payload = {
            userId: user.me._id
          }
          const getData = await sendRequest({
            method: 'post',
            path: '/order/getOrderByUserIds',
            data: payload
          })
          // userOrderData()
          if (getData) {
            userOrderData.value = getData.data
          } else {
            console.error(getData.message)
          }
          break
        case 'getAllOrder':
          const getAllOrder = await sendRequest({
            method: 'post',
            path: '/order/orderGet'
          })
          // userOrderData()
          if (getAllOrder) {
            allOrderData.value = getAllOrder.data.reverse()
          } else {
            console.error(getAllOrder.message)
          }
          break
        default:
          break
      }
    }

    const patchOrderCancelInfo = async (on, odn, reason, ammount) => {
      const payload = {
        orderNumber: on,
        orderDetailNumber: odn,
        cancelReason: reason,
        cancelAmmount: ammount,
        cancelDate: system.serverTime
      }

      console.log('취소 정보 작성 데이터', payload)

      const patchCancel = await sendRequest({
        method: 'patch',
        path: '/order/orderCancelPatcher',
        data: payload
      })
      const result = patchCancel.result
      if (result === 'ok') {
        console.log('주문 취소 정보 작성 완료')
        return result
      } else {
        console.log('주문 취소 정보 작성 실패')
      }
    }

    const sendBizTalk = async (status, on, odn) => {
      const paylaod = {
        status: status,
        on: on,
        odn: odn
      }
      const sendbiztalk = await sendRequest({
        method: 'post',
        path: '/message/sendPlusMessage',
        data: paylaod
      })
      const result = sendbiztalk
      console.log(result)
    }

    const patchOrders = async (on, odn, status) => {
      const payload = {
        orderNumber: on,
        orderDetailNumber: odn,
        newStatus: status
      }
      const patchOrder = await sendRequest({
        method: 'patch',
        path: '/order/orderStatusPatcher',
        data: payload
      })
      const result = patchOrder.result

      if (result === 'ok') {
        // await sendBizTalk(status, on, odn)
        // toast(`결과: ${result}, 주문 상태 변경 완료`, {
        //   theme: 'light',
        //   type: 'success',
        //   autoClose: 1200,
        //   transition: 'flip',
        //   dangerouslyHTMLString: true
        // })
        // await getOrder('getAllOrder')
        // await getOrder('getOrderByUserIds')

        return result
      } else {
        toast(`주문 상태 변경 실패`, {
          theme: 'light',
          type: 'error',
          autoClose: 1200,
          transition: 'flip',
          dangerouslyHTMLString: true
        })
      }
    }

    const patchShippingInfo = async (info, on, odn, status, isEdit, cmd) => {
      console.log('주문 패쳐 동작')
      const payload = {
        shippingInfo: info,
        orderNumber: on,
        orderDetailNumber: odn,
        commend: cmd
      }
      const patchOrderShippingInfo = await sendRequest({
        method: 'patch',
        path: '/order/orderShippingInfoUpdate',
        data: payload
      })
      console.log('result', patchOrderShippingInfo.result)

      console.log('수정: ', isEdit)
      await patchOrders(on, odn, status)
    }

    const patchExchangeInfo = async (info, on, odn, status, isEdit, cmd) => {
      console.log('주문 패쳐 동작')
      const payload = {
        exchangeInfo: info,
        orderNumber: on,
        orderDetailNumber: odn,
        commend: cmd
      }
      const patchOrderExchangeInfo = await sendRequest({
        method: 'patch',
        path: '/order/orderExchangeInfoUpdate',
        data: payload
      })
      console.log('result', patchOrderExchangeInfo.result)

      console.log('수정: ', isEdit)
      await patchOrders(on, odn, status)
    }

    const patchRefundAndEx = async (patchData) => {
      console.log('주문 패쳐 동작')
      const sendData = {
        orderNumber: patchData.orderNumber,
        orderDetailNumber: patchData.orderDetailNumber,
        refundInfo: patchData.refundData,
        newStatus: patchData.status
      }
      const patchOrderShippingInfo = await sendRequest({
        method: 'patch',
        path: '/order/orderRefundExhangePatcher',
        data: sendData
      })

      const result = patchOrderShippingInfo.result
      console.log(result)

      if (result === 'ok') {
        return result
      } else {
        console.error('result', patchOrderShippingInfo)
      }

      // await patchOrders(on, odn, status)
    }

    const orderMultiPatcher = async ({ orderNumber, orderDetailNumber, ...updateFields }) => {
      const payload = {
        orderNumber,
        orderDetailNumber,
        ...updateFields
      }

      const patcOrderMulti = await sendRequest({
        method: 'patch',
        path: '/order/orderMultiPatcher',
        data: payload
      })

      const result = patcOrderMulti.result
      if (result === 'ok') {
        return result
      } else {
        console.error('result', patcOrderMulti)
      }
    }

    return {
      // State
      userOrderData,
      orderFilterData,
      refundDats,
      allOrderData,
      orederPatchData,
      shippingInputData,
      exchangeInputData,
      mappers,
      mapperData,
      // Getter
      orderCountCaclulator,
      orderDetailData,
      cancleListByOdn,
      refundListByOdn,
      exchangeListByOdn,
      totalOrderDetailCount,
      cancleEanbleCount,
      refundEnableCount,
      // mapperData,
      // Action
      getOrder,
      orderStatusHandller,
      patchOrders,
      patchShippingInfo,
      patchExchangeInfo,
      patchRefundAndEx,
      fetchMapperData,
      patchOrderCancelInfo,
      orderMultiPatcher
    }
  },
  {
    // persist: true
  }
)
