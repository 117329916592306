import { defineStore } from 'pinia'
import { sendRequest } from '../api/serviceApi'
import { ref, watch, computed, watchEffect } from 'vue'
import { useUserStore } from './useUser'
import { useOrderStore } from './userOrder'
export const usePaymentStore = defineStore(
  'payment',
  () => {
    // State
    const order = useOrderStore()
    const orderNumber = ref(order.orderFilterData.on)
    const orderDetailNumber = ref(order.orderFilterData.odn)
    const userPaymentData = ref()
    // Getter

    // Action
    const getPaymentByOrderNumber = async (cmd, on) => {
      switch (cmd) {
        case 'getPayDataByOrderNum':
          // console.log('결제 스토어에서 받은 on', on)

          const payload = {
            orderNumber: on
          }

          const getData = await sendRequest({
            method: 'post',
            path: '/payment/getPayDataByOrderNum',
            data: payload
          })
          // userOrderData()
          if (getData) {
            userPaymentData.value = getData.data
          } else {
            console.error(getData.message)
          }
          break
        default:
          break
      }
    }
    return {
      // State
      userPaymentData,
      orderDetailNumber,
      orderNumber,
      // Getter
      // Action
      getPaymentByOrderNumber
    }
  },
  {
    // persist: true
  }
)
