<template>
  <div class="rounded-md shadow-lg w-[192px] h-auto">
    <div class="pt-[16px] pb-[7px]">
      <div
        v-for="brandList in filterData"
        :key="brandList"
        class="flex items-center pb-[9px] pl-[10px]"
      >
        <input
          type="checkbox"
          class="w-[18px] h-[18px] accent-[#389033] border-none"
          :value="brandList"
          v-model="brandCheck"
        /><label
          :for="brandList"
          class="ml-[10px] font-notosansCJKmd text-[#7D7D7D] text-[15px] pt-[0.1px]"
          >{{ brandList }}</label
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect } from 'vue'
import { useProductStore } from '../../../stores/useProduct'

const product = useProductStore()
const props = defineProps({
  filterData: Array
})
const brandCheck = ref([])

watchEffect(() => {
  if (product.filterList.brandFilter.check) {
    product.filterList.brandFilter.class = 'border border-[#389033] text-[#389033] w-[97px]'
  } else {
    product.filterList.brandFilter.class = 'w-[97px] text-[#808080]'
  }
})

watch(
  brandCheck,
  (newChecked) => {
    product.filterList.brandFilter.check = newChecked.length > 0
    product.filterList.brandFilter.list = newChecked
  },
  { deep: true }
)

onMounted(() => {
  brandCheck.value = product.filterList.brandFilter.list
})
</script>

<style lang="scss" scoped></style>
