<template>
  <div
    class="relative h-full w-full bg-cover bg-center bg-no-repeat animate-detailZoom"
    :class="currentBanner"
    :key="currentBanner"
  >
    <div class="absolute bg-[#49494965] w-full h-full"></div>
    <div class="mx-auto h-[490px] lg:w-[1180px] top-[2rem]">
      <div class="absolute top-[16rem] mx-auto flex items-center">
        <!-- left text contianer -->
        <div class="ml-[34px]">
          <svg class="bigLeft w-[18px] h-[95.25px]"></svg>
        </div>
        <!-- <p class="font-notosansCJKmd text-[70px] text-white mb-2 mx-3">자연스럽다</p> -->
        <div class="w-[391px] h-[97px]" :class="bannerText" :key="bannerText"></div>
        <div>
          <svg class="bigRight w-[18px] h-[95.25px]"></svg>
        </div>
        <!-- right text contianer  -->
        <div class="ml-[45px] text-white text-[22px]">
          <p class="">억지로 꾸미지 아니하여 이상함이 없다</p>
          <p class="">순리에 맞고 저절로 된 듯하다</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const currentBanner = 'bg-[url("../../img/about/brand.webp")]'
const bannerText = 'bg-[url("../../img/about_img/about_banner_text.png")]'

// import imgs from '../../img/about_img/about_banner_text.png'
</script>

<style lang="scss" scoped></style>
