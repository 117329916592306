<template>
  <div class="w-full">
    <div class="">
      <div class="">
        <section>
          <div class="lg:flex items-center hidden">
            <div v-for="(item, index) in data" :key="index + 'bestProduct'" class="">
              <div class="mr-[25px]">
                <router-link v-if="index < 4" :to="{ path: `/product/${item._id}` }">
                  <homeRwHomeCard :item="item" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="swiper-container lg:hidden mt-[11.5px]">
            <swiper
              :slides-per-view="'auto'"
              :space-between="spaces"
              :loop="false"
              class="swiper-wrapper"
            >
              <swiper-slide
                v-for="(item, index) in data"
                :key="'item' + index"
                class="mb-[44px] cursor-pointer"
              >
                <homeRwHomeCard :item="item" class="" @click="goToDetailProduct(item._id)" />
              </swiper-slide>
            </swiper>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { sendRequest } from '../../api/serviceApi'

import productCardHome from '../prodcut/productCardHome.vue'
import shopCard from '../shop/shopCard.vue'
import homeRwHomeCard from './homeRwHomeCard.vue'
import { useRouter } from 'vue-router'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
const props = defineProps({
  data: Object
})

const spaces = ref(10)
const router = useRouter()

const goToDetailProduct = (id) => {
  router.push(`/product/${id}`)
}
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
}
.swiper-wrapper {
  display: flex;
  position: relative;
}

.swiper-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 42px; /* 그라디언트의 너비, 필요에 따라 조절 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  ); /* 왼쪽에서 오른쪽으로 투명에서 완전 
    불투명으로 */
  z-index: 2;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
