<template>
  <div class="w-full">
    <!-- heroBanner container -->
    <!-- pc -->
    <section class="hidden md:block">
      <div class="flex justify-center">
        <img src="/img/main/mainPetHouse.webp" alt="" class="" />
      </div>
    </section>
    <!-- mobile -->
    <section class="md:hidden mx-[10px] mt-[13px]">
      <div>
        <img
          src="/img/main/mainPetHouseMobile.webp"
          alt=""
          class="aspect-356/404 w-full rounded-lg"
        />
      </div>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
