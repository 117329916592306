<template>
  <div v-if="pageReady" class="w-auto mx-3 md:w-[1180px] md:mx-auto">
    <section class="hidden md:block">
      <div class="mt-[66px] border-b border-[#B2B2B2]">
        <p class="text-[20px] font-notosansCJKmd text-[#171717] ml-[10px] pb-[5px]">
          {{ titles }}
        </p>
      </div>
    </section>
    <section class="md:hidden">
      <section class="py-[15.5px] bg-[#FBFBFB]">
        <div>
          <p class="text-[16px] font-notosansCJKmd text-[#171717] text-center">{{ titles }}</p>
        </div>
      </section>
    </section>

    <div class="mt-[16px]">
      <noticeBoard :item="getOneNotice" :readonly="true" />
    </div>
    <!-- 에디터 -->
    <section v-if="getOneNotice.length && getOneNotice[0].boardEditor">
      <editorReadOnly :editors="getOneNotice[0].boardEditor" />
    </section>
    <hr class="mt-[100px]" />
    <div class="flex justify-center mb-[350px] mt-[13.5px]">
      <button
        class="w-[202px] h-[39px] border border-[#6D6D6D] rounded-md text-[15px] text-[#1C1C1C] font-notosansCJR mt-[19px]"
        @click="goBack()"
      >
        목록으로 돌아가기
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import noticeBoard from '../../components/account/notices/noticeBoard.vue'
import { useBoardStore } from '../../stores/useBoard'
import editorReadOnly from '../../components/test/editor-readOnly.vue'

const pageReady = ref(false)
const board = useBoardStore()
const route = useRoute()
const router = useRouter()
const getOneNotice = ref([])
const boardType = ref('')

const goBack = () => {
  if (boardType.value === 'qna') {
    router.push(`/qna`)
  } else if (boardType.value === 'announce') {
    router.push(`/notices`)
  }
}

const titles = ref('')
const titleSelector = (type) => {
  switch (type) {
    case 'qna':
      titles.value = '자주 묻는 질문'
      break

    case 'announce':
      titles.value = '공지사항'
      break

    default:
      break
  }
}

onMounted(async () => {
  if (route) {
    await board.getBoardIds(route.query.ad)
    getOneNotice.value.push(board.getOneBoard)
    boardType.value = board.getOneBoard.boardType
    titleSelector(boardType.value)
    pageReady.value = true
  }
})
</script>

<style lang="scss" scoped></style>
