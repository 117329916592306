<template>
  <div>
    <div class="w-full h-auto flex-wrap flex">
      <div v-for="tagItem in tagList" :key="tagItem.tag" class="mr-[9px]">
        <div class="flex items-center">
          <div
            class="w-auto h-[28px] pt-[0.5px] mt-[16.5px] rounded-md flex items-center"
            :class="tagItem.bgColor"
          >
            <p class="px-[13px] py-[7px] text-[13px] font-notosansCJKmd text-[#7D7D7D]">
              {{ tagItem.tagName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref } from 'vue'
const props = defineProps({
  item: Object
})
const tagList = ref([])

watchEffect(() => {
  if (props.item) {
    tagList.value = []
    if (props.item.ship_method === '무료배송') {
      tagList.value.push({
        tagName: '무료배송',
        tag: 'freeShipping',
        bgColor: 'bg-[#F6F6F6]'
      })
    }
    if (props.item.company === '연들') {
      tagList.value.push({
        tagName: '연들',
        tag: 'yeondeul',
        bgColor: 'bg-[#F6F6F6]'
      })
    }
    if (props.item.company === '자기다움') {
      tagList.value.push({
        tagName: '자기다움',
        tag: 'jagidaum',
        bgColor: 'bg-[#F6F6F6]'
      })
    }
    if (props.item.signature === true) {
      tagList.value.push({
        tagName: '연들 시그니쳐',
        tag: 'signature',
        bgColor: 'bg-[#FFF8EF]'
      })
    }
    if (props.item.special === true) {
      tagList.value.push({
        tagName: '기획전',
        tag: 'special',
        bgColor: 'bg-[#FFFFEF]'
      })
    }
  }
})
</script>

<style lang="scss" scoped></style>
