<template>
  <div v-if="product.setMaxPrice" class="rounded-md shadow-md w-[320px] h-[104px]">
    <div class="mt-[5px] ml-[22px]">
      <p class="pt-[14px] text-[16px] font-notosansCJKmd text-[#0C370A]">
        {{ value[0] }}만원 ~ {{ value[1] }}만원
      </p>
      <div class="slider-container w-[268px]">
        <vue-slider
          v-model="value"
          class="mt-[16px]"
          :dot-style="dotStyle"
          :rail-style="railStyle"
          :process-style="processStyle"
          :min="0"
          :max="product.setMaxPrice"
          :tooltip-formatter="formatPrice"
        ></vue-slider>
      </div>
    </div>
    <div class="w-[290px] flex items-center justify-between ml-[11px] mt-[5px]">
      <p class="text-[13px] font-notosansCJKmd text-[#389033]">{{ value[0] }}만원</p>
      <p class="text-[13px] font-notosansCJKmd text-[#389033]">{{ value[1] }}만원</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watchEffect } from 'vue'
import { useProductStore } from '../../../stores/useProduct'
const product = useProductStore()

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

const value = ref([product.minPrice, product.maxPrice])

watchEffect(() => {
  if (product.filterList.priceFilter.check) {
    product.filterList.priceFilter.class = 'border border-[#389033] text-[#389033] w-[75px]'
  } else {
    product.filterList.priceFilter.class = 'w-[75px] text-[#808080]'
  }
})

watchEffect(() => {
  if (value.value) {
    product.minPrice = value.value[0]
    product.maxPrice = value.value[1]
  }
})

const dotStyle = {
  borderColor: '#0C370A'
}

const railStyle = {
  backgroundColor: '#E2E2E2'
}

const processStyle = {
  backgroundColor: '#0C370A'
}

// 슬라이더 값에 '만원' 단위를 붙여주는 함수
const formatPrice = (val) => `${val}0,000 원`

onMounted(() => {
  product.filterList.priceFilter.check = true
})
</script>

<style lang="scss" scoped></style>
