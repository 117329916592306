<template>
  <div
    class="fixed top-[74px] inset-0 overflow-x-hidden bg-white z-[1000] bg-opacity-100 overflow-hidden"
    ref="mobileMenu"
  >
    <div class="relative h-full">
      <section class="mt-[16.3px] ml-[12.5px] flex items-center">
        <button
          v-if="isLogin"
          class="w-[99px] h-[32px] border border-[#389033] rounded-md"
          @click="purgeCookie()"
        >
          <p class="text-[12px] font-notosansCJKmd text-[#389033]">로그아웃</p>
        </button>
        <button
          v-else
          class="w-[99px] h-[32px] border border-[#389033] rounded-md flex justify-center items-center"
          @click="goToLogin()"
        >
          <i class="pi pi-sign-in text-[12px] text-[#1b1b1b] mr-[6.1px]"></i>
          <p class="text-[12px] font-notosansCJKmd text-[#389033] mr-3 pt-[0.5px]">로그인</p>
        </button>
        <button
          v-if="!isLogin"
          class="w-[99px] h-[32px] border border-[#6D6D6D] rounded-md ml-[13px]"
          @click="goToRegister()"
        >
          <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">회원가입</p>
        </button>
        <button
          v-if="isLogin"
          class="w-[99px] h-[32px] border border-[#6D6D6D] rounded-md ml-[13px]"
          @click="goToMyPage()"
        >
          <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">마이 페이지</p>
        </button>
      </section>
      <div class="hide-scrollbar h-full">
        <section class="mx-3 mt-[27px]">
          <div class="border border-[#D0D0D0] rounded-xl w-full shadow-md">
            <div class="flex items-center justify-between mx-[15px] my-[11px]">
              <img src="/img/mobileMenu/home.webp" alt="homeMobile" />
              <div class="text-right">
                <p class="text-[16px] font-notosansCJKmd text-[#389033] mr-1">
                  연들로 시작하는 우리집 인테리어
                </p>
                <p class="text-[12px] font-notosansCJK text-[#707070] pt-[3.2px] mr-1">
                  포인트 소품부터 품격있는 원목가구까지
                </p>
              </div>
            </div>
          </div>
        </section>
        <section class="mt-[37px] ml-[21px] pb-[60%]">
          <div v-for="(item, index) in middleMenuArray" :key="index" class="mb-[23px]">
            <p class="font-notosansCJKmd mb-[13px]" :class="item.class">
              {{ item.header }}
            </p>
            <div v-if="item.header === '카테고리'" class="mb-[45px]">
              <div v-for="(buttons, ex) in item.button" :key="'buttons' + ex" class="ml-[7px]">
                <router-link
                  :to="{ path: `/shop/${buttons.link}`, query: { type: buttons.title } }"
                  @click="system.mobileMenu = false"
                >
                  <p class="text-[15px] text-[#404040] font-notosansCJKmd mb-[22px]">
                    {{ buttons.title }}
                  </p>
                </router-link>
              </div>
            </div>
            <div v-else>
              <div v-for="(buttons, ex) in item.button" :key="'buttons' + ex" class="ml-[7px]">
                <button class="mb-[22.7px]" @click="buttons.function">
                  <p class="text-[15px] text-[#404040] font-notosansCJKmd">
                    {{ buttons.title }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="absolute w-full bg-[#F8F8F8] bottom-[16px]">
        <div class="h-[56px] w-[320px] mx-auto">
          <mobileBottomMenu class="pt-[2px]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSystemStore } from '../../stores/useSystem'
import { useUserStore } from '../../stores/useUser'
import { useElementSize } from '@vueuse/core'
import { ref, watchEffect } from 'vue'
import mainCatData from '../../data/mainCatData'
import { useRoute, useRouter } from 'vue-router'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import mobileBottomMenu from './mobileBottomMenu.vue'
const cookies = useCookies() // 쿠키 초기화
const route = useRoute()
const router = useRouter()

const mobileMenu = ref()
const user = useUserStore()
const { width, height } = useElementSize(mobileMenu)
const system = useSystemStore()

const isLogin = ref(false)

const categoryDatas = {
  ...mainCatData
}
const menuButtonsFunction = (link, auth) => {
  if (!auth) {
    router.push(link), (system.mobileMenu = false)
  } else {
    if (user?.me?._id) {
      router.push(link)
      system.mobileMenu = false
    } else {
      // return alert('로그인 후 사용이 가능합니다.')
      system.mobileMenu = false
      return router.push({ path: '/login', query: { redirect: `${link}` } })
    }
  }
}

const middleMenuArray = ref([
  {
    header: '연들 브랜드',
    class: 'text-[18px]  text-[#389033]',
    button: [
      {
        title: '브랜드소개',
        class: '',
        function: () => {
          router.push('/about'), (system.mobileMenu = false)
        }
      },
      {
        title: '매거진',
        class: '',
        function: () => {
          router.push('/post'), (system.mobileMenu = false)
        }
      },
      {
        title: '기획전',
        link: 'obj',
        class: '',
        function: () => {
          router.push('/post'), (system.mobileMenu = false)
        }
      }
    ]
  },
  {
    header: '카테고리',
    class: 'text-[18px] text-[#389033]',
    button: categoryDatas
  },
  {
    header: '고객센터',
    class: 'text-[18px]  text-[#389033]',
    button: [
      {
        title: '고객센터',
        class: '',
        function: () => {
          menuButtonsFunction('/account/support', true)
        }
      },
      {
        title: '공지사항',
        class: '',
        auth: true,
        link: '/account/notices',
        function: () => {
          menuButtonsFunction('/account/notices', true)
        }
      }
    ]
  }
])

const goToMyPage = () => {
  if (user?.me?._id) {
    system.mobileMenu = false
    // router.push(`${route}/main`)
    router.push('/account/main')
  } else {
    // alert('마이페이지는 로그인 후 사용이 가능합니다.')
    system.mobileMenu = false
    return router.push({ path: '/login', query: { redirect: '/account/main' } })
  }
}

const goToLogin = () => {
  system.mobileMenu = false
  router.push('/login')
}

const goToRegister = () => {
  system.mobileMenu = false
  router.push('/register')
}

const purgeCookie = () => {
  system.mobileMenu = false
  cookies.remove('yeondeul', { path: '/' })
  router.push('/login').then(() => {
    location.reload()
  })
}

watchEffect(() => {
  if (user.token) {
    isLogin.value = true
  } else {
    isLogin.value = false
  }
})

//  너비값 측정해서 모바일 메뉴 자동OFF
watchEffect(() => {
  if (width.value > 991) {
    console.log(width.value)
    system.mobileMenu = false
  }
})
</script>

<style lang="scss" scoped>
/* 모달 내부에 적용할 스타일 */
.hide-scrollbar {
  overflow-y: scroll; /* 세로 스크롤은 가능하지만 */
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>
