<template>
  <div class="w-[365px] md:w-[737px] border rounded-lg border-[#D5D5D5]">
    <!-- 약관 동의 안내 섹션 -->
    <section class="text-center">
      <div class="hidden md:flex items-center justify-center mt-[20px]">
        <img src="/img/register/checkGreen.webp" alt="checkGreen" />
        <p class="text-[13px] md:text-[18px] font-notosansCJKmd ml-1">
          연들 서비스 약관에 동의해주세요 :)
        </p>
      </div>
      <div class="flex items-center md:hidden mt-[10px] justify-center">
        <p class="text-[13px] md:text-[18px] font-notosansCJKmd">연들 서비스 약관</p>
      </div>
    </section>
    <!-- 전체 동의 및 개별 체크박스 섹션 -->
    <section class="mt-[15px] md:mt-[32px]">
      <!-- 전체 동의 체크박스 -->
      <div class="flex items-center ml-[19px] md:ml-[32px]">
        <input
          type="checkbox"
          class="w-[19px] h-[19px] md:w-[24px] md:h-[24px] rounded-lg accent-[#389033]"
          v-model="allChecked"
          @change="toggleAllChecks"
        />
        <p class="text-[13px] md:text-[18px] text-[#232323] font-notosansCJKmd ml-2">전체동의</p>
      </div>
      <!-- 설명 텍스트 -->
      <div class="ml-[47px] md:ml-[67px] md:mt-[6px]">
        <p class="text-[13px] text-[#B5B5B5] font-notosansCJR hidden md:block">
          필수 동의 외 더 나은 서비스를 제공하기 위한 위치정보 서비스와 마케팅 수신동의를
          포함합니다.
        </p>
        <p class="text-[10px] text-[#B5B5B5] font-notosansCJR md:hidden">
          필수 동의 외 더 나은 서비스를 제공하기 위한 위치정보 서비스와 마케팅
        </p>
        <p class="text-[10px] text-[#B5B5B5] font-notosansCJR md:hidden">수신동의를 포함합니다.</p>
      </div>

      <hr class="mx-0 md:mx-4 mt-[15px] md:mt-[34px]" />

      <!-- 개별 체크박스 리스트 -->
      <section class="mt-[15px] md:mt-[34px]">
        <div
          v-for="(item, index) in checListDataArr"
          :key="index"
          class="flex items-center ml-[19px] md:ml-[32px] mb-[15px] md:mb-[30px]"
        >
          <input
            type="checkbox"
            class="w-[19px] h-[19px] md:w-[24px] md:h-[24px] rounded-lg accent-[#389033]"
            v-model="item.checked"
            @change="checkAllStatus"
          />
          <p class="text-[13px] md:text-[18px] text-[#232323] font-notosansCJR ml-2">
            {{ item.pText }}
            <span class="text-[10px] md:text-[15px] font-notosansCJR" :class="item.sClass">
              ({{ item.sText }})
            </span>
          </p>
        </div>
      </section>
    </section>
    <!-- 서비스 약관 다운로드 버튼 -->
    <section>
      <div class="flex justify-end mr-[8px] mb-[12px] md:mb-[15px]">
        <button class="flex items-center">
          <p class="text-[11px] md:text-[14px] text-[#6D6D6D] font-notosansCJR">
            서비스 약관 다운로드
          </p>
          <i class="pi pi-angle-right ml-[4px] text-[#6D6D6D] text-[10px] md:text-[14px] pb-1"></i>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect } from 'vue'
import { useRegisterStore } from '../../stores/useRegister'

const register = useRegisterStore()
// 전체 체크 상태를 관리하는 변수
const allChecked = ref(false)

// 개별 체크박스 데이터 배열
const checListDataArr = ref([
  {
    pText: '연들 이용약관',
    sText: '필수',
    sClass: 'text-[#389033]',
    checked: false
  },
  {
    pText: '개인정보 수집 및 이용 동의',
    sText: '필수',
    sClass: 'text-[#389033]',
    checked: false
  },
  {
    pText: '만 14세 이상입니다.',
    sText: '필수',
    sClass: 'text-[#389033]',
    checked: false
  },
  {
    pText: '마케팅 수신동의',
    sText: '선택',
    sClass: 'text-[#6D6D6D]',
    checked: false
  },
  {
    pText: '위치정보 마케팅 활용동의',
    sText: '선택',
    sClass: 'text-[#6D6D6D]',
    checked: false
  }
])

// 전체 체크박스를 클릭했을 때 호출되는 함수
const toggleAllChecks = () => {
  const newValue = allChecked.value
  checListDataArr.value.forEach((item) => {
    item.checked = newValue
  })
}

// 개별 체크박스 상태를 감시하여 전체 체크박스를 업데이트하는 함수
const checkAllStatus = () => {
  const isAllChecked = checListDataArr.value.every((item) => item.checked)
  allChecked.value = isAllChecked
}

watchEffect(() => {
  if (
    checListDataArr.value[0].checked === true &&
    checListDataArr.value[1].checked === true &&
    checListDataArr.value[2].checked === true
  ) {
    register.registerStage.stage0.required = true
  } else {
    register.registerStage.stage0.required = false
  }
})

watchEffect(() => {
  if (checListDataArr.value[3].checked === true) {
    register.registerStage.stage0.sns = true
  } else {
    register.registerStage.stage0.sns = false
  }
})

watchEffect(() => {
  if (checListDataArr.value[4].checked === true) {
    register.registerStage.stage0.location = true
  } else {
    register.registerStage.stage0.location = false
  }
})
</script>

<style scoped>
/* 필요한 스타일 정의 */
</style>
