<template>
  <div class="">
    <div class="flex justify-center mt-[15.5px] md:mt-[46px]">
      <div>
        <section>
          <div class="flex justify-center">
            <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">회원가입</p>
          </div>
        </section>
        <section class="md:mt-[40.5px]">
          <p
            class="text-center text-[14px] md:text-[15px] text-[#232323] md:text-[#6D6D6D] font-notosansCJKmd hidden md:block"
          >
            SNS계정으로 간편한 회원가입
          </p>
          <section class="hidden md:block md:mt-[15px]">
            <div class="flex justify-center">
              <button @click="kakaoReady()">
                <img src="/img/social/kakao.webp" class="w-[49px] h-[49px]" alt="kakaoBadge" />
              </button>
              <button class="ml-[38.6px]" @click="requestNaverLogin()">
                <img src="/img/social/naver.webp" class="w-[49px] h-[49px]" alt="naverBadge" />
              </button>
            </div>
          </section>
          <div class="flex justify-center md:hidden mt-[17px]">
            <img class="w-[50px]" src="/img/register/cupboard.webp" alt="" />
          </div>
          <p class="text-center md:hidden text-[14px] text-[#389033] font-notosansCJKmd mt-[6px]">
            연들에 오신것을 환영합니다 :)
          </p>
        </section>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="">
        <hr class="mt-[39px] w-[917px] hidden md:block" />
      </div>
      <div></div>
    </div>
    <div class="flex justify-center mt-[21px] md:mt-[41px]">
      <registerAgreement />
    </div>
    <div class="mt-[23.5px] md:mt-[42px] mb-0 md:mb-[191px]">
      <section class="">
        <div class="flex justify-center">
          <button
            class="border border-[#389033] md:border-[#0C370A] w-[320px] md:w-[356px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033] md:text-white bg-transparent md:bg-[#389033]"
            @click="stepToStage()"
          >
            다음 단계
          </button>
        </div>
      </section>
    </div>
    <div class="flex justify-center md:hidden">
      <div class="">
        <hr class="mt-[39px] w-[917px] hidden md:block" />
        <section class="mt-[42.5px] md:mt-[45px]">
          <p
            class="text-center text-[14px] md:text-[15px] text-[#232323] md:text-[#6D6D6D] font-notosansCJKmd"
          >
            SNS 간편 회원가입
          </p>
        </section>
        <section class="mt-[15.7px] md:mt-[15px]">
          <div class="flex justify-center">
            <button @click="kakaoReady()">
              <img src="/img/social/kakao.webp" class="w-[42px]" alt="kakaoBadge" />
            </button>
            <button class="ml-[38.6px]" @click="requestNaverLogin()">
              <img src="/img/social/naver.webp" alt="naverBadge" />
            </button>
          </div>
        </section>
      </div>
    </div>
    <p
      class="md:hidden mt-[32px] mb-[60px] text-center text-[10px] md:text-[14px] text-[#6D6D6D] font-notosansCJR"
    >
      ⓒ ruruci.inc All rights reserved.
    </p>
  </div>
</template>
<script setup>
import registerAgreement from './registerAgreement.vue'
import { useRegisterStore } from '../../stores/useRegister'
import axios from 'axios'
import { useRouter } from 'vue-router'
const router = useRouter()

const register = useRegisterStore()

const stepToStage = () => {
  if (register.registerStage.stage0.required === false) {
    alert('서비스 이용을 위해 모든 필수 동의 항목을 체크해 주세요. ')
    return
  }
  register.registStage = 1
}

// 네이버 회원가입
const requestNaverLogin = async () => {
  try {
    // const reqData = await axios.get('http://localhost:5000/servers/hey')
    const reqData = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/naver/oAuth/login`)
    // const reqData = await axios.get('http://localhost:5000/servers/naver/oAuth/hey')
    const result = reqData.data
    window.location.href = result
  } catch (error) {
    console.error(error)
  }
}

const kakaoReady = () => {
  window.location.href = `${import.meta.env.VITE_API_BASE_URL}/kakao/oAuth/login`
}
</script>

<style lang="scss" scoped></style>
