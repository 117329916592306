<template>
  <div class="">
    <table>
      <!-- 상품유형&타입 -->
      <td class="w-[460px]">
        <p class="text-[12px] lg:text-[15px] font-notosansCJKmd text-[#6D6D6D]">
          상품유형: {{ options.types }}
        </p>
        <div class="flex items-end text-[12px] lg:text-[15px] text-[#6D6D6D]">
          <p class="">┗</p>
          <p class="ml-1">선택:</p>
          <p class="ml-1">{{ options.color }}</p>
        </div>
      </td>
      <!-- 수량 -->
      <td class="hidden lg:table-cell w-[90px]">
        <div v-if="!isOrder" class="flex justify-between">
          <div
            class="w-[84px] flex h-[30px] ml-[11px] border-[1.5px] border-solid border-[#707070] rounded-xl mx-auto items-center justify-center"
          >
            <form @submit.prevent>
              <div class="flex justify-center items-center">
                <button
                  class="w-[26px] mr-[3px] bg-white rounded-l-xl text-[16px] font-bold"
                  @click="adjustQuantityHandler(options.index, 'minus')"
                >
                  -
                </button>
                <span class="text-center border-0 border-solid border-tlack">
                  <!-- 갯수 -->
                  {{ options.ea }}
                </span>
                <button
                  class="w-[26px] ml-[3px] bg-white rounded-r-xl text-[16px] font-bold"
                  @click="adjustQuantityHandler(options.index, 'plus')"
                >
                  +
                </button>
              </div>
            </form>
          </div>
        </div>
        <div v-else class="flex justify-center">
          <p class="text-[16px] font-notosansCJKmd text-[#1C1C1C] pl-3">
            <!-- 갯수 -->
            {{ options.ea }}
          </p>
        </div>
      </td>
      <!-- 가격 -->
      <td class="hidden lg:table-cell w-[310px] mx-auto text-center">
        <p class="text-[18px] font-notosansCJKmd text-[#171717]">
          {{ resultPrice.toLocaleString('ko-KR') }}원
        </p>
      </td>
      <!-- 배송비 -->
      <td class="hidden lg:table-cell w-[80px]">
        <p class="text-[18px] font-notosansCJKmd text-[#171717] text-center">
          {{ cart.cartData[options.index].products.ship_ammount.toLocaleString('ko-KR') }}원
        </p>
      </td>
      <!-- 제거 -->
      <td v-if="!isOrder" class="hidden lg:table-cell pl-[107px]">
        <a href="#" class="">
          <i class="pi pi-times" @click.prevent="cartDbHandller(options.index, 'delete')"></i>
        </a>
      </td>
      <!-- </div> -->
    </table>
    <!-- 모바일 -->
    <div class="flex justify-between lg:hidden mt-[16.5px] w-full pb-3">
      <!-- 수량 -->
      <div class="flex justify-between">
        <div
          class="w-[78.5px] h-[26px] flex border border-[#707070] rounded-xl mx-auto items-center justify-center"
        >
          <form @submit.prevent>
            <div class="flex justify-center items-center">
              <button
                class="w-[26px] mr-[3px] bg-white rounded-md text-[16px] font-bold"
                @click="adjustQuantityHandler(options.index, 'minus')"
              >
                -
              </button>
              <span class="text-[13px] text-center">
                <!-- 갯수 -->
                {{ options.ea }}
              </span>
              <button
                class="w-[26px] ml-[3px] bg-white rounded-md text-[16px] font-bold"
                @click="adjustQuantityHandler(options.index, 'plus')"
              >
                +
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- 남은 데이터 -->
      <div class="flex items-center">
        <div class="">
          <!-- 가격 -->
          <p class="text-[15px] text-[#171717] text-right">
            {{ resultPrice.toLocaleString('ko-KR') }}원
          </p>
          <!-- 택배비 -->
          <p class="text-[11px] text-[#6D6D6D] text-right">
            {{ cart.cartData[options.index].products.ship_ammount.toLocaleString('ko-KR') }}원
          </p>
        </div>
        <div class="ml-[15.3px] mr-[5px]">
          <a href="#" class="">
            <i
              class="pi pi-times text-[#A8A8A8]"
              @click.prevent="cartDbHandller(options.index, 'delete')"
            ></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { sendRequest } from '../../api/serviceApi'
import { useCartStore } from '../../stores/useCart'
import { useUserStore } from '../../stores/useUser'

const cart = useCartStore()
const user = useUserStore()
const props = defineProps({
  options: Object,
  isOrder: Boolean
})

const adjustQuantityHandler = (index, command) => {
  cart.adjustQuantity(index, command)
}

const resultPrice = ref(0)

const salePrice = computed(() => {
  return props.options.price
})

const finalPrice = async () => {
  let ea = await cart.cartData[props.options.index].options.ea
  if (cart.cartData[props.options.index].products.sale_of_per > 0) {
    resultPrice.value = salePrice.value * ea
  } else if (cart.cartData[props.options.index].products.sale_of_per === 0) {
    resultPrice.value = props.options.price * ea
  }
}
watchEffect(() => {
  if (cart.cartData[props.options.index]) {
    finalPrice()
  }
})

const cartDbHandller = async (index, cmnd) => {
  const data = {
    params: cmnd,
    payload: {
      order: index,
      userId: user.me._id
    }
  }
  await sendRequest({ method: 'patch', path: '/cart/cartPatch', data })
  cart.getCartData()
}
</script>

<style lang="scss" scoped></style>
