<template>
  <div class="">
    <div class="mt-[16px] mb-[30px] hidden lg:flex justify-between items-center">
      <div class="flex items-center">
        <!-- 선택 버튼 -->
        <div
          v-for="(data, index) in filterData"
          :key="index + 'data'"
          class=""
          v-show="!(!noCategory && data.name === '카테고리')"
        >
          <button
            v-if="data.menuList.length > 0"
            :class="
              data.check
                ? `${data.class} border-[#389033] text-[#389033]`
                : `${data.class} border-[#AAAAAA] text-[#7D7D7D]`
            "
            class="text-[14px] border relative h-[38px] rounded-[5px] font-notosansCJKmd mr-[16px]"
            @click="dropMenuController(index)"
          >
            {{ data.name }}
            <i class="pi pi-angle-down text-[12px] ml-[7px] text-[#707070]"></i>
          </button>

          <!-- 선택 메뉴 -->
          <div
            v-if="selectOpen[index]"
            v-motion
            :initial="{ opacity: 0, y: -20 }"
            :enter="{ opacity: 1, y: 5 }"
            :leave="{ opacity: 0, y: 0 }"
            :delay="50"
            :duration="100"
            class="absolute z-10 bg-white rounded-md"
          >
            <shopFilterGroupVue
              v-if="data.tag === 'category'"
              :filterData="filterData[0].menuList"
            />
            <shopFilterPrice v-if="data.tag === 'price'" class="" />
            <shopFilterproduct
              v-if="data.tag === 'product'"
              class=""
              :filterData="filterData[2].menuList"
            />
            <shopFilterBrand
              v-if="data.tag === 'brand'"
              class=""
              :filterData="filterData[3].menuList"
            />
            <!-- 상품유형과 브랜드에 해당하는 컴포넌트 추가 -->
          </div>
        </div>
        <section v-if="showReset">
          <button
            class="border w-[105px] h-[38px] bg-[#389033] rounded-md font-notosansCJR text-white text-[14px]"
            @click="resetbtn()"
          >
            필터 초기화
          </button>
        </section>
      </div>
      <div class="mr-[20px]">
        <shopFilterDefaultSet />
      </div>
    </div>
    <!-- swiper  -->
    <div class="swiper-container flex lg:hidden">
      <swiper :slides-per-view="'auto'" :space-between="18" :loop="false" class="swiper-wrapper">
        <swiper-slide
          v-for="(data, index) in filterData"
          :key="index + 'data'"
          v-show="!(!noCategory && data.name === '카테고리')"
        >
          <button :class="data.class" @click="dropMenuController(index)">
            {{ data.name }}
          </button>
          <!-- 선택 메뉴 -->
          <div
            v-if="selectOpen[index]"
            v-motion
            :initial="{ opacity: 0, y: -20 }"
            :enter="{ opacity: 1, y: 5 }"
            :leave="{ opacity: 0, y: 0 }"
            :delay="50"
            :duration="100"
            class="absolute z-30 bg-white"
          >
            <shopFilterGroupVue v-if="data.name === '카테고리'" />
            <shopFilterPrice v-if="data.name === '가격'" />
            <!-- 상품유형과 브랜드에 해당하는 컴포넌트 추가 -->
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="">
            <button
              class="text-[15px] text-[#808080] border-2 relative h-[38px] rounded-[5px] w-[109px]"
            >
              기본정렬
            </button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import shopFilterGroupVue from './shopFilterUtil/shopFilterGroup.vue'
import shopFilterPrice from './shopFilterUtil/shopFilterPrice.vue'
import shopFilterDefaultSet from './shopFilterUtil/shopFilterDefaultSet.vue'
import shopFilterBrand from './shopFilterUtil/shopFilterBrand.vue'
import shopFilterproduct from './shopFilterUtil/shopFilterproduct.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useRoute } from 'vue-router'
import { useProductStore } from '../../stores/useProduct'

const pageReady = ref(false)
const product = useProductStore()
const { resetFilter } = product

const props = defineProps({
  noCategory: Boolean
})

const route = useRoute()
const path = route.path

const selectOpen = ref([false, false, false, false])
const closeAllOpen = () => {
  selectOpen.value = selectOpen.value.map(() => false)
}

const resetbtn = () => {
  // product.minPrice = 0
  // product.maxPrice = 25
  resetFilter()
  closeAllOpen()
}
const dropMenuController = (index) => {
  // 현재 인덱스의 상태가 true이면 false로 변경
  if (selectOpen.value[index] === true) {
    selectOpen.value[index] = false
    return
  }
  // 모든 상태를 false로 설정 후, 현재 인덱스만 true로 설정
  for (let i = 0; i < selectOpen.value.length; i++) {
    selectOpen.value[i] = false
  }
  selectOpen.value[index] = true
}

const filterData = ref([
  {
    name: '카테고리',
    class: computed(() => product.filterList.groupFilter.class),
    select: '',
    menuList: [],
    tag: 'category',
    check: computed(() => product.filterList.groupFilter.check)
  },
  {
    name: '가격',
    class: 'w-[75px]',
    select: '',
    menuList: [0, 200],
    tag: 'price',
    check: computed(() => product.filterList.priceFilter.check)
  },
  {
    name: '상품유형',
    class: 'w-[109px]',
    select: '',
    menuList: [],
    tag: 'product',
    check: computed(() => product.filterList.productFilter.check)
  },
  {
    name: '브랜드',
    class: 'w-[97px] ',
    select: '',
    menuList: [],
    tag: 'brand',
    check: computed(() => product.filterList.brandFilter.check)
  }
])

const showReset = ref(false)

watchEffect(() => {
  const isAnyChecked = filterData.value.some((filter) => filter.check)
  if (isAnyChecked) {
    // console.log('하나 이상의 check 값이 true다냥!')
    showReset.value = true
  } else {
    // console.log('모든 check 값이 false다냥...')
    showReset.value = false
  }
})

onMounted(() => {
  if (product.productSorter !== undefined) {
    filterData.value[0].menuList = product.productSorter.catParent
    filterData.value[2].menuList = product.productSorter.catChild
    filterData.value[3].menuList = product.productSorter.company
  }
})

onUnmounted(() => {
  // product.minPrice = 0
  // product.maxPrice = 25
  resetFilter()
})
</script>

<style lang="scss" scoped>
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 13px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
