<template>
  <div v-if="dataReady">
    <div class="lg:w-[1180px] lg:mx-auto mb-[45px]">
      <postBanner class="animate-fadeInBottom opacity-0" :data="bgColor" />
    </div>
    <div class="lg:w-[1180px] mx-auto mb-0 lg:mb-[96px]">
      <div v-for="(post, index) in postDivide" :key="post.title + index" class="lg:mb-[40px]">
        <div class="lg:border-b-2">
          <button
            class="text-[18px] lg:text-[19px] font-notosansCJK lg:font-notosansCJK text-[#389033] relative ml-[12px] lg:ml-[25px]"
          >
            {{ post.title }}
            <!-- 밑줄 -->
            <div class="absolute -bottom-0.5 -left-2 -right-2 h-0.5 lg:bg-[#389033]"></div>
          </button>
        </div>
        <div class="lg:w-[1180px] mx-auto">
          <postListMain
            class="opacity-0 animate-aboutTri1"
            :data="post.dataClass"
            :type="post.title"
          />
          <div class="flex justify-center items-center mt-[30.5px]">
            <button
              class="w-[175px] h-[33px] rounded-md border border-[#AAAAAA] text-[15px]"
              :class="post.class"
              @click="detailRouter(post.router)"
            >
              {{ post.buttonTitle }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, onUnmounted, nextTick } from 'vue'
import postBanner from './postBanner.vue'
import postListMain from './postList.vue/postListMain.vue'
import { sendRequest } from '../../api/serviceApi'
import { useRouter } from 'vue-router'

const router = useRouter()
const bgColor = ref('#FFFAF3')

const pageRady = ref(false)
const dataReady = ref(false)

const newPost = ref(null)
const blogData = ref(null)
const interiorData = ref(null)
const caseData = ref(null)

const postDivide = ref([
  {
    title: '최신글',
    buttonTitle: '',
    class: 'hidden',
    router: '',
    dataClass: newPost
  },
  {
    title: '인테리어 · 시공사례',
    buttonTitle: '시공사례 더보기',
    class: '',
    router: 'interior',
    dataClass: interiorData
  },
  {
    title: '칼럼',
    buttonTitle: '칼럼 더보기',
    class: '',
    router: 'case',
    dataClass: caseData
  },
  {
    title: '연들소식',
    buttonTitle: '연들소식 더보기',
    class: '',
    router: 'blog',
    dataClass: blogData
  }
])

const getAllBlogs = async () => {
  const getPost = await sendRequest({ method: 'post', path: '/post/getPostMain' })
  const result = getPost.data
  if (result) {
    // console.log(result)
    newPost.value = result.new
    blogData.value = result.blog.slice(0, 3)
    interiorData.value = result.interior.slice(0, 3)
    caseData.value = result.case.slice(0, 3)
    dataReady.value = true
  } else {
    console.error(result.message)
  }
}

const detailRouter = (location) => {
  router.push(`/post/${location}`)
}

const goTodetails = (id, type) => {
  router.push({
    path: `/post/details/${id}`,
    query: { type: type }
  })
}

onMounted(() => {
  pageRady.value = true
})

watchEffect(async () => {
  if (pageRady.value === true) {
    await getAllBlogs()
  }
})
</script>

<style lang="scss" scoped></style>
