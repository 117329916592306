<template>
  <div v-if="user" class="">
    <div class="flex justify-center mt-[32.5px] md:mt-[91px]">
      <div>
        <section>
          <div class="flex justify-center">
            <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">회원 로그인</p>
          </div>
        </section>
        <section class="mt-[3.5px] md:mt-[66px]">
          <div class="flex justify-center md:block">
            <p class="hidden md:block text-[18px] font-notosansCJR text-[#6D6D6D]">ID (이메일)</p>
            <input
              type="text"
              class="border w-[320px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[13.5px] pl-[16px] placeholder:text-[13px] md:placeholder:text-[16px]"
              placeholder="ID (이메일)"
              v-model="payload.email"
              @blur="validateEmail"
            />
          </div>
          <p v-if="emailError.status" class="text-red-500 text-[11px] ml-2 mb-2">
            {{ emailError.message }}
          </p>
          <div class="flex justify-center md:block mt-[0px] md:mt-[31.5px]">
            <p class="hidden md:block text-[18px] font-notosansCJR text-[#6D6D6D]">비밀번호</p>
            <input
              type="password"
              class="border w-[320px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[13.5px] pl-[16px] placeholder:text-[13px] md:placeholder:text-[16px]"
              placeholder="비밀번호"
              v-model="payload.password"
              @keyup.enter="fetchProduct"
            />
          </div>
        </section>
        <!-- 리다이렉트 url : {{ redirecturl }} -->
        <section class="hidden md:block mt-[13.5px]">
          <div class="flex justify-between">
            <div class="flex">
              <input
                type="checkbox"
                class="w-[24px] h-[24px] accent-[#389033]"
                v-model="userEmailsave"
              />
              <p class="ml-[12px] text-[16px] text-[#6D6D6D] font-notosansCJR">ID 저장</p>
            </div>
            <div>
              <router-link to="/password/reset">
                <button class="text-[16px] text-[#6D6D6D] font-notosansCJR">비밀번호 변경</button>
              </router-link>
            </div>
          </div>
        </section>
        <section class="mt-[38.5px] md:mt-[40px]">
          <button
            class="border border-[#389033] w-[320px] md:w-[569px] h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033]"
            @click="fetchProduct()"
          >
            로그인
          </button>
        </section>
        <section class="mt-[10px] md:hidden">
          <div class="flex justify-between mx-1">
            <div class="flex items-center">
              <input
                type="checkbox"
                class="w-[18px] h-[18px] accent-[#389033]"
                v-model="autoLogin"
              />
              <p class="text-[12px] font-notosansCJR text-[#6D6D6D] ml-[5px]">자동로그인</p>
            </div>
            <div>
              <router-link to="/password/reset">
                <button class="text-[12px] font-notosansCJR text-[#6D6D6D]">비밀번호 변경</button>
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="">
        <hr class="mt-[39px] w-[917px] hidden md:block" />
        <section class="mt-[42.5px] md:mt-[45px]">
          <p
            class="text-center text-[14px] md:text-[15px] text-[#232323] md:text-[#6D6D6D] font-notosansCJKmd"
          >
            SNS 간편 로그인
          </p>
        </section>
        <section class="mt-[15.7px] md:mt-[15px]">
          <div class="flex justify-center">
            <button @click="kakaoReady()">
              <img src="/img/social/kakao.webp" class="w-[48.29px]" alt="kakaoBadge" />
            </button>
            <button class="ml-[38.6px]" @click="requestNaverLogin()">
              <img src="/img/social/naver.webp" alt="naverBadge" />
            </button>
          </div>
        </section>
      </div>
    </div>
    <hr class="md:hidden mx-auto w-[375px] mt-[38.5px]" />
    <div class="mt-[23.5px] md:mt-[42px] mb-0 md:mb-[191px]">
      <section class="">
        <div class="flex justify-center">
          <router-link to="/register">
            <button
              class="border border-[#6D6D6D] md:border-[#D5D5D5] w-[320px] md:w-[569px] h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#6D6D6D]"
            >
              회원가입
            </button>
          </router-link>
        </div>

        <p
          class="text-center text-[11px] md:text-[14px] text-[#6D6D6D] font-notosansCJKmd mt-[15.7px]"
        >
          회원가입 후 다양한 혜택을 누리세요
        </p>
      </section>
    </div>
    <p
      class="md:hidden mt-[139px] mb-[60px] text-center text-[10px] md:text-[14px] text-[#6D6D6D] font-notosansCJR"
    >
      ⓒ ruruci.inc All rights reserved.
    </p>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { useUserStore } from '../../stores/useUser'
import axios from 'axios'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useRoute, useRouter } from 'vue-router'
import { useSystemStore } from '../../stores/useSystem'

const system = useSystemStore()
const autoLogin = ref(false)
const userEmailsave = ref(false)
const route = useRoute()
const cookies = useCookies()
const user = useUserStore
const router = useRouter()
const redirecturl = ref('')

let emailError = {
  message: '',
  status: false
}

const payload = ref({
  email: '',
  password: ''
})

const validateEmail = async () => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!emailPattern.test(payload.value.email)) {
    emailError.message = '올바른 이메일 형식이 아닙니다.'
    emailError.status = true
  } else {
    emailError.message = ''
    emailError.status = false
  }
}

const setToAuth = async (token) => {
  try {
    const tokenCheck = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/getAuth`,
      {},
      {
        headers: {
          authentication: token
        }
      }
    )

    const setMeData = tokenCheck.data

    // user 객체 상태 설정
    user.status = true
    user.me = setMeData
    user.auth = setMeData.auth
    if (redirecturl.value === undefined) {
      location.href = '/'
      return
    }
    let urls = redirecturl.value
    location.href = urls
    return
  } catch (e) {
    // console.error('Token validation failed:', e)
    // 에러 발생 시 user 상태 초기화
    user.status = false
    user.me = null
    user.auth = null
  }
}

const setAuth = async () => {
  const myJwt = cookies.get('yeondeul')
  if (myJwt) {
    user.token = myJwt
    // console.log('유저 토큰', user.token)
    await setToAuth(myJwt)
    return
  } else {
    console.warn('JWT token is missing.')
    return
  }
}

const fetchProduct = async () => {
  try {
    const getToken = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/loginUsers`,
      payload.value
    )
    const token = getToken.data

    if (token.status === 400) {
      alert(token.message)
    } else if (token.status === 200) {
      if (userEmailsave.value) {
        localStorage.setItem('lastUserEmail', payload.value.email)
      }

      // 자동 로그인에 따른 쿠키 만료 시간 설정
      const expiresTime = autoLogin.value
        ? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) // 1주일
        : new Date(Date.now() + 3 * 60 * 60 * 1000) // 3시간

      // system.autoLogin = autoLogin.value
      localStorage.setItem('autoLoginInfo', autoLogin.value)

      // 쿠키 설정
      cookies.set('yeondeul', token.message, { expires: expiresTime, path: '/' })

      // 인증 로직 실행
      await setAuth()
    }
  } catch (error) {
    console.error('Login failed:', error)
  }
}

const requestNaverLogin = async () => {
  try {
    const reqData = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/naver/oAuth/login`)
    const result = reqData.data
    window.location.href = result
  } catch (error) {
    console.error(error)
  }
}

const kakaoReady = async () => {
  window.location.href = `${import.meta.env.VITE_API_BASE_URL}/kakao/oAuth/login`
}

watchEffect(() => {
  if (payload.value.email) {
    validateEmail()
  }
})

onMounted(() => {
  cookies.remove('yeondeul', { path: '*' })
  const savedUserEmail = localStorage.getItem('lastUserEmail')
  if (savedUserEmail) {
    payload.value.email = savedUserEmail
    userEmailsave.value = true
  }
  if (route.path) {
    redirecturl.value = route.query.redirect
  }
})

watch(
  () => route.query.redirect, // 감시 대상 설정
  (newRoute) => {
    if (newRoute) {
      console.log('새로운 경로로 리디렉션:', newRoute)
      redirecturl.value = newRoute
    } else {
      console.warn('쿼리 파라미터에 redirect가 없습니다.')
      redirecturl.value = undefined
    }
  },
  { immediate: true } // 컴포넌트가 마운트될 때도 실행
)
// 이메일 저장

watch(
  () => userEmailsave.value,
  (autoSave) => {
    if (autoSave === false) {
      localStorage.removeItem('lastUserEmail')
    }
  }
)
</script>

<style lang="scss" scoped></style>
