<template>
  <div class="w-[170px] h-[298px] lg:w-[251px] lg:h-[434px]">
    <!-- 1. img -->
    <img
      :src="item.thumb_img"
      alt=""
      class="rounded-md object-center object-cover w-[170px] h-[210px] lg:w-[251px] lg:h-[305px]"
    />
    <!-- des -->
    <div class="mt-[10.1px]">
      <!-- 2. category -->
      <section v-if="item.category">
        <p
          v-if="item.category.child.catName !== ''"
          class="ml-[5.4px] lg:ml-[7.5px] text-[11px] lg:text-[16px] text-[#707070] font-notosansCJKmd"
        >
          {{
            `[${item.category.parent.catName} - ${item.category.child.catName}]` ||
            '[카테고리 - 임시]'
          }}
        </p>
        <p
          v-if="item.category.child.catName === ''"
          class="ml-[5.4px] lg:ml-[7.5px] text-[11px] lg:text-[16px] text-[#707070] font-notosansCJKmd"
        >
          {{ `[${item.category.parent.catName}]` || '[카테고리]' }}
        </p>
      </section>
      <!-- 3. title -->
      <p class="ml-[5.4px] lg:ml-[7.5px] text-[12px] lg:text-[16px] font-notosansCJKmd truncate">
        {{ item.title }}
      </p>
      <!-- 4. price -->
      <div class="flex items-center mt-[7.6px] lg:mt-[8px]">
        <!-- 원가 -->
        <p
          v-if="item.sale_of_per === 0"
          class="ml-[5.4px] lg:ml-[8.5px] font-notosansCJKmd text-[#232323] text-[11px] lg:text-[16px]"
        >
          {{ item.price.toLocaleString('ko-KR') || 'null' }} 원
        </p>
        <!-- 세일가  -->
        <p
          v-if="item.sale_of_per !== 0"
          class="ml-[5.4px] lg:ml-[8.5px] font-notosansCJKmd text-[#232323] text-[11px] lg:text-[16px]"
        >
          {{ (item.price - item.sale_of_per).toLocaleString('ko-KR') || 'null' }} 원
        </p>
        <!-- 세일시 원가 취소선 -->
        <p
          v-if="item.sale_of_per !== 0"
          class="ml-[8.5px] font-notosansCJKmd text-[#707070] line-through text-[11px] lg:text-[16px]"
        >
          {{ item.price.toLocaleString('ko-KR') || 'null' }} 원
        </p>
        <!-- <p v-if="!checkSoldOut()">품절</p> -->
      </div>
    </div>
    <!-- 5. badge -->
    <!-- <div class="mt-[12.7px] flex">
      <p
        v-for="badge in badgeData"
        :key="badge"
        class="mr-[9px] px-2 h-[28px] text-[13px] text-[#7D7D7D] font-notosansCJKmd items-center flex justify-center rounded-md"
        :class="badge.class"
      >
        {{ badge.name }}
      </p>
    </div> -->
    <shopCardTag :item="props.item" class="-mt-[13px] lg:mt-0" />
    <!-- <div class="flex">
      <p
        class="px-2 h-[28px] text-[13px] text-[#7D7D7D] font-notosansCJKmd items-center flex justify-center rounded-md"
        :class="badgeData[0].class"
      >
        {{ badgeData[0].name }}
      </p>
      <p
        class="px-2 h-[28px] text-[13px] text-[#7D7D7D] font-notosansCJKmd items-center flex justify-center rounded-md"
        :class="badgeData[0].class"
      >
        {{ badgeData[0].name }}
      </p>
    </div> -->
  </div>
</template>

<script setup>
import { ref } from 'vue'
import shopCardTag from './shopCardTag.vue'
const props = defineProps({
  item: Object
})

const badgeData = [
  {
    name: '연들',
    tag: 'yeondeul',
    class: 'bg-[#F6F6F6] w-[50px]'
  },
  {
    name: '무료배송',
    tag: 'freeShipped',
    class: 'bg-[#F6F6F6] w-[73px]'
  }
]
</script>

<style lang="scss" scoped></style>
