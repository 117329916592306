<template>
  <div v-if="pageReady" ref="reViewFrame">
    <div class="flex md:block justify-between items-center">
      <section>
        <div class="">
          <p
            class="text-[14px] md:text-[18px] font-notosansCJKbold text-[#3D3D3D] ml-[22px] md:ml-[9px]"
          >
            리뷰
            <sapn class="text-[14px] md:text-[18px] ml-[9px] font-notosansCJR text-[#D23F3F]">
              {{ product?.productItem?.reviewCount }}
            </sapn>
          </p>
        </div>
      </section>
      <section>
        <div class="ml-[35px] md:hidden mr-[10.9px] w-[103px] h-[28px]">
          <div
            class="relative flex items-center border-[1px] rounded-md border-[#AAAAAA] h-full w-full cursor-pointer"
          >
            <!-- 선택된 값 표시 -->
            <div
              @click="toggleDropdown"
              class="w-full h-full pl-[17px] text-[15px] text-[#7D7D7D] flex items-center justify-between"
            >
              <span class="text-[13px] font-notosansCJR text-[#7D7D7D]">{{
                selectedOption.name || '선택'
              }}</span>
              <i class="pi pi-chevron-down text-[8px] pr-2 pt-1 text-[#707070]"></i>
            </div>

            <!-- 드롭다운 메뉴 -->
            <div
              v-if="dropdownOpen"
              class="absolute top-full left-0 w-full bg-white border border-[#AAAAAA] z-10"
            >
              <div
                v-for="(item, index) in selectBox"
                :key="index"
                @click="selectOption(item)"
                class="px-[17px] py-[5px] hover:bg-gray-200 ] cursor-pointer"
              >
                <p class="text-[13px] font-notosansCJR text-[#7D7D7D]">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <!-- 별점 상단 노출 이미지 -->
    <div
      class="md:w-[772px] h-[97px] flex justify-between items-center ml-[25.4px] md:ml-[35px] mt-[21.6px] md:mt-0"
    >
      <!-- 별점 모바일 -->
      <div class="md:hidden">
        <div>
          <p class="text-[24px] font-notosansCJKmd text-[#389033] text-center">
            {{ product?.productItem?.rating ? product.productItem.rating + '.0' : '' }}
          </p>
          <Rating
            v-if="
              product?.productItem?.rating !== null && product?.productItem?.rating !== undefined
            "
            v-model="product.productItem.rating"
            class="flex text-[#389033] space-x-[2.5px]"
            :onIcon="'pi pi-star-fill text-[13px]'"
            :offIcon="'pi pi-star text-[13px] hidden'"
            :cancel="false"
            readonly
          />
          <!-- <Rating v-model="startRating" :cancel="false" class="flex text-[#389033]" /> -->
        </div>
      </div>
      <!-- 별점 -->
      <div class="h-[35px] hidden md:flex items-center justify-between">
        <!-- 별 -->
        <div>
          <Rating
            v-if="
              product?.productItem?.rating !== null && product?.productItem?.rating !== undefined
            "
            v-model="product.productItem.rating"
            :cancel="false"
            class="flex text-[#389033] text-[21px] space-x-[2.5px] custom-rating"
            :onIcon="'pi pi-star-fill text-[21px]'"
            :offIcon="'pi pi-star text-[21px] hidden'"
            readonly
          />
        </div>
        <!-- 점 -->
        <p class="ml-[22px] text-[32px] font-notosansCJKmd text-[#389033] md:pb-3">
          {{ product?.productItem?.rating ? product.productItem.rating + '.0' : '' }}
        </p>
      </div>
      <!-- 사진 -->
      <div class="flex mr-[12.8px] md:mr-0">
        <div v-for="(imgs, index) in reviewTopimgArray" :key="imgs" class="ml-[10px]">
          <img
            :src="imgs"
            :alt="index"
            class="w-[66.8px] h-[66.8px] md:w-[97px] md:h-[97px] bg-center bg-cover rounded-md"
          />
        </div>
      </div>
    </div>
    <!-- 필터 드롭다운 -->
    <div class="mt-[28px] w-[772px] hidden md:flex justify-end ml-[35px]">
      <form
        action="#"
        class="flex items-center w-[109px] h-[38px] border border-1 border-[#AAAAAA] rounded-md outline-none pr-2"
      >
        <select
          name="reviewBox"
          id="reviewFilter"
          class="w-full h-full pl-[8.5px] text-[15px] text-[#7D7D7D] border-[#AAAAAA] rounded-md outline-none"
        >
          <option v-for="(item, index) in selectBox" :key="index" :value="item.value" class="">
            <p>
              {{ item.name }}
            </p>
          </option>
        </select>
      </form>
    </div>
    <section class="mt-[22.8px] ml-[15.5px] md:mt-[14px] md:ml-[25.2px]">
      <!-- 필터 -->
      <!-- 상세 -->
      <div v-for="(reviews, index) in sendData" :key="index">
        <productReviewCard :reviewDatas="reviews" class="mb-[62px] hidden md:block" />
        <productReviewMobileCard :reviewDatas="reviews" class="mb-[22.8px] md:hidden" />
      </div>
    </section>
    <button
      v-if="!reviewMobileLoadDone && isMobile"
      class="lg:hidden w-full h-[33px] bg-[#F2F2F2] text-[12px] font-notosansCJKmd mt-[22px] flex items-center justify-center"
      @click="reviewMobileLoader()"
    >
      <p>리뷰 더보기</p>
      <i class="pi pi-chevron-down ml-[5px]"></i>
    </button>
    <pagination
      class="hidden lg:block"
      :numberOfData="4"
      :data="reviewDatas"
      @update-data="handleData"
    />
    <p
      v-if="isNotReviewd"
      class="text-center text-[14px] lg:text-[16px] font-notosansCJKmd text-[#707070] pb-[50px]"
    >
      리뷰 내용이 없습니다
    </p>
  </div>
</template>

<script setup>
import { ref, watchEffect, watch, onMounted } from 'vue'
import productReviewCard from './productReview/productReviewCard.vue'
import productReviewMobileCard from './productReview/productReviewMobileCard.vue'
import pagination from '../../util/pagination.vue'
import Rating from 'primevue/rating'
import { useSystemStore } from '../../../stores/useSystem'
import productReviewImgModalVue from '../../modal/productReviewImgModal.vue'
import { useProductStore } from '../../../stores/useProduct'
import { sendRequest } from '../../../api/serviceApi'

const product = useProductStore()
const system = useSystemStore()
const sendData = ref(null)
const reviewMobileLimiter = ref(2)
const reviewMobileLoadDone = ref(false)
const isNotReviewd = ref(false)

const props = defineProps({
  isMobile: Boolean
})

const reviewMobileLoader = () => {
  reviewMobileLimiter.value += 2
}

const selectBox = [
  {
    name: '베스트순',
    value: 'best'
  },
  {
    name: '작성순',
    value: 'date'
  },
  {
    name: '별점높은순',
    value: 'rate'
  }
]

const childData = ref('')
const handleData = (data) => {
  childData.value = data
}

const selectedOption = ref({}) // 선택된 값
const dropdownOpen = ref(false) // 드롭다운 열림 상태

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value // 드롭다운 열기/닫기
}

const selectOption = (item) => {
  selectedOption.value = item // 선택한 값 설정
  dropdownOpen.value = false // 드롭다운 닫기
}

const reviewFilter = ref()
const reviewOptions = ref(['베스트 순', '별점 순', '최신 순'])

const reviewDatas = ref([])
const reviewTopimgArray = ref([]) // 리뷰 이미지 상단 배열

// 리뷰 데이터에서 최대 3개의 이미지를 추출하는 함수
const extractTopImages = (reviews) => {
  try {
    const images = []

    // 리뷰 데이터를 순회하며 이미지 추출
    reviews.forEach((review) => {
      if (review.reviewData && review.reviewData.reviewImg) {
        images.push(...review.reviewData.reviewImg) // 이미지 배열에 추가
      }
    })

    // 최대 3개의 이미지만 추출, 3개 미만이면 가능한 만큼만 반환
    return images.slice(0, Math.min(images.length, 3))
  } catch (error) {
    // console.error('이미지 추출 중 오류 발생:', error)
    return [] // 오류 발생 시 빈 배열 반환
  }
}

const gteProductReview = async () => {
  try {
    const product_id = product.productItem._id
    // console.log(product_id)

    const payload = {
      id: product_id
    }
    const getReview = await sendRequest({
      method: 'post',
      path: '/review/getProductReiew',
      data: payload
    })
    const result = getReview.data
    reviewDatas.value = result

    // 리뷰 데이터를 불러온 후 이미지 추출
    const threeImgs = extractTopImages(reviewDatas.value)
    reviewTopimgArray.value = threeImgs
  } catch (error) {}
}

watchEffect(() => {
  if (reviewDatas.value === null) {
    isNotReviewd.value = true
    return
  }
  if (
    reviewMobileLimiter.value === reviewDatas.value.length ||
    reviewMobileLimiter.value > reviewDatas.value.length
  ) {
    reviewMobileLoadDone.value = true
    reviewMobileLimiter.value = reviewDatas.value.length
  }
  if (props.isMobile) {
    sendData.value = reviewDatas.value
  } else if (!props.isMobile) {
    sendData.value = childData.value
  }
})

const pageReady = ref(false)

onMounted(() => {
  pageReady.value = true
})

watchEffect(() => {
  if (pageReady.value === true) {
    gteProductReview()
  }
})
</script>

<style lang="scss" scoped></style>
