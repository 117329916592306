import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '../stores/useUser'
import { nextTick } from 'vue'

// Layouts
import mainLayout from '../layouts/mainLayout.vue'
import adminLayout from '../layouts/adminLayout.vue'
import mypageLayout from '../layouts/mypageLayout.vue'

// Pages
import Index from '../pages/Index.vue'
import about from '../pages/about.vue'
import mypage from '../pages/mypage.vue'
import notfound from '../pages/error/notfound.vue'
import product from '../pages/product/index.vue'
import postDetails from '../pages/post/details/index.vue'
import post from '../pages/post/index.vue'
import postId from '../pages/post/[id].vue'
import shop from '../pages/shop/index.vue'
import reactiveShop from '../pages/shop/[id].vue'
import account from '../pages/account/[id].vue'
import admin from '../pages/admin/[id].vue'
import adminOld from '../pages/admin/index.vue'
import search from '../pages/search/index.vue'
import test from '../pages/test/index.vue'
import cart from '../pages/cart/index.vue'
import checkout from '../pages/checkout.vue'
import billing from '../pages/billing/index.vue'
import login from '../pages/login/index.vue'
import register from '../pages/register/index.vue'
import passwordReset from '../pages/password/reset/index.vue'
import registerNaver from '../pages/register/naver/index.vue'
import registerkakao from '../pages/register/kakao/index.vue'
import support from '../pages/support/index.vue'
import notices from '../pages/notices/index.vue'
import qna from '../pages/qna/index.vue'
import noticeDetail from '../pages/noticeDetail/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    meta: { layout: mainLayout }
  },
  {
    path: '/support',
    name: 'support',
    component: support,
    meta: { layout: mainLayout }
  },
  {
    path: '/notices',
    name: 'notices',
    component: notices,
    meta: { layout: mainLayout }
  },
  {
    path: '/qna',
    name: 'qna',
    component: qna,
    meta: { layout: mainLayout }
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: noticeDetail,
    meta: { layout: mainLayout }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta: { layout: mainLayout }
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart,
    meta: { layout: mainLayout }
  },
  {
    path: '/product/:id',
    name: 'product',
    component: product,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/post',
    name: 'post',
    component: post,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/post/:id',
    name: 'postId',
    component: postId,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/account/:id',
    name: 'account',
    component: account,
    props: true,
    meta: { layout: mainLayout },
    beforeEnter: (to, from, next) => {
      const user = useUserStore()
      if (user.auth === 'user' || user.auth === 'admin') {
        next() // 권한이 있는 경우 정상적으로 이동
      } else {
        // 로그인 페이지로 이동하며 원래 경로를 쿼리 파라미터로 전달
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    }
  },
  {
    path: '/admin/:id',
    name: 'admin',
    component: admin,
    props: true,
    meta: { layout: mainLayout },
    // 네비게이션 가드
    beforeEnter: (to, from, next) => {
      const user = useUserStore()
      if (user.auth === 'admin') {
        next()
      } else {
        next('/404')
      }
    }
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/post/details/:id',
    name: 'postDetails',
    component: postDetails,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/mypage/:id',
    name: 'mypage',
    component: mypage,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/shop',
    name: 'shop',
    component: shop,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/shop/:id',
    name: 'reactiveShop',
    component: reactiveShop,
    props: true,
    meta: { layout: mainLayout }
  },
  // {
  //   path: '/404',
  //   name: 'notFound',
  //   component: notfound,
  //   meta: { layout: mainLayout }
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: notfound,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/test',
    name: 'test',
    component: test,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: checkout,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/billing',
    name: 'billing',
    component: billing,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/register/naver',
    name: 'registerNaver',
    component: registerNaver,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/register/kakao',
    name: 'registerkakao',
    component: registerkakao,
    props: true,
    meta: { layout: mainLayout }
  },
  {
    path: '/password/reset',
    name: 'passwordReset',
    component: passwordReset,
    props: true,
    meta: { layout: mainLayout }
  }
]

const router = createRouter({
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

// // 라우트 가드에서 Pinia 스토어 사용
// router.beforeEach(async (to, from, next) => {
//   const userStore = useUserStore()

//   console.log('라우트 전, 이동하려는 경로:', to.path)
//   console.log('라우트 전, 사용자 권한:', userStore.auth)

//   if (to.meta.requiresAuth && userStore.auth !== 'admin') {
//     console.log('관리자 권한이 필요합니다.')
//     next('/')
//   } else {
//     next()
//   }
// })

export default router
