const refundGuide = [
  {
    title: '교환·반품 사유에 따른 요청 가능 기간',
    des: [
      {
        left: '1. 구매자 단순 변심의 경우 상품 수령 후 7일 이내',
        right: ' (구매자 반품 배송비 부담)'
      },
      {
        left: '2. 표시/광고와 상이, 계약 내용과 다르게 이행된 경우 상품 수령 후 3개월 이내 혹은 표시/광고와 다른 사실을 안 날로부터',
        right: ''
      },
      {
        left: '30일 이내',
        right: ' (판매자 반품 배송비 부담)',
        style: 'ml-[15px] '
      }
    ]
  },
  {
    title: '교환·반품이 불가한 경우',
    des: [
      {
        left: '1. 교환/반품 요청이 기간이 지난 경우',
        right: ''
      },
      {
        left: '2. 소비자의 책임 있는 사유로 상품 등이 분실/파손/훼손된 경우',
        right: ' (단, 확인을 위한 포장 훼손 제외)'
      },
      {
        left: '3. 소비자의 사용/소비에 의해 상품 등의 가치가 현저히 감소한 경우',
        right: ' (ex) 식품, 화장품, 향수, 음반)'
      },
      {
        left: '4. 제품을 설치 또는 장착하였거나 개통한 경우',
        right: ' (ex) 전자제품, 설치상품 등'
      },
      {
        left: '5. 시간의 경과에 의해 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우',
        right: '(ex) 신선식품, 화장품)'
      },
      {
        left: '6. 복제가 가능한 상품 등의 포장을 훼손한 경우',
        right: '(ex)CD/DVD/게임CD/책의 경우 포장 개봉 시)'
      },
      {
        left: '7. 주문제작 상품 중 상품제작에 들어간 경우',
        right: ' (주문접수 후 개별생산, 맞춤 제작 등)'
      }
    ]
  }
]

export default refundGuide
