<template>
  <div>
    <p class="font-notosansCJKmd text-[22px] text-[#171717] mb-[10.5px] ml-[10px]">결제수단 선택</p>
    <div class="border-t-[2px] border-[#6D6D6D] flex justify-between">
      <!-- left  -->
      <div class="mt-[27.5px] ml-[10px]">
        <!-- 결제수단 선택창 -->
        <div class="mb-[44.8px]">
          <checkoutPayInfoSelector />
        </div>
        <!-- 고지사항 -->
        <div v-for="(text, index) in pixeData" :key="'pixeData' + index" class="">
          <div class="mb-[22.5px]">
            <p class="text-[14px] text-[#232323] font-notosansCJKmd">{{ text.label }}</p>
            <p class="text-[12px] text-[#6D6D6D] font-notosansCJKmd mt-[10.5px]">{{ text.des }}</p>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="mt-[24.7px]">
        <checkoutPayInfo />
      </div>
    </div>
  </div>
</template>

<script setup>
import checkoutPayInfo from './checkoutPayInfo.vue'
import checkoutPayInfoSelector from './checkoutPayInfoSelector.vue'

const pixeData = [
  {
    label: '현금영수증 자진발급제 도입',
    des: '＊ 현금영수증 미발행 고객에 한하여 국세청 임의번호 (010-000-1234)로 자동 발행 (미발행 고객의 경우 홈택스에서 번호 수정)'
  },
  {
    label: '사업자 고객 세금계산서 발행',
    des: '＊ 무통장 입금 고객 중 세금계산서가 필요한 사업자 고객의 경우 당사 고객센터 연락 혹은 배송요청사항에 사업자 사항 기재시 세금계산서 발행 가능합니다.'
  },
  {
    label: '무이자 할부 안내',
    des: '＊ 무이자 할부는 카드사 별로 혜택이 상이할 수 있으며 결제 전 무이자 혜택을 꼭 확인하신 후 결제를 부탁드립니다.'
  }
]
</script>

<style lang="scss" scoped></style>
