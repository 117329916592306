<template>
  <div>
    <section class="md:hidden">
      <div>
        <section class="">
          <div class="flex justify-center">
            <p class="text-[12px] text-[#389033] font-notosansCJKmd">{{ request }}비용 (배송비)</p>
            <p class="text-[13px] text-[#389033] font-notosansCJKmd ml-[19px]">
              {{ highestRefundPrice.toLocaleString() }}원
            </p>
          </div>
        </section>
        <!-- 2. 반품비 계산책정 -->
        <section
          v-if="order.refundDats.stage0.selectedReason.value === 'customer'"
          class="mt-[15px]"
        >
          <div class="flex justify-center">
            <button
              v-if="request !== '교환'"
              class="w-[128px] h-[28px] border rounded-md"
              :class="selecteData[0].border"
              @click="selectBoxCommander(0)"
            >
              <p class="text-[11px] font-notosansCJKmd text-[#7D7D7D]" :class="selecteData[0].text">
                환불금 차감
              </p>
            </button>
            <button
              class="w-[128px] h-[28px] border rounded-md ml-[12px]"
              :class="selecteData[1].border"
              @click="selectBoxCommander(1)"
            >
              <p class="text-[11px] font-notosansCJKmd text-[#7D7D7D]" :class="selecteData[1].text">
                송금·현금동봉
              </p>
            </button>
          </div>
        </section>
        <section class="mt-[22.5px]">
          <div class="flex justify-center">
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">{{ request }}금액 계산</p>
          </div>
          <hr class="mt-[8.7px] mx-2" />
        </section>
        <!-- 4. 금액 정산  -->
        <section class="mx-7 mt-[21px] mb-[32px]">
          <div v-if="request !== '교환'" class="flex justify-between">
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">상품금액</p>
            <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">
              {{ totalOrderPrice.toLocaleString() }}원
            </p>
          </div>
          <div
            v-if="request !== '교환' && isFullRefund === true"
            class="flex justify-between mt-[12px]"
          >
            <p class="text-[12px] font-notosansCJKmd text-[#232323]">배송비</p>
            <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">
              + {{ initialShippingFee.toLocaleString() }}원
            </p>
          </div>
          <div
            v-if="order.refundDats.stage0.selectedReason.value === 'customer'"
            class="flex justify-between mt-[12px]"
          >
            <p v-if="request !== '교환'" class="text-[12px] font-notosansCJKmd text-[#232323]">
              반품비용 (배송비)
            </p>
            <p v-else class="text-[12px] font-notosansCJKmd text-[#232323]">교환비용 (배송비)</p>
            <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">
              - {{ totalExchangePrice.toLocaleString() }}원
            </p>
          </div>
          <hr class="mt-[16px]" />
          <div v-if="request === '반품'" class="flex justify-between mt-[12px]">
            <p class="text-[13px] font-notosansCJKmd text-[#232323]">환불 예정 금액</p>
            <p class="text-[14px] font-notosansCJKmd text-[#389033]">
              {{ totalRefundAmount.toLocaleString() }}원
            </p>
          </div>
          <div v-else class="flex justify-between mt-[12px]">
            <p class="text-[13px] font-notosansCJKmd text-[#232323]">송금·현금동봉 필요 금액</p>
            <p class="text-[14px] font-notosansCJKmd text-[#389033]">
              {{ highestRefundPrice.toLocaleString() }}원
            </p>
          </div>
        </section>
        <!-- 5. 유의 사항 -->
        <section class="mb-[11.5px]">
          <div class="flex justify-end mr-5 cursor-pointer" @click="cautionStatus = !cautionStatus">
            <p class="text-[9px] font-notosansCJKmd text-[#6D6D6D]">교환·반품시 유의사항</p>
            <i
              class="pi pi-angle-down ml-[4px] text-[9px] pt-[1px] text-[#6D6D6D] -rotate-90"
              :class="`${reloate}`"
            ></i>
          </div>
        </section>
        <!-- 6.상황 -->
        <modalRefundExchangeCaution v-if="cautionStatus" />
      </div>
    </section>

    <!-- pc -->
    <section class="hidden md:block">
      <!-- 1. 배송비 계산 -->
      <section>
        <div class="flex justify-center">
          <p class="text-[18px] text-[#389033] font-notosansCJKmd">{{ request }}비용 (배송비)</p>
          <p class="text-[18px] text-[#389033] font-notosansCJKmd ml-[43px]">
            {{ totalExchangePrice.toLocaleString() }}원
          </p>
        </div>
      </section>
      <!-- 2. 반품비 계산책정 -->
      <section v-if="order.refundDats.stage0.selectedReason.value === 'customer'" class="mt-[21px]">
        <div class="flex justify-center">
          <button
            v-if="request !== '교환'"
            class="w-[175px] h-[37px] border rounded-md"
            :class="selecteData[0].border"
            @click="selectBoxCommander(0)"
          >
            <p class="text-[15px] font-notosansCJKmd text-[#7D7D7D]" :class="selecteData[0].text">
              환불금 차감
            </p>
          </button>
          <button
            class="w-[175px] h-[37px] border rounded-md ml-[11px]"
            :class="selecteData[1].border"
            @click="selectBoxCommander(1)"
          >
            <p class="text-[15px] font-notosansCJKmd text-[#7D7D7D]" :class="selecteData[1].text">
              송금·현금동봉
            </p>
          </button>
        </div>
      </section>
      <!-- 3. 금액 계산 p  -->
      <section class="mt-[20px]">
        <div class="flex justify-center">
          <p class="text-[18px] font-notosansCJKmd text-[#232323]">{{ request }}금액 계산</p>
        </div>
        <hr class="mt-[16px] mx-2" />
      </section>
      <!-- 4. 금액 정산  -->
      <section class="w-[425px] mx-auto mt-[21px] mb-[32px]">
        <div v-if="request !== '교환'" class="flex justify-between">
          <p class="text-[15px] font-notosansCJKmd text-[#232323]">상품금액</p>
          <p class="text-[17px] font-notosansCJKmd text-[#6D6D6D]">
            {{ totalOrderPrice.toLocaleString() }}원
          </p>
        </div>
        <div
          v-if="request !== '교환' && isFullRefund === true"
          class="flex justify-between mt-[12px]"
        >
          <p class="text-[15px] font-notosansCJKmd text-[#232323]">배송비</p>
          <p class="text-[17px] font-notosansCJKmd text-[#6D6D6D]">
            + {{ initialShippingFee.toLocaleString() }}원
          </p>
        </div>
        <div
          v-if="order.refundDats.stage0.selectedReason.value === 'customer'"
          class="flex justify-between mt-[12px]"
        >
          <p v-if="request === '반품'" class="text-[15px] font-notosansCJKmd text-[#232323]">
            반품비용 (배송비)
          </p>
          <p v-else class="text-[15px] font-notosansCJKmd text-[#232323]">교환비용 (배송비)</p>
          <p class="text-[17px] font-notosansCJKmd text-[#6D6D6D]">
            - {{ totalExchangePrice.toLocaleString() }}원
          </p>
        </div>
        <hr class="mt-[16px]" />
        <div v-if="request !== '교환'" class="flex justify-between mt-[12px]">
          <p class="text-[15px] font-notosansCJKmd text-[#232323]">환불 예정 금액</p>
          <p class="text-[17px] font-notosansCJKmd text-[#389033]">
            {{ totalRefundAmount.toLocaleString() }}원
          </p>
        </div>
        <div v-else class="flex justify-between mt-[12px]">
          <p class="text-[15px] font-notosansCJKmd text-[#232323]">송금·현금동봉 필요 금액</p>
          <p class="text-[17px] font-notosansCJKmd text-[#389033]">
            {{ totalExchangePrice.toLocaleString() }}원
          </p>
        </div>
      </section>
      <section class="mb-[10.5px]">
        <div
          class="flex justify-end mr-[30px] cursor-pointer"
          @click="cautionStatus = !cautionStatus"
        >
          <p class="text-[14px] font-notosansCJKmd text-[#6D6D6D]">교환·반품시 유의사항</p>
          <i
            class="pi pi-angle-down ml-[4px] pt-[1px] text-[#6D6D6D] -rotate-90"
            :class="`${reloate}`"
          ></i>
        </div>
      </section>
      <modalRefundExchangeCaution v-if="cautionStatus" />
    </section>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useOrderStore } from '../../../../stores/userOrder'
import modalRefundExchangeCaution from '../modalRefundExchangeCaution.vue'

const props = defineProps({
  request: String
})

const order = useOrderStore()
const pageReady = ref(false)
const cautionStatus = ref(false)
const reloate = ref('')

watchEffect(() => {
  if (!cautionStatus.value) {
    reloate.value = 'animate-rotateRevers'
  } else {
    reloate.value = 'animate-rotate'
  }
})

const selecteData = ref([
  {
    border: '',
    text: ''
  },
  {
    border: '',
    text: ''
  }
])

const selectBoxCommander = (i) => {
  if (i === 0) {
    order.refundDats.stage2.refundPricePayMethod = 'calcToRefund'
  }
  if (i === 1) {
    order.refundDats.stage2.refundPricePayMethod = 'additonal'
  }
  selecteData.value.forEach((selData) => {
    selData.border = ''
    selData.text = ''
  })
  // selecteData.value = resetSelData
  selecteData.value[i].border = 'border-[1px] border-green-700'
  selecteData.value[i].text = 'text-green-700'
  if (props.request === '반품') {
    order.refundDats.stage2.chargePrice = highestRefundPrice.value
  } else if (props.request === '교환') {
    order.refundDats.stage2.chargePrice = totalExchangePrice.value
  }
}

//
// 선택된 항목을 사용하여 refundListByOdn 필터링
const selectedOrderDetails = computed(() => {
  const selectedItems = order.refundDats.stage0.selectedItems

  if (order.refundListByOdn && selectedItems.length > 0) {
    return order.refundListByOdn.filter((refundItem) =>
      selectedItems.some(
        (selected) =>
          selected.orderNumber === refundItem.orderNumber &&
          selected.orderDetailNumber === refundItem.orderDetailNumber
      )
    )
  }

  return [] // 선택된 항목이 없거나 일치하는 항목이 없을 경우 빈 배열 반환
})

// 선택된 항목의 총 상품 금액 계산 (totalPrice)
const totalOrderPrice = computed(() => {
  const selectedDetails = selectedOrderDetails.value

  if (selectedDetails.length > 0) {
    return selectedDetails.reduce((acc, detail) => acc + (detail.totalPrice || 0), 0)
  }

  return 0
})

// 선택된 항목의 가장 높은 refundPrice 계산
const highestRefundPrice = computed(() => {
  const selectedDetails = selectedOrderDetails.value

  if (order.refundDats.stage0.selectedReason.value === 'company') {
    return 0
  }

  if (selectedDetails.length > 0) {
    const refundPrices = selectedDetails.map((detail) => detail.extreafee?.refundPrice || 0)
    return Math.max(...refundPrices)
  }

  return 0
})

const totalExchangePrice = computed(() => {
  const selectedDetails = selectedOrderDetails.value

  if (order.refundDats.stage0.selectedReason.value === 'company') {
    return 0
  }

  if (selectedDetails.length > 0) {
    const refundPrices = selectedDetails.map((detail) => {
      if (props.request === '반품') {
        return detail.extreafee?.refundPrice || 0
      } else if (props.request === '교환') {
        return detail.extreafee?.changePrcie || 0
      }
    })
    console.log('반품/교환 비용', refundPrices)

    // return refundPrices.reduce((total, price) => total + price, 0) //
    return Math.max(...refundPrices)
  }

  return 0
})

// 초기 배송비 확인
const initialShippingFee = computed(() => {
  // if (order.refundDats.stage0.selectedReason.value === 'company') {
  //   return 0
  // }
  const selectedDetails = selectedOrderDetails.value
  if (selectedDetails.length > 0) {
    const company = selectedDetails[0].company // 선택된 첫 번째 상품의 회사명

    const buyPriceInfos = order.orderDetailData.orderData.buyPriceInfos[company]

    if (buyPriceInfos) {
      const shippingFeeData = buyPriceInfos[0].ammountData.find((data) => data.includes('+ 배송비'))
      console.log('shippingFeeData', shippingFeeData)

      const shippingFee = shippingFeeData
        ? parseInt(shippingFeeData.match(/[\d,]+/)[0].replace(/,/g, ''))
        : 0
      return shippingFee
    }
  }

  return 0
})

// 도서산간 추가 비용 확인
const extraShippingFee = computed(() => {
  const selectedDetails = selectedOrderDetails.value
  if (selectedDetails.length > 0) {
    const company = selectedDetails[0].company

    const buyPriceInfos = order.orderDetailData.orderData.buyPriceInfos[company]

    if (buyPriceInfos) {
      const extraFeeData = buyPriceInfos[0].ammountData.find((data) =>
        data.includes('+ 도서산간지역')
      )
      const extraFee = extraFeeData
        ? parseInt(extraFeeData.match(/[\d,]+/)[0].replace(/,/g, ''))
        : 0
      return extraFee
    }
  }

  return 0
})

// 모든 환불 여부 확인
const isFullRefund = computed(() => {
  const selectedDetails = selectedOrderDetails.value
  if (selectedDetails.length > 0) {
    const company = selectedDetails[0].company
    const allItemsForCompany = order.refundListByOdn.filter((item) => item.company === company)
    return allItemsForCompany.every((item) =>
      selectedDetails.some(
        (selected) =>
          selected.orderNumber === item.orderNumber &&
          selected.orderDetailNumber === item.orderDetailNumber
      )
    )
  }

  return false
})

const totalRefundAmount = computed(() => {
  // 초기 셋업
  let refundAmount = totalOrderPrice.value + extraShippingFee.value

  // 회사 과실 처리
  if (order.refundDats.stage0.selectedReason.value === 'company') {
    if (isFullRefund.value) {
      refundAmount += initialShippingFee.value
    }
    return refundAmount // 회사 과실일 경우 여기서 반환
  }

  // 고객 과실 처리
  if (order.refundDats.stage0.selectedReason.value === 'customer') {
    switch (order.refundDats.stage2.refundPricePayMethod) {
      case 'additonal':
        if (isFullRefund.value) {
          refundAmount += initialShippingFee.value
        }
        break

      case 'calcToRefund':
        if (isFullRefund.value) {
          refundAmount += initialShippingFee.value - highestRefundPrice.value
        } else {
          refundAmount -= highestRefundPrice.value
        }
        break

      default:
        break
    }
  }

  return refundAmount // 고객 과실일 경우 여기서 반환
})

// const totalRefundAmount3 = computed(() => {
//   // 기본 환불 금액은 총 상품 금액으로 설정
//   let refundAmount = totalOrderPrice.value
//   console.log('초기 환불 금액:', refundAmount)
//   // 1단계 내 책임이냐 회사 책임이냐

//   // 회사 책임일 시, 배송비는 0원, 환불 금액은 물건값으로 처리
//   if (order.refundDats.stage0.selectedReason.value === 'company') {
//     // 전체 환불
//     if (isFullRefund.value) {
//       refundAmount = refundAmount + initialShippingFee.value // 전체면 구매시 냈던 택비 환불
//     } else {
//       refundAmount = refundAmount // 아니면 물건값만 환불
//     }
//     return refundAmount
//   }

//   // 본인 책임일 시, 환불금에서 배송비 차감 또는 따로 납부 처리
//   if (order.refundDats.stage2.refundPricePayMethod === 'calcToRefund') {
//     // 1 전체 환불인가  처음에 낸 택배비를 더하고 빈품비를 뺀다
//     if (isFullRefund.value) {
//       refundAmount = refundAmount + initialShippingFee.value - extraShippingFee.value
//     }
//     el
//     console.log('고객 과실 선택:', order.refundDats.stage2.refundPricePayMethod)
//     refundAmount -= highestRefundPrice.value // 반품 배송비 차감
//     console.log('반품비에서 배송비 차감 후:', refundAmount)

//     // // 전체 환불일 경우만 초기 배송비 추가 (차감된 배송비가 없을 경우에만)
//     // if (isFullRefund.value && highestRefundPrice.value === 0) {
//     //   refundAmount += initialShippingFee.value // 전액 환불 시, 초기 배송비 추가
//     //   // console.log('초기 배송비 추가:', refundAmount)
//     // }
//     // if (isFullRefund.value && initialShippingFee.value !== 0) {
//     //   refundAmount += initialShippingFee.value // 전액 환불 시, 초기 배송비 추가
//     //   // console.log('초기 배송비 추가:', refundAmount)
//     // }
//   }

//   // 도서 산간 추가 배송비 반영
//   // refundAmount += extraShippingFee.value
//   // console.log('최종 환불 금액:', refundAmount)

//   return refundAmount
// })

watchEffect(() => {
  if (isFullRefund.value === true) {
    order.refundDats.fullRefund = true
    console.log('풀반품')
  }
})

//
onMounted(() => {
  pageReady.value = true
  order.refundDats.request = props.request
})
</script>

<style lang="scss" scoped></style>
