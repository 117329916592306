<template>
  <div class="hidden lg:block">
    <!-- pc -->
    <section class="mt-[28px] w-[169px]">
      <div class="flex items-center h-[123px] justify-end text-right">
        <div class="">
          <p class="text-[28px] text-[#232323] font-notosansCJKlight">지금,</p>
          <p class="text-[28px] text-[#389033] font-notosansCJKlight tracking-tighter">
            가장 인기있는
          </p>
          <p class="text-[28px] text-[#232323] font-notosansCJKlight">상품</p>
        </div>
      </div>
      <div class="mt-[40px]">
        <div class="flex justify-end">
          <hr class="w-[105px] border-1 border-[#E2E2E2]" />
        </div>
        <div class="h-[24px] flex items-center mt-[3px] justify-end mr-[5px]">
          <p class="text-[16px] font-notosansCJKmd text-[#232323] mt-[7px]">BEST</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
