<template>
  <!-- 교환 교환  -->
  <!-- 교환 교환 -->
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-10 overflow-hidden"
  >
    <div class="w-[800px] h-auto bg-white rounded-xl overflow-y-clip">
      <div class="flex justify-center items-center mt-[24px] mb-[15px] relative">
        <p class="flex-grow text-[20px] font-notosansCJKmd text-[#171717] text-center">교환 신청</p>
        <button class="absolute right-[31px]">
          <i
            class="pi pi-times text-[19px] text-[#A8A8A8]"
            @click="system.exchangeModal = false"
          ></i>
        </button>
      </div>
      <hr class="mx-2" />
      <!-- 가운데 뭔들 로직 -->
      <div class="text-center mt-[32px] mb-[25px]">
        <p class="text-[18px] font-notosansCJKmd text-[#232323]">교환하실 상품을 선택해주세요</p>
      </div>
      <hr class="mx-2" />

      <!-- 버튼 제어 -->

      <div class="flex justify-center mb-[16px]">
        <button
          class="w-[310px] h-[51px] border border-[#0C370A] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
          @click="system.exchangeModal = false"
        >
          취소하기
        </button>
        <button
          class="w-[310px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[40px]"
        >
          신청완료
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'

import { useOrderStore } from '../../../../stores/userOrder'
import { useSystemStore } from '../../../../stores/useSystem'

import modalOrderCard from '../modalOrderCard.vue'
import selectExchangeMethod from './selectExchangeMethod.vue'
import excahngePriceCalc from './excahngePriceCalc.vue'

const order = useOrderStore()
const system = useSystemStore()
const pageReady = ref(false)
const stage = ref(0)

const props = defineProps({
  item: Object
})
</script>

<style lang="scss" scoped></style>
