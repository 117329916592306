import { ref } from 'vue'
import { sendRequest } from '../api/serviceApi'

const createPaymentData = ref()

const pgRequest = (users) => {
  return new Promise((resolve, reject) => {
    try {
      document.body.classList.add('modal-open')
      const IMP = window.IMP
      IMP.init('imp61876687') // 고객사 코드 -> 연동정보 -> 식별코드
      const names = productName.value
      IMP.request_pay(
        {
          // pg: `danal_tpay.${CPID}`, // 다날
          // pg: `nice.nictest00m`, // 나이스페이 테스트
          pg: paymethodSelector.value, // 네이버 테스트
          escrow: true,
          pay_method: 'card',
          merchant_uid: 'IMP' + newOrderNum.value, // 주문 고유 번호 (중복되지 않도록 해야 함)
          name: names, // 상품 정보
          amount: cart.calculator.totalPayPrice, //1000, // 결제 금액
          buyer_email: users.email, // 구매자 이메일
          buyer_name: users.name, // 구매자 이름
          buyer_tel: users.mobile, // 구매자 전화번호
          buyer_addr: addresCombiner.value, // 구매자 주소
          buyer_postcode: users.postcode // 우편번호
        },
        (rsp) => {
          if (rsp.success) {
            console.log('결제 완료', rsp)

            // 결제 성공 후 받은 데이터를 서버에 맞는 형식으로 가공
            const formattedPaymentData = {
              payUserId: user.me._id, // 사용자 ID
              paymentDate: system.serverTime, // 결제 날짜
              orderNumber: tempOrderNumber.value, // 주문 번호
              pgResultData: {
                success: rsp.success, // 결제 성공 여부
                imp_uid: rsp.imp_uid, // 아임포트 결제 고유 ID
                pay_method: rsp.pay_method, // 결제 수단
                merchant_uid: rsp.merchant_uid, // 주문 고유 번호
                name: rsp.name, // 상품명
                paid_amount: rsp.paid_amount, // 결제 금액
                currency: 'KRW', // 통화
                pg_provider: rsp.pg_provider, // 결제 PG사
                pg_type: 'payment', // 결제 타입
                pg_tid: rsp.pg_tid, // PG사 거래 ID
                apply_num: rsp.apply_num, // 승인 번호
                buyer_name: rsp.buyer_name, // 구매자 이름
                buyer_email: rsp.buyer_email, // 구매자 이메일
                buyer_tel: rsp.buyer_tel, // 구매자 전화번호
                buyer_addr: rsp.buyer_addr, // 구매자 주소
                buyer_postcode: rsp.buyer_postcode, // 우편번호
                status: rsp.status, // 결제 상태
                receipt_url: rsp.receipt_url, // 영수증 URL
                card_name: rsp.card_name, // 카드 이름
                card_number: rsp.card_number, // 카드 번호 (마스킹 처리됨)
                card_quota: rsp.card_quota // 할부 개월 수
              }
            }

            resolve(formattedPaymentData) // 가공된 결제 데이터를 resolve로 반환
          } else {
            console.error('결제 실패:', rsp.error_msg)
            alert(`결제 실패: ${rsp.error_msg}`)
            reject(new Error(rsp.error_msg)) // 결제 실패 시 reject 처리
          }
        }
      )
    } catch (error) {
      console.error('결제 요청 중 오류 발생', error)
      reject(error) // 예외 발생 시 reject 처리
    }
  })
}

export const payment = async (userInfo) => {
  const user = { ...userInfo }
  console.log(user.name)

  // createPaymentData.value = await pgRequest()
}
