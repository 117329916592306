<template>
  <div>
    <div class="flex justify-center mt-[27.6px] md:mt-[33px]">
      <p class="text-[14px] md:text-[18px] font-notosansCJKmd text-[#232323]">
        첨부해주실 사진이 있으신가요?
      </p>
    </div>
    <div class="flex justify-center mt-[11px]">
      <div>
        <label
          for="input"
          class="text-[10px] w-[86px] h-[22px] md:text-[14px] text-[#389033] font-notosansCJKmd border-[1px] border-[#389033] rounded-md md:w-[116px] md:h-[31px] py-[7.5px] px-[18px] cursor-pointer hover:shadow-md"
        >
          사진 첨부하기
        </label>
        <input
          ref="image"
          id="input"
          type="file"
          name="image"
          accept="image/*"
          class="hidden"
          @change="uploadImage"
        />
      </div>
    </div>
    <div v-if="imgArr.length === 0" class="mt-[27px] mb-[57px] flex justify-center">
      버튼을 눌러 사진을 추가해보세요
    </div>

    <div class="flex flex-wrap mt-[27px] justify-start mx-[6px] md:mx-[35px]">
      <div
        v-for="(img, index) in imgArr"
        :key="index"
        class="relative w-[74px] md:w-[104px] h-[74px] md:h-[104px] mr-2 mb-2 flex-shrink-0"
      >
        <img :src="img" alt="" class="w-full h-full object-cover" />
        <div
          class="absolute top-0 right-0 w-[25px] h-[25px] bg-[#2E2E2E] opacity-80 rounded-md text-white text-xs p-1 cursor-pointer"
          @click="removeImage(index)"
        >
          <i class="pi pi-times flex justify-center mt-[2.5px]"></i>
        </div>
        <div v-if="img === mainImg" class="absolute top-0 left-0 bg-red-500 text-white text-xs p-1">
          메인
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue'
import axios from 'axios'
import { toast } from 'vue3-toastify'
import { useSystemStore } from '../../../../stores/useSystem'

const props = defineProps({
  imgList: Array
})

const system = useSystemStore()
const imgArr = ref([]) // 이미지 배열
const currentPic = ref({ img: '', index: 0 }) // 현재 선택된 이미지
const mainImg = ref('') // 메인 이미지

const emit = defineEmits(['updateReviewImg']) // 부모 컴포넌트로 이벤트 전달

const sendData = () => {
  emit('updateReviewImg', imgArr.value) // 이미지 배열을 부모 컴포넌트로 전달
}

async function uploadImage(e) {
  const file = e.target.files[0]
  if (!file) return
  try {
    const formData = new FormData()
    formData.append('files', file)
    const url = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/upload`, formData)
    const result = url.data.locations[0]
    imgArr.value.push(result)
    // 업로드된 이미지를 현재 이미지로 설정
    currentPic.value.img = result
    currentPic.value.index = imgArr.value.length - 1
    toast.success('이미지가 업로드되었습니다.')
    sendData() // 이미지 업로드 후 부모로 데이터 전달
  } catch (e) {
    console.log('업로드 실패', e)
    toast.error('이미지 업로드에 실패했습니다.')
  }
}

const removeImage = (index) => {
  imgArr.value.splice(index, 1) // 해당 인덱스의 이미지 삭제
  toast.success('이미지가 삭제되었습니다.')
  sendData() // 이미지 삭제 후 부모로 데이터 전달
}

watchEffect(() => {
  // 기존 이미지 동기화
  if (system.reviewImgArray) {
    imgArr.value = system.reviewImgArray
  }
})

onMounted(() => {
  if (system.reviewEdit === true) {
    imgArr.value = system.reviewData.reviewData.reviewImg
    // console.log('이미지추가 컴포넌트', imgArr.value)
  }
})
</script>

<style lang="scss" scoped></style>
