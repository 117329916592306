<template>
  <div class="">
    <router-link :to="`/post/details/${postData._id}`">
      <div class="">
        <div class="hidden md:block">
          <div
            :style="`background-image: url(${postData.mainImg})`"
            class="relative w-full h-[514px] bg-center bg-cover bg-no-repeat lg:rounded-md"
          >
            <!-- 그라데이션 오버레이 -->
            <div
              class="absolute bottom-0 left-0 w-full h-[25%] lg:rounded-md"
              style="
                background: linear-gradient(
                  to top,
                  rgba(75, 75, 75, 1) 0%,
                  rgba(71, 71, 71, 0.7) 15%,
                  /* 중간 구간을 조금 더 진하게 15%까지 유지 */ rgba(57, 57, 57, 0) 80%
                    /* 완전 투명으로 변하는 구간을 80%로 조정 */
                );
              "
            ></div>
            <!-- 흰색 텍스트 -->
            <div class="absolute bottom-[8px] left-[9px] p-4 z-10 text-white">
              <div class="flex items-end">
                <p class="text-[32px] font-notosansCJKmd">{{ postData.title }}</p>
                <p class="text-[13px] font-notosansCJR mb-1 ml-[47px]">
                  {{ dayjs(postData.date).format('YYYY/MM/DD') }} By {{ postData.author }}
                </p>
              </div>
              <p class="mt-[9px] text-[13px] font-notosansCJKlight truncate-1">
                {{ postData.des }}
              </p>
            </div>
          </div>
        </div>
        <!-- mobile -->
        <div class="md:hidden pt-[14px]">
          <section>
            <div class="aspect-375/300">
              <img :src="postData.mainImg" class="aspect-375/300 object-cover object-center" />
            </div>
          </section>
          <section class="ml-[12px]">
            <p class="text-[19px] font-notosansCJKmd text-[#232323] mt-[11.5px]">
              {{ postData.title }}
            </p>
            <p class="text-[11px] font-notosansCJR text-[#707070] mt-[7px]">
              {{ dayjs(postData.date).format('YYYY/MM/DD') }} By {{ postData.author }}
            </p>
            <p class="text-[12px] font-notosansCJR text-[#707070] mt-[13.5px] line-clamp-2">
              {{ postData.des }}
            </p>
          </section>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { ref, watchEffect, onMounted } from 'vue'
import { sendRequest } from '../../api/serviceApi'
const props = defineProps({
  data: String
})

const pickColor = ref('')
pickColor.value = props.data

const tumbImg = 'bg-[url("../../img/blog/mainBlogImg.png")]'

const des =
  '집들이는 내게 당일치기 여행이다. 언제든 할 수 있을 것 같으나 막상 모든 조건이 맞아야만 가능한 매우 비일상적인 행사. 우선 모일 수 있는 마땅한 집이 있어야 하고, 마음이 맞는 사람들이있어야 하며, 각자의 바쁜 일정 중 만날 수 있는 유일한 하루를 정해야 한다. 약속한 당일이 되었을 땐 함께 나누어 먹을 음식이 필요하며, 모두가 무탈히 장소에 도착할 수 있어야 한다.'

const des2 =
  '집들이 경험이 전무한 나와 L은 용케 호스트를 자처하며 서툴지만, 정성을 다해 파티를 준비한다. 모든 공간을 깨끗이 청소하고, 편안한 동선을 위해 배치를 조금씩 바꾸기도 하고, 홈메이드 퀴진을 표방하며 쉐프에 빙의한다. 점점 약속 시간이 다가올수록 호스트 둘은 다급해진다. 예상치 못한 이슈가 한두 개씩 꼬-옥 발생하거든.다행히 게스트 중 누군가가 조금 늦을 것 같다고 한다.'

const postData = ref({})

const getPost = async () => {
  const getInterior = await sendRequest({
    method: 'post',
    path: '/post/getLatestInterior'
  })

  const getCase = await sendRequest({
    method: 'post',
    path: '/post/getLatestCase'
  })

  if (getCase.data?.length) {
    const allCaseAndInterior = [...getInterior.data, ...getCase.data]

    // const result = await sendRequest({ method: 'post', path: '/post/getLatestOnePost' })

    postData.value = allCaseAndInterior[0]
  } else {
    return
  }
}

onMounted(async () => {
  await getPost()
})
</script>

<style lang="scss" scoped></style>
