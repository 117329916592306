<template>
  <div>
    <myAddrListModal v-if="system.addressListModal === true" />
    <p
      class="font-nanumsquareEB lg:font-notosansCJKmd text-[15px] lg:text-[22px] text-[#171717] mb-[6.5px] lg:mb-[12.5px] ml-[10px]"
    >
      주문자 정보
    </p>
    <!-- 1단 -->
    <div class="border-t-[2px] border-[#6D6D6D] hidden lg:flex">
      <!-- 1단 좌 -->
      <div>
        <div v-for="(topleft, i) in topTableleft" :key="'topleft' + i" class="bg-[#FBFBFB]">
          <div class="w-[153px] h-[58px] flex items-center">
            <p class="ml-[27.5px] text-[15px] font-notosansCJKmd text-[#232323]">
              {{ topleft.label }}
            </p>
          </div>
        </div>
      </div>
      <!-- 1단 우 -->
      <div>
        <div v-for="(topright, e) in topTableleft" :key="'topRight' + e" class="">
          <div class="h-[58px] flex items-center">
            <input
              type="text"
              class="ml-[9.5px] border pt-1 pl-[14px] w-[227px] rounded-md text-[15px] h-[37px]"
              :placeholder="topright.placeholder"
              v-model="topright.value"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 모바일 상단-->
    <div class="mt-[6.5px] border-t-[1.5px] border-[#6D6D6D] lg:hidden">
      <div class="mx-[15.6px] mt-[19.5px] mb-[30.5px]">
        <div v-for="(totable, ci) in topTableleft" :key="'topleft' + ci" class="mb-[19.7px]">
          <p class="ml-1 text-[12px] text-[#6D6D6D] font-notosansCJKmd">{{ totable.label }}</p>
          <input
            type="text"
            class="border py-1 pl-2 w-full rounded-md mt-[6.5px] text-[13px] font-notosansCJKmd text-[#585858] h-[35px]"
            :placeholder="totable.placeholder"
            v-model="totable.value"
          />
        </div>
      </div>
    </div>
    <p
      class="lg:hidden font-nanumsquareEB lg:font-notosansCJKmd text-[15px] lg:text-[22px] text-[#171717] mb-[6.5px] lg:mb-[12.5px] ml-[10px]"
    >
      배송 정보
    </p>
    <!-- 모바일 하단-->
    <div class="mt-[6.5px] border-t-[1.5px] border-[#6D6D6D] lg:hidden">
      <div class="mx-[15.6px] mt-[19.5px] mb-[30.5px]">
        <div v-for="(totable, xi) in bottomTable" :key="'topleft' + xi" class="mb-[19.7px]">
          <p class="ml-1 text-[12px] text-[#6D6D6D] font-notosansCJKmd">
            {{ totable.label }}
          </p>
          <input
            v-if="xi !== 2 && xi !== 3 && xi !== 4"
            type="text"
            class="border py-1 pl-2 w-full rounded-md mt-[6.5px] text-[13px] font-notosansCJKmd text-[#585858] h-[35px]"
            :placeholder="totable.placeholder"
            v-model="totable.value"
          />
          <!-- 배송주소 -->
          <div v-if="xi === 2" class="flex items-center">
            <input
              type="text"
              class="border py-1 pl-2 rounded-md mt-[6.5px] text-[13px] font-notosansCJKmd text-[#585858] w-[75%] h-[35px]"
              :placeholder="totable.placeholder"
              v-model="totable.value"
            />
            <button
              v-if="totable.isButton"
              class="border border-[#389033] ml-[5px] w-[116px] h-[35px] mt-[6.5px] rounded-md text-[12px] font-notosansCJKmd text-[#389033]"
              @click="openPostCode"
            >
              <!-- 모바일 -->
              우편번호 검색
            </button>
          </div>
          <div v-if="xi === 3" class="-mt-[12px]">
            <input
              type="text"
              class="border py-1 pl-2 w-full rounded-md text-[13px] font-notosansCJKmd text-[#585858] h-[35px]"
              :placeholder="totable.placeholder[0]"
              v-model="cart.payUserInfo.receiverInfo.address"
            />
            <input
              type="text"
              class="border py-1 pl-2 w-full rounded-md mt-[6.5px] text-[13px] font-notosansCJKmd text-[#585858] h-[35px]"
              :placeholder="totable.placeholder[1]"
              v-model="cart.payUserInfo.receiverInfo.addressDetail"
            />
            <div class="ml-2 flex items-center justify-end mr-[4px] mt-[17.6px]">
              <input
                type="checkbox"
                name=""
                id="defaultCheck"
                class="w-[18px] h-[18px] border-[#A8A8A8] accent-[#389033]"
                v-model="cart.payUserInfo.setToDefault"
              />
              <label for="defaultCheck" class="ml-2 text-[12px] font-notosansCJKmd text-[#7D7D7D]"
                >기본 배송지로 설정</label
              >
            </div>
          </div>
          <!-- 요청사항 -->
          <div v-if="xi === 4" class="">
            <textarea
              v-if="totable.isTextArea"
              cols="30"
              rows="10"
              class="w-full h-[131px] border rounded-md pl-2 pt-2 text-[13px] font-notosansCJKmd text-[#585858] resize-none"
              :placeholder="totable.placeholder"
              v-model="cart.payUserInfo.receiverInfo.request"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <!-- 2단 -->
    <div class="border-t-[1px] border-b-[1px] border-[#B2B2B2] hidden lg:flex">
      <!-- 2단 좌 -->
      <div>
        <div v-for="(bottomLeft, c) in bottomTable" :key="'bottomLeft' + c" class="bg-[#FBFBFB]">
          <div class="w-[153px] flex items-center" :class="bottomLeft.class">
            <p class="ml-[27.5px] text-[15px] font-notosansCJKmd text-[#232323]">
              {{ bottomLeft.label }}
            </p>
          </div>
        </div>
      </div>
      <!-- 2단 우 -->
      <div>
        <div v-for="(bottomRight, x) in bottomTable" :key="'bottomRight' + x" class="">
          <div class="flex items-center" :class="bottomRight.class">
            <div v-if="!bottomRight.isTextArea && x !== 3">
              <input
                type="text"
                class="ml-[9.5px] border pt-1 pl-[14px] text-[15px] w-[227px] rounded-md h-[37px]"
                :placeholder="bottomRight.placeholder"
                v-model="bottomRight.value"
              />
            </div>
            <!-- 기본주소지 체크 -->
            <div v-if="x === 0" class="ml-2 flex items-center">
              <input
                type="checkbox"
                name=""
                id="defaultCheck"
                class="w-[18px] h-[18px] border-[#A8A8A8] accent-[#389033]"
                v-model="cart.payUserInfo.setToDefault"
              />
              <label for="defaultCheck" class="ml-2 text-[15px] font-notosansCJKmd text-[#7D7D7D]"
                >기본 배송지로 설정</label
              >
            </div>
            <!-- 주소지 입력 -->
            <div v-if="x === 3" class="flex">
              <input
                type="text"
                class="ml-[9.5px] border py-1 pl-2 w-[459.5px] rounded-md h-[40px] text-[15px]"
                :placeholder="bottomRight.placeholder[0]"
                v-model="cart.payUserInfo.receiverInfo.address"
              />
              <input
                type="text"
                class="ml-[9.5px] border py-1 pl-2 w-[284.5px] rounded-md h-[40px] text-[15px]"
                :placeholder="bottomRight.placeholder[1]"
                v-model="cart.payUserInfo.receiverInfo.addressDetail"
              />
            </div>
            <!-- 우편번호 검색 버튼 PC -->
            <button
              v-if="bottomRight.isButton"
              class="border border-[#389033] ml-[15.8px] w-[116px] h-[37px] rounded-md text-[14px] font-notosansCJKmd text-[#389033]"
              @click="openPostCode"
            >
              우편번호 검색
            </button>
            <button
              v-if="bottomRight.isButton"
              class="border border-[#232323] ml-[15.8px] w-[116px] h-[37px] rounded-md text-[14px] font-notosansCJKmd text-[#232323]"
              @click="system.addressListModal = true"
            >
              주소목록
            </button>
            <!-- 배송 요청사항 입력 -->
            <div class="ml-[9px]">
              <textarea
                v-if="bottomRight.isTextArea"
                cols="30"
                rows="10"
                class="w-[757px] h-[86px] border rounded-md pt-[12px] pl-[14px] text-[15px] resize-none"
                placeholder="배송요청 사항을 입력해 주세요"
                v-model="cart.payUserInfo.receiverInfo.request"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useCartStore } from '../../stores/useCart'
import { useUserStore } from '../../stores/useUser'
import { useAddressStore } from '../../stores/useAddress'
import { useSystemStore } from '../../stores/useSystem'
import myAddrListModal from '../account/modal/myAddrModal/myAddrListModal.vue'

const address = useAddressStore()
const system = useSystemStore()
const cart = useCartStore()
const user = useUserStore()
// const setDefaultAddr = ref(false)

// 유저 정보
const orderUserInfo = ref({
  name: user.me.name,
  mobile: user.me.mobile,
  email: user.me.email
})

// 수신자 정보
const receiverInfo = ref({
  name: '',
  mobile: '',
  postcode: '',
  address: '',
  addressDetail: '',
  request: ''
})

// 상단 테이블 왼쪽 데이터
const topTableleft = ref([
  {
    label: '주문하시는 분',
    placeholder: '이름을 입력해주세요',
    get value() {
      return orderUserInfo.value.name
    },
    set value(newValue) {
      orderUserInfo.value.name = newValue
    }
  },
  {
    label: '연락처',
    placeholder: '연락처를 입력해주세요',
    get value() {
      return orderUserInfo.value.mobile
    },
    set value(newValue) {
      orderUserInfo.value.mobile = newValue
    }
  },
  {
    label: '이메일',
    placeholder: '이메일을 입력해주세요',
    get value() {
      return orderUserInfo.value.email
    },
    set value(newValue) {
      orderUserInfo.value.email = newValue
    }
  }
])

// 하단 테이블 데이터
const bottomTable = ref([
  {
    label: '받으시는 분',
    placeholder: '이름을 입력해주세요',
    get value() {
      return cart.payUserInfo.receiverInfo.name
    },
    set value(newValue) {
      cart.payUserInfo.receiverInfo.name = newValue
    },
    class: 'h-[58px]',
    isButton: false,
    isTextArea: false
  },
  {
    label: '연락처',
    placeholder: '연락처를 입력해주세요',
    get value() {
      return cart.payUserInfo.receiverInfo.mobile
    },
    set value(newValue) {
      cart.payUserInfo.receiverInfo.mobile = newValue
    },
    class: 'h-[58px]',
    isButton: false,
    isTextArea: false
  },
  {
    label: '배송주소',
    placeholder: '우편번호',
    get value() {
      return cart.payUserInfo.receiverInfo.postcode
    },
    set value(newValue) {
      cart.payUserInfo.receiverInfo.postcode = newValue
    },
    class: 'h-[58px]',
    isButton: true,
    isTextArea: false
  },
  {
    label: '',
    placeholder: ['주소', '상세주소'],
    value: '',
    class: 'h-[58px]',
    isButton: false,
    isTextArea: false
  },
  {
    label: '배송요청사항',
    placeholder: '배송요청사항',
    value: '',
    class: 'h-[120px]',
    isButton: false,
    isTextArea: true
  }
])

const openPostCode = () => {
  new window.daum.Postcode({
    oncomplete: (data) => {
      cart.payUserInfo.receiverInfo.postcode = data.zonecode
      cart.payUserInfo.receiverInfo.address = data.roadAddress // payload 객체의 주소 필드 업데이트
    }
  }).open()
}

//lifeCycle
watchEffect(() => {
  if (orderUserInfo.value) {
    cart.payUserInfo.orderUserInfo = orderUserInfo.value
  }
  // if (receiverInfo.value) {
  //   cart.payUserInfo.receiverInfo = receiverInfo.value
  // }
})

onMounted(async () => {
  await address.getAddressByUser()
})

onUnmounted(() => {
  cart.payUserInfo.receiverInfo = {
    name: '',
    mobile: '',
    postcode: '',
    address: '',
    addressDetail: '',
    request: '',
    title: ''
  }
})
</script>

<style lang="scss" scoped>
/* 필요한 경우 추가 스타일 */
</style>
