<template>
  <div class="mx-0 lg:mx-auto h-auto lg:w-[1186px]">
    <div class="ml-[10px] lg:ml-[26px] mt-[11px] lg:mt-[18px] pb-[24px] text-[#5D5D5D]">
      <!-- logo -->
      <section class="hidden lg:flex items-end">
        <img src="/img/footer/footer_logo.webp" alt="footer_logo" class="" />
        <p
          class="ml-[14px] pt-[16px] font-Chosunilbo_myungjo text-[20px] text-[#389033] font-[550]"
        >
          자연에 물들다, <span class="font-Chosunilbo_myungjo text-[#0E430B]">연들</span>
        </p>
      </section>
      <!-- 모바일 로고 -->
      <section class="flex lg:hidden pt-[21px]">
        <p class="font-notosansCJKmd text-[14px] text-[#389033]">
          자연에 물들다, <span class="font-notosansCJKmd text-[#0E430B]">연들</span>
        </p>
      </section>
      <section>
        <div class="">
          <!-- top -->
          <div class="mt-[4px] lg:mt-[12px]">
            <div>
              <section>
                <div class="flex">
                  <p class="font-notosansCJKmd text-[12px] lg:text-[16px] text-[#232323]">
                    고객만족센터 : 063-278-7781
                  </p>
                  <button
                    class="ml-[10px] pb-[1px] lg:ml-[16px] w-[92px] h-[21px] lg:w-[110px] lg:h-[25px] border border-[#AAAAAA] rounded-md font-notosansCJR text-[10px] lg:text-[12px] text-[#5D5D5D] text-center"
                    @click="openKakaoinq()"
                  >
                    카카오톡 상담하기
                  </button>
                </div>
              </section>

              <div
                class="flex mt-[2px] lg:mt-[16px] text-[10px] lg:text-[13px] text-[#707070] lg:text-[#171717]"
              >
                <p class="font-notosansCJKmd">평일 10:00 ~ 18:00</p>
                <p class="font-notosansCJKmd">| 주말,공휴일 휴무</p>
                <p class="font-notosansCJKmd">| 점심시간 12:00 ~ 13:00</p>
              </div>
            </div>
            <div
              class="block lg:flex items-center mt-[4px] lg:mt-[4.3px] lg:ml-0 text-[10px] lg:text-[13px]"
            >
              <p class="font-notosansCJKmd text-[#232323]">문의 : yeondeul_cs@ruruci.com</p>
              <!-- <p class="mt-[2px] font-notosansCJKmd">입점 문의 : yeondeul@ruruci.com</p> -->
            </div>
          </div>
          <!-- middle -->
          <div class="text-[0.75em] lg:text-[12px]">
            <!-- middle top -->
            <div class="mt-[15px] lg:mt-[20px]">
              <div class="lg:flex items-start">
                <!-- 모바일 -->
                <p class="text-[10px] lg:text-[12px] font-notosansCJKbold lg:font-notosansCJR">
                  상호명 : 루루시(ruruci)
                  <span class="lg:hidden font-notosansCJR text-[#707070] ml-1">대표 : 이선호</span>
                </p>

                <!-- pc -->
                <p class="font-notosansCJR hidden lg:block">
                  <span class="mx-2 font-notosansCJR">|</span>

                  대표 : 이선호
                  <span class="mx-2 font-notosansCJR">|</span>
                  <span class="font-notosansCJR"
                    >사업장 소재지 : 전북 전주시 덕진구 건지2길 13-5 1층</span
                  >
                  <span class="mx-2 font-notosansCJR">|</span>

                  <span class="lg:mr-2 font-notosansCJR"> 사업자등록번호 : 414-06-27608</span>
                  <span class="mx-2 font-notosansCJR">|</span>
                  <span class="lg:mr-2 font-notosansCJR">
                    통신판매업 신고번호 : 2018-전주덕진-0443</span
                  >
                </p>
              </div>
              <div class="flex items-center lg:mt-[2px] lg:hidden">
                <p class="font-notosansCJR text-[10px] lg:text-[14px]">
                  사업장 소재지 : 전북 전주시 덕진구 건지2길 13-5 1층
                </p>
              </div>
              <div class="flex items-center lg:mt-[2px] lg:hidden">
                <p class="font-notosansCJR text-[10px] lg:text-[14px]">
                  사업자등록번호 : 414-06-27608
                </p>
                <p class="font-notosansCJR text-[10px] lg:text-[14px]">
                  통신판매업 신고번호 : 2018-전주덕진-0443
                </p>
              </div>
            </div>
            <!-- middle bottom -->
            <div class="mt-[0px]">
              <div class="lg:flex items-center"></div>
              <div v-show="false" class="lg:flex items-center mt-[6px] lg:mt-[2px]">
                <!-- <p class="font-notosansCJR text-[9px] lg:text-[12px]">
                  당사는 통신판매중개자로써 상품 정보 및 거래에 대하여 책임을 부담하지 않습니다.
                </p>
                <p class="font-notosansCJR text-[9px] lg:text-[12px]">
                  단, 당사가 직접 제조, 판매하는 제품에 대하여 판매 책임을 부담합니다.
                </p> -->
              </div>
              <div class="mt-[6px] lg:mt-[2px]">
                <p class="font-notosansCJR text-[9px] lg:text-[12px]">
                  사이트의 컨텐츠/UI/판매자 정보 등에 대한 무단복제, 전송, 스크래핑, 무단 배포의
                  행위는 엄격히 금지합니다. ⓒ ruruci E&C. All rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
const openNewWindow = () => {
  const path = '/account/billing'
  const query = `?on=${order.orderFilterData.on}&odn=${order.orderFilterData.odn}`
  const url = `${path}${query}` // 경로와 쿼리를 조합하여 URL 생성

  const options = 'width=400,height=700,left=100,top=100,resizable=no,scrollbars=no' // 창의 크기 및 옵션 설정
  window.open(url, '_blank', options) // 새 창으로 열기
}
const openKakaoinq = () => {
  const chatConfirm = confirm('카카오톡 1:1 문의를 시작합니다')
  if (!chatConfirm) {
    return
  }
  const url = 'http://pf.kakao.com/_wxhkxhn/chat'
  window.open(url) // 새 창으로 열기
}
</script>

<style lang="scss" scoped>
p {
  margin-right: 0.3rem;
}
</style>
