<template>
  <!-- 취소 신청 취소 신청 취소 신청  -->
  <div
    v-if="pageReady"
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div
      class="w-[365px] md:w-[800px] h-auto max-h-[80vh] bg-white rounded-xl overflow-y-clip hide-scrollbar"
    >
      <!-- 공통헤더 -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          취소 신청
        </p>
        <button class="absolute right-[15px] md:right-[31px]">
          <i
            class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
            @click="system.cancleModal = false"
          ></i>
        </button>
      </div>
      <section class="md:hidden">
        <hr class="mx-2" />
        <div>
          <section>
            <div class="text-center mt-[17.5px] mb-[12px]">
              <p class="text-[12px] font-notosansCJKmd text-[#232323]">
                취소하실 상품을 선택해주세요
              </p>
            </div>
          </section>
          <section class="mt-[12.5px]" :class="scrollClass">
            <div
              v-for="(item, index) in order.cancleListByOdn"
              :key="'orderDetail' + index"
              class="mb-[11px] cursor-pointer"
              @click="toggleSelection(item)"
            >
              <!-- 선택된 상태에 따라 클래스를 적용 -->
              <modalOrderCard
                :item="item"
                :style="isSelected(item) ? selectedClass : noneSelectedClass"
              />
            </div>
          </section>
          <section class="text-center mt-[17px] mx-2">
            <p class="text-[12px] font-notosansCJKmd text-[#232323] mb-[8px]">
              취소 사유를 선택해주세요
            </p>
            <div v-for="(select, ex) in selecteData" :key="ex" class="mb-[6px]">
              <button
                class="w-full h-[33px] border border-[#D5D5D5] rounded-md"
                :class="`${select.class}`"
                @click="selectBoxCommander(ex)"
              >
                <p class="text-[11px] font-notosansCJR text-[#1C1C1C]">
                  {{ select.title }}
                </p>
              </button>
            </div>
          </section>
          <section class="mt-[44.5px]">
            <hr />
            <div class="flex justify-center mb-[16px] mt-[8.3px]">
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#D5D5D5] rounded-lg md:rounded-md"
                @click="system.cancleModal = false"
              >
                <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
              </button>
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
                @click="orderStatusPatch()"
              >
                <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">신청완료</p>
              </button>
            </div>
          </section>
        </div>
      </section>
      <section class="hidden md:block">
        <hr class="mx-2" />
        <!-- 가운데 뭔들 로직 -->
        <div class="text-center mt-[32px] mb-[25px]">
          <p class="text-[18px] font-notosansCJKmd text-[#232323]">취소하실 상품을 선택해주세요</p>
        </div>
        <section class="mx-[32px] h-full" :class="scrollClass">
          <div
            v-for="(item, index) in order.cancleListByOdn"
            :key="'orderDetail' + index"
            class="mb-[11px] cursor-pointer"
            @click="toggleSelection(item)"
          >
            <!-- 선택된 상태에 따라 클래스를 적용 -->
            <modalOrderCard
              :item="item"
              :style="isSelected(item) ? selectedClass : noneSelectedClass"
            />
          </div>
        </section>
        <section class="text-center mt-[32px] mb-[25px]">
          <p class="text-[18px] font-notosansCJKmd text-[#232323] mb-[20px]">
            취소 사유를 선택해주세요
          </p>
          <div v-for="(select, ex) in selecteData" :key="ex" class="mb-[9px]">
            <button
              class="w-[439px] h-[48px] border border-[#D5D5D5] rounded-md"
              :class="`${select.class}`"
              @click="selectBoxCommander(ex)"
            >
              <p class="text-[16px] font-notosansCJR text-[#1C1C1C]">
                {{ select.title }}
              </p>
            </button>
          </div>
        </section>
        <hr class="mx-2" />
        <!-- 버튼 제어 -->
        <div class="flex justify-center mb-[16px] mt-[16.5px]">
          <button
            class="w-[310px] h-[51px] border border-[#D5D5D5] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
            @click="system.cancleModal = false"
          >
            취소하기
          </button>
          <button
            class="w-[310px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[40px]"
            @click="orderStatusPatch()"
          >
            신청완료
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useOrderStore } from '../../../../stores/userOrder'
import { useSystemStore } from '../../../../stores/useSystem'
import modalOrderCard from '../modalOrderCard.vue'
import { useUserStore } from '../../../../stores/useUser'
import { sendRequest } from '../../../../api/serviceApi'
import axios from 'axios'

const user = useUserStore()
const order = useOrderStore()
const system = useSystemStore()
const pageReady = ref(false)

const refundCall = async (orderNumber, orderDetailNumber, state, i) => {
  order.mappers.on = orderNumber
  order.mappers.odn = orderDetailNumber

  const getRemainsData = async () => {
    const payload = {
      orderNumber: orderNumber
    }

    const getData = await sendRequest({
      method: 'post',
      path: '/order/getOrderByOrderNumber',
      data: payload
    })

    return getData.data[0].remains
  }

  const checkRemains = async () => {
    try {
      console.log('잔여금 조회 중...')

      // getRemainsData의 비동기 호출 결과를 await로 받아옴
      const remainsData = await getRemainsData()

      console.log('잔여금 결과:', remainsData)

      return remainsData
    } catch (error) {
      console.error('잔여금 조회 실패:', error)
      return null
    }
  }
  // 호출

  let orderCheckSum = await checkRemains() // 잔여금
  // console.log('잔여금 조회 결과', orderCheckSum)

  await order.fetchMapperData()
  let checksum = 0
  // remains가 0일 경우 totalPayPrice를 checksum에 할당
  if (orderCheckSum == 0) {
    checksum = order.orderDetailData.orderData.priceInfo.totalPayPrice
  } else if (orderCheckSum > 0) {
    checksum = orderCheckSum
  }

  // 결과 출력
  console.log(
    '초기 잔여금',
    checksum,
    orderCheckSum,
    order.orderDetailData.orderData.priceInfo.totalPayPrice
  )

  // return
  // const shippingPrice = order.mapperData.orderData.priceInfo.totalShippingPrice

  // 사용 포인트
  const consumePoint = order.mapperData.orderData.priceInfo.pointToUse
  console.log('사용 포인트', consumePoint)

  // return
  // 환불 해줄 가격, 주문 개별 가격
  let amount = 0

  console.log('선택한 취소 갯수 :', selectedItems.value.length)
  console.log('현재 취소 카운트:', i)
  console.log('전체 취소 가능 갯수:', order.cancleEanbleCount)

  const highestRefundPrice = () => {
    let selectedDetails = order.cancleListByOdn
    console.log('가져왔나', selectedDetails)

    if (selectedDetails.length > 0) {
      const refundPrices = selectedDetails.map((detail) => detail.ship_ammount || 0)
      return Math.max(...refundPrices)
    }

    return 0
  }

  const highShipAmmount = highestRefundPrice()
  console.log('일단 가장높은 택배비', highShipAmmount)

  //  return

  // 캔슬 가능과 선택 값이 같다면 택배비를 포함한 환불 가격을 계산한다
  if (order.cancleEanbleCount === i) {
    amount = order.mapperData.orderDetailData.totalPrice + highShipAmmount
    console.log(' 전부 환불이라 택배비까지 돌려주자:', amount)
  }
  //  전부 취소는 아니다 택배비는 킵해놓자
  else if (order.cancleEanbleCount > i) {
    amount = order.mapperData.orderDetailData.totalPrice
    console.log(' 전부 취소는 아니다 택배비는 킵해놓자:', amount)
  }

  // return
  // console.log(checksum, shippingPrice, amount)
  const payload = {
    orderNumber: orderNumber
  }
  const getImp = await sendRequest({
    method: 'post',
    path: '/payment/getByOrderNumber',
    data: payload
  })

  const data = getImp.data
  const payImd = data.pgResultData.imp_uid
  const payUserid = data.payUserId

  // PG사에 승인된 실 결제 가격
  const paymentCachAmmount = data.pgResultData.paid_amount

  // console.log('paymentData: ', payImd, paymentCachAmmount, '결제유저', payUserid)

  let remains = 0 // 잔여금
  // let refundPoint = 0 // 환불 포인트
  const remiansCalc = checksum - amount //잔여금 계산기 = 결제금 - 옵션금
  // console.log('잔여금', consumePoint, '결제값', checksum, '환불금', amount)

  const pointUsingMatcher = consumePoint + remiansCalc // 잔여금이 - 인것은 포인트 값

  if (pointUsingMatcher === 0) {
    // 환불 잔금이 마이너스인 상태에서 사용 포인트랑 매칭이 되면 남은 포인트로 잔여금 정산 = 잔여금
    remains = pointUsingMatcher
    // refundPoint = consumePoint
  } else {
    // 아니라면 총 결제 금액에서 환불 값을 제한 남은 값 = 잔여금
    remains = remiansCalc
  }
  // 그래서 환불후 잔여금과 만일 전액 환불이라면 사용 포인트를 출력 한다
  // console.log('remains', remains, 'refundPoint', refundPoint) // 여기까지 찍히고

  // 환불 정보에는 결제시사용된 imp아이디 (주문번호)
  // 환불금  amount
  // 잔여금 checksum 세가지를 보낸다

  const refundPayload = {
    imp_uid: payImd,
    amount: amount,
    checksum: checksum
  }

  // 실환불
  // console.log('refundPayload', refundPayload) // 잘들어 갔고
  // async function processRefund(refundPayload) {
  //   try {
  //     const refunds = await axios({
  //       url: `${import.meta.env.VITE_API_BASE_URL}/portOne`,
  //       method: 'post',
  //       data: refundPayload
  //     })
  //     console.log('포트원 환불:', refunds)
  //     console.log('환불 요청 성공:', refunds)

  //     // 성공 여부에 따라 결과를 반환 (예: 성공 여부가 refunds.data.success라 가정)
  //     const refundResult = refunds ? true : false

  //     return refundResult // 성공 여부 반환
  //   } catch (error) {
  //     console.error('환불 요청 실패:', error)
  //     return false // 환불 실패 시 false 반환
  //   }
  // }

  // return
  // 개발 환불
  const refundResult = true
  // const refundResult = await processRefund(refundPayload)
  // const refundResult = true // 테스트
  // console.log('포트원 요청 결과', refundResult) // true
  if (refundResult === false) {
    return console.error('포트원 환불 실패', refundResult)
  }

  const orderData = {
    orderNumber: orderNumber,
    remains: remains
  }

  // order자체에다가 잔여금을 업뎃 해준다 order.remains = remains
  const upDateOrderRemian = await sendRequest({
    method: 'patch',
    path: '/order/updateOrderRemain',
    data: orderData
  })
  const upDateOrderRemianResult = upDateOrderRemian.result
  if (upDateOrderRemianResult !== 'ok') {
    return console.error('주문 잔여금 업데이트 실패')
  }

  // 같은 주문의 모든 취소라면 포인트를 돌려준다

  // 문제가 여기서 발상핸다.
  // 다른 업체라면 주문의 마지막인지 모른다는것

  if (order.cancleEanbleCount === selectedItems.value.length) {
    console.log('주문의 마지막 환불, 돌려줄 포인트', consumePoint)

    const refundUserPoint = await user.pointAdder(consumePoint)
    if (!refundUserPoint) {
      console.log('user point patcher fail')
      alert(
        '결제취소가 종료 되었습니다. \n자세한 사항은 마이페이지 -> 1:1문의하기로 문의 부탁드립니다.'
      )
    }
  }

  console.log('취소 이유 :', selectedReason.value.title)
  await order.patchOrderCancelInfo(
    orderNumber,
    orderDetailNumber,
    selectedReason.value.title,
    amount
  )
  await order.patchOrders(orderNumber, orderDetailNumber, state)

  // order 상태를 state에서 받아다가 바꿔 준다(refundDone, cancleDone)
}

const orderStatusPatch = async () => {
  try {
    const items = selectedItems.value
    if (items.length === 0) {
      alert('취소 신청할 상품을 골라 주세요.')
      return
    }
    if (selectedReason.value === undefined) {
      alert('취소 사유를 선택해 주세요.')
      return
    }
    let i = 1
    // 각 아이템을 순회하면서 주문 상태 패치
    for (const item of items) {
      const { orderNumber, orderDetailNumber } = item
      // 각 주문의 상태를 패치
      const status = 'cancleDone'
      console.log('refundCall?: ', orderNumber, orderDetailNumber, status)

      await refundCall(orderNumber, orderDetailNumber, status, i)
      i++ // i 값을 증가시킴
    }
    alert('취소 신청이 완료 되었습니다')
    system.cancleModal = false
    location.reload()
  } catch (error) {
    console.error('주문 상태 패치 실패:', error)
  }
}

const selecteData = ref([
  {
    title: '주문 실수',
    value: 'miss',
    class: ''
  },
  {
    title: '옵션 (컬러/사이즈 등) 잘못 선택',
    value: 'options-miss',
    class: ''
  },
  {
    title: '상품이 마음에 들지 않음',
    value: 'justDoit',
    class: ''
  }
])

const selectedReason = ref()

const selectBoxCommander = (i) => {
  selectedReason.value = selecteData.value[i]
  selecteData.value.forEach((selData) => {
    selData.class = ''
  })
  // selecteData.value = resetSelData
  selecteData.value[i].class = 'border-[1px] border-green-700'
}

const noneSelectedClass = ref({
  border: '',
  text: ''
})

const selectedClass = ref({
  border: 'border-[1px] border-green-700',
  text: 'text-green-700'
})

const selectedItems = ref([])

// 선택 상태를 토글하는 함수
const toggleSelection = (item) => {
  const itemIndex = selectedItems.value.findIndex(
    (selected) =>
      selected.orderNumber === item.orderNumber &&
      selected.orderDetailNumber === item.orderDetailNumber
  )

  if (itemIndex === -1) {
    // 선택되지 않았으면 추가
    selectedItems.value.push({
      orderNumber: item.orderNumber,
      orderDetailNumber: item.orderDetailNumber,
      company: item.company
    })
  } else {
    // 이미 선택되어 있으면 제거
    selectedItems.value.splice(itemIndex, 1)
  }
}

// 아이템이 선택되었는지 확인하는 함수
const isSelected = (item) => {
  return selectedItems.value.some(
    (selected) =>
      selected.orderNumber === item.orderNumber &&
      selected.orderDetailNumber === item.orderDetailNumber
  )
}

const props = defineProps({
  on: String
})

const scrollClass = ref('')

const submitCancle = () => {
  if (selectedItems.value.length < 1) {
    alert('취소할 상품을 선택해 주세요')
    return
  } else if (!selectedReason.value) {
    alert('취소 사유를 선택해 주세요')
    return
  }
  alert('취소 신청이 완료 되었습니다')
  system.cancleModal = false
}

// 라이프 사이클 훅
onMounted(() => {
  pageReady.value = true
})

watchEffect(() => {
  if (pageReady.value && order.cancleListByOdn.lenght) {
    if (order.cancleListByOdn.lenght > 2) {
      scrollClass.value = 'overflow-y-scroll'
    } else {
      scrollClass.value = ''
    }
  }
})

onUnmounted(() => {
  order.orderFilterData = {
    on: '',
    odn: '',
    company: '',
    shipMethod: '', // 같은 배송유형
    state: ''
  }
})
</script>

<style lang="scss" scoped>
/* 모달 내부에 적용할 스타일 */
.hide-scrollbar {
  overflow-y: scroll; /* 세로 스크롤은 가능하지만 */
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>
