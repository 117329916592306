<template>
  <div v-if="props.item" class="">
    <div v-if="!isSide" class="">
      <div class="">
        <!-- 옵션 위치 -->
        <hr class="w-[493px] mt-[16.5px]" />
        <section class="mr-[7px]">
          <div class="flex justify-end items-end mt-[12.4px]">
            <p class="mr-[142px] text-[18px] font-notosansCJKmd text-[#1C1C1C]">총 금액</p>
            <p
              v-if="product.calculateTotalPrice"
              class="text-[24px] font-notosansCJKmd text-[#1C1C1C]"
            >
              {{ ` ${product.calculateTotalPrice.toLocaleString('ko-KR')}` }} 원
            </p>
            <p v-else class="text-[24px] font-notosansCJKmd font-bold text-[#1C1C1C]">0 원</p>
          </div>
        </section>

        <div class="flex justify-end mt-[15px]">
          <button
            class="w-[199px] h-[56px] border-[1px] border-solid border-[#0C370A] rounded-lg"
            @click="saveCart()"
          >
            <span class="text-[#1C1C1C] text-[18px] font-notosansCJKmd">장바구니</span>
          </button>
          <button
            class="w-[199px] h-[56px] rounded-lg text-[18px] font-notosansCJKmd text-white bg-[#389033] ml-[15px]"
            @click="isLogin()"
          >
            구매하기
          </button>

          <!-- <button
            disabled
            v-if="isSoldOut"
            class="w-[199px] h-[56px] rounded-lg text-[18px] font-notosansCJKmd text-white bg-gray-400 ml-[15px]"
          >
            품절
          </button> -->
        </div>
      </div>
    </div>

    <!-- 사이드 메뉴 -->
    <div v-if="isSide && product" class="w-[312px]">
      <div class="">
        <div class="ml-[8px] mb-[19px] flex items-center justify-between">
          <p class="text-[18px] font-notosansCJKmd text-[#1C1C1C] ml-[8px] w-[23%]">총 금액</p>
          <p
            v-if="product.calculateTotalPrice"
            class="text-[24px] font-notosansCJKmd text-[#1C1C1C] mr-[9.25px]"
          >
            {{ ` ${product.calculateTotalPrice.toLocaleString('ko-KR')}` }} 원
          </p>
          <p v-else class="text-[24px] font-notosansCJKmd text-[#1C1C1C] mr-[9.25px]">0 원</p>
        </div>
        <div class="flex items-center">
          <button
            class="w-[150px] h-[56px] border border-solid-1 border-[#0C370A] rounded-md font-notosansCJKmd text-[18px] text-[#1C1C1C]"
            @click="saveCart()"
          >
            장바구니
          </button>
          <button
            class="w-[150px] h-[56px] bg-[#389033] border border-solid-1 border-[#389033] rounded-md font-notosansCJKmd text-[18px] text-[#FFFFFF] ml-[12px]"
            @click="isLogin()"
          >
            구매하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, onMounted } from 'vue'
import { useSystemStore } from '../../../stores/useSystem'
import { useUserStore } from '../../../stores/useUser'
import { useProductStore } from '../../../stores/useProduct'
// import { sendRequest } from '../../../api/serviceApi'
import axios from 'axios'

import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useCartStore } from '../../../stores/useCart'
import { useRouter } from 'vue-router'
import { sendRequest } from '../../../api/serviceApi'

const router = useRouter()
const cart = useCartStore()
const system = useSystemStore()
const product = useProductStore()
const user = useUserStore()
const selectedDone = ref([])
const resultPrice = ref(0)
const optionParent = ref([])
const isSoldOut = ref(false)

const testPrice = 1500000

const props = defineProps({
  item: [Object, Array],
  isSide: Boolean
})

const checkSoldOut = () => {
  const arr = props.item.done
  const soldCheck = arr.some(function (item) {
    return item.quantity > 0
  })
  isSoldOut.value = soldCheck
}

watchEffect(() => {
  if (props.item.done) {
    selectedDone.value = props.item.done
    checkSoldOut()
  }
  if (selectedDone.value.length > 0) {
    // const sumt = () => {
    //   let sum = 0
    //   for (const product of selectedDone.value) {
    //     sum += product.price
    //   }
    //   return sum
    // }
    // resultPrice.value = sumt()
  } else if (!selectedDone.value.length) {
    resultPrice.value = 0
  }
})

const isAnyBasicProductSelected = () => {
  const optionNames = props.item.done.map((option) => option.types)
  // console.log(optionNames)
  if (optionNames.length === 0) {
    return
  }
  const allAreAdditionalProducts = optionNames.every((name) => name === '부가상품')
  if (allAreAdditionalProducts) {
    // console.log('부가상품만 존재', false)
    return false
  } else {
    // console.log('일반상품 존재', true)
    return true
  }
}

const orderNumberGenerator = () => {
  var today = system.serverTime
  var year = today.getFullYear() // 년
  var month = (today.getMonth() + 1).toString().padStart(2, '0') // 월 (0부터 시작하기 때문에 1을 추가)
  var day = today.getDate().toString().padStart(2, '0') // 일
  var hours = today.getHours().toString().padStart(2, '0') // 시
  var minutes = today.getMinutes().toString().padStart(2, '0') // 분
  var seconds = today.getSeconds().toString().padStart(2, '0') // 초
  var milliseconds = today.getMilliseconds().toString().padStart(3, '0')
  var yearMonthDay = `${year}${month}${day}`
  var personNumber = `${hours}${minutes}${seconds}${milliseconds}`
  var makeMerchantUid = `${yearMonthDay}${personNumber}`
  return makeMerchantUid
}

const saveCart = async () => {
  if (!user.auth) {
    alert('로그인 후 장바구니 이용이 가능해요')
    return
  }
  if (selectedDone.value.length === 0) {
    alert('옵션을 선택하세요')
    return
  }
  if (isAnyBasicProductSelected() === false) {
    alert('부가 상품만 구매할 수 없습니다')
    return
  }
  for (const option of selectedDone.value) {
    const items = props.item.done
    items.id = orderNumberGenerator()
    items.options = option
    items.editor = ''

    try {
      // console.log(user.me._id)
      // const params = 'cartAdd'
      const payload = {
        userId: user.me._id,
        options: option
      }
      const result = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/cart/cartadd`, payload)
      // console.log(result)
      await cart.getCartData()
      if (result.data.type === 'error') {
        toast.error(result.data.msg)
      } else if (result.data.type === 'success') {
        toast.success(result.data.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }
  product.product_option_selected.done = []
}

const instantCart = async (id) => {
  const payload = {
    _id: id
  }
  const getProductData = await sendRequest({
    method: 'post',
    path: '/product/productGetByid',
    data: payload
  })

  const result = getProductData.result
  if (result === 'ok') {
    const carts = selectedDone.value.map((option) => {
      return {
        options: option,
        products: getProductData.data
      }
    })
    console.log(carts)

    cart.cartData = carts
    router.push({ path: '/checkout', query: { type: 'directCheckOut' } })
  }
}

const isLogin = async () => {
  if (isAnyBasicProductSelected() === false) {
    alert('부가 상품만 구매할 수 없습니다')
    return
  } else if (!user.auth) {
    alert('로그인 후 결제가 가능합니다')
  } else if (user.auth && selectedDone.value.length > 0) {
    // const productIds = selectedDone.value[0]
    const ids = selectedDone.value[0]._id
    console.log(ids)
    instantCart(ids)
    // await saveCart()
    // router.push('/checkout')
  } else if (user.auth && selectedDone.value.length === 0) {
    // router.push('/checkout')
    alert('상품을 선택해 주세요')
    return
  }
}
</script>

<style lang="scss" scoped></style>
