<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70"
  >
    <div class="w-[365px] md:w-[800px] h-auto max-h-[80vh] bg-white rounded-xl hide-scrollbar">
      <!-- 공통헤더 -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          주문 상품 문의
        </p>
        <button class="absolute right-[15px] md:right-[31px]">
          <i
            class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
            @click="system.orderInquirieModal = false"
          ></i>
        </button>
      </div>
      <!-- 모바일 -->
      <section class="md:hidden">
        <div>
          <section class="md:mx-[32px] mt-[12.5px] md:mt-[28px]" :class="scrollClass">
            <div
              v-for="(item, index) in items"
              :key="'orderDetail' + index"
              class="mb-[11px] cursor-pointer"
              @click="toggleSelection(item)"
            >
              <!-- 선택된 상태에 따라 클래스를 적용 -->
              <modalOrderCard
                :item="item"
                :style="isSelected(item) ? selectedClass : noneSelectedClass"
              />
            </div>
          </section>
          <section class="mx-2">
            <div class="">
              <p class="text-[12px] mb-[9px] font-notosansCJKmd text-[#6D6D6D]">
                문의 유형을 선택해주세요
              </p>
              <select
                name="tagSelect"
                id=""
                class="h-[35px] w-full text-center border border-[#CFCFCF] rounded-md outline-none text-[12px] font-notosansCJKmd text-[#6D6D6D]"
                v-model="payload.inquiryData.tag"
              >
                <option value="상품 관련 문의">상품 관련 문의</option>
                <option value="주문 및 결제 문의">주문 및 결제 문의</option>
                <option value="배송 관련 문의">배송 관련 문의</option>
              </select>
            </div>
            <div class="mt-[24px]">
              <p class="text-[12px] mb-[9px] font-notosansCJKmd text-[#6D6D6D]">문의 제목</p>
              <input
                maxlength="50"
                name="inquiredTitle"
                class="h-[35px] w-full border border-[#CFCFCF] rounded-md outline-none text-[12px] font-notosansCJKmd text-[#6D6D6D] px-3"
                placeholder="문의 제목을 적어주세요"
                v-model="payload.inquiryData.inquiryTitle"
              />
            </div>
            <div class="mt-[24px]">
              <p class="text-[12px] mb-[9px] font-notosansCJKmd text-[#6D6D6D]">문의 내용</p>
              <textarea
                name="inquireDes"
                class="h-[170px] w-full border border-[#CFCFCF] rounded-md outline-none px-3 pt-3 resize-none text-[12px] font-notosansCJKmd text-[#6D6D6D]"
                placeholder="문의 상세 내용을 적어주세요"
                v-model="payload.inquiryData.inquiryDetails"
                maxlength="1000"
              >
              </textarea>
            </div>
          </section>
          <section class="mt-[12px] mb-[12px] ml-3">
            <div class="flex justify-start">
              <p class="text-[11px] font-notosansCJKmd text-[#6D6D6D]">
                * <span class="text-[11px] font-notosansCJKmd text-[#D23F3F]">주문상품 문의</span>는
                마이페이지에서 문의하기 버튼을 눌러 문의하신 내역으로
              </p>
            </div>
            <div class="flex justify-start ml-2">
              <p class="text-[11px] font-notosansCJKmd text-[#6D6D6D]">
                당사가 아닌<span class="text-[11px] font-notosansCJKmd text-[#D23F3F]">
                  판매자가 답변을 진행</span
                >하고 있습니다
              </p>
            </div>
            <div class="flex justify-start mt-[12px]">
              <p class="text-[11px] font-notosansCJKmd text-[#6D6D6D]">
                ＊
                <span class="text-[11px] font-notosansCJKmd text-[#D23F3F]"
                  >연들 이용관련 문의는 1:1 문의하기</span
                >를 통해 문의를 부탁드립니다.
              </p>
            </div>
          </section>
          <hr />
          <div class="flex justify-center mb-[16px] mt-[8.3px]">
            <button
              class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#D5D5D5] rounded-lg md:rounded-md"
              @click="system.orderInquirieModal = false"
            >
              <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
            </button>
            <button
              v-if="!report.reportEdit"
              class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
              @click="saveOrderInqury()"
            >
              <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">문의하기</p>
            </button>
            <button
              v-else
              class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
              @click="updateOrderInqury()"
            >
              <p class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">수정하기</p>
            </button>
          </div>
        </div>
      </section>
      <section class="hidden md:block">
        <hr class="hidden md:block mx-8" />
        <!-- 가운데 뭔들 로직 -->
        <section class="mx-1 md:mx-[32px] mt-[12.5px] md:mt-[28px]" :class="scrollClass">
          <div
            v-for="(item, index) in items"
            :key="'orderDetail' + index"
            class="mb-[11px] cursor-pointer"
            @click="toggleSelection(item)"
          >
            <!-- 선택된 상태에 따라 클래스를 적용 -->
            <modalOrderCard
              :item="item"
              :style="isSelected(item) ? selectedClass : noneSelectedClass"
            />
          </div>
        </section>
        <div class="text-center mt-[32px]">
          <p class="text-[18px] font-notosansCJKmd text-[#232323]">문의 유형을 선택해주세요</p>
        </div>
        <section class="mt-[26px]">
          <div class="flex justify-center">
            <div class="w-[748px]">
              <div class="flex items-center ] ml-[52.9px]">
                <select
                  name="tagSelect"
                  id=""
                  class="ml-[102px] h-[48px] w-[439px] text-center border border-[#6D6D6D] rounded-md outline-none"
                  v-model="payload.inquiryData.tag"
                >
                  <option value="상품 관련 문의">상품 관련 문의</option>
                  <option value="주문 및 결제 문의">주문 및 결제 문의</option>
                  <option value="배송 관련 문의">배송 관련 문의</option>
                </select>
              </div>
              <div class="flex items-center mt-[24px] ml-[52.9px]">
                <p class="text-[15px] text-[#232323] font-notosansCJKmd">문의 제목</p>
                <input
                  maxlength="50"
                  name="inquiredTitle"
                  class="ml-[44px] h-[48px] w-[545px] pl-3 border border-[#CFCFCF] rounded-md outline-none"
                  placeholder="문의 제목을 적어주세요"
                  v-model="payload.inquiryData.inquiryTitle"
                />
              </div>
              <div class="flex mt-[24px] ml-[52.9px]">
                <p class="text-[15px] text-[#232323] font-notosansCJKmd">문의 내용</p>
                <textarea
                  name="inquireDes"
                  class="ml-[44px] h-[201px] w-[545px] border border-[#CFCFCF] rounded-md outline-none px-3 pt-3 resize-none"
                  placeholder="문의 상세 내용을 적어주세요"
                  v-model="payload.inquiryData.inquiryDetails"
                  maxlength="1000"
                >
                </textarea>
              </div>
            </div>
          </div>
        </section>
        <section class="mt-[28px] mb-[45px]">
          <div class="flex justify-center">
            <p class="text-[14px] font-notosansCJKmd text-[#6D6D6D]">
              ＊ 작성하신 주문 상품 문의는<span
                class="text-[14px] font-notosansCJKmd text-[#D23F3F]"
                >마이페이지의 문의 내역에서 확인</span
              >하실 수 있습니다.
            </p>
          </div>
        </section>
        <hr class="mx-8" />
        <div class="flex justify-center mb-[16px] mt-[16.5px]">
          <button
            class="w-[310px] h-[51px] border border-[#0C370A] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
            @click="system.orderInquirieModal = false"
          >
            취소하기
          </button>
          <button
            v-if="!report.reportEdit"
            class="w-[310px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[50px]"
            @click="saveOrderInqury()"
          >
            작성완료
          </button>
          <button
            v-else
            class="w-[310px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[50px]"
            @click="updateOrderInqury()"
          >
            수정하기
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useOrderStore } from '../../../../stores/userOrder'
import { useSystemStore } from '../../../../stores/useSystem'
import { useUserStore } from '../../../../stores/useUser'
import modalOrderCard from '../modalOrderCard.vue'
import { useProductStore } from '../../../../stores/useProduct'
import { sendRequest } from '../../../../api/serviceApi'
import { useReportStore } from '../../../../stores/useReport'

const order = useOrderStore()
const system = useSystemStore()
const user = useUserStore()
const report = useReportStore()
const prodct = useProductStore()

const items = ref([order.orderDetailData.orderDetailData])

const pageReady = ref(false)

const payload = ref({
  userData: {
    id: user.me._id, // 사용자 ID
    name: user.me.name // 사용자 이름
  },
  type: 'order', // 문의 타입: 주문 문의
  productId: '', // 제품 ID (orderDetailData의 _id)
  productTitle: '', // 제품 제목 (orderDetailData의 title)
  orderData: {
    orderNumber: items.value[0].orderNumber, // 주문 번호
    orderDetailNumber: items.value[0].orderDetailNumber, // 주문 상세 번호
    title: items.value[0].title, // 상품 제목
    thumbImg: items.value[0].thumbImg, // 상품 이미지 URL
    company: items.value[0].company, // 상품 회사 이름
    options: {
      color: items.value[0].options.color, // 색상 옵션
      types: items.value[0].options.types // 옵션 유형
    }
  },
  inquiryData: {
    inquiryTitle: '', // 문의 제목 (사용자가 입력)
    inquiryDetails: '', // 문의 상세 내용 (사용자가 입력)
    inquiryImg: [], // 문의 이미지 URL 배열
    tag: '', // 문의 태그 (예: '상품 관련 문의')
    isAnswer: false, // 초기 상태: 답변 없음
    answerDetails: null // 초기 상태: 답변 없음
  },
  answerImg: [], // 답변 이미지 배열 (초기 상태: 빈 배열)
  createAt: system.serverTime, // 생성일 (자동으로 현재 날짜)
  editAt: null // 수정일 (초기 상태: null)
})

const selectBoxCommander = (i) => {
  selectedReason.value = selecteData.value[i]
  selecteData.value.forEach((selData) => {
    selData.class = ''
  })
  // selecteData.value = resetSelData
  selecteData.value[i].class = 'border-[2px] border-green-700'
}

const selectedItems = ref([])
const noneSelectedClass = ref({
  border: '',
  text: ''
})

const selectedClass = ref({
  border: 'border-[2px] border-green-700',
  text: 'text-green-700'
})
// 선택 상태를 토글하는 함수
const toggleSelection = (item) => {
  const itemIndex = selectedItems.value.findIndex(
    (selected) =>
      selected.orderNumber === item.orderNumber &&
      selected.orderDetailNumber === item.orderDetailNumber
  )

  if (itemIndex === -1) {
    // 선택되지 않았으면 추가
    selectedItems.value.push({
      orderNumber: item.orderNumber,
      orderDetailNumber: item.orderDetailNumber
    })
  } else {
    // 이미 선택되어 있으면 제거
    selectedItems.value.splice(itemIndex, 1)
  }
}
// 아이템이 선택되었는지 확인하는 함수
const isSelected = (item) => {
  return selectedItems.value.some(
    (selected) =>
      selected.orderNumber === item.orderNumber &&
      selected.orderDetailNumber === item.orderDetailNumber
  )
}
const scrollClass = ref('')
const orderLength = ref()

const saveOrderInqury = async () => {
  if (
    payload.value.inquiryData.inquiryTitle === '' ||
    payload.value.inquiryData.inquiryDetails === '' ||
    payload.value.inquiryData.tag === ''
  ) {
    alert('누락된 내용이 존재합니다.')
    return
  }
  const rusure = confirm('문의 내용을 저장합니다.')
  if (!rusure) {
    return
  }

  const sendData = {
    data: payload.value
  }
  const patchSave = await sendRequest({
    method: 'post',
    path: '/report/createReport',
    data: sendData
  })

  const result = patchSave.result
  console.log(result)
  if (result === 'ok') {
    alert('문의가 작성 되었습니다.')
    system.orderInquirieModal = false
  }
}
const updateOrderInqury = async () => {
  if (
    payload.value.inquiryData.inquiryTitle === '' ||
    payload.value.inquiryData.inquiryDetails === '' ||
    payload.value.inquiryData.tag === ''
  ) {
    alert('누락된 내용이 존재합니다.')
    return
  }
  const rusure = confirm('문의 내용을 수정하시겠어요?')
  if (!rusure) {
    return
  }

  const sendData = {
    reportid: report.myReportData._id,
    data: payload.value
  }
  const patchSave = await sendRequest({
    method: 'patch',
    path: '/report/updateReport',
    data: sendData
  })

  const result = patchSave.result
  console.log(result)
  if (result === 'ok') {
    alert('문의가 수정 되었습니다.')
    system.orderInquirieModal = false
  }
}

onMounted(() => {
  pageReady.value = true
  orderLength.value = order.orderDetailData.orderData.orderDetail.length
  if (report.reportEdit) {
    payload.value = report.myReportData
  }
})

onUnmounted(() => {
  report.myReportData = null
  report.reportEdit = false
})
</script>

<style lang="scss" scoped>
/* 모달 내부에 적용할 스타일 */
.hide-scrollbar {
  overflow-y: scroll; /* 세로 스크롤은 가능하지만 */
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>
