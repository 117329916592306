<template>
  <div :class="`${textcolor} ${eio} w-full mx-auto ${bgsis}`" ref="nav">
    <login-modal v-if="system.loginModals" />
    <register-modal v-if="system.registerModals" />
    <div
      class="lg:w-[1220px] mb-[11px] lg:mb-0 mt-[20px] lg:mt-[26px] flex justify-between mx-auto"
    >
      <homeLogoAndSeach class="" />
      <headerButton class="mt-[10px] ml-5 hidden md:block" />
    </div>
    <div class="bg-white w-full lg:mb-[24px] hidden lg:block"></div>
    <hr class="lg:hidden" />
    <mobileCatShop class="h-[45px] lg:hidden pl-[12.5px]" />
    <hr class="lg:hidden block mt-[0px] w-full bg-[#E2E2E2]" />
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useUserStore } from '../../stores/useUser'
import { useCookies } from '@vueuse/integrations/useCookies'
import axios from 'axios'
import loginModal from '../../components/modal/loginModal.vue'
import registerModal from '../../components/modal/registerModal.vue'
import { useSystemStore } from '../../stores/useSystem'
import homeLogoAndSeach from '../../components/home/homeLogoAndSeach.vue'
import headerButton from '../headerUtil/headerButton.vue'
import mobileCatShop from './mobileCatShop.vue'
import { useElementSize, useWindowScroll } from '@vueuse/core'

const nav = ref(null)
const { width, height } = useElementSize(nav)

const system = useSystemStore()
const user = useUserStore()
const cookies = useCookies()
const authCookies = ref()
const bgsis = ref('bg-white')
const textcolor = ref('white')
const eio = ref('')

const { y } = useWindowScroll()

const setToAuth = async (token) => {
  try {
    const tokenCheck = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/getAuth`,
      {},
      {
        headers: {
          authentication: token
        }
      }
    )
    const setMeData = tokenCheck.data
    user.status = true
    user.me = setMeData
    user.auth = setMeData.auth
  } catch (e) {
    console.error('Error in token validation:', e)
    user.status = false
    user.me = null
    user.auth = null
  }
}

// 스크롤 위치에 따라 헤더 스타일 변경
watch(
  y,
  (newY) => {
    if (newY >= 150) {
      bgsis.value = 'bg-white shadow-md'
      textcolor.value = 'text-[#707070]'
      eio.value = 'transition-colors duration-500 '
    } else {
      bgsis.value = 'bg-white'
      textcolor.value = 'text-[#707070]'
      eio.value = 'transition-colors duration-500 '
    }
  },
  { immediate: true }
)

// 쿠키(authCookies) 변경을 감시하여 토큰이 있는 경우 setToAuth 호출
watch(
  () => cookies.get('yeondeul'),
  async (newVal) => {
    if (newVal) {
      authCookies.value = newVal // 쿠키가 변경되면 authCookies를 업데이트
      user.token = newVal
      await setToAuth(newVal) // 비동기 작업을 기다림
    } else {
      user.status = false
      user.me = null
      user.auth = null
    }
  },
  { immediate: true }
)

onMounted(() => {
  authCookies.value = cookies.get('yeondeul')
})
</script>

<style scoped></style>
