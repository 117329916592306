<template>
  <div>
    <div id="select_box" class="z-60">
      <div class="">
        <!-- 상품 선택자 -->
        <div
          v-if="isSelect"
          ref="selectorTop"
          class="h-auto mb-[7px] w-full bg-white opacity-[98%] fixed bottom-[75px] left-0 z-40"
          :class="bottomPx"
        >
          <div class="flex flex-col items-center">
            <div class="absolute left-[93%] pt-[9px] items-center">
              <button v-if="isSelect === true" class="text-[23px]" @click="isSelect = false">
                <i class="pi pi-times text-[17px]"></i>
              </button>
            </div>
            <!-- 상품 타입 -->
            <div class="mt-[50px] w-[95%]">
              <div
                class="w-full h-[40px] block rounded-lg border-[1px] border-solid border-[#C1C1C1] pl-[15.6px]"
              >
                <section
                  class="flex justify-between items-center pr-3 h-full cursor-pointer"
                  @click="option1 = !option1"
                >
                  <p class="text-[#A5A5A5] font-notosansCJR text-[12px]">
                    {{
                      selectedType
                        ? selectedType.optionName +
                          ` + ${selectedType.extraCoast.toLocaleString('ko-KR')}` +
                          '원'
                        : '상품 유형'
                    }}
                  </p>
                  <i class="pi pi-chevron-down text-[#A5A5A5] text-[12px]"></i>
                </section>
              </div>
              <div
                v-if="option1"
                class="w-full bg-white border-[1px] border-solid border-[#C1C1C1] rounded-lg mt-[5px]"
              >
                <div
                  v-for="(types, i) in optionParent"
                  :key="'types' + i"
                  class="pl-[15.6px] p-[10px] cursor-pointer"
                  @click="(selectedType = types), (option1 = !option1)"
                >
                  <p class="text-[#A5A5A5] font-notosansCJR text-[12px]">
                    {{ types.optionName + ` + ${types.extraCoast.toLocaleString('ko-KR')}` }}원
                  </p>
                </div>
              </div>
            </div>
            <!-- 상품 색상 -->
            <div class="w-[95%] mt-[14.5px]">
              <div
                class="w-full h-[40px] block rounded-lg border-[1px] border-solid border-[#C1C1C1] pl-[15.6px] text-[12px] text-[#A5A5A5]"
              >
                <section
                  class="flex justify-between items-center pr-3 h-full cursor-pointer"
                  @click="colorDropDown()"
                >
                  <p class="text-[#A5A5A5] font-notosansCJR text-[12px]">
                    {{
                      selectedColor
                        ? selectedColor.optionName +
                          ` + \\ ${selectedColor.extraCoast.toLocaleString('ko-KR')}`
                        : '선택'
                    }}
                  </p>
                  <i class="pi pi-chevron-down text-[#A5A5A5] text-[12px]"></i>
                </section>
              </div>
              <div
                v-if="option2"
                class="w-full bg-white border-[1px] border-solid border-[#C1C1C1] rounded-lg mt-[5px]"
              >
                <div
                  v-for="(colors, i) in optionChild"
                  :key="'colors' + i"
                  class="pl-[15.6px] p-[10px] cursor-pointer"
                  @click="cololrselector(colors)"
                >
                  <p class="text-[#A5A5A5] font-notosansCJR text-[12px]">
                    {{ colors.optionName + ` + \\ ${colors.extraCoast.toLocaleString('ko-KR')}` }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="item.extra.length > 0" class="w-[95%] mt-[14.5px]">
              <select
                name=""
                id=""
                class="w-full h-[34.47px] block rounded-lg border-[1px] border-solid border-[#707070] pl-[15.6px] text-[12px] text-[#A5A5A5]"
                v-model="selectedExtra"
              >
                <option :value="null" selected hidden>추가 상품</option>
                <option v-for="(extras, ex) in item.extra" :key="'extras' + ex" :value="extras">
                  {{ extras.title }}
                </option>
              </select>
            </div>
            <section class="mb-[26px] h-auto w-[95%]">
              <!-- 선택 상품 옵션 -->
              <div
                class="mt-[10px]"
                v-for="(selected, o) in product.product_option_selected.done"
                :key="'selected' + o"
              >
                <div class="h-[80px] rounded-lg border-[1px] border-solid border-[#C1C1C1]">
                  <div class="flex justify-between pt-[12.6px] text-[11px]">
                    <p class="pl-[9.6px] text-[14px] font-notosansCJKmd">{{ selected.name }}</p>
                    <i class="pi pi-times cursor-pointer pr-[12.6px]" @click="selectedDlete(o)"></i>
                  </div>
                  <div class="mt-[8.5px] flex justify-between">
                    <div
                      class="flex h-[25px] w-[86px] ml-[11px] border-[1.5px] border-solid border-[#C1C1C1] rounded-xl"
                    >
                      <button
                        class="w-[30px] bg-white rounded-l-xl text-[16px] font-bold"
                        @click="adjustQuantity(o, 'minus')"
                      >
                        -
                      </button>
                      <input
                        type="text"
                        class="w-[24px] text-center font-notosansCJKmd text-[12px]"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        v-model="product.product_option_selected.done[o].ea"
                      />
                      <button
                        class="w-[30px] bg-white rounded-r-xl text-[16px] font-bold"
                        @click="adjustQuantity(o, 'plus')"
                      >
                        +
                      </button>
                    </div>
                    <p class="pr-[12.6px] text-[15px] font-bold">
                      <!-- {{ `\\ ${selectedDone[o].price.toLocaleString('ko-KR')}` }} -->
                      {{ product.product_option_selected.done[o].price.toLocaleString('ko-KR') }} 원
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="fixed z-[100] bottom-[75px] w-full pr-4 bg-white pt-2">
        <div
          v-if="product.product_option_selected.done.length >= 1"
          class="flex justify-end mb-1 mr-1"
        >
          <p class="text-[#1C1C1C] text-[12px] font-bold">총 금액</p>
          <p class="text-[#1C1C1C] text-[17px] font-bold ml-[49.8px]">
            <!-- {{ `\\ ${resultPrice.toLocaleString('ko-KR')}` }} -->
            {{ ` ${product.calculateTotalPrice.toLocaleString('ko-KR')}` }} 원
          </p>
        </div>
      </div>
      <!-- 상품 선택 영역 -->
      <div class="flex justify-between h-[75px] w-full bottom-0 fixed z-50 bg-white">
        <div class="w-full text-[14px]">
          <div class="w-full h-[0px] absolute right-2 bg-yellow-200">
            <div v-if="isSelect === false" class="flex justify-end" @click="isSelect = !isSelect">
              <button
                class="text-[15px] text-[#858282] font-bold w-[155px] h-[45px] border-[1px] border-solid border-[#353635] rounded-lg"
              >
                장바구니
              </button>
              <button
                v-if="!isSoldOut"
                class="text-[15px] text-white font-bold ml-[14px] w-[155px] h-[45px] rounded-lg bg-[#389033]"
                @click="isLogin()"
              >
                구매하기
              </button>
              <button
                disabled
                v-if="isSoldOut"
                class="text-[15px] text-white font-bold ml-[14px] w-[155px] h-[43px] rounded-lg bg-gray-400"
              >
                품절
              </button>
            </div>
            <!-- 창 떴을시 동작 -->
            <div v-if="isSelect === true" class="flex justify-end">
              <button
                class="text-[15px] text-[#1C1C1C] font-bold w-[155px] h-[45px] border-[1px] border-solid border-[#353635] rounded-lg"
                @click="saveCart()"
              >
                장바구니
              </button>
              <button
                v-if="!isSoldOut"
                class="text-[15px] text-white font-bold ml-[14px] w-[155px] h-[45px] rounded-lg bg-[#389033]"
                @click="isLogin()"
              >
                구매하기
              </button>
              <button
                disabled
                v-if="isSoldOut"
                class="text-[15px] text-white font-bold ml-[14px] w-[152px] h-[40px] rounded-lg bg-gray-400"
              >
                품절
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'

import { ref, watchEffect, onMounted, onUnmounted } from 'vue'

import { useProductStore } from '../../../stores/useProduct'
import { useUserStore } from '../../../stores/useUser'
import { useSystemStore } from '../../../stores/useSystem'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useCartStore } from '../../../stores/useCart'
import { useRouter } from 'vue-router'
import { sendRequest } from '../../../api/serviceApi'
const router = useRouter()

const props = defineProps({
  item: Object,
  isSide: Boolean
})
const system = useSystemStore()
const cart = useCartStore()

const user = useUserStore()
const product = useProductStore()
const pageReady = ref(false)

const isSelect = ref(false)
const isSoldOut = ref(false)

const selectedType = ref(null)
const selectedColor = ref(null)

const optionParent = ref([])
const optionChild = ref([])

const selectedExtra = ref(null)

const bottomPx = ref('bottom-[56px]')

const alertTime = ref(1200)
const toastLock = ref(false)

const option1 = ref(false)
const option2 = ref(false)
const option3 = ref(false)

const toastLockCounter = () => {
  setTimeout(() => {
    toastLock.value = false
  }, alertTime.value)
}

const toatsInOven = () => {
  if (toastLock.value) {
    return
  }
  toastLock.value = true
  toast(`구매 가능한 수량을 초과 하였어요`, {
    theme: 'auto',
    type: 'error',
    autoClose: alertTime.value,
    transition: 'flip',
    dangerouslyHTMLString: true
  })
  toastLockCounter()
}

let options = props.item.options

onMounted(() => {
  if (props.item) {
    pageReady.value = true
  }
})

const selectedDlete = (ois) => {
  product.product_option_selected.done.splice(ois, 1)
  user.mySelected = product.product_option_selected.done
}

const adjustQuantity = (oi, command) => {
  if (command === 'minus') {
    if (product.product_option_selected.done[oi].ea < 2) {
      return
    }
    product.product_option_selected.done[oi].ea -= 1
  } else if (command === 'plus') {
    // 초과 체크

    // 부가상품 핸들링
    const types = product.product_option_selected.done[oi].types
    if (types === '부가상품') {
      let findQuantity = product.productItem.extra.find((item) => {
        return item.title === product.product_option_selected.done[oi].name
      })

      const names = product.product_option_selected.done[oi].name
      // 카트에 짱박은 부가상품도 찾아야됨
      let cartEaCounter = cart.cartData
        .filter((cartItem) => cartItem.options.name === names)
        .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

      let eaCounter = product.product_option_selected.done
        .filter((item) => item.name === names)
        .reduce((sum, item) => sum + item.ea, 0)

      const totalEaCounter = eaCounter + cartEaCounter
      console.log(
        types,
        '의 ',
        '설정된 최대 갯수: ',
        findQuantity.quantity,
        '중 ',
        totalEaCounter,
        ' 선택됨'
      )
      if (findQuantity.quantity === totalEaCounter || findQuantity.quantity < totalEaCounter) {
        toatsInOven()
        return
      }

      product.product_option_selected.done[oi].ea += 1
      return
    }

    // 수정

    let findCart = product.product_option_selected.parnet.find((item) => {
      return item.optionName === types
    })

    // 장바구니에서 동일한 타입의 상품을 찾아 수량을 합산
    let cartEaCounter = cart.cartData
      .filter((cartItem) => cartItem.options.types === types)
      .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

    let eaCounter = product.product_option_selected.done
      .filter((item) => item.types === types)
      .reduce((sum, item) => sum + item.ea, 0)

    const totalEaCounter = eaCounter + cartEaCounter
    if (findCart.quantity === totalEaCounter || findCart.quantity < totalEaCounter) {
      toatsInOven()
      return
    }

    product.product_option_selected.done[oi].ea += 1
  }
}

const isAnyBasicProductSelected = () => {
  const optionNames = product.product_option_selected.done.map((option) => option.types)
  console.log(optionNames.length)

  if (optionNames.length === 0) {
    return
  }
  const allAreAdditionalProducts = optionNames.every((name) => name === '부가상품')
  if (allAreAdditionalProducts) {
    console.log('부가상품만 존재', false)
    return false
  } else {
    console.log('일반상품 존재', true)
    return true
  }
}

const saveCart = async () => {
  if (isAnyBasicProductSelected() === false) {
    alert('부가 상품만 구매할 수 없습니다')
    return
  }
  if (product.product_option_selected.done.length === 0) {
    alert('옵션을 선택하세요')
    return
  } else if (!user.auth) {
    alert('로그인 후 장바구니 이용이 가능해요')
    return
  }
  for (const option of product.product_option_selected.done) {
    try {
      const payload = {
        userId: user.me._id,
        options: option
      }
      const result = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/cart/cartadd`, payload)
      console.log(result)
      await cart.getCartData()
      if (result.data.type === 'error') {
        toast.error(result.data.msg)
      } else if (result.data.type === 'success') {
        toast.success(result.data.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }
  product.product_option_selected.done = []
}

const instantCart = async (id) => {
  const payload = {
    _id: id
  }
  const getProductData = await sendRequest({
    method: 'post',
    path: '/product/productGetByid',
    data: payload
  })

  const result = getProductData.result
  if (result === 'ok') {
    const carts = product.product_option_selected.done.map((option) => {
      return {
        options: option,
        products: getProductData.data
      }
    })
    // console.log(carts)

    cart.cartData = carts
    router.push({ path: '/checkout', query: { type: 'directCheckOut' } })
  }
}

const isLogin = () => {
  if (!user.auth) {
    alert('로그인 후 결제가 가능합니다')
  } else if (user.auth && product.product_option_selected.done.length > 0) {
    instantCart(product.product_option_selected.done[0]._id)
    // saveCart()
    // router.push('/checkout')
  } else if (user.auth && product.product_option_selected.done.length === 0) {
    alert('상품을 선택해 주세요')
    return
    // router.push('/checkout')
  }
}

const optionOrgernizer = () => {
  const paraentGroup = options.filter((parent) => parent.parentOption === '')
  optionParent.value = paraentGroup
  product.product_option_selected.parnet = optionParent.value
}

const addProduct = () => {
  const newProduct = {
    name: `${selectedType.value.optionName} - ${selectedColor.value.optionName}`,
    types: selectedType.value.optionName,
    color: selectedColor.value.optionName,
    _id: props.item._id,
    ea: 1,
    price:
      props.item.price +
      selectedType.value.extraCoast +
      selectedColor.value.extraCoast -
      props.item.sale_of_per,
    fixPrice:
      props.item.price +
      selectedType.value.extraCoast +
      selectedColor.value.extraCoast -
      props.item.sale_of_per
  }
  if (product.product_option_selected.done.some((product) => product.name === newProduct.name)) {
    alert('이미 선택하신 상품입니다.')
    resetSelected()
    return
  }
  // console.log('현재 선택된 옵션 토탈 값: ', quantityCheckerIsOver('newOption'))

  if (quantityCheckerIsOver('newOption') === true) {
    toatsInOven()
    resetSelected()
    return
  }

  product.product_option_selected.done.push(newProduct)
  product.product_option_selected.done = product.product_option_selected.done
  user.mySelected = product.product_option_selected.done
  resetSelected()
}

const quantityCheckerIsOver = (cmd) => {
  if (!product.product_option_selected.done) {
    console.log('현재 설정된 옵션값 없음')
    return false
  }

  let maxSelectedQuantity
  let types

  switch (cmd) {
    case 'newOption':
      console.log('newOptions selected')
      maxSelectedQuantity = selectedType.value.quantity
      types = selectedType.value.optionName
      break
    default:
      maxSelectedQuantity = selectedType.value.quantity
      types = selectedType.value.optionName
      break
  }

  console.log('선택값', types)
  // 총 ea 값 계산
  const totalEa = product.product_option_selected.done
    .filter((item) => item.types === types)
    .reduce((sum, item) => sum + item.ea, 0)

  console.log('현재 선택된 옵션 토탈 값: ', totalEa)

  // 총 ea 값이 최대 선택 가능 수량과 같은지 확인
  return totalEa >= maxSelectedQuantity
}

const resetSelected = () => {
  selectedType.value = null
  selectedColor.value = null
  selectedExtra.value = null
  optionChild.value = [] // 선택 초기화 시 optionChild도 초기화
}

watchEffect(() => {
  if (pageReady.value === true) {
    // getReview()
    optionOrgernizer()
  }
})

watchEffect(() => {
  if (selectedType.value !== null) {
    const childGroup = options.filter(
      (child) => child.parentOption === selectedType.value.optionName
    )
    optionChild.value = childGroup
  }
})

watchEffect(() => {
  if (selectedType.value && selectedColor.value) {
    addProduct()
  }
})

watchEffect(() => {
  if (product.product_option_selected.done.length >= 1) {
    bottomPx.value = 'bottom-[83px]'
  } else {
    bottomPx.value = 'bottom-[61px]'
  }
})

// 추가 상품
const addExtraProduct = () => {
  const newExtra = {
    name: selectedExtra.value.title,
    types: '부가상품',
    color: selectedExtra.value.title,
    _id: props.item._id,
    ea: 1,
    price: selectedExtra.value.price,
    fixPrice: selectedExtra.value.price
  }

  if (product.product_option_selected.done.some((extra) => extra.name === newExtra.name)) {
    alert('이미 선택하신 추가 상품입니다.')
    return
  }

  product.product_option_selected.done.push(newExtra)
  selectedExtra.value = null // 추가 상품 선택 후 초기화
}

const extraProductquantityCheck = (quantity, titles) => {
  let findQuantity = quantity

  const names = titles

  let cartEaCounter = cart.cartData
    .filter((cartItem) => cartItem.options.name === names)
    .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

  let eaCounter = product.product_option_selected.done
    .filter((item) => item.name === names)
    .reduce((sum, item) => sum + item.ea, 0)

  const totalEaCounter = eaCounter + cartEaCounter
  if (findQuantity === totalEaCounter || findQuantity < totalEaCounter) {
    selectedExtra.value = null
    return true
  }
  return false
}

watchEffect(() => {
  if (selectedExtra.value) {
    const checkResult = extraProductquantityCheck(
      selectedExtra.value.quantity,
      selectedExtra.value.title
    )
    if (checkResult) {
      toatsInOven()
      return
    } else {
      addExtraProduct()
    }
  }
})

const cololrselector = (color) => {
  if (selectedType.value) {
    selectedColor.value = color
    option2.value = false
  }
}

const colorDropDown = () => {
  if (selectedType.value) {
    option2.value = true
  } else if (option2.value === true) {
    console.log('열렸다')

    option2.value = false
  }
}
</script>

<style lang="scss" scoped></style>
