import { defineStore } from 'pinia'
import { computed, ref, watchEffect } from 'vue'
import { useCartStore } from './useCart'
import { sendRequest } from '../api/serviceApi'

export const useRegisterStore = defineStore(
  'register',
  () => {
    // setter
    const vendor = ref('')
    const sendSms = ref(false)
    const smsAuth = ref(false)
    const validateEmail = ref(false)
    const registStage = ref(0)
    const naverToken = ref('')
    const registerStage = ref({
      stage0: {
        sns: false,
        location: false,
        required: false
      },
      stage1: {
        userInfo: {
          email: '',
          password: '',
          passwordCheck: '',
          name: '',
          gender: '',
          birth: '',
          mobile: ''
        }
      },
      stage2: {
        receiverInfo: {
          name: '',
          mobile: '',
          postcode: '',
          address: '',
          addressDetail: ''
        }
      }
    })

    //getter

    //action

    return {
      // setter
      sendSms,
      smsAuth,
      registStage,
      registerStage,
      validateEmail,
      naverToken,
      vendor
      //getter
      //action
    }
  },
  {
    // persist: true
  }
)
