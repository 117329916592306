import { defineStore } from 'pinia'
import { ref, watch, computed, watchEffect } from 'vue'
import axios from 'axios'
import { useUserStore } from './useUser'
import { useAddressStore } from './useAddress'
import { sendRequest } from '../api/serviceApi'
import { toast } from 'vue3-toastify'
import { deepCopy } from '../utils/deepCopy'
import 'vue3-toastify/dist/index.css'

export const useCartStore = defineStore('cart', () => {
  // 상태 정의
  const userStore = useUserStore()
  const address = useAddressStore()
  const cartData = ref([])
  const buyCartData = ref([])
  const cartCount = ref(0)
  const currentUser = computed(() => userStore.me?._id || null)
  const island = ref(false)
  const islandPriceSetup = ref(4000)
  const islandPrice = ref(0)
  const freeShipAmmount = ref({})
  const usePoint = ref(0)
  const buyPriceInfos = ref({})

  const addCompanyData = (data) => {
    const companyName = data.company

    if (!companyName || companyName === 'undefined') {
      console.error('유효하지 않은 회사 이름:', companyName)
      return
    }

    // 회사 이름에 해당하는 데이터가 없으면 새 배열 생성
    if (!buyPriceInfos.value[companyName]) {
      buyPriceInfos.value[companyName] = []
    }

    // 최신 데이터로 덮어쓰기 위해 기존 배열을 초기화
    buyPriceInfos.value[companyName] = [data]
  }

  watchEffect(() => {
    if (island.value) {
      islandPrice.value = islandPriceSetup.value
    } else {
      islandPrice.value = 0
    }
  })

  const payUserInfo = ref({
    orderUserInfo: {
      name: '',
      mobile: '',
      email: ''
    },
    receiverInfo: {
      name: '',
      mobile: '',
      postcode: '',
      address: '',
      addressDetail: '',
      request: '',
      title: ''
    },
    checkoutMethod: '',
    setToDefault: false
  })

  // watchEffect(() => {
  //   // let user = useUserStore()
  //   if (userStore.me.reciverList && userStore.me.reciverList[0]) {
  //     payUserInfo.value.receiverInfo = userStore.me.reciverList[0]
  //   } else {
  //     return
  //   }
  // })

  // userStore의 me.reciverList가 변경될 때마다 실행
  watch(
    () => address.defaultAddress,
    (newValue) => {
      if (newValue !== undefined) {
        payUserInfo.value.receiverInfo = deepCopy(address.defaultAddress)
      }
    },
    { immediate: true, deep: true }
  )
  // watch(
  //   () => userStore.me,
  //   (newUser) => {
  //     if (newUser && newUser.reciverList && newUser.reciverList[0]) {
  //       payUserInfo.value.receiverInfo = newUser.reciverList[0]
  //     } else {
  //       payUserInfo.value.receiverInfo = null
  //     }
  //   },
  //   { immediate: true, deep: true }
  // )

  const pointCacluator = (price, pointPer, ea) => {
    const eaTimePrice = price * ea
    const points = eaTimePrice * (pointPer / 100)
    const totalPointsRounded = Math.floor(points)
    return totalPointsRounded
  }

  // 세터
  const calculator = computed(() => {
    const pointToUse = usePoint.value
    const totalDiscount = cartMaker.value.reduce(
      (sum, company) => sum + company.totalDiscountAmmount,
      0
    )

    const totalPrice = cartMaker.value.reduce(
      (sum, company) => sum + company.totalPrice + company.totalDiscountAmmount,
      0
    )

    const totalShippingPrice = cartMaker.value.reduce(
      (sum, company) => sum + company.totalShipAmmount,
      0
    )

    const totalPayPrice = totalPrice - totalDiscount + totalShippingPrice - pointToUse

    const totalPoint = cartData.value.reduce((accumulator, item, i) => {
      const points = pointCacluator(
        item.options.price,
        item.products.pointPercentage,
        item.options.ea
      )
      return accumulator + points
    }, 0)

    return {
      totalDiscount,
      totalPayPrice,
      totalPrice,
      totalShippingPrice,
      totalPoint,
      pointToUse
    }
  })

  const cartMaker = computed(() => {
    if (!cartData.value.length) return []

    // 회사별로 그룹화
    const groupedData = cartData.value.reduce((acc, item, index) => {
      const company = item.products.company
      if (!acc[company]) {
        acc[company] = {
          company,
          items: [],
          totalDiscountAmmount: 0,
          totalPrice: 0,
          resultShipAmmount: null, // 처음엔 null로 설정
          totalShipAmmount: 0 // 새로 만들었어요
        }
      }

      // title별로 그룹화된 데이터를 items 배열에 추가
      const title = item.products.title
      if (!acc[company].items[title]) {
        acc[company].items[title] = {
          products: item.products,
          itemOptions: []
        }
      }
      // index 추가
      acc[company].items[title].itemOptions.push({ ...item.options, index })
      acc[company].totalDiscountAmmount += item.products.sale_of_per * item.options.ea
      acc[company].totalPrice += item.options.fixPrice * item.options.ea

      // 배송비 계산
      const shipAmmount = item.products.ship_ammount
      const islands = islandPrice.value

      // 무료 배송 조건을 먼저 체크
      if (
        (company === '연들' && acc[company].totalPrice >= freeShipAmmount.value.yeondeul) ||
        (company === '자기다움' && acc[company].totalPrice >= freeShipAmmount.value.jagidaum)
      ) {
        acc[company].resultShipAmmount = 0
      } else if (shipAmmount === 0) {
        acc[company].resultShipAmmount = 0
      } else if (acc[company].resultShipAmmount !== 0) {
        acc[company].resultShipAmmount = Math.max(acc[company].resultShipAmmount || 0, shipAmmount)
      }
      acc[company].totalShipAmmount = acc[company].resultShipAmmount + islands
      return acc
    }, {})

    // 새로운 데이터 셋을 배열 형태로 변환
    return Object.values(groupedData).map((group) => ({
      ...group,
      items: Object.values(group.items)
    }))
  })

  // 액션
  const getVendor = async () => {
    const getShippingData = await sendRequest({
      method: 'post',
      path: '/system/getSystem'
    })

    const result = getShippingData.result
    if (result === 'ok') {
      // console.log(getShippingData.data[0].vendors)
      getShippingData.data[0].vendors.forEach((item) => {
        freeShipAmmount.value[item.vendor] = item.amount
      })
    } else {
      console.error(result)
    }
  }
  getVendor()

  const getCartData = async () => {
    if (currentUser.value) {
      // console.log('user has found')
      const payload = { userid: currentUser.value }
      try {
        const { data: result } = await axios.post(
          `${import.meta.env.VITE_API_BASE_URL}/cart/cartGetByUser`,
          { payload }
        )
        if (result) {
          cartData.value = result
          cartCount.value = result.length
        }
      } catch (error) {
        console.error('Error fetching cart data:', error)
      }
    } else {
      console.log('No user found')
    }
  }

  watch(
    () => userStore.me,
    (newVal) => {
      if (newVal && newVal._id) {
        currentUser.value = newVal._id
        getCartData()
      }
    },
    { immediate: true }
  )

  const adjustQuantity = async (index, command) => {
    const item = cartData.value[index]
    const ea = item.options.ea

    if (command === 'minus') {
      if (ea < 2) return

      item.options.ea -= 1
    } else if (command === 'plus') {
      const types = item.options.types

      if (types === '부가상품') {
        // console.log('부가상품 핸들링 중')
        const names = item.options.name
        const findCart = item.products.extra.find((option) => option.title === names)
        console.log(findCart.title, findCart.quantity)

        const eaCounter = cartData.value
          .filter((cartItem) => cartItem.options.name === names)
          .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

        // console.log('eaCounter', eaCounter)

        if (findCart.quantity === eaCounter) {
          // console.warn('재고 초과')
          toast(`구매 가능한 수량을 초과 하였어요`, {
            theme: 'auto',
            type: 'error',
            autoClose: 1200,
            transition: 'flip',
            dangerouslyHTMLString: true
          })
          return
        }

        item.options.ea += 1

        const data = {
          params: command,
          payload: {
            userId: currentUser.value,
            order: index
          }
        }
        await sendRequest({ method: 'patch', path: '/cart/cartPatch', data })
        return
      }
      const findCart = item.products.options.find((option) => option.optionName === types)

      const eaCounter = cartData.value
        .filter((cartItem) => cartItem.options.types === types)
        .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

      // console.log(types, '의 설정된 최대 갯수: ', findCart.quantity, '중 ', eaCounter, ' 선택됨')
      if (findCart.quantity === eaCounter) {
        // console.warn('재고 초과')
        toast(`구매 가능한 수량을 초과 하였어요`, {
          theme: 'auto',
          type: 'error',
          autoClose: 1200,
          transition: 'flip',
          dangerouslyHTMLString: true
        })
        return
      }

      item.options.ea += 1
    }

    const data = {
      params: command,
      payload: {
        userId: currentUser.value,
        order: index
      }
    }
    await sendRequest({ method: 'patch', path: '/cart/cartPatch', data })
  }

  const removeItemFromCart = async (index) => {
    const payload = {
      order: index,
      userId: currentUser.value
    }

    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/cart/cartPatch`, {
        params: 'delete',
        payload
      })
      await getCartData()
    } catch (error) {
      console.error('Error removing item from cart:', error)
    }
  }

  const cartDbHandller = async (index, cmnd) => {
    const data = {
      params: cmnd,
      payload: {
        order: index,
        userId: currentUser.value
      }
    }
    await sendRequest({ method: 'patch', path: '/cart/cartPatch', data })
    getCartData()
  }

  // `cartMaker`를 감시하여 부가상품만 남은 경우 삭제하는 로직 추가
  watch(
    () => cartData.value,
    () => {
      // 제거할 항목의 인덱스를 수집하기 위한 배열
      const removeIndices = new Set()

      // cartMaker의 데이터를 참조해서
      cartMaker.value.forEach((company) => {
        // company의 각 item을 순회
        company.items.forEach((item) => {
          // 모든 itemOptions가 부가상품인지 확인
          const onlyAddOns = item.itemOptions.every((option) => option.types === '부가상품')
          if (onlyAddOns) {
            console.log('부가상품만 있는 상품', item.products._id)

            // cartData에서 제거할 항목의 인덱스를 수집
            cartData.value.forEach((cartItem, index) => {
              if (
                cartItem.products._id === item.products._id &&
                cartItem.options.types === '부가상품'
              ) {
                removeIndices.add(index)
              }
            })
          }
        })
      })

      // 수집된 인덱스를 역순으로 정렬하여 제거
      Array.from(removeIndices)
        .sort((a, b) => b - a)
        .forEach((index) => {
          cartDbHandller(index, 'delete')
        })
    },
    { deep: true } // 깊은 감시를 통해 내부 배열의 변화까지 감지
  )

  // 라이프 사이클 훅

  return {
    cartData,
    cartCount,
    buyCartData,
    getCartData,
    adjustQuantity,
    removeItemFromCart,
    calculator,
    cartMaker,
    payUserInfo,
    cartDbHandller,
    island,
    islandPrice,
    islandPriceSetup,
    freeShipAmmount,
    usePoint,
    buyPriceInfos,
    addCompanyData
  }
})
