<template>
  <div
    class="border rounded-lg lg:rounded-md w-full h-[150px] md:h-[162px] lg:w-auto bg-white"
    :class="`${defaultColorPicker}`"
  >
    <!-- 헤드  -->
    <section
      class="flex justify-between items-center md:mt-[13px] ml-[13px] mr-[15px] md:ml-[20px] md:mr-[18px] h-[42px] md:h-auto"
    >
      <div class="flex items-center">
        <img
          src="/img/mypage/mapPoint.webp"
          alt=""
          class="w-[18px] h-[18px] lg:w-[23px] lg:h-[23px]"
        />
        <p
          class="ml-[11px] md:ml-[14px] text-[14px] md:text-[16px] font-notosansCJKmd text-[#232323]"
        >
          {{ address.title }}
        </p>
        <p
          v-if="defaultAddr"
          class="mt-[1px] md:mt-[2px] ml-[15px] md:ml-[11px] text-[13px] md:text-[14px] font-notosansCJKmd text-[#389033]"
        >
          기본 배송지
        </p>
      </div>
    </section>
    <hr class="md:hidden border-1 border-[#D9D9D9]" />
    <section class="ml-[13px] mr-[15px] md:ml-[25px] md:mr-[18px] h-auto">
      <div class="md:flex items-end justify-between">
        <!-- 주소 내역 -->
        <!-- <div v-for="(addrText, ti) in address" :key="'addrText' + ti"></div> -->
        <div class="mt-[14.5px] md:mt-[15px] h-[94px] md:h-auto">
          <p class="text-[13px] md:text-[16px] text-[#232323] font-notosansCJKmd">
            {{ address.name }}
          </p>
          <div class="text-[13px] md:text-[16px] text-[#6D6D6D]">
            <p class="font-notosansCJR mt-[3px] md:mt-0">{{ address.mobile }}</p>
            <p class="font-notosansCJR mt-[3px] md:mt-0 leading-tight">{{ address.address }}</p>
            <p class="font-notosansCJR -mt-[2px] md:-mt-[4px]">{{ address.addressDetail }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useAddressStore } from '../../../../stores/useAddress'
import { useSystemStore } from '../../../../stores/useSystem'
import { useOrderStore } from '../../../../stores/userOrder'
import { ref, toRef, watchEffect } from 'vue'
//setup
const addressStore = useAddressStore()
const system = useSystemStore()
const order = useOrderStore()
const props = defineProps({
  address: Object,
  defaultAddr: Boolean,
  nowIndex: Number
})

//setter
const defaultColorPicker = ref('border-[#6D6D6D]')
const opcitymng = ref('opacity-100')

// props.defaultAddr를 참조로 변환
const isDefaultAddr = toRef(props, 'defaultAddr')
const address = toRef(props, 'address')
const nowIndex = toRef(props, 'nowIndex')

// action
const editAddrModalOpen = () => {
  system.myAddrModal = true
  addressStore.addressEdit = true
  addressStore.patchAddrindex = nowIndex.value
}

const reloader = async (result) => {
  if (result === 'ok') {
    // location.reload()
    await addressStore.getAddressByUser()
  }
  return
}

const setToDefault = async () => {
  // (cmd, data, index, defaultIndex)
  const conSetDef = confirm('해당 주소를 기본 배송지로 설정합니다.')
  if (!conSetDef) {
    return
  }
  const result = await addressStore.patchRenew('defaultSet', null, null, nowIndex.value)
  reloader(result)
  alert('기본 배송지가 변경 되었습니다.')
}

const delSingleAddr = async () => {
  const conDelOne = confirm('선택하신 배송지를 삭제 합니다.')
  if (!conDelOne) {
    return
  }
  const result = await addressStore.patchRenew('singleDel', null, nowIndex.value)
  reloader(result)

  alert('선택하신 배송지가 삭제 되었습니다.')
}

//lifeCycle
watchEffect(() => {
  defaultColorPicker.value = isDefaultAddr.value ? 'border-[#389033]' : 'border-[#6D6D6D]'
  opcitymng.value = isDefaultAddr.value ? 'opacity-0' : 'opacity-100'
})
</script>

<style lang="scss" scoped></style>
