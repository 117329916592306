<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div
      class="w-[365px] md:w-[800px] h-auto max-h-[80vh] bg-white rounded-xl overflow-y-clip hide-scrollbar"
    >
      <!-- 공통헤더 -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[24px] mb-[13px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          {{ request }} 신청
        </p>
        <button class="absolute right-[15px] md:right-[31px]">
          <i
            class="pi pi-times text-[15px] md:text-[19px] text-[#A8A8A8]"
            @click="system.refundModal = false"
          ></i>
        </button>
      </div>
      <hr class="mx-2" />
      <section class="md:hidden">
        <div>
          <section class="text-center mt-[17px] mb-[12px]">
            <div class="">
              <p class="text-[12px] font-notosansCJKmd text-[#232323]">
                {{ request }}하실 상품을 선택해주세요
              </p>
            </div>
          </section>
          <section class="" :class="scrollClass">
            <div
              v-for="(item, index) in order.refundListByOdn"
              :key="'orderDetail' + index"
              class="mb-[11px] cursor-pointer"
              @click="toggleSelection(item)"
            >
              <!-- <div
              v-for="(item, index) in order.refundListByOdn"
              :key="'orderDetail' + index"
              class="mb-[11px] cursor-pointer"
              @click="toggleSelection(item)"
            > -->
              <!-- 선택된 상태에 따라 클래스를 적용 -->
              <modalOrderCard
                :item="item"
                :style="isSelected(item) ? selectedClass : noneSelectedClass"
              />
            </div>
          </section>
          <!-- stage 0 = 취소사유 선택 -->
          <section class="text-center mt-[17.5px] mb-[8.5px] mx-5">
            <div>
              <p v-if="stage === 0" class="text-[12px] font-notosansCJKmd text-[#232323] mb-[20px]">
                {{ request }} 사유를 선택해주세요
              </p>
              <p v-if="stage === 1" class="text-[12px] font-notosansCJKmd text-[#232323] mb-[20px]">
                {{ request }} 방법을 선택하세요
              </p>
            </div>
            <div v-if="stage === 0">
              <div v-for="(select, ex) in selecteData" :key="ex" class="mb-[9px]">
                <button
                  v-if="
                    select.request === 'common' || select.request === order.orderFilterData.request
                  "
                  class="h-[33px] w-full border border-[#D5D5D5] rounded-md"
                  :class="`${select.class}`"
                  @click="selectBoxCommander(ex)"
                >
                  <p class="text-[11px] font-notosansCJR text-[#1C1C1C]">
                    {{ select.title }}
                  </p>
                </button>
              </div>
              <div v-if="selectedReason" class="mt-[17px]">
                <p
                  v-if="selectedReason.value === 'customer'"
                  class="text-[10px] font-notosansCJR text-[#6D6D6D]"
                >
                  배송비 구매자 부담
                </p>
                <p v-else class="text-[10px] font-notosansCJR text-[#6D6D6D]">배송비 판매자 부담</p>
              </div>
            </div>
          </section>
          <!-- stage 1 = 반품/교환 택배편 선택 -->
          <section v-if="stage === 1" class="">
            <selectRefundMethod />
          </section>
          <!-- stage 2 = 반품/교환 가격계산 및 신청완료 -->
          <section>
            <refundPriceCalc v-if="stage === 2" :request="request" />
          </section>
          <section class="">
            <hr class="mx-3" />
            <div class="flex justify-center mb-[16px] mt-[8.3px]">
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#6D6D6D] rounded-lg md:rounded-md"
                @click="system.refundModal = false"
              >
                <p class="text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd">취소하기</p>
              </button>
              <button
                class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border bg-[#389033] rounded-lg md:rounded-md ml-[15px] md:ml-[40px]"
                @click="stageController()"
              >
                <p
                  v-if="!stage === 2"
                  class="text-[13px] md:text-[18px] text-white font-notosansCJKmd"
                >
                  다음단계
                </p>
                <p v-else class="text-[13px] md:text-[18px] text-white font-notosansCJKmd">
                  신청완료
                </p>
              </button>
            </div>
          </section>
        </div>
      </section>
      <section class="hidden md:block">
        <div class="text-center mt-[32px] mb-[25px]">
          <p class="text-[18px] font-notosansCJKmd text-[#232323]">
            {{ request }}하실 상품을 선택해주세요
          </p>
        </div>
        <section class="mx-[32px]" :class="scrollClass">
          <div
            v-for="(item, index) in order.refundListByOdn"
            :key="'orderDetail' + index"
            class="mb-[11px] cursor-pointer"
            @click="toggleSelection(item)"
          >
            <!-- <div
            v-for="(item, index) in order.refundListByOdn"
            :key="'orderDetail' + index"
            class="mb-[11px] cursor-pointer"
            @click="toggleSelection(item)"
          > -->
            <!-- 선택된 상태에 따라 클래스를 적용 -->
            <modalOrderCard
              :item="item"
              :style="isSelected(item) ? selectedClass : noneSelectedClass"
            />
          </div>
        </section>
        <!-- satage {{ stage }} -->
        <!-- stage 0 = 취소사유 선택 -->
        <section class="text-center mt-[32px] mb-[25px]">
          <div>
            <p v-if="stage === 0" class="text-[18px] font-notosansCJKmd text-[#232323] mb-[20px]">
              {{ request }} 사유를 선택해주세요
            </p>
            <p v-if="stage === 1" class="text-[18px] font-notosansCJKmd text-[#232323] mb-[20px]">
              {{ request }} 방법을 선택하세요
            </p>
          </div>
          <div v-if="stage === 0">
            <div v-for="(select, ex) in selecteData" :key="ex" class="mb-[9px]">
              <button
                v-if="
                  select.request === 'common' || select.request === order.orderFilterData.request
                "
                class="w-[439px] h-[48px] border border-[#D5D5D5] rounded-md"
                :class="`${select.class}`"
                @click="selectBoxCommander(ex)"
              >
                <p class="text-[16px] font-notosansCJR text-[#1C1C1C]">
                  {{ select.title }}
                </p>
              </button>
            </div>
            <div v-if="selectedReason" class="mt-[17px]">
              <p
                v-if="selectedReason.value === 'customer'"
                class="text-[14px] font-notosansCJR text-[#6D6D6D]"
              >
                배송비 구매자 부담
              </p>
              <p v-else class="text-[14px] font-notosansCJR text-[#6D6D6D]">배송비 판매자 부담</p>
            </div>
          </div>
        </section>
        <!-- stage 1 = 반품/교환 택배편 선택 -->
        <section v-if="stage === 1" class="flex justify-center">
          <selectRefundMethod />
        </section>
        <!-- stage 2 = 반품/교환 가격계산 및 신청완료 -->
        <section>
          <refundPriceCalc v-if="stage === 2" :request="request" />
        </section>
        <hr class="mx-[1.5em]" />
        <!-- 버튼 제어 -->
        <div class="flex justify-center mb-[16px] mt-[16.5px]">
          <button
            class="w-[310px] h-[51px] border border-[#D5D5D5] rounded-md text-[18px] text-[#1C1C1C] font-notosansCJKmd"
            @click="system.refundModal = false"
          >
            취소하기
          </button>
          <button
            class="w-[310px] h-[51px] bg-[#389033] border rounded-md text-[18px] text-white font-notosansCJKmd ml-[40px]"
            @click="stageController()"
          >
            다음단계
          </button>
        </div>
      </section>
      <!-- 가운데 뭔들 로직 -->
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'

import { useOrderStore } from '../../../../stores/userOrder'
import { useSystemStore } from '../../../../stores/useSystem'
import modalOrderCard from '../modalOrderCard.vue'
import selectRefundMethod from './selectRefundMethod.vue'
import refundPriceCalc from './refundPriceCalc.vue'
import { payment } from '../../../../utils/purchase'
import { useUserStore } from '../../../../stores/useUser'
//setup
const order = useOrderStore()
const system = useSystemStore()
const user = useUserStore()
// payment(user.me)

//setter
const pageReady = ref(false)
const stage = ref(0)
const request = ref('')

watchEffect(() => {
  if (order.orderFilterData.request === 'refund') {
    request.value = '반품'
  } else if (order.orderFilterData.request === 'exchange') {
    request.value = '교환'
  }
})

const selecteData = ref([
  {
    title: '주문 실수',
    value: 'customer',
    request: 'common'
  },
  {
    title: '옵션 (컬러/사이즈 등) 잘못 선택',
    value: 'customer',
    request: 'common'
  },
  {
    title: '상품이 마음에 들지 않음',
    value: 'customer',
    request: 'refund'
  },
  {
    title: '오배송 / 상품 불량',
    value: 'company',
    request: 'exchange'
  },
  {
    title: '상품 불량 및 파손',
    value: 'company',
    request: 'refund'
  },
  {
    title: '다른 상품이 배송됨',
    value: 'company',
    request: 'refund'
  },
  {
    title: '상품이 도착하지 않음',
    value: 'company',
    request: 'common'
  },
  {
    title: '상품이 파손되어 도착',
    value: 'company',
    request: 'exchange'
  }
])

const selectedReason = ref()

const selectBoxCommander = (i) => {
  selectedReason.value = selecteData.value[i]
  selecteData.value.forEach((selData) => {
    selData.class = ''
  })
  // selecteData.value = resetSelData
  selecteData.value[i].class = 'border-[1px] border-green-700'
}

const selectedItems = ref([])

const noneSelectedClass = ref({
  border: '',
  text: ''
})

const selectedClass = ref({
  border: 'border-[1px] border-green-700',
  text: 'text-green-700'
})

// 선택 상태를 토글하는 함수
const toggleSelection = (item) => {
  const itemIndex = selectedItems.value.findIndex(
    (selected) =>
      selected.orderNumber === item.orderNumber &&
      selected.orderDetailNumber === item.orderDetailNumber
  )

  if (itemIndex === -1) {
    // 선택되지 않았으면 추가
    selectedItems.value.push({
      orderNumber: item.orderNumber,
      orderDetailNumber: item.orderDetailNumber
    })
  } else {
    // 이미 선택되어 있으면 제거
    selectedItems.value.splice(itemIndex, 1)
  }
}

// 아이템이 선택되었는지 확인하는 함수
const isSelected = (item) => {
  return selectedItems.value.some(
    (selected) =>
      selected.orderNumber === item.orderNumber &&
      selected.orderDetailNumber === item.orderDetailNumber
  )
}

const scrollClass = ref('')

//이제 남은건
//  stage0.selectedItems 만큼 패쳐 넣고 반복 해주고
//  fullRefund 일때 환불비 밀어 넣어 주면됨
// 나머지는 환불 금액만 밀어 넣어주면 되고
const refundCalls = async (status) => {
  // console.log('반품 요청 시작')

  // 반품 데이터 취합
  const refundData = {
    refundValue: order.refundDats.stage0.selectedReason.value,
    refundReason: order.refundDats.stage0.selectedReason.title,
    refundDate: system.serverTime,
    refundAmmount: 0,
    refundShippingAmmount: 0, // 기본값 설정
    refundPoints: 0, // 기본값 설정
    refundDetails: order.refundDats.stage1.refundDetaild,
    refundMethod: order.refundDats.stage1.refundMethod,
    refundPaymethod: order.refundDats.stage2.refundPricePayMethod
  }

  for (let i = 0; i < order.refundDats.stage0.selectedItems.length; i++) {
    const item = order.refundDats.stage0.selectedItems[i]

    // patcher 기본 설정
    let patcher = {
      orderNumber: item.orderNumber,
      orderDetailNumber: item.orderDetailNumber,
      refundData: { ...refundData }, // 새로운 refundData 객체 복사
      status: status
    }

    // order.refundListByOdn에서 해당 orderDetailNumber를 찾음
    const findDetailData = order.refundListByOdn.find(
      (details) => details.orderDetailNumber === patcher.orderDetailNumber
    )

    // 찾은 데이터가 있을 경우, 해당 항목의 가격 정보 등 설정
    // 찾은 데이터가 있을 경우, 해당 항목의 가격 정보 등 설정
    if (findDetailData) {
      patcher.refundData.refundAmmount = findDetailData.totalPrice
      // switch (refundData.refundValue) {
      //   // 회사 과실일 경우
      //   case 'company':
      //     patcher.refundData.refundAmmount = findDetailData.totalPrice // 전체 가격 환불
      //     break

      //   // 고객 과실일 경우
      //   case 'customer':
      //     // 'calcToRefund' 방식일 때
      //     if (patcher.refundData.refundPaymethod === 'calcToRefund') {
      //       patcher.refundData.refundAmmount = findDetailData.totalPrice // 배송비 포함
      //     } else {
      //       // 다른 방식일 때는 배송비 제외
      //       patcher.refundData.refundAmmount = findDetailData.totalPrice
      //     }
      //     break

      //   default:
      //     break
      // }
    }

    // 전체 환불일 경우 마지막 항목에만 반품비와 포인트 환불비 추가
    if (order.refundDats.fullRefund && i === order.refundDats.stage0.selectedItems.length - 1) {
      // 마지막 항목에만 추가 금액 적용
      console.log('마지막 상품', i)

      if (patcher.refundData.refundPaymethod === 'calcToRefund' && request.value === '반품') {
        patcher.refundData.refundShippingAmmount = order.refundDats.stage2.chargePrice // 마지막 항목에만 반품비 추가
      }

      if (request.value === '교환') {
        patcher.refundData.refundShippingAmmount = order.refundDats.stage2.chargePrice
      }

      patcher.refundData.refundPoints = order.orderDetailData.orderData.priceInfo.pointToUse // 마지막 항목에 포인트 환불 추가
    }
    console.log(patcher)

    // return
    await order.patchRefundAndEx(patcher)
  }
}

const requestChangeStatus = async () => {
  // staus 변경하고 부가 자료 넣어주면 끝
  // order만 쿼리
  // 여러개 일때는 cancle모달 참조 여러개 해야됨
  // shipping 참조 shippingInfo대신에 order.refundData넣어 주고 받은데서 쿼리 하면 끝

  let status = ''
  if (order.refundDats.request === '교환') {
    status = 'exchange'
  } else if (order.refundDats.request === '반품') {
    status = 'refund'
  }

  await refundCalls(status)
  // return
  alert('신청이 완료 되었습니다')
  system.refundModal = false
  location.reload()
}

const stageController = () => {
  if (stage.value === 0) {
    if (selectedItems.value.length < 1) {
      alert(`${request.value} 하실 상품을 선택해 주세요`)
      return
    } else if (!selectedReason.value) {
      alert(`${request.value} 사유를 선택해 주세요`)
      return
    }
    stage.value = 1
    return
  }
  if (stage.value === 1) {
    if (order.refundDats.stage1.refundMethod === '') {
      alert(`${request.value} 방법을 선택해 주세요`)
      return
    }
    stage.value = 2
    return
  }
  if (stage.value === 2) {
    if (selectedItems.value.length < 1) {
      alert(`${request.value} 하실 상품을 선택해 주세요`)
      return
    }
    if (
      order.refundDats.stage0.selectedReason.value !== 'company' &&
      order.refundDats.stage2.refundPricePayMethod === ''
    ) {
      alert(`${request.value} 금액 정산 방법을 선택해 주세요.`)
      return
    }
    requestChangeStatus()
  }
}
onMounted(() => {
  pageReady.value = true
})

watchEffect(() => {
  if (selectedItems.value) {
    order.refundDats.stage0.selectedItems = selectedItems.value
  }
  if (selectedReason.value) {
    order.refundDats.stage0.selectedReason = selectedReason.value
  }
})

onUnmounted(() => {
  order.orderFilterData = {
    on: '',
    odn: '',
    company: '',
    shipMethod: '',
    request: ''
  }
  order.refundDats = {
    stage0: {
      selectedItems: {},
      selectedReason: {}
    },
    stage1: {
      refundMethod: '',
      refundDetaild: {}
    },
    stage2: {
      refundPricePayMethod: ''
    }
  }
})
</script>

<style lang="scss" scoped>
/* 모달 내부에 적용할 스타일 */
.hide-scrollbar {
  overflow-y: scroll; /* 세로 스크롤은 가능하지만 */
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
</style>
