<template>
  <div v-if="user">
    <div class="hidden lg:inline-block lg:h-[102px] bg-[#f8f8f8] rounded-2xl w-full">
      <!-- 유저 정보 패널 -->
      <div class="lg:ml-[25px] lg:mr-[22px] lg:mt-[16px]">
        <div class="">
          <p v-if="user.me.name" class="lg:text-[26px] font-notosansCJKmd text-[#389033]">
            {{ user.me.name || 'USER_NAME' }}
          </p>
        </div>
        <div class="flex justify-between items-center">
          <p class="lg:text-[18px] font-notosansCJKmd text-[#171717]">고객님 안녕하세요!</p>
          <div class="flex items-center">
            <p class="lg:text-[14px] font-notosansCJKmd text-[#6D6D6D]">보유 포인트</p>
            <p class="lg:ml-[14px] lg:text-[18px] font-notosansCJKmd text-[#171717]">
              {{ user.me && user.me.point ? user.me.point.toLocaleString('ko-KR') : '0' }}p
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 모바일? -->
    <div class="lg:hidden">
      <section class="rounded-lg bg-[#FBFBFB] mx-1 mt-[13.5px]">
        <div v-if="user.me.name" class="ml-[8.5px] pt-[19px]">
          <p class="text-[12px] ml-[8px] text-[#171717] font-notosansCJKmd">
            <span class="mr-[8px] text-[15px] font-notosansCJKmd text-[#389033]">
              {{ user.me.name || 'USER_NAME' }}</span
            >고객님 안녕하세요!
          </p>
          <hr class="w-[162px] border border-[#D9D9D9]" />
        </div>
        <div class="flex justify-end mr-[8px] pb-[12px] mt-[19.5px]">
          <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">보유 포인트</p>
          <p class="ml-[23px] text-[12px] font-notosansCJKmd text-[#171717]">
            {{ user.me && user.me.point ? user.me.point.toLocaleString('ko-KR') : '0' }}p
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from '../../stores/useUser'
const user = useUserStore()
</script>

<style lang="scss" scoped></style>
