import { defineStore } from 'pinia'
import { ref, watchEffect } from 'vue'
import { useCartStore } from './useCart'
import { sendRequest } from '../api/serviceApi'
import axios from 'axios'

export const usePasswordStore = defineStore(
  'password',
  () => {
    // setter
    const nowPassword = ref('')
    const validateEmail = ref(false)
    const validatePassword = ref(false)
    const emailAuth = ref(false)
    const sendSms = ref(false)
    const smsAuth = ref(false)
    const passStage = ref(0)
    const passData = ref({
      stage0: {
        emaill: '',
        name: ''
      },
      stage1: {
        mobile: '',
        otp: ''
      },
      stage2: {
        password: '',
        passwordCheck: ''
      }
    })
    //getter

    //action

    return {
      // setter
      validateEmail,
      emailAuth,
      sendSms,
      smsAuth,
      passStage,
      passData,
      nowPassword,
      validatePassword
      //getter
      //action
    }
  },
  {
    // persist: true
  }
)
