<template>
  <div v-if="pageReady">
    <div class="lg:w-[405px] mx-auto lg:mt-[91px] flex justify-center items-center text-[21px]">
      <!-- 이전 버튼 -->
      <button
        v-if="nowPage > 0"
        class="ml-[19px] mr-[29px] flex items-center"
        @click="movePage('prev')"
      >
        <i class="pi pi-angle-left" style="font-size: 1rem"></i>
        <p class="ml-[12px] font-notosansCJKmd text-[15px]">이전</p>
      </button>

      <!-- 페이지 번호 -->
      <div class="flex space-x-4">
        <div
          v-for="item in visiblePages"
          :key="item"
          class="flex items-center"
          @click="movePage('goto', item)"
        >
          <button v-if="nowPage === item" class="text-[#D23F3F]">
            <p class="text-[17px] font-notosansCJKmd">{{ item + 1 }}</p>
          </button>
          <button v-else>
            <p class="text-[17px] font-notosansCJKmd">{{ item + 1 }}</p>
          </button>
        </div>
      </div>

      <!-- 다음 버튼 -->
      <button v-if="nowPage < totalPage - 1" class="mr-[19px] ml-[29px]" @click="movePage('next')">
        <div class="flex items-center">
          <p class="mr-[12px] font-notosansCJKmd text-[15px]">다음</p>
          <i class="pi pi-angle-right" style="font-size: 1rem"></i>
        </div>
      </button>
    </div>
  </div>
</template>
<script setup>
import 'primeicons/primeicons.css'
import { computed, watchEffect, onMounted, ref, onUnmounted, watch } from 'vue'

const pageReady = ref(false)

// 페이징 관련
const slicePerPage = ref(2)
const totalPage = ref(0)
// const presentCounter = ref(12)
const nowPage = ref(0)
const displayCount = ref(5)

const isMobile = ref(false)
const breakpoint = ref(1024)
const pageData = ref(null)

// emit
const emit = defineEmits(['updateData'])

const sendData = () => {
  emit('updateData', pageData.value)
}

watchEffect(() => {
  sendData()
})

//페이지 데이터 임포트
const props = defineProps({
  data: Array,
  numberOfData: Number
})

const checkViewport = () => {
  isMobile.value = window.innerWidth < breakpoint.value
}

watchEffect(() => {
  if (checkViewport) {
    displayCount.value = isMobile.value ? 3 : 5
  }
})

onMounted(() => {
  window.addEventListener('resize', checkViewport) // 뷰포트 크기 변경 시 체크
  checkViewport()
  displayCount.value = isMobile.value ? 3 : 5
  pageReady.value = true
})

onUnmounted(() => {
  window.removeEventListener('resize', checkViewport) // 컴포넌트 언마운트 시 이벤트 리스너 제거
})

const visiblePages = computed(() => {
  let startPage = Math.max(0, nowPage.value - Math.floor(displayCount.value / 2))
  startPage = Math.min(startPage, Math.max(0, totalPage.value - displayCount.value))
  return Array.from(
    { length: Math.min(displayCount.value, totalPage.value) },
    (_, i) => startPage + i
  )
})

const updatePageData = () => {
  const start = nowPage.value * slicePerPage.value
  const end = start + slicePerPage.value
  pageData.value = props.data.slice(start, end)
}

const movePage = (action, page = 0, pageIndex) => {
  switch (action) {
    case 'first':
      nowPage.value = 0
      break
    case 'last':
      nowPage.value = totalPage.value - 1
      break
    case 'prev':
      nowPage.value = Math.max(0, nowPage.value - 1)
      break
    case 'next':
      nowPage.value = Math.min(totalPage.value - 1, nowPage.value + 1)
      break
    case 'goto':
      nowPage.value = page
      break
  }
  updatePageData()
}

watchEffect(async () => {
  if (pageReady.value === true) {
    const boardList = await props.data
    if (boardList) {
      totalPage.value = Math.ceil(boardList.length / slicePerPage.value)
      // console.log(totalPage.value)
      pageData.value = boardList.slice(0, slicePerPage.value)
    }
  }
  if (props.numberOfData) {
    slicePerPage.value = props.numberOfData
  }
})
</script>

<style lang="scss" scoped></style>
