<template>
  <div v-if="pageReady">
    <kakaoRegisterMain />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import kakaoRegisterMain from '../../../components/register/kakao/kakaoRegisterMain.vue'
import { useRoute, useRouter } from 'vue-router'
import { sendRequest } from '../../../api/serviceApi'
import { useRegisterStore } from '../../../stores/useRegister'
import { useUserStore } from '../../../stores/useUser'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import axios from 'axios'
const cookies = useCookies()

const user = useUserStore()
const router = useRouter()
const register = useRegisterStore()
const route = useRoute()

const kakaoEmail = ref()
const kakaoid = ref(0)

const naverUser = ref()
const tokenData = ref()
const pageReady = ref(false)

const setUpRegisterd = () => {
  register.registerStage.stage1.userInfo.email = kakaoEmail.value
  // register.registerStage.stage1.userInfo.name = naverUser.value.response.name
  register.naverToken = kakaoid.value
}

// 로그인 ---

const setAuthReady = ref(false)
// 백엔드에서도 토큰값 있으면 패스워드 무시하게 하자
const payload = ref({})

const setToAuth = async (token) => {
  try {
    const tokenCheck = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/getAuth`,
      {},
      {
        headers: {
          authentication: token
        }
      }
    )
    const setMeData = tokenCheck.data
    user.status = true
    user.me = setMeData
    user.auth = setMeData.auth
  } catch (e) {
    // console.error('Token validation failed:', e)
    user.status = false
    user.me = null
    user.auth = null
  }
}

const setAuth = async () => {
  const myJwt = cookies.get('yeondeul')
  if (myJwt) {
    user.token = myJwt
    await setToAuth(myJwt)
    const userReady = await user.status
    // alert(`${userReady}`)
    setAuthReady.value = userReady
  } else {
    console.warn('JWT token is missing.')
    return
  }
}

watch(setAuthReady, (newVal) => {
  if (newVal) {
    router.push('/')
  }
})

const fetchProduct = async () => {
  try {
    const getToken = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/loginUsers`,
      payload.value
    )
    const token = getToken.data
    if (token.status === 400) {
      alert(token.message)
    } else if (token.status === 200) {
      // cookies.set('yeondeul', token.message, { maxAge: 100 * 180 })
      cookies.set('yeondeul', token.message, {
        path: '/', // 사이트 전체에서 유효
        maxAge: 60 * 60 * 1000, // 1시간 동안 유효
        secure: true, // HTTPS에서만 전송
        sameSite: 'Strict' // CSRF 보호
      })
      // alert('연들에 오신 것을 환영합니다.')
      await setAuth() // setAuth가 완료된 후에 다음 로직을 실행하도록 await 추가
    }
  } catch (error) {
    console.error('Login failed:', error)
  }
}

const checkAuthfication = async () => {
  console.log('start auth matcher')
  const datas = {
    email: kakaoEmail.value,
    auth: kakaoid.value
  }

  console.log('data', datas)
  const matchAuth = await sendRequest({
    method: 'post',
    path: '/users/userAuthMatcher',
    data: datas
  })
  const result = matchAuth.data
  console.log(result)
  return result
}

// --
const checkRegisterd = async (userEmail) => {
  const payload = {
    email: userEmail
  }
  const emailCheck = await sendRequest({
    method: 'post',
    path: '/users/emailChecker',
    data: payload
  })
  const result = emailCheck.data
  console.log(result)
  if (result === 'bad') {
    const checkAuth = await checkAuthfication() // 가입된 3자 id값 검사
    console.log('checkAuth', checkAuth)
    // 만일 서로다른 auth값이면
    // authmatch
    // missmatch
    if (checkAuth === 'missmatch') {
      alert(
        '카카오톡에 연동된 동일한 이메일 계정이 존재합니다. \n\n자세한 사항은 yeondeul_cs@ruruci.com으로 문의해 주세요.'
      )
      window.location.href = '/login'
      return
    } else if (checkAuth === 'authmatch') {
      fetchProduct()
    }
  } else if (result === 'isDelete') {
    alert('사용 불가능한 이메일 주소 입니다.')
    return (location.href = '/login')
  } else {
    setUpRegisterd()
    pageReady.value = true
  }
}

onMounted(() => {
  if (route.query) {
    kakaoEmail.value = route.query.user
    kakaoid.value = route.query.token
    register.vendor = 'kakao'

    payload.value = {
      email: kakaoEmail.value,
      naverAuth: kakaoid.value
    }
    const userEmail = kakaoEmail.value
    checkRegisterd(userEmail)
  }
})

onUnmounted(() => {
  // register.registerStage = {
  //   stage0: {
  //     sns: false,
  //     location: false,
  //     required: false
  //   },
  //   stage1: {
  //     userInfo: {
  //       email: '',
  //       password: '',
  //       passwordCheck: '',
  //       name: '',
  //       gender: '',
  //       birth: '',
  //       mobile: ''
  //     },
  //     smsAuth: false
  //   },
  //   stage2: {
  //     receiverInfo: {
  //       name: '',
  //       mobile: '',
  //       postcode: '',
  //       address: '',
  //       addressDetail: '',
  //       request: ''
  //     }
  //   }
  // }
  // register.sendSms = false
  // register.smsAuth = false
  // register.validateEmail = false
  // register.naverToken = ''
})
</script>

<style lang="scss" scoped></style>
