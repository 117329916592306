<template>
  <div v-if="pageReady" class="mr-[9px] lg:mr-0 lg:w-[806px]">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <p class="text-[14px] lg:text-[18px] font-notosansCJKbold text-[#3D3D3D] ml-[9px]">문의</p>
        <p class="text-[14px] lg:text-[16px] ml-[9px] font-notosansCJR text-[#D23F3F]">
          {{ inquiryCount }}
        </p>
      </div>
      <button
        class="w-[95px] h-[29px] border border-1 border-[#c9c9c9] rounded-md"
        @click="openModal()"
      >
        <p class="text-[15px] font-notosansCJKmd text-[#6D6D6D]">문의하기</p>
      </button>
    </div>
    <section class="ml-[11px] mt-[29px]">
      <div
        v-for="(inqutyData, inquryIndex) in childData"
        :key="'inqury' + inquryIndex"
        class="mb-[22.7px] lg:mb-[44px]"
      >
        <productInquiryCardVue class="" :inquirys="inqutyData" />
      </div>
    </section>
    <pagination :numberOfData="3" :data="inquirys" @update-data="handleData" />
    <p
      v-if="inquiryCount === 0"
      class="text-center text-[14px] lg:text-[16px] font-notosansCJKmd text-[#707070] pb-[50px]"
    >
      문의 내용이 없습니다
    </p>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from 'vue'
import { useSystemStore } from '../../../../stores/useSystem'
import { useUserStore } from '../../../../stores/useUser'
import productInquiryCardVue from './productInquiryCard.vue'
import pagination from '../../../util/pagination.vue'
import { sendRequest } from '../../../../api/serviceApi'
import { useProductStore } from '../../../../stores/useProduct'
const system = useSystemStore()
const user = useUserStore()
const product = useProductStore()
const childData = ref('')
const isNotInquiry = ref(false)

const getReportByProductIds = async () => {
  if (product.productItem === null) {
    isNotInquiry.value = true
    return
  }
  const productIds = product.productItem._id
  // console.log(productIds)

  const payload = {
    productId: productIds
  }
  const getData = await sendRequest({
    method: 'post',
    path: '/report/getReportsByProductId',
    data: payload
  })

  const result = getData.data
  if (result) {
    inquirys.value = result
    inquiryCount.value = result.length
    console.log(result)
  } else {
    return
  }
}

const openModal = () => {
  const auth = user.auth
  switch (auth) {
    case null:
      alert('문의하기는 로그인 후에 사용이 가능합니다.')
      break
    case 'user':
      system.inquiryModals = true
      break
    case 'admin':
      system.inquiryModals = true
      break
    // case 'admin':
    //   alert('유저만 사용 가능해요! 가서 답변이나 달아줘요! 일하라고요 일!')
    //   break
    default:
      break
  }
  // system.inquiryModals = true
}

const handleData = (data) => {
  childData.value = data
}

const pageReady = ref(false)

const inquirys = ref([
  // {
  //   userName: '사용자',
  //   createAt: '2023-07-05T07:35:41.344+0000',
  //   inquiryTitle: '공구 필요한가요?',
  //   inquiryDetails: '제품 구매하려고 하는데요. 따로 공구가 있어야만 설치가 가능한가요?',
  //   isAnswer: true,
  //   answerDetails:
  //     '안녕하세요 고객님! 연들을 이용해주셔서 감사드립니다.저희는 미관상 더 예쁘면서 더욱 튼튼하기도 한 논프라피스를 제공해드리고 있습니다. 제품 구성품에 들어있는 나사 (논프라피스)를 벽에 부착하기 위해서는 구멍을 뚫을 수 있는 해머드릴이 필요합니다. 일반적인 6mm가 아닌 3.5mm 구멍을 내기에 일반적인 12v 드릴로도 충분히 작업이 가능합니다. 공구가 없으신 경우에는 공구를 구매하시거나 시공 서비스를 이용하셔야 한다는 점 양해 부탁드립니다. 감사합니다.'
  // },
  // {
  //   userName: '익명4523',
  //   createAt: '2023-07-05T07:35:41.344+0000',
  //   inquiryTitle: '돈 주면 조립해줌?',
  //   inquiryDetails: '방문 서비스 안함?',
  //   isAnswer: false,
  //   answerDetails: ''
  // },
  // {
  //   userName: '어디에나있는어그로',
  //   createAt: '2023-07-05T07:35:41.344+0000',
  //   inquiryTitle: 'ㅎㅎㅎㅎㅎ',
  //   inquiryDetails: 'ㅎㅎㅎㅎㅎㅎㅎㅎㅎㅎㅎㅎ',
  //   isAnswer: false,
  //   answerDetails: ''
  // },
  // {
  //   userName: '사용자',
  //   createAt: '2023-07-05T07:35:41.344+0000',
  //   inquiryTitle: '무거운거 올려놓아도 되나요?',
  //   inquiryDetails: '세제통 같은것도 버티나요?',
  //   isAnswer: true,
  //   answerDetails:
  //     '안녕하세요 고객님! 연들을 이용해주셔서 감사드립니다. 정상적으로 시공이 된 경우, 해당 제품은 3kg 전후의 무게까지 버틸 수 있습니다. 사용에 참조 부탁드립니다. 감사합니다.'
  // },
  // {
  //   userName: '사용자',
  //   createAt: '2023-07-05T07:35:41.344+0000',
  //   inquiryTitle: '물 뭍어도 되나요?',
  //   inquiryDetails: '청소하거나 샤워할 때 물 튀어도 괜찮나요?',
  //   isAnswer: true,
  //   answerDetails:
  //     '안녕하세요 고객님! 연들을 이용해주셔서 감사드립니다. 제품 제작 후 코팅과정을 거치기 때문에 물이 어느정도 뭍어도 걱정없이 사용이 가능합니다. 다만 너무 습한 환경에 지속적으로 노출되거나 물에 오랜시간 젖어있을 경우 문제가 있을 수 있으니 가급적 마른 상태에서 사용을 권장합니다 :)'
  // }
])

const inquiryCount = ref(0)

onMounted(() => {
  pageReady.value = true
})

watchEffect(() => {
  if (pageReady.value === true) {
    getReportByProductIds()
  }
})
</script>

<style lang="scss" scoped></style>
