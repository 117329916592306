<template>
  <div>
    <div class="flex justify-center mt-[32.5px] md:mt-[91px]">
      <section>
        <div class="flex justify-center">
          <p class="hidden md:block text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
            비밀번호 변경
          </p>
          <p class="md:hidden text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
            새로운 비밀번호 입력
          </p>
        </div>
        <!-- pc -->
        <div class="hidden md:block mt-[20px] text-center">
          <p class="text-[16px] font-notosansCJR text-[#6D6D6D]">
            비밀번호는 암호화되어 저장되어 연들 고객센터에서도
          </p>
          <p class="text-[16px] font-notosansCJR text-[#6D6D6D]">
            기존 비밀번호를 확인해드릴 수 있는 방법이 없습니다.
          </p>
          <p class="text-[16px] font-notosansCJR text-[#6D6D6D] mt-[15px]">
            본인확인을 통해 새로운 비밀번호로 변경해드리도록 하겠습니다.
          </p>
        </div>
        <!-- mobile -->
        <section class="md:hidden">
          <div class="flex justify-center my-[17px]">
            <img src="/img/password/padlock.webp" alt="" />
          </div>
        </section>
      </section>
    </div>
    <!-- 패스워드 -->
    <div class="mt-[48.5px] md:mt-[91px] flex justify-center">
      <section class="">
        <p class="md:hidden mb-[19px] text-center text-[12px] font-notosansCJKmd text-[#232323]">
          새로운 비밀번호를 입력해주세요
        </p>
        <div>
          <p class="hidden md:block text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            새로운 비밀번호
            <span class="hidden ml-1 md:inline text-[13px] font-notosansCJR text-[#6D6D6D]"
              >영문, 숫자 조합의 8자 이상의 비밀번호를 입력해주세요</span
            >
          </p>
          <input
            type="password"
            class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            placeholder="비밀번호"
            v-model="password.passData.stage2.password"
          />
          <p v-if="passwordError.status" class="text-red-500 text-[11px] ml-2 mb-2">
            {{ passwordError.message }}
          </p>
          <input
            type="password"
            class="block border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            placeholder="비밀번호 확인"
            v-model="password.passData.stage2.passwordCheck"
          />
          <p v-if="passwordCheckError.status" class="text-red-500 text-[11px] ml-2 mb-2">
            {{ passwordCheckError.message }}
          </p>
        </div>
        <p class="md:hidden mt-[9px] text-center text-[10px] font-notosansCJR text-[#6D6D6D]">
          영문, 숫자 조합의 8자 이상의 비밀번호를 입력해주세요
        </p>
      </section>
    </div>
    <div class="mt-[56px] md:mt-[85px] mb-0 md:mb-[191px]">
      <section class="">
        <div class="flex justify-center">
          <button
            class="border border-[#389033] md:border-[#0C370A] w-[320px] md:w-[569px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033] bg-transparent"
            @click="resetPassword()"
          >
            비밀번호 변경
          </button>
        </div>
        <div class="hidden md:block mt-[31.5px]">
          <p class="text-[14px] font-notosansCJR text-[#D23F3F] mt-[8px] text-center">
            회원가입시 입력한 정보가 기억나지 않는다면 고객센터 (이메일, 전화 등)를 통해
            문의해주세요
          </p>
        </div>
        <section class="md:hidden text-center mt-[64.5px]">
          <div class="">
            <p class="text-[11px] font-notosansCJR text-[#D23F3F] mt-[8px] text-center">
              회원가입시 입력한 정보가 기억나지 않는다면
            </p>
            <p class="text-[11px] font-notosansCJR text-[#D23F3F] text-center">
              고객센터 (이메일, 전화 등)를 통해 문의해주세요
            </p>
          </div>
        </section>
        <section class="mt-[13.5px] md:mt-[9px]">
          <div class="flex justify-center">
            <p class="text-[14px] md:text-[16px] font-notosansCJKmd text-[#6D6D6D]">
              고객센터 가기
            </p>
          </div>
        </section>
      </section>
    </div>
    <div class="mt-[86px] md:hidden">
      <p class="text-center text-[10px] font-notosansCJR text-[#6D6D6D] my-[27px]">
        ⓒ ruruci.inc All rights reserved.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { sendRequest } from '../../../api/serviceApi'
import { usePasswordStore } from '../../../stores/usePassword'
const password = usePasswordStore()
const passwordError = ref({
  message: '',
  status: false
})

const passwordCheckError = ref({
  message: '',
  status: false
})

// 비밀번호 검증 함수
const validatePassword = async () => {
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
  if (!passwordPattern.test(password.passData.stage2.password)) {
    passwordError.value.message = '영문, 숫자 조합의 8자 이상의 비밀번호를 입력해주세요.'
    passwordError.value.status = true
  } else {
    passwordError.value.message = ''
    passwordError.value.status = false
  }
}

const validatePasswordCheck = async () => {
  const data = password.passData.stage2
  if (data.password !== data.passwordCheck) {
    passwordCheckError.value.message = '비밀번호가 일치하지 않습니다.'
    passwordCheckError.value.status = true
  } else {
    passwordCheckError.value.message = ''
    passwordCheckError.value.status = false
  }
}

// 비밀번호 입력 감시
watchEffect(() => {
  if (password.passData.stage2.password) {
    validatePassword()
  }
})

watchEffect(() => {
  if (password.passData.stage2.passwordCheck) {
    validatePasswordCheck()
  }
})

const resetPassword = async () => {
  if (passwordError.value.status || passwordCheckError.value.status) {
    alert('형식에 맞게 비밀번호를 입력해 주세요.')
    return
  }
  const payload = {
    email: password.passData.stage0.emaill,
    password: password.passData.stage2.password
  }
  const patchNewPass = await sendRequest({
    method: 'patch',
    path: '/users/resetPassword',
    data: payload
  })
  const result = patchNewPass.data
  if (!result) {
    alert(
      '비밀번호 변경에 실패 했습니다. 자세한 사항은 고객센터에 문의 바랍니다. 불편을 드려 대단히 죄송합니다.'
    )
    return
  } else {
    password.passStage = 3
  }
}
</script>

<style lang="scss" scoped></style>
