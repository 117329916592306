<template>
  <div>
    <div class="">
      <p class="ml-[12px] lg:ml-[10px] text-[17px] lg:text-[20px] font-notosansCJKmd">
        이런 상품은 어떠세요?
      </p>
      <div class="lg:ml-[21px]">
        <homeSellNew class="" :data="combineProduct.recoment" :isRecomnd="true" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { sendRequest } from '../../api/serviceApi'
import homeSellNew from '../home/homeSellNew.vue'

const combineProduct = ref({
  recoment: {
    tag: 'Pick',
    title: '트렌드',
    item: []
  }
})

const bestItem = ref(null)

const getBestProduct = async () => {
  const getBestDatas = await sendRequest({ method: 'post', path: '/product/getBestProduct' })
  const results = getBestDatas.result
  if (results === 'ok') {
    combineProduct.value.recoment.item = getBestDatas.data
  } else {
    return
  }
}

onMounted(() => {
  getBestProduct()
})
</script>

<style lang="scss" scoped></style>
