<template>
  <div>
    <div class="flex justify-center mt-[15.5px] md:mt-[46px]">
      <section>
        <div class="flex justify-center">
          <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">회원가입 완료</p>
        </div>
      </section>
    </div>
    <section>
      <div class="flex justify-center">
        <div
          class="md:border md:border-[#D5D5D5] md:rounded-xl w-auto md:w-[737px] mt-[132px] md:mt-[46px] mb-0 md:mb-[298px]"
        >
          <div class="flex justify-center">
            <!-- pc이미지 -->
            <img
              src="/img/register/shooting-star-big.webp"
              alt="bigStart"
              class="hidden md:block mt-[56px] w-[96px] h-[96px]"
            />
            <!-- 모바일 이미지 -->
            <img
              src="/img/register/shooting-star.webp"
              alt="shootingStar"
              class="md:hidden w-[50px] h-[50px]"
            />
          </div>
          <div class="mt-[17px] md:mt-[51px]">
            <p class="text-[14px] md:text-[26px] font-notosansCJKmd text-[#389033] text-center">
              연들 가족이 되신 것을 환영합니다 <span class="md:hidden">:)</span>
            </p>
            <p
              class="mt-[5.8px] md:mt-[19px] text-[12px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
            >
              {{ register.registerStage?.stage1?.userInfo?.name || 'USER_NAME' }} 고객님께 최선을
              다하겠습니다
            </p>
          </div>
          <div class="flex justify-center mt-[210px] md:mt-[84px] mb-[139px] md:mb-[106px]">
            <router-link to="/">
              <button
                class="w-[320px] md:w-[247px] h-[43px] md:h-[56px] border rounded-lg md:rounded-md border-[#D5D5D5]"
              >
                <p class="text-[15px] md:text-[18px] font-notosansCJKmd text-[#6D6D6D]">홈으로</p>
              </button>
            </router-link>
          </div>
          <p class="md:hidden text-center text-[10px] font-notosansCJR text-[#6D6D6D] my-[27px]">
            ⓒ ruruci.inc All rights reserved.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useSystemStore } from '../../stores/useSystem'
import { useRegisterStore } from '../../stores/useRegister'
import { useUserStore } from '../../stores/useUser'

const system = useSystemStore()
const register = useRegisterStore()

const clentName = ref('고객명')
</script>

<style lang="scss" scoped></style>
