<template>
  <div v-if="pageReady">
    <div class="flex">
      <img
        :src="data.img"
        alt=""
        class="w-[100px] h-[100px] rounded-lg border bg-cover bg-center bg-no-repeat"
      />
      <div class="ml-2">
        <p>
          [ {{ data.category.parent.catName }} <span v-if="data.category.child.catName">- </span
          >{{ data.category.child.catName }} ]
        </p>
        <p>{{ data.title }}</p>
        <p>{{ data.price.toLocaleString('ko-KR') || 'null' }} 원</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, ref, onMounted } from 'vue'

const pageReady = ref(false)
const props = defineProps({
  data: Object
})

watchEffect(() => {
  if (props.data) {
    pageReady.value = true
  }
})
</script>

<style lang="scss" scoped></style>
