<template>
  <div class="rounded-md shadow-lg w-[192px] h-auto">
    <div class="pt-[16px] pb-[7px]">
      <div
        v-for="productList in filterData"
        :key="productList"
        class="flex items-center pb-[9px] pl-[10px]"
      >
        <input
          type="checkbox"
          class="w-[18px] h-[18px] accent-[#389033] border-none"
          :value="productList"
          v-model="productCheck"
        /><label
          :for="productList"
          class="ml-[10px] font-notosansCJKmd text-[#7D7D7D] text-[15px] pt-[0.1px]"
          >{{ productList }}</label
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect } from 'vue'
import { useProductStore } from '../../../stores/useProduct'
const product = useProductStore()

const props = defineProps({
  filterData: Array
})

watchEffect(() => {
  if (product.filterList.productFilter.check) {
    product.filterList.productFilter.class = 'border border-[#389033] text-[#389033] w-[109px]'
  } else {
    product.filterList.productFilter.class = 'w-[109px] text-[#808080]'
  }
})

const productCheck = ref([])
watch(
  productCheck,
  (newChecked) => {
    product.filterList.productFilter.check = newChecked.length > 0
    product.filterList.productFilter.list = newChecked
  },
  { deep: true }
)

onMounted(() => {
  productCheck.value = product.filterList.productFilter.list
})
</script>

<style lang="scss" scoped></style>
