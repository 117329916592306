<template>
  <div class="bg-white w-[450px] mt-3 z-40 border shadow-md rounded-md">
    <div class="flex justify-center mt-3">
      <button class="" @click.stop="delets()">취소</button>
    </div>

    <div class="mx-3 pt-3 flex justify-center">
      <input
        type="text"
        placeholder=""
        class="border w-[400px] h-[35px]"
        @click.stop
        v-model="keywords"
      />
    </div>
    <div class="my-3 mx-3 overflow-auto h-[250px]">
      <div v-if="productData !== null" class="text-center my-14">
        <p v-if="productData.length === 0">검색 결과가 존재하지 않습니다</p>
      </div>
      <div v-else class="text-center my-14">
        <p>제품을 검색해 보세요</p>
      </div>
      <div
        v-for="data in productData"
        :key="data._id"
        class="my-3 flex justify-between items-center"
      >
        <postSeachProductCard
          :data="data"
          class="mb-3 shadow-md w-full py-3"
          @click="selecteFunction(data)"
        />
        <!-- <button class="h-[30px] ml-3">선택</button> -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { sendRequest } from '../../../../api/serviceApi'
import postSeachProductCard from './postSearchProductCard.vue'
const productData = ref(null)
const keywords = ref('')
const selectDone = ref(false)
const selectedData = ref(null)

const props = defineProps({
  index: Number
})

// 요놈을 이밋합니다
// 선택을 마치면 데이터를 넘깁니다
// 데이터가 넘어가면 해당 드롭 다운을 닫아야 합니다

// 이밋 정의
const emit = defineEmits(['updateData', 'updateIndex'])

const sendClosed = () => {
  emit('updateData', {
    data: selectedData.value,
    done: selectDone.value,
    index: props.index
  })
}

const sendIndex = () => {
  if (props.index !== undefined) {
    emit('updateIndex', {
      index: props.index,
      isDelete: true
    })
  }
}

const selecteFunction = (data) => {
  selectedData.value = data
  selectDone.value = true
}

const getProduct = async () => {
  const payload = {
    keyword: keywords.value
  }
  if (keywords.value === '&nbsp') {
    return
  }
  const result = await sendRequest({
    method: 'post',
    path: '/product/productGetByKeyword',
    data: payload
  })
  if (result) {
    productData.value = result.data
  } else {
    console.error('error', result.message)
  }
}

const delets = () => {
  if (props.index !== undefined) {
    console.log('index send Done', props.index)
    sendIndex()
  }
}
watchEffect(() => {
  if (keywords.value) {
    setTimeout(() => {
      getProduct()
    }, 250)
  }
  if (selectDone.value === true) {
    sendClosed()
  }
})

// watchEffect(() => {
//   if (props.index !== undefined) {
//     console.log('index send Done', props.index)
//     sendIndex()
//   }
// })
</script>

<style lang="scss" scoped></style>
