<template>
  <div v-if="cartload" class="relative">
    <cartMobilePopmenu class="bottom-0 fixed lg:hidden" :types="types" />
    <!-- {{ cartload }} -->
    <!-- 상단 아이콘 -->
    <div class="mb-[19px] lg:mb-[29px]">
      <div
        v-if="!isOrder"
        class="flex justify-center items-center bg-[#FBFBFB] h-[48.5px] lg:h-auto lg:bg-transparent"
      >
        <i class="pi pi-shopping-cart text-[22px] lg:text-[37px] w-[37px] h-[37px] pt-1"> </i>
        <p
          class="text-[16px] lg:text-[26px] ml-1 lg:ml-[27px] text-[#171717] font-bold flex lg:mt-3"
        >
          장바구니
        </p>
      </div>
      <div
        v-else
        class="flex justify-center items-center bg-[#FBFBFB] h-[48.5px] lg:h-auto lg:bg-transparent"
      >
        <p
          class="text-[16px] lg:text-[26px] ml-1 lg:ml-[27px] text-[#171717] font-bold flex lg:mt-3"
        >
          주문/결제
        </p>
      </div>

      <div v-if="cart.cartCount === 0 && !types" class="text-center mt-[42px]">
        <section>
          <h3>{{ '장바구니에 선택한 상품이 존재 하지 않아요' }}</h3>
          <router-link :to="{ path: `/shop/wood`, query: { type: '원목가구' } }">
            <button
              class="border rounded-md border-gray-400 hover:border-white transition-colors mt-11"
              to="/shop"
            >
              <p
                class="text-[15px] font-notosansCJKmd text-gray-500 px-4 py-2 hover:bg-gray-300 hover:text-white transition-colors"
              >
                제품 둘러 보기
              </p>
            </button>
          </router-link>
        </section>
      </div>
    </div>
    <!-- 하단 장바구니 카드 -->
    <div v-if="isOrder || types === 'directCheckOut'" class="mt-[26px] lg:hidden">
      <checkoutMain />
    </div>
    <!-- 모바일 주문상품 문구 -->
    <div v-if="isOrder" class="border-b-[1.5px] border-[#6D6D6D] lg:hidden mb-[19.5px]">
      <p
        class="lg:hidden font-nanumsquareEB lg:font-notosansCJKmd text-[15px] lg:text-[22px] text-[#171717] mb-[6.5px] lg:mb-[12.5px] ml-[10px]"
      >
        주문상품
      </p>
    </div>
    <div v-if="cart.cartCount > 0 || types === 'directCheckOut'">
      <div v-for="(items, index) in cart.cartMaker" :key="'cartImte' + index" class="mb-[28px]">
        <cartContainer :data="items" :isOrder="isOrder" />
      </div>
    </div>
    <div v-if="cart.cartCount > 0 || types === 'directCheckOut'" class="w-full">
      <div>
        <div v-if="!isOrder" class="mt-[38.8px] ml-[15px] mb-[28px] hidden lg:block">
          <button
            class="w-[116px] h-[32.6px] text-[14px] font-bold border-[1px] border-solid border-[#0C370A] rounded-lg"
            @click="cartDbHandller('deleteAll')"
          >
            장바구니 비우기
          </button>
        </div>

        <div v-if="!isOrder" class="mt-[25px]">
          <cartPriceCounter />
        </div>
        <div v-else class="mt-[32px] lg:mt-[90px]">
          <!-- 모바일 결제 금액 문구 -->
          <section>
            <div class="border-b-[1.5px] border-[#6D6D6D] lg:hidden mb-[19.5px]">
              <p
                class="lg:hidden font-nanumsquareEB lg:font-notosansCJKmd text-[15px] lg:text-[22px] text-[#171717] mb-[6.5px] lg:mb-[12.5px] ml-[10px]"
              >
                결제금액
              </p>
            </div>
          </section>
          <cartPriceCounter class="-mt-[18px]" />
        </div>
        <!-- 모바일 결제 선택기  -->
        <section v-if="isOrder" class="lg:hidden mt-[26.4px]">
          <div class="border-b-[1.5px] border-[#6D6D6D] lg:hidden mb-[19.5px]">
            <p
              class="lg:hidden font-nanumsquareEB lg:font-notosansCJKmd text-[15px] lg:text-[22px] text-[#171717] mb-[6.5px] lg:mb-[12.5px] ml-[10px]"
            >
              결제방법
            </p>
          </div>
          <mobileCheckOutSelector />
        </section>
        <!-- 모바일 결제 안내사항 -->
        <section class="mt-[23.5px] mb-[20.3px] lg:hidden">
          <mobileCheckoutNoti />
        </section>
      </div>
      <div v-if="!isOrder" class="hidden lg:flex justify-end mt-[64px] mb-[280px]">
        <form @submit.prevent>
          <button
            class="w-[150px] h-[45px] lg:w-[199px] lg:h-[56px] border border-solid border-[#0C370A] bg-[#389033] rounded-lg text-white text-[20px] font-bold"
            @click="isLogin()"
          >
            구매하기
          </button>
        </form>
      </div>
      <div v-if="isOrder || types === 'directCheckOut'" class="mt-[81px] hidden lg:block">
        <checkoutMain />
      </div>
    </div>
  </div>
</template>

<script setup>
import checkoutMain from '../../components/checkout/checkoutMain.vue'
import cartContainer from './cartContainer.vue'
import cartPriceCounter from './cartPriceCounter.vue'
import mobileCheckOutSelector from '../checkout/mobileCheckOutSelector.vue'
import mobileCheckoutNoti from '../checkout/mobileCheckoutNoti.vue'
import { sendRequest } from '../../api/serviceApi'
import { useUserStore } from '../../stores/useUser'
import 'vue3-toastify/dist/index.css'
import { useCartStore } from '../../stores/useCart'
import { onMounted, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import cartMobilePopmenu from './cartMobilePopmenu.vue'
import axios from 'axios'

const props = defineProps({
  isOrder: Boolean,
  types: String
})

const route = useRoute()
const router = useRouter()
const cart = useCartStore()
const user = useUserStore()

const pageReady = ref(false)
const cartload = ref(false)

const cartDbHandller = async (cmnd) => {
  if (confirm('장바구니를 삭제합니다')) {
    const data = {
      params: cmnd,
      payload: {
        userId: user.me._id
      }
    }
    await sendRequest({ method: 'patch', path: '/cart/cartPatch', data })
    location.reload()
  }
}

const isLogin = async () => {
  router.push('/checkout')
}

onMounted(() => {
  pageReady.value = true
})

watchEffect(async () => {
  if (pageReady.value === true) {
    if (props.types === 'directCheckOut') {
      cartload.value = true
      return
    }
    await cart.getCartData()
    cartload.value = true
  }
})

watchEffect(async () => {
  if (route.path !== '/checkout') {
    return
  }
  if (cart.payUserInfo?.receiverInfo?.postcode !== '') {
    let postcodes = Number(cart.payUserInfo.receiverInfo.postcode)
    console.log('보낼 우편 번호', postcodes)
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/islandRoute/check-island`,
        {
          postcode: postcodes
        }
      )

      const result = response.data.result
      cart.island = result.island
      console.log('island', result.message)
      // cart 상태 변경
    } catch (error) {
      console.log('도서산간 지역 체크 중 오류가 발생.', error)
    }
  }
})
</script>

<style lang="scss" scoped></style>
