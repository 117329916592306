<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-gray-800 z-[1000] bg-opacity-50 overflow-auto"
  >
    <div
      class="w-[360px] h-[580px] fixed bg-white rounded-lg text-black text-[16px] p-[16px]"
      v-motion-slide-top
    >
      <div class="flex items-center justify-between">
        <p>회원가입</p>
        <button @click="system.registerModals = false">X</button>
      </div>
      <div class="pt-[24px] mb-[10px]">
        <p class="text-[14px] mb-[10px]">가입정보</p>
        <input
          class="modal-input my-[4px]"
          type="text"
          placeholder="이메일 주소"
          v-model="payload.email"
        />
        <input
          class="modal-input my-[4px]"
          type="password"
          placeholder="비밀번호"
          v-model="payload.password"
        />
        <input
          class="modal-input my-[4px]"
          type="password"
          placeholder="비밀번호 확인"
          v-model="payload.passwrodCheck"
        />
        <p v-if="!passwordMatch" class="ml-2 text-[10px] text-red-500">
          * 패스워드가 일치하지 않습니다
        </p>
      </div>
      <div class="py-[20px]">
        <p class="text-[14px] mb-[10px]">개인정보</p>
        <input class="modal-input my-[4px]" type="text" placeholder="이름" v-model="payload.name" />
        <input
          class="modal-input my-[4px]"
          type="tel"
          placeholder="연락처"
          v-model="payload.mobile"
        />
        <input
          class="modal-input my-[4px]"
          type="text"
          placeholder="생년월일"
          v-model="payload.birth"
        />
      </div>
      <div class="flex justify-center text-[14px] mb-[20px]">
        <input type="checkbox" class="w-auto" />
        <span class="ml-[6px]"> 이용약관에 동의합니다</span>
        <button
          class="w-[143px] border-[#d5d5d5d5] text-[14px] leading-[18px] text-center cursor-pointer"
        >
          이용약관 보기
        </button>
      </div>
      <button
        class="third-party-btn mb-2"
        style="background-color: #389033; color: white"
        @click="resgisterRequset()"
      >
        회원가입
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { sendRequest } from '../../api/serviceApi'
import { useSystemStore } from '../../stores/useSystem'
const system = useSystemStore()

const passwordMatch = ref(true)

const payload = ref({
  email: '',
  password: '',
  passwrodCheck: '',
  name: '',
  mobile: '',
  birth: ''
})

watchEffect(() => {
  if (
    payload.value.password !== payload.value.passwrodCheck &&
    payload.value.passwrodCheck !== ''
  ) {
    passwordMatch.value = false
  } else {
    passwordMatch.value = true
  }
})

const resgisterRequset = () => {
  if (!passwordMatch.value) {
    return alert('비밀번호 확인이 일치 하지 않습니다')
  }

  const nullCheck = () => {
    // Object.values()를 사용해서 객체의 모든 값을 가져온다냥
    const nullChecks = Object.values(payload.value).filter((element) => {
      return element === ''
    })
    return nullChecks || []
  }

  if (nullCheck().length > 0) {
    console.log(nullCheck())
    return alert('모든 정보를 입력해주세요!')
  }
}
</script>

<style lang="scss" scoped></style>
