<template>
  <div>
    <hr class="mx-3 md:mx-11" />
    <section class="ml-3 mr-2 md:mr-0 md:ml-[37px] mb-[8.6px]">
      <p class="text-[9px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D] mt-[11px]">
        ＊ 상품과 무관한 리뷰 작성, 사진 첨부는 통보 없이 삭제될 수 있습니다.
      </p>
      <p class="text-[9px] md:text-[12px] font-notosansCJKmd text-[#6D6D6D] mt-[7px]">
        ＊ 욕설, 다수 리뷰에 동일 사진 사용, 동일 단어로 작성할 경우 통보 없이 삭제될 수 있습니다.
      </p>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
