<template>
  <div>
    <div class="flex justify-center mt-[32.5px] md:mt-[91px]">
      <section>
        <div class="flex justify-center">
          <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">비밀번호 변경</p>
        </div>
        <!-- pc -->
        <div class="hidden md:block mt-[20px] text-center">
          <p class="text-[16px] font-notosansCJR text-[#6D6D6D]">
            비밀번호는 암호화되어 저장되어 연들 고객센터에서도
          </p>
          <p class="text-[16px] font-notosansCJR text-[#6D6D6D]">
            기존 비밀번호를 확인해드릴 수 있는 방법이 없습니다.
          </p>
          <p class="text-[16px] font-notosansCJR text-[#6D6D6D] mt-[15px]">
            본인확인을 통해 새로운 비밀번호로 변경해드리도록 하겠습니다.
          </p>
        </div>
        <!-- mobile -->
        <section class="md:hidden">
          <div class="flex justify-center my-[17px]">
            <img src="/img/password/padlock.webp" alt="" />
          </div>
          <p class="text-[12px] font-notosansCJR text-[#6D6D6D] text-center">
            비밀번호는 암호화되어 저장되어 연들 고객센터에서도
          </p>
          <p class="text-[12px] font-notosansCJR text-[#6D6D6D] mt-[1px] text-center">
            기존 비밀번호를 확인해드릴 수 있는 방법이 없습니다.
          </p>
          <p class="text-[12px] font-notosansCJR text-[#6D6D6D] mt-[8px] text-center">
            본인확인을 통해 새로운 비밀번호로 변경해드리도록 하겠습니다.
          </p>
        </section>
      </section>
    </div>
    <!-- 인증 -->
    <div class="flex justify-center mt-[37.5px] md:mt-[91px]">
      <section class="">
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            연락처 (휴대폰)
          </p>
          <div class="flex mt-[6.5px] md:mt-[13.5px]">
            <input
              type="text"
              class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
              placeholder="- 을 제외한 휴대폰 번호 입력"
              v-model="password.passData.stage1.mobile"
              @input="validatePhoneInput"
            />
            <button
              class="border border-[#389033] w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] text-[#389033] md:text-[18px] font-notosansCJKmd"
              @click="geMyNumber()"
            >
              휴대폰 인증
            </button>
          </div>
        </div>
        <div v-if="password.sendSms" class="">
          <p
            class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323] md:hidden mt-[13.5px]"
          >
            휴대폰 인증번호 입력
          </p>
          <div class="flex mt-[6.5px] md:mt-[22.5px]">
            <input
              type="text"
              class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
              placeholder="인증번호 입력"
              v-model="password.passData.stage1.otp"
              @input="validateOtpInput"
            />
            <button
              class="border w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] md:text-[18px] font-notosansCJKmd"
              @click="authDone()"
              :class="smsAuthBtn.class"
              :disabled="smsAuthBtn.disable"
            >
              {{ smsAuthBtn.text }}
            </button>
          </div>
        </div>
      </section>
    </div>
    <div class="mt-[56px] md:mt-[85px] mb-0 md:mb-[191px]">
      <section class="">
        <div class="flex justify-center">
          <button
            class="border border-[#389033] md:border-[#0C370A] w-[320px] md:w-[569px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033] bg-transparent"
            @click="stepToStage()"
          >
            다음 단계
          </button>
        </div>
        <div class="hidden md:block mt-[31.5px]">
          <p class="text-[14px] font-notosansCJR text-[#D23F3F] mt-[8px] text-center">
            회원가입시 입력한 정보가 기억나지 않는다면 고객센터 (이메일, 전화 등)를 통해
            문의해주세요
          </p>
        </div>
        <section class="md:hidden text-center mt-[64.5px]">
          <div class="">
            <p class="text-[11px] font-notosansCJR text-[#D23F3F] mt-[8px] text-center">
              회원가입시 입력한 정보가 기억나지 않는다면
            </p>
            <p class="text-[11px] font-notosansCJR text-[#D23F3F] text-center">
              고객센터 (이메일, 전화 등)를 통해 문의해주세요
            </p>
          </div>
        </section>
        <section class="mt-[13.5px] md:mt-[9px]">
          <div class="flex justify-center">
            <p class="text-[14px] md:text-[16px] font-notosansCJKmd text-[#6D6D6D]">
              고객센터 가기
            </p>
          </div>
        </section>
      </section>
    </div>
    <div class="mt-[86px] md:hidden">
      <p class="text-center text-[10px] font-notosansCJR text-[#6D6D6D] my-[27px]">
        ⓒ ruruci.inc All rights reserved.
      </p>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref } from 'vue'
import { sendRequest } from '../../../api/serviceApi'
import { usePasswordStore } from '../../../stores/usePassword'
const password = usePasswordStore()
const authInput = ref()
const smsAuthBtn = ref({ text: '확인', class: 'border-[#389033] text-[#389033]', disable: false })

// 모바일
const validatePhoneInput = (event) => {
  // 숫자만 남기기 위해 입력 값에서 숫자 이외의 모든 문자 제거
  let onlyNumbers = event.target.value.replace(/[^0-9]/g, '')
  // 최대 11자리로 제한
  if (onlyNumbers.length > 11) {
    onlyNumbers = onlyNumbers.slice(0, 11)
  }
  password.passData.stage1.mobile = onlyNumbers
}

const validateOtpInput = (event) => {
  // 숫자만 남기기 위해 입력 값에서 숫자 이외의 모든 문자 제거
  let onlyNumbers = event.target.value.replace(/[^0-9]/g, '')
  // 최대 11자리로 제한
  if (onlyNumbers.length > 6) {
    onlyNumbers = onlyNumbers.slice(0, 6)
  }
  password.passData.stage1.otp = onlyNumbers
}

const sendAuth = async () => {
  if (password.smsAuth) {
    alert('인증이 완료된 전화번호 입니다.')
    return
  }
  try {
    const sendAuth = await axios({
      // url: 'https://yeondeul.com/servers/smsAuth',
      url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth`,
      method: 'POST',
      data: {
        mobile: password.passData.stage1.mobile
      }
    })
    const result = sendAuth.data
    if (result === 'isDuplicate') {
      // console.log(sendAuth)
      alert('전송된 인증번호가 존재 합니다')
      return
    } else if (result === 'sendVerify') {
      alert('인증번호가 고객님의 휴대전화로 발송 되었습니다.')
      password.sendSms = true
    } else {
      alert(result)
      return
    }
  } catch (error) {}
}

const geMyNumber = async () => {
  const payload = {
    email: password.passData.stage0.emaill
  }
  try {
    const reqMyMobile = await sendRequest({
      method: 'post',
      path: '/users/getMyMobileByEmail',
      data: payload
    })
    const result = reqMyMobile.data
    console.log(result)

    if (password.passData.stage1.mobile === result) {
      await sendAuth()
    } else {
      alert('등록된 고객님의 전화번호와 일치 하지 않습니다.')
      return
    }
  } catch (error) {
    console.error('Error fetching mobile number:', error)
  }
}

const authDone = async () => {
  const confirmSmsAuth = await axios({
    // url: 'https://rurucidev2.com/servers/smsAuth/verifyAuth',
    url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth/verifyAuth`,
    method: 'POST',
    data: {
      mobile: password.passData.stage1.mobile,
      otp: password.passData.stage1.otp
    }
  })
  // alert(confirmSmsAuth)
  if (confirmSmsAuth.data === true) {
    register.smsAuth = true
    smsAuthBtn.value.class = ''
    smsAuthBtn.value.text = '완료'
    smsAuthBtn.value.disable = true
    alert('휴대폰 인증이 완료 되었습니다.')
  } else {
    alert('인증 번호가 틀렸습니다.')
  }
}

const stepToStage = () => {
  if (!password.smsAuth) {
    alert('휴대폰 인증을 완료해 주세요.')
    return
  }
  password.passStage = 2
}
</script>

<style lang="scss" scoped></style>
