<template>
  <div class="bg-white w-[400px] h-[130px] mt-3 rounded-md z-40 border shadow-md">
    <div class="flex ml-3 my-3 w-[94%]">
      <router-link :to="`/product/${item._id}`">
        <img
          :src="item.img"
          alt=""
          class="w-[100px] h-[100px] rounded-lg border bg-cover bg-center bg-no-repeat"
        />
      </router-link>

      <div class="ml-2 w-full mt-3">
        <p class="font-notosansCJKmd text-[16px]">
          [ {{ item.category.parent.catName }} <span v-if="item.category.child.catName">- </span
          >{{ item.category.child.catName }} ]
        </p>
        <router-link :to="`/product/${item._id}`">
          <p class="font-notosansCJKmd text-[16px]">{{ item.title }}</p>
        </router-link>

        <p class="font-notosansCJKmd text-[16px]">
          {{ item.price.toLocaleString('ko-KR') || 'null' }} 원
        </p>
      </div>
      <div class="mr-[5px]">
        <i class="pi pi-times cursor-pointer" @click.stop="closeDrop()"></i>
        <button v-if="props.main === false" @click.stop="deleteSetItem()">삭제</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const props = defineProps({
  item: Object,
  index: Number,
  main: Boolean
})

const router = useRouter()
// //상품 페이지로 이동
const goToDetails = (data) => {
  const id = data.item._id
  router.push(`/product/${id}`)
}

const emit = defineEmits(['updateIndex', 'updateClose']) // 이벤트 정의

// updateIndex 이벤트 전송
const sendIndex = () => {
  if (props.index !== undefined) {
    emit('updateIndex', {
      index: props.index,
      isDelete: true
    })
  }
}

// closeIndex 이벤트 전송
const sendCloseIndex = () => {
  if (props.index !== undefined) {
    emit('updateClose', {
      index: props.index,
      show: false
    })
    // console.log(props.index) // 인덱스 로그 확인 0
  }
}

// 드롭다운 닫기
const closeDrop = () => {
  sendCloseIndex()
}

// 아이템 삭제
const deleteSetItem = () => {
  sendIndex()
}
</script>

<style lang="scss" scoped></style>
