import { defineStore } from 'pinia'
import { useUserStore } from './useUser'
import { sendRequest } from '../api/serviceApi'
import { ref, watch, computed, watchEffect } from 'vue'

export const useReportStore = defineStore(
  'report',
  () => {
    // State
    const contactType = ref('')
    const myReportData = ref(null)
    const reportEdit = ref(false)

    const userProductReport = ref(null)

    // Getter
    const user = useUserStore()

    // Action
    const getReportByProductIds = async () => {
      const userIds = user.me._id
      const payload = {
        userid: userIds
      }

      const getData = await sendRequest({
        method: 'post',
        path: '/report/getReportsByUserId',
        data: payload
      })

      const result = getData.data
      if (result) {
        // 데이터를 products와 others로 나누고 날짜 기준으로 역정렬
        const processedData = {
          products: [],
          others: []
        }

        result.forEach((report) => {
          if (report.type === 'product') {
            processedData.products.push(report)
          } else if (['contact', 'order'].includes(report.type)) {
            processedData.others.push(report)
          }
        })

        // 최신 날짜 순으로 역정렬 (editAt을 기준으로)
        processedData.products.sort((a, b) => new Date(b.editAt) - new Date(a.editAt))
        processedData.others.sort((a, b) => new Date(b.editAt) - new Date(a.editAt))

        // 나눈 데이터를 vue의 상태에 저장
        userProductReport.value = processedData

        // console.log(processedData); // 확인을 위해 출력할 수 있다냥
      } else {
        return
      }
    }

    return {
      // State
      contactType,
      myReportData,
      reportEdit,
      userProductReport,

      // Getter
      // Action
      getReportByProductIds
    }
  },
  {
    // persist: true
  }
)
