<template>
  <div class="flex h-[74vh]">
    <div class="m-auto text-center">
      <h1 class="text-6xl font-bold text-gray-800">404</h1>
      <p class="text-xl mt-4 text-gray-600">페이지를 찾을 수 없어요!</p>
      <p class="text-xl text-gray-600">불편을 드려 죄송합니다</p>
      <a
        href="/"
        class="mt-6 inline-block bg-green-400 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        홈으로 가기
      </a>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()

const rewind = () => {
  alert('it will be done.')
  // router.push(`/${route.redirectedFrom.fullPath}`);
  router.push('/')
}
</script>

<style lang="scss" scoped></style>
