<template>
  <table class="w-full lg:w-[1180px] border-collapse">
    <p
      class="ml-[12px] lg:ml-[16px] mb-[6.5px] lg:mb-[11.5px] text-[15px] lg:text-[20px] font-nanumsquareEB lg:font-notosansCJKmd text-[#171717]"
    >
      주문정보
    </p>
    <tbody>
      <tr v-for="(label, index) in labels" :key="index" class="">
        <th
          class="text-[12px] lg:text-[15px] pl-[16px] lg:pl-[27.5px] font-notosansCJR text-left lg:bg-[#FBFBFB]"
          :class="leftClass[index]"
        >
          {{ label }}
        </th>
        <td
          class="pl-[25px] lg:pl-[41px] text-[13px] font-notosansCJKmd"
          :class="rightClass[index]"
        >
          {{ values[index] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
const props = defineProps({
  data: Array
})

// 주문 정보의 라벨 (labels)
const labels = ref([
  '주문번호',
  '받으시는 분',
  '연락처',
  '배송주소',
  '',
  '배송요청사항',
  '결제정보',
  '결제수단',
  '결제금액'
])

// 주문 정보의 값 (values)
const values = ref([])

// 왼쪽 표 스타일 클래스
const leftClass = ref([
  'border-t-[2px] border-[#6D6D6D] pt-[26px] lg:pt-[28.5px]', // 주문번호
  'pt-[16px] lg:pt-[28.5px]', // 주문자 이름
  'pt-[16px] lg:pt-[29.5px]', // 전화번호
  'pt-[16px] lg:pt-[27.4px]', // 배송주소
  'lg:pt-[1px]', // 배송상세 주소 *배소주소랑 붙어야 함
  'pt-[16px] lg:pt-[36px] pb-[37.4px]', //배송 요청사항 *좌우 높이 틀림 잘 봐야됨 *바닥도 있음
  // 여기서 부터 결제 정보
  'lg:hidden text-[15px] pb-[6.5pxpx] font-nanumsquareEB',
  'border-t-[2px] lg:border-t-[1px] border-[#6D6D6D] lg:border-[#B2B2B2] pt-[26px] lg:pt-[28px] font-notosansCJKmd', // 결제수단
  'pt-[16px] lg:border-b-[1px] border-[#B2B2B2] lg:pt-[27.8px] lg:pb-[25.5px] font-notosansCJKmd' // 결제금액
])

// 오른쪽 표 스타일 클래스
const rightClass = ref([
  'border-t-[2px] border-[#6D6D6D] pt-[26px] lg:pt-[27.5px] text-[#D23F3F] lg:text-[17px]', // 주문번호
  'pt-[15px] lg:pt-[28px] text-[#232323] lg:text-[17px]', // 주문자 이름
  'pt-[15px] lg:pt-[28px] text-[#6D6D6D] lg:text-[17px]', // 전화번호
  'pt-[15px] lg:pt-[28px] text-[#6D6D6D] lg:text-[17px]', // 배송주소
  'lg:pt-[1px] text-[#6D6D6D] lg:text-[17px]', // 배송상세 주소 *배소주소랑 붙어야 함
  'pt-[16px] lg:pt-[28px] pb-[37.4px] lg:text-[15px] text-[#6D6D6D] lg:text-[15px]', //배송 요청사항 *좌우 높이 틀림 잘 봐야됨 *바닥도 있음
  // 여기서 부터 결제 정보
  'lg:hidden pb-[6.5pxpx]',
  'border-t-[2px] lg:border-t-[1px] border-[#6D6D6D] lg:border-[#B2B2B2] pt-[26px] lg:pt-[28px] lg:text-[17px] font-notosansCJKmd', // 결제수단
  'pt-[16px] lg:border-b-[1px] border-[#B2B2B2] lg:pt-[27.8px] lg:pb-[25.5px] lg:text-[17px] font-notosansCJKmd' // 결제금액
])

watchEffect(() => {
  if (props.data) {
    values.value = props.data
  }
})
</script>

<style scoped></style>
