<template>
  <div>
    <section class="pb-[10px] md:pb-0">
      <postHeader v-if="postData && postData.type" :type="postData.type" :isDetail="true" />
    </section>
    <div class="mt-[14px] md:-mt-[10px]">
      <postDetails class="" :item="postData" />
      <!-- <postMenu class="lg:pl-[95px]" :itemid="types" /> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import postDetails from '../../../components/post/postDetails.vue'
import postHeader from '../../../components/post/postHeader.vue'
import postMenu from '../../../components/post/postMenu.vue'
import { sendRequest } from '../../../api/serviceApi'
const router = useRouter()
const route = useRoute()
const id = route.params.id
const types = route.query.type

const postData = ref(null)

const fetchReview = async () => {
  const payload = {
    _id: id
  }
  const result = await sendRequest({
    method: 'post',
    path: '/post/postGetByIds',
    data: payload
  })
  if (result) {
    if (result.success === 'notFound') {
      router.push('/404')
    }
    // console.log(result)
    postData.value = result.data
  } else {
    console.error('error', result.message)
  }
}

watchEffect(() => {
  if (id) {
    fetchReview()
  }
})
</script>

<style lang="scss" scoped></style>
