<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-[#5A5A5A] z-[1000] bg-opacity-70 overflow-hidden"
  >
    <div class="w-[365px] md:w-[550px] h-auto bg-white rounded-xl overflow-y-clip">
      <!-- mobile done -->
      <div
        class="flex justify-center items-center mt-[14px] md:mt-[20px] mb-[13.5px] md:mb-[15px] relative"
      >
        <p
          class="flex-grow text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717] text-center"
        >
          주소지 목록
        </p>
        <button class="absolute right-[15px] md:right-[19px]">
          <i
            class="pi pi-times text-[15px] md:text-[25px] text-[#A8A8A8]"
            @click="system.addressListModal = false"
          >
          </i>
        </button>
      </div>
      <hr class="mx-2" />
      <!-- mobile done -->
      <section>
        <div class="flex justify-center items-center mt-[22.5px] md:mt-[48px]">
          <img src="/img/mypage/mapPoint.webp" alt="mapPoint" class="hidden md:inline" />
          <img src="/img/mypage/mapPointMini.webp" alt="mapPoint" class="md:hidden" />
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd ml-[6px] md:ml-[5px]">
            주소지 목록
          </p>
        </div>
      </section>
      <section class="mt-[34px] mx-[15px] mb-[127px]">
        <!-- <myAddListCard /> -->
        <section v-if="address.userAddrData !== null" class="mx-[5px] mt-[21px] md:mt-[27px]">
          <!-- 표시용도 -->
          <div
            v-for="(address, index) in addressData.addressData"
            :key="index"
            class="mb-[9px] md:mb-[11px]"
          >
            <myAddListCard
              v-if="index === defaultIndex"
              class="cursor-pointer"
              :address="address"
              :defaultAddr="true"
              :nowIndex="index"
              :style="{
                borderColor: index === selectedCardIndex ? '#389033' : '#D5D5D5',
                borderWidth: index === selectedCardIndex ? '1px' : '1px'
              }"
              @click="selectedIndex(index)"
            />
          </div>
          <div
            v-for="(address, index) in addressData.addressData"
            :key="index"
            class="mb-[9px] md:mb-[11px]"
          >
            <myAddListCard
              v-if="index !== defaultIndex"
              class="cursor-pointer"
              :address="address"
              :nowIndex="index"
              :style="{
                borderColor: index === selectedCardIndex ? '#389033' : '#D5D5D5',
                borderWidth: index === selectedCardIndex ? '1px' : '1px'
              }"
              @click="selectedIndex(index)"
            />
          </div>
        </section>
        <section v-if="addressData === null" class="flex justify-center my-48 text-center">
          <div>
            <p>추가된 배송지가 없습니다.</p>
            <p>마이페이지 -> 배송지 관리 메뉴를 참조해 주세요.</p>
          </div>
        </section>
        <!-- 명칭 -->
      </section>
      <!-- 모바일 -->
      <section class="md:hidden"></section>
      <hr class="mx-2" />
      <!-- 버튼 제어 -->
      <div class="flex justify-center mb-[16px] mt-[16.5px]">
        <button
          class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] border border-[#0C370A] rounded-lg md:rounded-md text-[13px] md:text-[18px] text-[#1C1C1C] font-notosansCJKmd"
          @click="system.addressListModal = false"
        >
          취소하기
        </button>
        <button
          class="w-[164px] h-[36px] md:w-[226px] md:h-[51px] bg-[#389033] border rounded-lg md:rounded-md text-[13px] md:text-[18px] text-white font-notosansCJKmd ml-[15px] md:ml-[40px]"
          @click="setToAddr()"
        >
          주소선택
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useAddressStore } from '../../../../stores/useAddress'
import { useSystemStore } from '../../../../stores/useSystem'
import { useCartStore } from '../../../../stores/useCart'
import { deepCopy } from '../../../../utils/deepCopy'
import { useOrderStore } from '../../../../stores/userOrder'
import myAddListCard from './myAddListCard.vue'

//setup
const system = useSystemStore()
const address = useAddressStore()
const cart = useCartStore()
const order = useOrderStore()

//state
const addressData = ref([])
const defaultIndex = ref(0)
const selectedCardIndex = ref(null)

//action
const selectedIndex = (index) => {
  selectedCardIndex.value = index
  //   console.log('selected: ', index)
}

const setToAddr = () => {
  cart.payUserInfo.receiverInfo = deepCopy(
    address.userAddrData.addressData[selectedCardIndex.value]
  )
  system.addressListModal = false
  if (Object.keys(order.refundDats.stage1.refundDetaild).length === 0) {
    // refundDetaild가 빈 객체일 때 실행할 코드
    // console.log('refundDetaild는 빈 객체다냥!')
    return
  } else {
    // refundDetaild가 비어있지 않을 때 실행할 코드
    const requestSave = deepCopy(order.refundDats.stage1.refundDetaild.request)
    order.refundDats.stage1.refundDetaild = deepCopy(
      address.userAddrData.addressData[selectedCardIndex.value]
    )
    order.refundDats.stage1.refundDetaild.request = requestSave
    // console.log('refundDetaild에는 데이터가 있다냥!')
  }
}

//lifeCycle
onMounted(async () => {
  defaultIndex.value = address.defaultAddrIndex
  addressData.value = address.userAddrData
  selectedCardIndex.value = address.defaultAddrIndex
})
</script>

<style lang="scss" scoped></style>
