<template>
  <div class="lg:h-[398px]" ref="conttainers">
    <div class="hidden lg:flex justify-start items-start mt-[21px]">
      <div class="hidden lg:block mr-[26px]" v-for="(data, index) in data" :key="'mini' + index">
        <!-- <router-link :to="{ path: `/post/details/${data._id}` }"> -->

        <postListCardVue
          v-if="index < 3"
          :data="data"
          :type="type"
          class="cursor-pointer"
          @click="goToDetails(data._id)"
        />
        <!-- </router-link> -->
      </div>
    </div>
    <div class="swiper-container lg:hidden ml-[15.5px] lg:ml-0 mt-[11.5px]">
      <swiper
        :slides-per-view="'auto'"
        :space-between="spaces"
        :loop="false"
        class="swiper-wrapper"
      >
        <swiper-slide v-for="(data, index) in data" :key="'swiper' + index">
          <router-link :to="{ path: `/post/details/${data._id}` }">
            <postListCardVue :data="data" :type="type" />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <!-- w: {{ width }}px, h: {{ height }}px -->
  </div>
</template>

<script setup>
import postListCardVue from './postListCard.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const goToDetails = (ids) => {
  // location.reload()
  router.push(`/post/details/${ids}`)
}

const props = defineProps({
  data: Array,
  type: String
})

const spaces = ref(10)

const imsiData = []
</script>

<style lang="scss" scoped>
.swiper-container {
  position: relative;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
