import { defineStore } from 'pinia'
import { useUserStore } from './useUser'
import { sendRequest } from '../api/serviceApi'
import { ref, watch, computed, watchEffect } from 'vue'

export const useBoardStore = defineStore(
  'board',
  () => {
    // State
    const user = useUserStore()
    const boardEdit = ref(false)
    const boardData = ref(null)
    const announceData = ref(null)
    const searchData = ref(null)
    const qnaData = ref(null)
    const getOneBoard = ref(null)
    // Getter

    // Action
    const getAnnounceData = async () => {
      const getAnnounceBoard = await sendRequest({
        method: 'post',
        path: '/board/getAnnounceBaoard'
      })
      const result = getAnnounceBoard.result
      if (result === 'ok') {
        announceData.value = getAnnounceBoard.data
      } else {
        return
      }
    }
    const getQnaBoard = async () => {
      const getQnasBoards = await sendRequest({
        method: 'post',
        path: '/board/getQnABoard'
      })
      const result = getQnasBoards.result
      if (result === 'ok') {
        qnaData.value = getQnasBoards.data
      } else {
        return
      }
    }

    const getBoardIds = async (ids) => {
      const sendData = {
        id: ids
      }
      const getBoardbyIds = await sendRequest({
        method: 'post',
        path: '/board/getBoardByIds',
        data: sendData
      })
      const result = getBoardbyIds.result
      if (result === 'ok') {
        getOneBoard.value = getBoardbyIds.data
      } else {
        return
      }
    }

    const getQnaByKeywords = async (keywords) => {
      if (keywords === '' || keywords === ' ') {
        searchData.value = null
        return
      }
      const payload = {
        keyword: keywords
      }
      const getQnaSearchData = await sendRequest({
        method: 'post',
        path: '/board/seachQnaByKeyword',
        data: payload
      })
      const result = getQnaSearchData.result
      if (result === 'ok') {
        searchData.value = getQnaSearchData.data
      } else {
        return
      }
    }

    return {
      // State
      boardEdit,
      boardData,
      announceData,
      qnaData,
      getOneBoard,
      searchData,
      // Getter
      // Action
      getAnnounceData,
      getQnaBoard,
      getBoardIds,
      getQnaByKeywords
    }
  },
  {
    // persist: true
  }
)
