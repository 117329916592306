<template>
  <div class="w-[365px] md:w-[737px] md:border rounded-lg border-[#D5D5D5]">
    <section class="md:mt-[36px]">
      <div class="flex justify-center">
        <section>
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">ID (이메일)</p>
          <input
            type="text"
            class="w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            v-model="register.registerStage.stage1.userInfo.email"
            :disabled="true"
          />
        </section>
      </div>
      <div class="flex justify-center mt-[23.5px] md:mt-[31.5px]">
        <section>
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">성함</p>
          <input
            type="text"
            class="border w-[343.22px] md:w-[568.83px] h-[43px] md:h-[51px] rounded-md mt-[6.5px] md:mt-[13.5px] pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
            v-model="register.registerStage.stage1.userInfo.name"
          />
        </section>
      </div>
    </section>
    <!-- 인증 -->
    <section class="mt-[23.5px] md:mt-[31.5px] md:mb-[56.5px]">
      <div class="flex justify-center">
        <div class="">
          <p class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323]">
            연락처 (휴대폰)
          </p>
          <div class="flex mt-[6.5px] md:mt-[13.5px]">
            <input
              type="text"
              class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px] no-arrow"
              placeholder="- 을 제외한 휴대폰 번호 입력"
              v-model="register.registerStage.stage1.userInfo.mobile"
              @input="validatePhoneInput"
            />
            <button
              class="border border-[#389033] w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] text-[#389033] md:text-[18px] font-notosansCJKmd"
              @click="sendAuth()"
            >
              휴대폰 인증
            </button>
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <div v-if="register.sendSms" class="">
          <p
            class="text-[12px] md:text-[18px] font-notosansCJKmd text-[#232323] md:hidden mt-[13.5px]"
          >
            휴대폰 인증번호 입력
          </p>
          <div class="flex mt-[6.5px] md:mt-[22.5px]">
            <input
              type="text"
              class="border w-[217px] md:w-[367.67px] h-[43px] md:h-[51px] rounded-md pl-[10px] md:pl-[16px] placeholder:text-[12px] md:placeholder:text-[16px]"
              placeholder="인증번호 입력"
              v-model="password.passData.stage1.otp"
              @input="validateOtpInput"
            />
            <button
              class="border w-[116px] md:w-[188.83px] h-[43px] md:h-[51px] rounded-md placeholder:text-[12px] md:placeholder:text-[16px] ml-[10px] md:ml-[12px] text-[12px] md:text-[18px] font-notosansCJKmd"
              @click="authDone()"
              :class="smsAuthBtn.class"
              :disabled="smsAuthBtn.disable"
            >
              {{ smsAuthBtn.text }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import axios from 'axios'
import { ref, watchEffect } from 'vue'
import { usePasswordStore } from '../../../stores/usePassword'
import { useRegisterStore } from '../../../stores/useRegister'

const register = useRegisterStore()
const password = usePasswordStore()

const smsAuthBtn = ref({ text: '확인', class: 'border-[#389033] text-[#389033]', disable: false })

// 모바일
const validatePhoneInput = (event) => {
  // 숫자만 남기기 위해 입력 값에서 숫자 이외의 모든 문자 제거
  let onlyNumbers = event.target.value.replace(/[^0-9]/g, '')
  // 최대 11자리로 제한
  if (onlyNumbers.length > 11) {
    onlyNumbers = onlyNumbers.slice(0, 11)
  }
  password.passData.stage1.mobile = onlyNumbers
}

const validateOtpInput = (event) => {
  // 숫자만 남기기 위해 입력 값에서 숫자 이외의 모든 문자 제거
  let onlyNumbers = event.target.value.replace(/[^0-9]/g, '')
  // 최대 11자리로 제한
  if (onlyNumbers.length > 6) {
    onlyNumbers = onlyNumbers.slice(0, 6)
  }
  password.passData.stage1.otp = onlyNumbers
}

const sendAuth = async () => {
  if (register.smsAuth) {
    alert('인증이 완료된 전화번호 입니다.')
    return
  }
  if (register.registerStage.stage1.userInfo.name === '') {
    alert('성함은 필수로 입력 하셔야 합니다.')
    return
  }
  try {
    const sendAuth = await axios({
      // url: 'https://yeondeul.com/servers/smsAuth',
      url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth`,
      method: 'POST',
      data: {
        mobile: password.passData.stage1.mobile
      }
    })
    const result = sendAuth.data
    if (result === 'isDuplicate') {
      // console.log(sendAuth)
      alert('전송된 인증번호가 존재 합니다')
      return
    } else if (result === 'sendVerify') {
      alert('인증번호가 고객님의 휴대전화로 발송 되었습니다.')
      register.sendSms = true
    } else {
      alert(result)
      return
    }
  } catch (error) {}
}

const authDone = async () => {
  const confirmSmsAuth = await axios({
    // url: 'https://rurucidev2.com/servers/smsAuth/verifyAuth',
    url: `${import.meta.env.VITE_API_BASE_URL}/smsAuth/verifyAuth`,
    method: 'POST',
    data: {
      mobile: password.passData.stage1.mobile,
      otp: password.passData.stage1.otp
    }
  })
  // alert(confirmSmsAuth)
  if (confirmSmsAuth.data === true) {
    register.smsAuth = true
    smsAuthBtn.value.class = ''
    smsAuthBtn.value.text = '완료'
    smsAuthBtn.value.disable = true
    alert('휴대폰 인증이 완료 되었습니다.')
  } else {
    alert('인증 번호가 틀렸습니다.')
  }
}

const stepToStage = () => {
  if (!password.smsAuth) {
    alert('휴대폰 인증을 완료해 주세요.')
    return
  }
  password.passStage = 2
}
</script>

<style lang="scss" scoped></style>
