<template>
  <div class="bg-[#FCFBFB] lg:bg-transparent">
    <div class="flex items-end h-[37px] lg:h-auto">
      <button
        v-for="(buttons, index) in buttonArray"
        :key="buttons.name"
        :class="`${buttons.class} ${buttons.textColor}`"
        class="text-[14px] lg:text-[18px] font-notosansCJKmd hover:text-[#389033] relative pb-[6px] lg:pb-0"
        @click="clickEvent(index)"
      >
        {{ buttons.name }}
        <div
          v-if="buttons.textColor === 'text-[#389033]'"
          class="absolute -bottom-0 -left-0 -right-0 h-0.5 bg-[#389033]"
        ></div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

const props = defineProps({
  cmdChange: String
})
const selectCmd = ref('')
const buttonArray = ref([
  {
    name: '통합검색',
    textColor: 'text-[#389033]',
    class: 'mr-[33px]',
    cmd: 'combine'
  },
  {
    name: '스토어',
    textColor: 'text-[#707070]',
    class: 'mr-[33px]',
    cmd: 'store'
  },
  {
    name: '블로그',
    textColor: 'text-[#707070]',
    class: '',
    cmd: 'post'
  }
])

const clickEvent = (index) => {
  buttonArray.value.forEach((item) => (item.textColor = 'text-[#707070]'))
  buttonArray.value[index].textColor = 'text-[#389033]'
  selectCmd.value = buttonArray.value[index].cmd
}

const emit = defineEmits(['updateCmd'])
const sendEmit = () => {
  emit('updateCmd', selectCmd.value)
}

watchEffect(() => {
  if (selectCmd.value) {
    sendEmit()
  }
  // if (props.cmnChang) {
  //   console.log(props.cmnChang)
  //   switch (props.cmnChang) {
  //     case 'store':
  //       clickEvent(1)
  //       return
  //     case 'post':
  //       clickEvent(2)
  //       return
  //     default:
  //       return
  //   }
  // }
})
</script>

<style lang="scss" scoped></style>
