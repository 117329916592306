<template>
  <div v-if="product" ref="productMains">
    <!-- <p>width: {{ width }}px</p> -->
    <!-- <p>isMobile: {{ isMobile }}</p> -->
    <div class="h-full relative">
      <div class="h-full">
        <productPostArea class="mt-[25px] md:hidden min-h-[259px] mb-[24px]" />
      </div>

      <!-- 네비버튼 -->
      <!-- <div class="sticky top-[115px] pt-[15px] md:pt-[25px] z-10 bg-white"> -->
      <div class="top-[115px] pt-[15px] md:pt-[25px] z-10 bg-white">
        <div class="flex ml-[21px]">
          <div v-for="(menu, index) in navList" :key="index" class="mr-[33px]">
            <button
              :class="[
                'font-notosansCJKmd text-[14px] md:text-[18px] hover:text-[#389033] relative md:pb-2',
                activeIndex === index ? 'text-[#389033]' : menu.color
              ]"
              @click="scrollToSection(menu.ref, index)"
            >
              {{ menu.name }}
              <div
                v-if="activeIndex === index"
                class="absolute -bottom-1 lg:-bottom-[0px] left-0 w-full h-[1.5px] bg-[#389033]"
              ></div>
            </button>
          </div>
        </div>
        <hr class="border-1 bg-[#B2B2B2] mt-0" />
      </div>

      <!-- 인테리어~ ~ 부터 오른쪽 메뉴 -->
      <div class="flex justify-between">
        <!-- 하단 메뉴 모음 -->
        <div class="w-full md:w-[842px]">
          <productPostArea class="mt-[32px] hidden md:block" />
          <div ref="editorArea" class="mt-[20px] md:mt-[53px]">
            <productEditorReadVue v-if="product.productItem" :item="product.productItem.editor" />
          </div>
          <div ref="reviewArea" class="mt-[55px] lg:mt-[284px]">
            <productReview :isMobile="isMobile" />
          </div>
          <div ref="inquiryArea" class="mt-[66px] lg:mt-[135px] mb-[44.7px] lg:mb-[58px]">
            <productInquiryArea />
          </div>
          <div ref="shippingArea" class="mb-[105px]">
            <productShippingGuide />
          </div>
        </div>
        <!-- 가운데 라인 -->
        <div
          class="h-auto ml-[12.5px] border-l border-1 border-[#CBCBCB] mt-[19px] mb-[47.7px] hidden md:block"
        ></div>
        <!-- 사이드 장바구니 -->
        <div
          class="sticky top-[200px] h-[calc(100vh-200px)] mt-[14px] w-[294px] ml-[13.5px] hidden md:block"
        >
          <div class="flex flex-col h-full">
            <!-- 사이드바 상단 -->
            <productDetailOption
              v-if="product.productItem"
              :item="product.productItem"
              class="overflow-auto"
            />

            <!-- 사이드바 콘텐츠 -->
            <div class="flex-grow"></div>

            <!-- 사이드바 하단 -->
            <productDetailselector
              v-if="product.product_option_selected"
              :item="product.product_option_selected"
              :isSide="true"
              class="mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted, onUnmounted, watchEffect, watch } from 'vue'
import { useProductStore } from '../../stores/useProduct'

import { useElementSize } from '@vueuse/core'

import productEditorReadVue from './productDescription/productEditorRead.vue'
import productPostArea from './productDescription/productPostArea.vue'
import productReview from './productDescription/productReview.vue'
import productInquiryArea from './productDescription/productInquiry/productInquiryArea.vue'
import productShippingGuide from './productDescription/productShippingGuide.vue'
import productDetailOption from './productDetail/productDetailOption.vue'
import productDetailselector from './productDetail/productDetailselector.vue'
const product = useProductStore()
// console.log('너가 범인이냐', product.product_option_selected)

// 각 섹션에 대한 ref 정의
const editorArea = ref(null)
const reviewArea = ref(null)
const inquiryArea = ref(null)
const shippingArea = ref(null)

const productMains = ref(null)
const { width } = useElementSize(productMains)
const isMobile = ref(false)

watch(width, () => {
  if (width._rawValue > 1164) {
    isMobile.value = false
  } else if (width._rawValue < 1164) {
    isMobile.value = true
  }
})

const navList = ref([
  {
    name: '상세설명',
    class: '',
    color: 'text-[#707070]',
    ref: editorArea
  },
  {
    name: '리뷰',
    class: '',
    color: 'text-[#707070]',
    ref: reviewArea
  },
  {
    name: '문의',
    class: '',
    color: 'text-[#707070]',
    ref: inquiryArea
  },
  {
    name: '배송',
    class: '',
    color: 'text-[#707070]',
    ref: shippingArea
  }
])

const scrollToSection = async (sectionRef, index) => {
  await nextTick() // 모든 DOM 업데이트가 완료될 때까지 대기
  const section = sectionRef
  if (section) {
    const offsetTop = section.getBoundingClientRect().top + window.scrollY - 200 // 원하는 오프셋 값을 변경
    window.scrollTo({ top: offsetTop, behavior: 'smooth' })
    activeIndex.value = index
  } else {
    console.log('section not found')
  }
}

const activeIndex = ref(-1)

const onScroll = () => {
  const sections = [editorArea.value, reviewArea.value, inquiryArea.value, shippingArea.value]
  const scrollPosition = window.scrollY - 750 // 오프셋 값과 일치시키기

  sections.forEach((section, index) => {
    if (section) {
      const sectionTop = section.offsetTop
      const sectionBottom = sectionTop + section.offsetHeight

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        activeIndex.value = index
      }
    }
  })
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style scoped></style>
