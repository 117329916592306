<template>
  <div>
    <div class="flex justify-center mt-[15.5px] md:mt-[59px]">
      <section>
        <div class="flex justify-center">
          <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
            기본 배송지 입력
          </p>
        </div>
        <div class="hidden md:block mt-[20px]">
          <p class="text-[18px] font-notosansCJKmd text-[#6D6D6D]">
            마지막 단계입니다! 간편한 주문을 위해 고객님의 배송지 정보를 입력해주세요
          </p>
        </div>
        <section class="md:hidden mt-[19px]">
          <div class="flex justify-center">
            <img src="/img/register/delivery-truck.webp" class="w-[50px] h-[50px]" alt="" />
          </div>
          <p class="text-[12px] font-notosansCJKmd text-[#389033] mt-[3px] text-center">
            마지막 단계입니다!
          </p>
          <p class="text-[12px] font-notosansCJKmd text-[#389033] mt-[3px] text-center">
            간편한 주문을 위해
          </p>
          <p class="text-[12px] font-notosansCJKmd text-[#389033] mt-[3px] text-center">
            고객님의 배송지 정보를 입력해주세요
          </p>
        </section>
      </section>
    </div>
    <section class="flex justify-center mt-[32px] md:mt-[34px]">
      <registerAddresBox />
    </section>
    <!-- 모바일 버튼 -->
    <section class="md:hidden">
      <div class="mt-[23.5px] md:mt-[42px] mb-0 md:mb-[191px]">
        <section class="">
          <div class="flex justify-center">
            <button
              class="border border-[#D5D5D5] w-[164px] h-[36px] rounded-lg text-[13px] font-notosansCJKmd text-[#6D6D6D] bg-transparent"
              @click="fallBack()"
            >
              이전단계
            </button>
            <button
              class="border border-[#389033] w-[164px] h-[36px] rounded-lg text-[13px] font-notosansCJKmd text-[#389033] bg-transparent ml-[14px]"
              @click="registerdDone()"
            >
              가입완료
            </button>
          </div>
        </section>
        <p
          class="md:hidden mt-[74px] mb-[60px] text-center text-[10px] md:text-[14px] text-[#6D6D6D] font-notosansCJR"
        >
          ⓒ ruruci.inc All rights reserved.
        </p>
      </div>
    </section>
    <section class="hidden md:block">
      <div class="mt-[23.5px] mb-[191px]">
        <section class="">
          <div class="flex justify-center">
            <button
              class="border border-[#D5D5D5] w-[226px] h-[51px] rounded-md text-[18px] font-notosansCJKmd text-[#6D6D6D] bg-transparent"
              @click="fallBack()"
            >
              이전단계
            </button>
            <button
              class="border border-[#0C370A] bg-[#389033] w-[226px] h-[51px] rounded-md text-[18px] font-notosansCJKmd text-white ml-[40px]"
              @click="registerdDone()"
            >
              가입완료
            </button>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script setup>
import registerAddresBox from './registerAddresBox.vue'
import { useRegisterStore } from '../../stores/useRegister'
import { sendRequest } from '../../api/serviceApi'
import axios from 'axios'
import { useSystemStore } from '../../stores/useSystem'
import { useCookies } from '@vueuse/integrations/useCookies.mjs'
import { useUserStore } from '../../stores/useUser'
import { onUnmounted } from 'vue'

const cookies = useCookies()
const system = useSystemStore()
const user = useUserStore()
const register = useRegisterStore()

const setToAuth = async (token) => {
  try {
    const tokenCheck = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/getAuth`,
      {},
      {
        headers: {
          authentication: token
        }
      }
    )
    const setMeData = tokenCheck.data
    user.status = true
    user.me = setMeData
    user.auth = setMeData.auth
  } catch (e) {
    // console.error('Token validation failed:', e)
    user.status = false
    user.me = null
    user.auth = null
  }
}

const setAuth = async () => {
  const myJwt = cookies.get('yeondeul')
  if (myJwt) {
    user.token = myJwt
    await setToAuth(myJwt)
    const userReady = user.status
    // alert(`${userReady}`)
    if (userReady) {
      register.registStage = 3
    }
  } else {
    console.warn('JWT token is missing.')
    return
  }
}

const requestNaverLogin = async () => {
  try {
    await axios.get(`${import.meta.env.VITE_API_BASE_URL}/naver/oAuth/login`)
    register.registStage = 3 // 로그인 후 환영페이지 안내
  } catch (error) {
    console.error(error)
  }
}

const kakaoReady = async () => {
  try {
    register.registStage = 3 // 로그인 후 환영페이지 안내
    window.location.href = `${import.meta.env.VITE_API_BASE_URL}/kakao/oAuth/login`
  } catch (error) {
    console.error(error)
  }
}

const autoLogin = async () => {
  if (register.vendor === 'naver') {
    return requestNaverLogin()
  } else if (register.vendor === 'kakao') {
    return kakaoReady()
  }
  const payload = {
    email: register.registerStage.stage1.userInfo.email,
    password: register.registerStage.stage1.userInfo.password
  }

  try {
    const getToken = await axios.post(
      `${import.meta.env.VITE_API_BASE_URL}/users/loginUsers`,
      payload
    )
    const token = getToken.data
    if (token.status === 400) {
      alert(token.message)
    } else if (token.status === 200) {
      cookies.set('yeondeul', token.message, { maxAge: 100 * 180 }) // 유효 기간을 60분으로 설정
      await setAuth() // setAuth가 완료된 후에 다음 로직을 실행하도록 await 추가
    }
  } catch (error) {
    console.error('Login failed:', error)
  }
}

const registerdDone = async () => {
  const payload = {
    registData: register.registerStage,
    naverToken: register.naverToken,
    vendor: register.vendor
  }
  const createNewUser = await sendRequest({
    method: 'post',
    path: '/users/createUser',
    data: payload
  })
  const result = createNewUser.data
  if (result) {
    await autoLogin()
  } else {
    return
  }
}

const fallBack = () => {
  if (register.naverToken) {
    window.history.back()
  } else {
    register.registStage = 1
  }
}
</script>

<style lang="scss" scoped></style>
