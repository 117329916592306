<template>
  <div class="">
    <div class="lg:w-[1180px] w-auto md:mx-5 lg:mx-auto" :key="keyword">
      <!-- 1. 카테고리  -->
      <!-- <homePostAndCat class="ml-[1px]" /> -->
      <!-- 2. 선택 카테고리 제목 -->
      <p
        v-if="keyword"
        class="ml-[16px] lg:ml-0 mt-[13.5px] lg:mt-[37px] text-[16px] lg:text-[26px] text-[#404040] font-nanumsquareEB lg:pl-[10px]"
      >
        {{ titles }}
        <!-- {{ mainCatDatas }} -->
      </p>
      <hr class="mt-[16px] border-0 bg-[#E2E2E2] h-[0.12em] hidden lg:block" />
      <!-- 3. 상품 목록  -->
      <shopMain class="" :keyword="keyword" />
    </div>
  </div>
</template>

<script setup>
import shopMain from '../../components/shop/shopMain.vue'
import homePostAndCat from '../../components/home/homePostAndCat.vue'
import mainCatData from '../../data/mainCatData'
import { useRoute } from 'vue-router'
import { ref, watchEffect } from 'vue'
const mainCatDatas = { ...mainCatData }
const route = useRoute()
const keyword = ref(route.params.id)
const titles = ref(route.query.type)
watchEffect(() => {
  if (route) {
    keyword.value = route.params.id
    titles.value = route.query.type
  }
})
</script>

<style lang="scss" scoped></style>
