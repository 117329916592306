<template>
  <div class="lg:w-[1180px] lg:mx-auto h-full mt-0 lg:mt-[36px]">
    <div
      v-if="cart.cartData.length === 0"
      class="text-center h-[80vh] flex items-center justify-center"
    >
      <section>
        <h3>{{ '장바구니에 선택한 상품이 존재 하지 않아요' }}</h3>
        <router-link :to="{ path: `/shop/wood`, query: { type: '원목가구' } }">
          <button
            class="border rounded-md border-gray-400 hover:border-white transition-colors mt-11"
            to="/shop"
          >
            <p
              class="text-[15px] font-notosansCJKmd text-gray-500 px-4 py-2 hover:bg-gray-300 hover:text-white transition-colors"
            >
              제품 둘러 보기
            </p>
          </button>
        </router-link>
      </section>
    </div>
    <div v-else>
      <cartArea :isOrder="true" :types="types" />
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from '../stores/useCart'
import cartArea from '../components/cart/cartArea.vue'
import checkOutArea from '../components/checkout/checkOutArea.vue'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
const cart = useCartStore()

const route = useRoute()

const types = ref('')
onMounted(() => {
  if (route.query.type) {
    types.value = route.query.type
  }
})
</script>

<style lang="scss" scoped></style>
