<template>
  <div
    class="w-full md:w-[807px] md:h-[120px] border-[1px] md:border-[2px] border-[#389033] rounded-xl md:rounded-md"
  >
    <div
      class="md:flex items-center justify-between md:ml-[72px] md:mr-[66px] mt-[20px] md:mt-[35px]"
    >
      <p class="text-center text-[14px] md:text-[20px] text-[#389033] font-notosansCJKmd">
        무엇을 도와드릴까요?
      </p>
      <div class="relative mx-3 md:mx-0">
        <div class="mt-[11px] md:mt-0 mb-[17px] md:mb-0 relative w-full md:w-[453px]">
          <input
            type="text"
            class="w-full h-[41px] md:h-[50px] outline-none bg-[#F3F3F3] lg:bg-[#F3F3F3] rounded-lg pl-3 pr-11 text-[14px] md:text-[17px] font-notosansCJR"
            v-model="searchKeyword"
            @keyup.enter="goSearchPage()"
            @click="resultBoxVisible = true"
          />
        </div>
        <div v-if="board.searchData" class="">
          <section
            v-show="resultBoxVisible"
            class="absolute bg-[#F3F3F3] w-full md:w-[453px] top-[30px] md:top-[40px] rounded-md"
            ref="resultBox"
          >
            <div v-for="(item, index) in board.searchData" :key="'result' + index" class="">
              <router-link
                :to="{
                  path: `${detailpath}`,
                  query: { ad: item._id, type: 'qna' }
                }"
              >
                <p class="font-notosansCJR pl-3 my-3 text-[14px] md:text-[17px]">
                  {{ item.boardTitle }}
                </p>
              </router-link>
            </div>
          </section>
        </div>

        <div
          class="absolute bottom-0 right-4 md:bottom-[4px] md:right-[15px] transform -translate-y-1/2 w-[21px] h-[21px]"
          @click="goSearchPage()"
        >
          <svg
            class="w-[21px] h-[21px] cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.3"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// setup
import { computed, onUnmounted, ref, watch } from 'vue'
import { useBoardStore } from '../../../stores/useBoard'
import { onClickOutside } from '@vueuse/core'
const board = useBoardStore()
const props = defineProps({
  public: Boolean
})

//state
const resultBox = ref(null)
const resultBoxVisible = ref(false)
const searchKeyword = ref('')

// action
const goSearchPage = async () => {
  // console.log('키워드', searchKeyword.value)
  await getQnaData(searchKeyword.value)
  resultBoxVisible.value = true
}

const getQnaData = async (keyword) => {
  await board.getQnaByKeywords(keyword)
}

//getter
const detailpath = computed(() => {
  if (props.public) {
    return '/noticeDetail'
  } else {
    return '/account/noticeDetail'
  }
})

//lifeCycleHook
watch(searchKeyword, (newVal) => {
  // 자동검색
  if (newVal === '' || newVal === ' ') {
    board.searchData = null
  }
  if (newVal) {
    goSearchPage()
    // console.log('검색어:', newVal)
  }
})

onUnmounted(() => {
  // 컴포넌트 파괴시 검색결과 초기화
  board.searchData = null
})

//Event
onClickOutside(resultBox, (event) => (resultBoxVisible.value = false))
</script>

<style lang="scss" scoped></style>
