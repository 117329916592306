import { defineStore } from 'pinia'
import { ref, watchEffect } from 'vue'
import { useCartStore } from './useCart'
import { sendRequest } from '../api/serviceApi'
import axios from 'axios'

export const useUserStore = defineStore(
  'user',
  () => {
    // setter
    const token = ref('') // 쿠키가 들어왔고
    const me = ref({})
    const status = ref(null)
    const auth = ref('')
    const reviewPatch = ref(false)
    const reviewPost = ref(false)
    const originProduct = ref(null)
    const myRate = ref(0)
    const myProfilePass = ref(false)

    const loginPayload = ref({
      email: '',
      passwor: ''
    })

    const mySelected = ref([])

    const profileData = ref({
      userInfo: {
        newPassword: '',
        newPasswordCheck: '',
        newMobile: ''
      },
      addrInfo: {
        address: '',
        addressDetail: '',
        mobile: '',
        name: '',
        postcode: '',
        title: ''
      },
      serviceInfo: {
        email: false,
        sns: false,
        sms: false,
        location: false
      }
    })

    function $resetSelected() {
      mySelected.value = []
    }
    //getter
    //action
    const getrReciverList = () => {
      if (me.value.reciverList) {
        profileData.value.addrInfo = me.value.reciverList[0]
      }
    }
    const pointPatcher = async () => {
      const cart = useCartStore()
      console.log('cart.usePoint', cart.usePoint)

      const usePointResult = me.value.point - cart.usePoint + cart.calculator.totalPoint
      // 모든 물건 더해서 한 번에 받는 포인트
      console.log('usePointResult', usePointResult, 'id', me.value._id)
      const payload = {
        id: me.value._id,
        remainPoint: usePointResult
      }
      const patchUserPoint = await sendRequest({
        method: 'patch',
        path: '/users/userPointPatcher',
        data: payload
      })
      const result = patchUserPoint.result
      console.log(result)
      return result
    }

    const pointAdder = async (point) => {
      console.log('addPoint', point, 'id', me.value._id)
      const addUserPoint = me.value.point + point
      const payload = {
        id: me.value._id,
        remainPoint: addUserPoint
      }
      const patchUserPoint = await sendRequest({
        method: 'patch',
        path: '/users/userPointPatcher',
        data: payload
      })
      const result = patchUserPoint.result
      if (result === 'ok') {
        return true
      } else {
        return false
      }
    }

    const pointUse = async (point) => {
      console.log('uesPoint', point, 'id', me.value._id)
      const addUserPoint = me.value.point - point
      const payload = {
        id: me.value._id,
        remainPoint: addUserPoint
      }
      const patchUserPoint = await sendRequest({
        method: 'patch',
        path: '/users/userPointPatcher',
        data: payload
      })
      const result = patchUserPoint.result
      if (result === 'ok') {
        return true
      } else {
        return false
      }
    }

    const setToAuth = async (token) => {
      try {
        // console.log('sent token value', token)
        const tokenCheck = await axios.post(
          `${import.meta.env.VITE_API_BASE_URL}/users/getAuth`,
          {},
          {
            headers: {
              authentication: token
            }
          }
        )
        // console.log("hashing token: ", tokenCheck);
        const setMeData = tokenCheck.data
        user.status = true
        user.me = setMeData
        user.auth = setMeData.auth
      } catch (e) {
        console.log(e)
      }
    }

    return {
      // setter
      token,
      status,
      me,
      auth,
      reviewPost,
      originProduct,
      reviewPatch,
      myRate,
      mySelected,
      myProfilePass,
      profileData,
      loginPayload,
      //getter
      $resetSelected,
      //action
      getrReciverList,
      pointPatcher,
      setToAuth,
      pointAdder,
      pointUse
    }
  },
  {
    // persist: true
  }
)
