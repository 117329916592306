<template>
  <div v-if="register">
    <registerMain v-if="register.registStage === 0" />
    <registerInfo v-if="register.registStage === 1" />
    <registerReciveInfo v-if="register.registStage === 2" />
    <registerDone v-if="register.registStage === 3" />
  </div>
</template>

<script setup>
import { useRegisterStore } from '../../stores/useRegister'
import { onUnmounted } from 'vue'
import registerMain from '../../components/register/registerMain.vue'
import registerInfo from '../../components/register/registerInfo.vue'
import registerReciveInfo from '../../components/register/registerReciveInfo.vue'
import registerDone from '../../components/register/registerDone.vue'

const register = useRegisterStore()

onUnmounted(() => {
  register.registStage = 0
  register.registerStage = {
    stage0: {
      sns: false,
      location: false,
      required: false
    },
    stage1: {
      userInfo: {
        email: '',
        password: '',
        passwordCheck: '',
        name: '',
        gender: '',
        birth: '',
        mobile: ''
      },
      smsAuth: false
    },
    stage2: {
      receiverInfo: {
        name: '',
        mobile: '',
        postcode: '',
        address: '',
        addressDetail: '',
        request: ''
      }
    }
  }
  register.sendSms = false
  register.smsAuth = false
  register.validateEmail = false
  register.vendor = ''
})
</script>

<style lang="scss" scoped></style>
