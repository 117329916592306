<template>
  <div
    class="fixed inset-0 flex items-center justify-center overflow-x-hidden bg-black z-[1000] bg-opacity-80 overflow-auto"
  >
    <div class="absolute top-[5%]">
      <button class="text-white text-[32px]" @click="system.reviewImgModal = false">
        <i class="pi pi-times"></i>
      </button>
    </div>
    <swiper
      v-if="system.reviewImgArray"
      :slides-per-view="1"
      :space-between="30"
      :loop="false"
      :pagination="true"
      class="mx-3"
    >
      <swiper-slide v-for="(item, index) in system.reviewImgArray" :key="'imgs' + index" class="">
        <img
          :src="item"
          :alt="'reviewsImgs' + index"
          class="w-full h-[38vh] sm:h-[80vh] object-cover object-center rounded-md"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useSystemStore } from '../../stores/useSystem'
import { onUnmounted } from 'vue'
const system = useSystemStore()

onUnmounted(() => {
  system.reviewImgArray = []
})
</script>

<style lang="scss" scoped></style>
