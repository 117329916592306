<template>
  <div>
    <div class="items-center justify-start hidden h-auto sm:flex">
      <div v-for="menu in menuList" :key="menu.title" class="">
        <!-- <svg class="w-[20px] h-[20px] menuIcon"></svg> -->
        <div
          v-if="menu.auth === isLogin"
          class="mr-[33px] relative cursor-pointer"
          @click="modalController(menu.logic, menu.route)"
        >
          <div class="mx-auto"></div>
          <div class="flex justify-center">
            <i :class="menu.icon" style="font-size: 23px"></i>
          </div>
          <p class="text-[11px] mt-[6px] font-notosansCJK">
            {{ menu.title }}
          </p>
          <div
            v-if="menu.logic === 'goCart' && cart.cartData && cart.cartCount > 0"
            class="h-[18px] w-[18px] bg-red-600 absolute -top-2 -right-1 rounded-full flex items-center justify-center"
          >
            <p class="text-[10px] text-white font-notosansCJKmd">{{ cart.cartCount }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, useAttrs, onMounted, watchEffect, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '../../stores/useUser'
import { useSystemStore } from '../../stores/useSystem'
import { useCookies } from '@vueuse/integrations/useCookies'
import axios from 'axios'
import { useMenualCartStore } from '../../stores/useMenualCart'
import { useCartStore } from '../../stores/useCart'

const cart = useCartStore()
const cookies = useCookies() // 쿠키 초기화
const user = useUserStore()
const isLogin = ref(false)
const system = useSystemStore()
const router = useRouter()
const cartCounter = ref(0)

const getCartCount = async () => {
  if (user.me._id) {
    const payload = { userid: user.me._id }
    console.log('user detected', user.me._id)
    const getData = await axios({
      url: `${import.meta.env.VITE_API_BASE_URL}/cart/cartGetByUser`,
      method: 'POST',
      data: {
        payload: payload
      }
    })
    const result = getData.data
    cartCounter.value = result.length
    return
  } else {
    return
  }
}

const purgeCookie = async () => {
  // console.log('눌렀당')
  // cookies.set('yeondeul', { expires: new Date(Date.now() + 1 * 1 * 1) })
  // 1. 쿠키 제거시 도메인 명시
  // cookies.remove('yeondeul', { path: '/', domain: `${import.meta.env.COOKIE_BASE_URL}` })
  // 2.  expires 대신 maxAge 사용
  // cookies.set('yeondeul', '', { maxAge: 10 }) // 10ms 뒤 자동 만료 // 살아나더라
  // 3. 브라우저 캐시와 세션 쿠키 지우기
  // res.clearCookie('yeondeul', { path: '/', domain: 'yourdomain.com' }) // 택도 없지
  // 4. SameSite 속성 추가하기
  // cookies.set('yeondeul', '', { maxAge: 10, sameSite: 'Strict' }) // 지워지기는 하는데 로그인시 두번 해야하는 문제 있음 // 값이 없는 공갈 쿠키가 생김
  // 5.  로그아웃 시 서버와 클라이언트 모두에서 쿠키 삭제
  // user.token = ''
  // cookies.remove('yeondeul', { path: '/', domain: `${import.meta.env.COOKIE_BASE_URL}` })
  cookies.set('yeondeul', { expires: new Date(Date.now() + 10) }) // 10밀리초
  cookies.remove('yeondeul', { path: '/' })
  // 서버
  // res.clearCookie('yeondeul', { path: '/', domain: 'localhost' }) // res is not define.
  // vue라우터 보다 location함수가 깔끔하다
  // location.href = '/login'
  location.reload()
}

const loginRedirection = () => {}

const modalController = (logic, route) => {
  // if (logic !== 'logout' && !route) {
  //   console.log('Route is empty, defaulting to home')
  //   route = '/login' // 기본 경로로 설정
  // }
  console.log(logic, route)

  switch (logic) {
    case 'login':
      return router.push('/login')
    case 'signUp':
      return router.push('/register')
    case 'logout':
      purgeCookie()
      break // 여기에 break 추가
    case 'goCart':
      return router.push('/cart')
    case 'account':
      if (user?.me?._id) {
        return router.push(`${route}/main`)
      } else {
        // alert('마이페이지는 로그인 후 사용이 가능합니다.')
        return router.push({ path: '/login', query: { redirect: '/account/main' } })
      }
    case 'support':
      if (user?.me?._id) {
        return router.push('/account/support')
      } else {
        // console.log('auth expired');
        return router.push({ path: '/login', query: { redirect: '/account/support' } })
        // return router.push('/support')
      }
    case 'pubSpport':
      return router.push(`${route}`)
    default:
      return router.push(`/login`)
  }
}

// const getCartData = async () => {}

// watch(user, async () => {
//   await getCartCount()
// })

watchEffect(() => {
  if (user.me) {
    isLogin.value = true
  } else if (user.me === null || user.token === '') {
    isLogin.value = false
  }
})

const menuList = [
  {
    title: '로그인',
    route: '',
    icon: 'pi pi-sign-in',
    auth: false,
    logic: 'login'
  },

  {
    title: '회원가입',
    route: '',
    icon: 'pi pi-user-plus',
    auth: false,
    logic: 'signUp'
  },
  {
    title: '로그아웃',
    route: '',
    icon: 'pi pi-sign-out',
    auth: true,
    logic: 'logout'
  },
  {
    title: '장바구니',
    route: '',
    icon: 'pi pi-shopping-cart',
    auth: true,
    logic: 'goCart'
  },
  {
    title: '장바구니',
    route: '',
    icon: 'pi pi-shopping-cart',
    auth: false,
    logic: 'goCart'
  },
  {
    title: '마이페이지',
    route: '/account',
    icon: 'pi pi-user-edit',
    auth: true,
    logic: 'account'
  },
  {
    title: '고객센터',
    route: '/',
    icon: 'pi pi-info-circle',
    auth: true,
    logic: 'support'
  },
  {
    title: '고객센터',
    route: '/support',
    icon: 'pi pi-info-circle',
    auth: false,
    logic: 'pubSpport'
  }
]
</script>

<style lang="scss" scoped></style>
