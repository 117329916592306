<template>
  <!-- 공용메뉴 박스 PC -->
  <div class="lg:w-[233px]">
    <!-- 마이페이지 상단 -->
    <div
      class="hidden lg:flex lg:h-[102px] lg:mb-[19px] justify-center border-b-[1px] border-[#6D6D6D]"
    >
      <router-link to="/account/main" class="h-[30px] mt-[28px]">
        <p class="text-[26px] font-notosansCJKmd text-[#171717]">MY PAGE</p>
      </router-link>
    </div>
    <!-- 하단 메뉴 데이터 반복-->
    <div class="pl-[16px] lg:pl-0">
      <div v-for="(menus, index) in accountMenuList" :key="index" class="mb-[16px] lg:mb-[45px]">
        <!-- 타이틀 -->
        <div class="border-b-[0.5px] border-[#B2B2B2]">
          <p class="hidden lg:block lg:mb-[19px] lg:text-[16px] font-notosansCJKmd text-[#171717]">
            {{ menus.title }}
          </p>
        </div>
        <!-- 메뉴 -->
        <div v-for="menu in menus.menu" :key="menu.name">
          <router-link :to="menu.route">
            <p
              class="text-[13px] lg:text-[16px] font-notosansCJKmd text-[#232323] lg:text-[#6D6D6D] mt-[20px] lg:mt-[17px]"
              :class="menu.class"
            >
              {{ menu.name }}
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const accountMenuList = ref([
  {
    title: '주문 정보',
    menu: [
      {
        name: '주문내역·배송조회',
        route: '/account/orders',
        class: ''
      },
      {
        name: '주문취소·반품·교환내역',
        route: '/account/returns',
        class: ''
      }
    ]
  },
  {
    title: '나의 활동',
    menu: [
      {
        name: '리뷰작성',
        route: '/account/writeReview',
        class: ''
      },
      {
        name: '내가 쓴 리뷰',
        route: '/account/myReviews',
        class: ''
      },
      {
        name: '상품 문의',
        route: '/account/inquiries',
        class: ''
      }
    ]
  },
  {
    title: '내 정보',
    menu: [
      {
        name: '배송지 관리',
        route: '/account/address',
        class: ''
      },
      {
        name: '회원정보 관리',
        route: '/account/profile',
        class: ''
      },
      {
        name: '포인트 내역',
        route: '/account/points',
        class: ''
      }
    ]
  },
  {
    title: '고객센터',
    menu: [
      {
        name: '1:1 문의하기',
        route: '/account/contact',
        class: ''
      },
      {
        name: '문의내역',
        route: '/account/history',
        class: ''
      },
      {
        name: '공지사항',
        route: '/account/notices',
        class: ''
      },
      {
        name: '고객센터',
        route: '/account/support',
        class: ''
      }
    ]
  }
])

watchEffect(() => {
  if (route.path) {
    accountMenuList.value.forEach((element) => {
      element.menu.forEach((menuItem) => {
        menuItem.class = 'text-[#6D6D6D]'
        if (menuItem.route === route.path) {
          menuItem.class = 'text-black lg:text-black'
        }
      })
      return
    })
  }
})
</script>

<style lang="scss" scoped></style>
