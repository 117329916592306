<template>
  <div>
    <p class="font-notosansCJKmd text-[22px] text-[#171717] mb-[10.5px] ml-[10px]">결제수단 선택</p>
    <!-- 1단 -->
    <div class="border-t-[2px] border-b-[1px] border-b-[#B2B2B2] border-[#6D6D6D] hidden lg:flex">
      <!-- 1단 좌 -->
      <div>
        <div class="bg-[#FBFBFB]">
          <div class="w-[153px] h-[65px] flex items-center">
            <p class="ml-[27.5px] text-[15px] font-notosansCJKmd text-[#232323]">포인트 사용</p>
          </div>
        </div>
      </div>
      <!-- 1단 우 -->
      <div>
        <div class="">
          <div class="h-[65px] flex items-center">
            <input
              type="number"
              class="ml-[9.5px] border pt-1 pl-[14px] w-[227px] rounded-md text-[15px] h-[37px] text-right outline-none pr-[10px]"
              :placeholder="0"
              v-model="usePointAmmount"
            />
            <button
              class="ml-[15.8px] w-[103px] h-[37px] text-[14px] font-notosansCJKmd text-[#6D6D6D] border border-[#c4c4c4] rounded-md"
              @click="useAllPoint()"
            >
              모두 사용
            </button>
            <div class="flex items-center ml-[16px]">
              <p class="text-[15px] font-notosansCJKmd text-[#7D7D7D]">보유 포인트</p>
              <p class="ml-[9px] ext-[15px] font-notosansCJKmd text-[#232323]">
                {{ user?.me?.point.toLocaleString('kr-KO') }}p
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import { useCartStore } from '../../stores/useCart'
import { useUserStore } from '../../stores/useUser'

const cart = useCartStore()
const user = useUserStore()
const usePointAmmount = ref(0)

const temperPrice = computed(() => {
  return (
    cart.calculator.totalPrice + cart.calculator.totalShippingPrice - cart.calculator.totalDiscount
  )
})
const useAllPoint = () => {
  usePointAmmount.value = user?.me?.point
  if (user?.me?.point > temperPrice.value) {
    usePointAmmount.value = temperPrice.value
    console.log('포인트가 물건가 보다 많음', temperPrice.value)
    return
  }
  // 포인트가 물건가 보다 적을때 모든 포인트
  else if (user?.me?.point < temperPrice.value) {
    usePointAmmount.value = user?.me?.point
    console.log('포인트가 물건가 보다 적음', user?.me?.point)
  }
}

watchEffect(() => {
  if (usePointAmmount.value > user?.me?.point) {
    alert('보유 하신 포인트 이상 사용이 불가능 합니다')
    usePointAmmount.value = user?.me?.point
  }
  cart.usePoint = usePointAmmount.value
})

watchEffect(() => {
  if (cart.calculator.totalPayPrice < 0) {
    // 포인트가 물건가 보다 많을 때 물건가 맞춰서
    if (user?.me?.point > temperPrice.value) {
      usePointAmmount.value = temperPrice.value
      console.log('포인트가 물건가 보다 많음', temperPrice.value)
      return
    }
    // 포인트가 물건가 보다 적을때 모든 포인트
    else if (user?.me?.point < temperPrice.value) {
      usePointAmmount.value = user?.me?.point
      console.log('포인트가 물건가 보다 적음', user?.me?.point)
    }
    return
  }
})
</script>

<style lang="scss" scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
