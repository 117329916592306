<template>
  <div v-if="pageRady" class="w-[828px]">
    <section>
      <div class="flex justify-between ml-[15px] md:ml-[6px] mr-[9px]">
        <div class="flex items-center mb-[5px]">
          <p
            class="text-[15px] md:text-[18px] font-nanumsquareEB md:font-notosansCJKmd text-[#171717]"
          >
            공지사항
          </p>
        </div>
        <button
          class="text-[15px] font-notosansCJKmd text-[#7D7D7D] hidden md:inline"
          @click="goToAnnnounce()"
        >
          더보기
        </button>
      </div>
      <hr class="border border-[#6D6D6D] md:border-[#DCDCDC]" />
    </section>
    <section v-if="noticeDataList.length === 0">
      <div class="flex justify-center my-48">
        <p class="text-[13px] md:text-[14.5px] font-notosansCJKmd text-[#232323]">
          공지 사항이 없습니다.
        </p>
      </div>
    </section>
    <section>
      <div
        v-for="(notice, i) in noticeDataList"
        :key="i"
        class="flex items-center h-[45px] border-b justify-between"
      >
        <router-link
          :to="{
            path: `${detailpath}`,
            query: { ad: notice._id, type: 'notices' }
          }"
        >
          <p class="ml-[14px] text-[13px] md:text-[14.5px] font-notosansCJKmd text-[#232323]">
            {{ notice.boardTitle }}
          </p>
        </router-link>

        <p class="text-[14.5px] font-notosansCJKmd text-[#707070] mr-[8px] hidden md:inline">
          {{ dayjs(notice.createAt).format('YYYY.MM.DD') }}
        </p>
        <i class="pi pi-angle-right text-[#707070] text-[15px] mr-[9px] md:hidden"></i>
      </div>
    </section>
    <section class="mt-[10px] md:hidden">
      <div v-if="noticeDataList.length > 0" class="flex justify-center">
        <button
          class="w-[175px] h-[28px] border border-[#D5D5D5] rounded-md"
          @click="goToAnnnounce()"
        >
          <p class="text-[11px]">더보기</p>
        </button>
      </div>
    </section>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useBoardStore } from '../../../stores/useBoard'

const props = defineProps({
  public: Boolean
})

const pageRady = ref(false)
const board = useBoardStore()
const router = useRouter()

const detailpath = computed(() => {
  if (props.public) {
    return '/noticeDetail'
  } else {
    return '/account/noticeDetail'
  }
})

const goToAnnnounce = () => {
  // router.push('/account/notices')

  if (props.public) {
    router.push('/notices')
  } else {
    router.push('/account/notices')
  }
}
const noticeDataList = ref([])

onMounted(async () => {
  await board.getAnnounceData()
  noticeDataList.value = [...board.announceData].reverse()

  pageRady.value = true
})
</script>

<style lang="scss" scoped></style>
