<template>
  <div class="w-full h-full">
    <div class="w-full lg:w-[1186px] lg:mx-auto">
      <!-- contact title section -->
      <div class="mx-auto flex justify-center mt-[56px]">
        <!-- lef img section -->
        <section class="mt-[14px] mx-auto md:mx-0 w-[282px] md:w-auto">
          <img src="/img/about_img/about_top_left.png" alt="" />
        </section>
        <section class="ml-[111px]">
          <p class="text-[22px] mb-[113px] text-center">브랜드 소개</p>
          <div class="flex justify-center mr-[50px] relative">
            <img src="/img/about_pc/about_top_text.webp" alt="about_top_text" />
          </div>
          <div class="flex justify-center mt-[42.5px] relative">
            <img src="/img/about_pc/line_top.webp" alt="line_top" />
          </div>
          <div class="flex justify-center mt-[27.5px] relative">
            <img src="/img/about_pc/about_top_second_text.webp" alt="about_top_second_text" />
          </div>
          <div class="flex justify-center mt-[39.5px] relative">
            <img src="/img/about_pc/line_middle.webp" alt="line_middl" />
          </div>
          <div class="flex justify-center mt-[39.5px] relative">
            <img src="/img/about_img/about_top_bottom_text.png" alt="about_top_bottom_text" />
          </div>
          <div class="flex justify-center mt-[39.5px] relative">
            <img src="/img/about_pc/line_bottom.webp" alt="line_bottom" />
          </div>
          <div class="flex justify-center mt-[33px] relative">
            <img src="/img/about_pc/middle_ceo.webp" alt="middle_ceo" class="w-[333px] h-[429px]" />
          </div>
        </section>
      </div>
      <div class="flex justify-center mt-[70px] relative ml-[140px]">
        <img src="/img/about_pc/bottom_text_arry.webp" alt="bottom_text_arry" class="" />
      </div>
      <div class="mt-[78px] mb-[204px]">
        <div class="flex justify-end relative mr-[250px]">
          <img
            src="/img/about_pc/about_logo.webp"
            alt="bottom_text_arry"
            class="w-[206px] h-[82px]"
          />
        </div>
      </div>
      <!-- middle text section  -->

      <!-- middle double line -->
    </div>
    <!-- bottom text -->

    <!-- bottom Logo -->
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
