import { defineStore } from 'pinia'
import { useUserStore } from './useUser'
import { sendRequest } from '../api/serviceApi'
import { ref, computed } from 'vue'

export const useAddressStore = defineStore('address', () => {
  // State
  const userAddrData = ref(null)
  const user = useUserStore()
  const addressEdit = ref(false)
  const patchAddrindex = ref(null)
  const paymentSelectAddress = ref(null)

  // Getter
  const defaultAddress = computed(() => {
    if (userAddrData.value) {
      return userAddrData.value.addressData[userAddrData.value.defaultIndex]
    }
  })

  const userAddrDataid = computed(() => {
    if (userAddrData.value) {
      return userAddrData.value._id
    }
  })

  const defaultAddrIndex = computed(() => {
    if (userAddrData.value) {
      return userAddrData.value.defaultIndex
    }
  })
  // Action
  const getAddressByUser = async () => {
    const payload = {
      userId: user.me._id
    }
    const getData = await sendRequest({
      method: 'post',
      path: '/address/getAddressByUser',
      data: payload
    })
    const res = getData
    if (res.result === 'ok') userAddrData.value = res.data
    return res.data
  }

  const createAddress = async (datas) => {
    const payload = {
      userId: user.me._id,
      data: datas
    }
    const createReq = await sendRequest({
      method: 'post',
      path: '/address/createAddress',
      data: payload
    })
    const res = createReq
    if (res.result === 'ok') {
      return res.result
    }
  }

  const patchRenew = async (cmd, data, index, defaultIndex) => {
    const id = userAddrDataid.value
    console.log('유저 addr id: ', id)
    if (id === undefined) {
      const createNewUserAddrDb = await createAddress(data)
      if (createNewUserAddrDb === 'ok') {
        return createNewUserAddrDb
      }
      return 'badRequest'
    }
    const snaCap = async (path, payload) => {
      console.log('현재 주소지 패치 요청 커맨더', cmd)
      console.log('페이로드', payload)

      const response = await sendRequest({
        method: 'patch',
        path,
        data: payload
      })
      if (response.result === 'ok') {
        await getAddressByUser()
        return response.result
      } else {
        console.error('요청 실패:', response)
        return null
      }
    }

    switch (cmd) {
      case 'singlePatch':
        return await snaCap('/address/singleAddressPatch', { id, index, data })
      case 'singleAdd':
        return await snaCap('/address/singleAddresAdd', { id, data })
      case 'setDefaultAdd':
        return await snaCap('/address/singleDefaultAdder', { id, data })
      case 'singleDel':
        return await snaCap('/address/singleAddressDelete', { id, index })
      case 'defaultSet':
        return await snaCap('/address/changeDefaultAddress', { id, defaultIndex })
      default:
        break
    }
  }

  return {
    // State
    userAddrData,
    addressEdit,
    patchAddrindex,
    paymentSelectAddress,
    // Getter
    defaultAddress,
    userAddrDataid,
    defaultAddrIndex,
    // Action
    createAddress,
    getAddressByUser,
    patchRenew
  }
})
