<template>
  <div class="lg:max-w-[1180px] mx-auto">
    <div class="hidden lg:block">
      <!-- 테이블 헤더 -->
      <div class="flex w-full bg-[#FBFBFB]">
        <div
          v-for="(header, index) in headers"
          :key="'header' + index"
          :class="getColumnClass(index)"
          class="text-center p-4"
        >
          <p class="text-[15px] font-notosansCJKmd text-[#232323]">{{ header }}</p>
        </div>
      </div>
      <!-- 테이블 본문 -->
      <div
        v-for="(row, rowIndex) in tableData"
        :key="'row' + rowIndex"
        class="flex w-full border-b"
      >
        <div
          v-for="(item, itemIndex) in row"
          :key="'item' + itemIndex"
          :class="getColumnClass(itemIndex)"
          class="text-center p-4"
        >
          <p :class="item.class">{{ item.text }}</p>
        </div>
      </div>
      <div class="flex justify-end items-end mr-[80px] mt-[19.8px]">
        <p class="text-[12px] font-notosansCJKmd text-[#232323]">적립예정 포인트</p>
        <p class="ml-[11px] text-[14px] font-notosansCJKmd text-[#389033]">
          {{ cart.calculator.totalPoint.toLocaleString('ko-KR') }}p
        </p>
      </div>
    </div>
    <!-- 모바일 -->
    <div class="bg-[#FBFBFB] w-full lg:hidden">
      <div class="">
        <div class="flex justify-between items-center mx-[12.5px] pt-[18px]">
          <p class="text-[11px] font-notosansCJKmd text-[#232323]">총 상품금액</p>
          <p class="text-[13px] font-notosansCJKmd text-[#232323]">
            {{ cart.calculator.totalPrice.toLocaleString('ko-KR') }}원
          </p>
        </div>
        <div class="flex justify-between items-center mx-[12.5px] pt-[18px]">
          <p class="text-[11px] font-notosansCJKmd text-[#232323]">총 할인금액</p>
          <p class="text-[13px] font-notosansCJKmd text-[#232323]">
            -{{ cart.calculator.totalDiscount.toLocaleString('ko-KR') }}원
          </p>
        </div>
        <div class="flex justify-between items-center mx-[12.5px] pt-[18px]">
          <p class="text-[11px] font-notosansCJKmd text-[#232323]">총 배송비</p>
          <p class="text-[13px] font-notosansCJKmd text-[#232323]">
            +{{ cart.calculator.totalShippingPrice.toLocaleString('ko-KR') }}원
          </p>
        </div>
        <div class="flex justify-end">
          <hr class="mt-[35px] mb-[28px] border-[#B2B2B2] w-[70%] mr-[12.5px]" />
        </div>
        <div class="flex justify-between items-center mx-[12.5px] py-[18px]">
          <p class="text-[11px] font-notosansCJKmd text-[#232323]">결제금액</p>
          <p class="text-[16px] font-notosansCJKmd text-[#389033]">
            {{ cart.calculator.totalPayPrice.toLocaleString('ko-KR') }}원
          </p>
        </div>
        <div class="text-right mr-[12.5px] pb-[12.7px]">
          <p class="text-[10px] font-notosansCJKmd text-[#6D6D6D]">
            위 주문 내용을 확인하였으며, 하기 필수 약관에 동의합니다.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useCartStore } from '../../stores/useCart'

const cart = useCartStore()
// 테이블 헤더 데이터
const headers = ['총 상품금액', '', '총 할인금액', '', '총 배송비', '', '결제 금액']

// 테이블 바디 데이터
const tableData = computed(() => [
  [
    {
      text: `${cart.calculator.totalPrice.toLocaleString('ko-KR')}원`,
      class: 'text-[18px] font-notosansCJKmd text-[#171717]' // 첫 번째 열의 클래스
    },
    {
      text: '-',
      class: 'text-[18px] font-notosansCJKmd text-[#171717]' // 필요에 따라 클래스 추가
    },
    {
      text: `${cart.calculator.totalDiscount.toLocaleString('ko-KR')}원`,
      class: 'text-[18px] font-notosansCJKmd text-[#171717]' // 두 번째 열의 클래스
    },
    {
      text: '+',
      class: 'text-[18px] font-notosansCJKmd text-[#171717]'
    },
    {
      text: `${cart.calculator.totalShippingPrice.toLocaleString('ko-KR')}원`,
      class: 'text-[18px] font-notosansCJKmd text-[#171717]' // 세 번째 열의 클래스
    },
    {
      text: '=',
      class: 'text-[18px] font-notosansCJKmd text-[#389033]'
    },
    {
      text: `${cart.calculator.totalPayPrice.toLocaleString('ko-KR')}원`,
      class: 'text-[18px] font-notosansCJKmd text-[#389033]' // 네 번째 열의 클래스
    }
  ]
])

// 짝수 열과 홀수 열의 너비 설정
const evenWidth = ref('w-[250px]') // 짝수 열의 너비
const oddWidth = ref('w-[50px]') // 홀수 열의 너비

// 인덱스에 따라 너비 클래스 반환
const getColumnClass = (index) => (index % 2 === 0 ? evenWidth.value : oddWidth.value)
</script>

<style scoped>
/* 필요한 경우 추가 스타일 */
</style>
