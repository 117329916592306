<template>
  <div class="rounded-md shadow-lg w-[192px] h-auto">
    <div class="pt-[16px] pb-[7px]">
      <div v-for="tem in filterData" :key="tem" class="flex items-center pb-[9px] pl-[10px]">
        <input
          type="checkbox"
          class="w-[18px] h-[18px] accent-[#389033] border-none"
          :name="tem"
          :value="tem"
          v-model="groupChecked"
        /><label
          :for="tem"
          class="ml-[10px] font-notosansCJKmd text-[#7D7D7D] text-[15px] pt-[0.1px]"
          >{{ tem }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, watchEffect } from 'vue'
import { useProductStore } from '../../../stores/useProduct'

const product = useProductStore()
const props = defineProps({
  filterData: Array
})

const groupChecked = ref([])

watchEffect(() => {
  if (product.filterList.groupFilter.check) {
    product.filterList.groupFilter.class = 'border border-[#389033] text-[#389033] w-[109px]'
  } else {
    product.filterList.groupFilter.class = 'w-[109px] text-[#808080]'
  }
})

watch(
  groupChecked,
  (newChecked) => {
    product.filterList.groupFilter.check = newChecked.length > 0
    product.filterList.groupFilter.list = newChecked
  },
  { deep: true }
)

onMounted(() => {
  groupChecked.value = product.filterList.groupFilter.list
})
</script>

<style lang="scss" scoped></style>
