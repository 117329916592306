<template>
  <div class="max-w-[1180px]">
    <!-- 판매사 & 라우터 -->
    <div class="flex items-center justify-between mb-[6.5px] lg:mb-[12.5px] ml-[19px] lg:ml-[8px]">
      <div class="flex items-center">
        <router-link>
          <p class="lg:text-[18px] text-[16px] font-notosansCJKmd text-[#232323]">
            {{ data.company }}
          </p>
        </router-link>
        <p class="hidden md:inline ml-[9.4px] text-[#707070] text-[10px]">></p>
        <p
          class="hidden md:inline ml-[9.4px] lg:text-[14px] text-[12px] text-[#232323] font-notosansCJKmd"
        >
          배송상품
        </p>
      </div>

      <p
        class="text-right text-[9px] font-notosansCJKmd text-[#6D6D6D] lg:hidden mr-[13.5px] -mb-3"
      >
        {{ shippingPrice.toLocaleString('ko-KR') }}원 이상 구매시 무료배송
      </p>
    </div>

    <!-- 테이블 -->
    <div class="border-t-2 lg:border-[#B2B2B2] pb-[14px] border-b-[1px] hidden lg:block">
      <div class="mt-[13.8px] flex w-full">
        <div class="w-[586px]">
          <p class="text-[15px] font-notosansCJKmd text-[#232323] ml-[177px]">제품명</p>
        </div>
        <div class="w-[84px]">
          <p class="text-[15px] font-notosansCJKmd text-[#232323] ml-[28.7px]">수량</p>
        </div>
        <div class="w-[287px]">
          <p class="text-[15px] font-notosansCJKmd text-[#232323] ml-[138px]">가격</p>
        </div>
        <div class="w-[117px]">
          <p class="text-[15px] font-notosansCJKmd text-[#232323] ml-[33.8px]">배송비</p>
        </div>
        <div class="w-[106px]"></div>
      </div>
    </div>
    <!-- 아이템 -->
    <div class="mb-[28px] w-full">
      <div v-for="(item, index) in data.items" :key="'datas' + index">
        <cartNewItem :item="item" :isOrder="isOrder" />
      </div>
    </div>
    <!-- 소계 정산 -->
    <div>
      <cartDivShippingCounter
        :price="price"
        :itemCount="itemCount"
        :company="data.company"
        @update-data="handleData"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useCartStore } from '../../stores/useCart'
import cartDivShippingCounter from './cartDivShippingCounter.vue'
import cartNewItem from './cartNewItem.vue'

const router = useRouter()
const cart = useCartStore()

const props = defineProps({
  data: Object,
  isOrder: Boolean
})

const childData = ref('')
const handleData = (data) => {
  childData.value = data
}

const ammountInfos = computed(() => ({
  company: props.data.company,
  ammountData: childData.value.slice(0, -2),
  totalPayment: childData.value[childData?.value?.length - 1]
}))

watchEffect(() => {
  if (ammountInfos.value) {
    cart.addCompanyData(ammountInfos.value)
  }
})

const shippingPrice = ref(0)

const price = computed(() => ({
  resultShipAmmount: props.data.resultShipAmmount,
  totalDiscountAmmount: props.data.totalDiscountAmmount,
  totalPrice: props.data.totalPrice
}))

const itemCount = computed(() => {
  return props.data.items.length
})

onMounted(() => {
  if (props.data.company === '연들') {
    shippingPrice.value = cart.freeShipAmmount.yeondeul
  } else {
    shippingPrice.value = cart.freeShipAmmount.jagidaum
  }
})
</script>

<style lang="scss" scoped></style>
