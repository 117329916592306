<template>
  <div>
    <div class="md:mt-[113px]">
      <div class="flex justify-center">
        <img
          src="/img/billing/payDone.webp"
          alt=""
          class="h-[35px] w-[35px] md:h-[60px] md:w-[60px]"
        />
      </div>
      <div class="text-center mt-[31px] md:mt-[46px]">
        <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#389033]">
          연들을 이용해 주셔서 감사드립니다.
        </p>
        <p
          v-if="user.me.name"
          class="mt-[11px] md:mt-[19px] text-[13px] md:text-[20px] font-notosansCJKmd text-[#171717]"
        >
          {{ user.me.name || 'USER_NAME' }} 고객님의 주문이 완료되었습니다.
        </p>
      </div>
    </div>
    <!-- 조작 버튼 -->
    <div class="flex justify-center mt-[56px] md:mt-[107px]">
      <button
        class="h-[43px] w-[165px] md:h-[56px] md:w-[247px] border border-solid border-[#0C370A] rounded-md"
        @click="goTo('account/orders')"
      >
        <p class="text-[15px] md:text-[18px] font-notosansCJKmd text-[#1C1C1C]">주문 상세보기</p>
      </button>
      <button
        class="ml-[14px] md:ml-[30px] h-[43px] w-[165px] md:h-[56px] md:w-[247px] border border-solid border-[#0C370A] rounded-md bg-[#389033]"
        @click="goTo('')"
      >
        <p class="text-[15px] md:text-[18px] font-notosansCJKmd text-white">쇼핑 계속하기</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useUserStore } from '../../stores/useUser'

const router = useRouter()
const user = useUserStore()

const goTo = (link) => {
  router.push(`/${link}`)
}
</script>

<style lang="scss" scoped></style>
