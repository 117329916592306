<template>
  <div class="ml-[13px] md:ml-[9px]">
    <p class="text-[14px] md:text-[18px] font-notosansCJKbold text-[#3D3D3D]">인테리어·시공사례</p>
    <div class="swiper-container mt-[16px] md:mt-[19px]">
      <swiper
        :slides-per-view="'auto'"
        :space-between="spaces"
        :loop="false"
        class="swiper-wrapper"
      >
        <swiper-slide v-for="(item, index) in postData" :key="'swiper' + index">
          <router-link :to="`/post/details/${item._id}`">
            <productPostCard :item="item" />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <section v-if="postData.length < 1" class="flex justify-center my-10">
      <p class="text-[16px] font-notosansCJR text-[#707070]">상품 관련 포스트를 준비중 입니다.</p>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import productPostCard from './productPostCard.vue'
import { sendRequest } from '../../../api/serviceApi'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { useProductStore } from '../../../stores/useProduct'

const product = useProductStore()
const spaces = ref(10)
const postData = ref([])

const getPost = async () => {
  // console.log('포스트 찾을 제품 아이디', product.productItem._id)

  const sendData = {
    ids: product.productItem._id
  }

  const getPostByIds = await sendRequest({
    method: 'post',
    path: '/post/getPostByBadgeList',
    data: sendData
  })

  // const getCase = await sendRequest({
  //   method: 'post',
  //   path: '/post/getLatestCase'
  // })

  if (getPostByIds.data?.length) {
    const allCaseAndInterior = [...getPostByIds.data]

    // const result = await sendRequest({ method: 'post', path: '/post/getLatestOnePost' })

    postData.value = allCaseAndInterior
  } else {
    return
  }
}

onMounted(async () => {
  setTimeout(() => {
    getPost()
  }, 100)
})
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  position: relative;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
