<template>
  <div class="lg:w-[1180px] lg:mx-auto h-full mt-0 lg:mt-[58px]">
    <shippingModal v-if="system.adminShippingInputModal" />
    <exchangeModal v-if="system.adminExchangeModal" />
    <refundModal v-if="system.adminRefundModal" />
    <div class="mx-auto">
      <!-- 플렉스 박스 -->
      <div class="md:flex">
        <!-- 좌측 메뉴 -->
        <div class="hidden lg:block">
          <adminMenuBox />
        </div>
        <!-- 오른쪽 컨테이너 -->
        <div class="lg:ml-[30px] w-full">
          <div>
            <div class="mt-[66px]">
              <p class="ml-[10px] mb-[5px] text-[20px] font-notosansCJKmd text-[#171717]">
                {{ route.params.id }}
              </p>
              <hr class="mb-[21px] border-[#c7c6c6]" />
            </div>
            <div class="mb-[50px]">
              <component
                v-if="isComponentLoaded && currentComponent"
                :is="currentComponent"
                class="ease-in-out"
              />
              <div v-else class="text-center mt-52">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"> </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="route.params.id !== 'main'" class="lg:hidden">
        <adminMenuBox />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, shallowRef, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import adminMenuBox from '../../components/admin/adminMenuBox.vue'
import { useSystemStore } from '../../stores/useSystem'
import shippingModal from '../../components/admin/order/shippingModal.vue'
import exchangeModal from '../../components/admin/order/exchangeModal.vue'
import refundModal from '../../components/admin/order/refundModal.vue'
import { useUserStore } from '../../stores/useUser'

const user = useUserStore()
const system = useSystemStore()
const router = useRouter()
const route = useRoute()

const pageReady = ref(false)

const isComponentLoaded = shallowRef(false)
const currentComponent = shallowRef(null)

const componentsMap = {
  // 어드민 기본페이지
  main: () => import('../../components/admin/adminMain.vue'),
  noticeDetail: () => import('../../components/admin/board/adminNoticeDetail.vue'), // 공지 상세
  // 상품관리
  productNew: () => import('../../components/admin/product/systemProductEditor.vue'),
  productManage: () => import('../../components/admin/product/systemProductList.vue'),
  productExtraNew: () => import('../../components/admin/extra/extraEditor.vue'),
  productExtraManage: () => import('../../components/admin/extra/extraMng.vue'),

  // 결제 관리
  payStatistics: () => import('../../components/admin/payment/adminPaystaticMain.vue'),

  // 판매관리
  orders: () => import('../../components/admin/order/adminCombineOrders.vue'),
  shipping: () => import('../../components/admin/order/adminCombineOrders.vue'),
  cancel: () => import('../../components/admin/order/adminCombineOrders.vue'),
  payment: () => import('../../components/admin/order/adminCombineOrders.vue'),
  refund: () => import('../../components/admin/order/adminCombineOrders.vue'),
  exchange: () => import('../../components/admin/order/adminCombineOrders.vue'),

  // 리뷰관리
  // 회원
  userManage: () => import('../../components/admin/user/adminUserManage.vue'),

  reviewManage: () => import('../../components/admin/review/adminReviewMain.vue'),
  orderReport: () => import('../../components/admin/report/orderReport/orderReportMain.vue'),
  productReport: () =>
    import('../../components/admin/report/productReport/adminProductReportMain.vue'),
  contactReport: () => import('../../components/admin/report/contactReport/contactReportMain.vue'),
  //게시글 관리
  postNew: () => import('../../components/admin/post/postEditor.vue'),
  news: () => import('../../components/admin/post/postManager.vue'),
  cases: () => import('../../components/admin/post/postManager.vue'),
  interior: () => import('../../components/admin/post/postManager.vue'),

  // 시스템관리
  board: () => import('../../components/admin/board/adminBoardMain.vue'),
  qna: () => import('../../components/admin/board/qna/adminQnaMain.vue'),
  banner: () => import('../../components/admin/system/systemMng.vue'),
  category: () => import('../../components/admin/system/systemCategoryMng.vue'),
  shippingAmount: () =>
    import('../../components/admin/system/shippingAmount/adminShippingAmountMain.vue')
}

// 현재 경로 및 컴포넌트 동적 로딩 처리
watchEffect(async () => {
  isComponentLoaded.value = false
  const loadComponent = componentsMap[route.params.id]

  if (loadComponent) {
    try {
      const componentModule = await loadComponent()
      currentComponent.value = componentModule.default || componentModule
      setTimeout(() => {
        isComponentLoaded.value = true
      }, 150)
    } catch (error) {
      console.error('컴포넌트를 로드하는 중 오류가 발생했습니다:', error)
      router.push('/error')
    }
  } else {
    router.push('/error')
  }
})

const auth = computed(() => user.auth)
watch(auth, (newAuth) => {
  if (newAuth) {
    onMounted(() => {
      console.log('권한', newAuth)

      if (newAuth !== 'admin') {
        console.log('관리자가 아니야!')
      } else {
        pageReady.value = true
      }
    })
  }
})
</script>

<style lang="scss" scoped></style>
