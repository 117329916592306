<template>
  <div
    class="bg-[#FBFBFB] lg:bg-transparent h-[52px] lg:h-auto mr-[2.5px] lg:mr-0 flex items-center justify-end lg:block"
    ref="counterRef"
  >
    <div class="mr-[5.3px] lg:mr-0">
      <div class="flex items-center justify-end">
        <p class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717]">
          총 상품
          {{ totalPrice.toLocaleString('ko-KR') }}원
        </p>
        <p
          v-if="price.totalDiscountAmmount > 0"
          class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717] ml-2"
        >
          - 할인 {{ price.totalDiscountAmmount.toLocaleString('ko-KR') }}원
        </p>
        <p class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717] ml-2">
          + 배송비 {{ price.resultShipAmmount.toLocaleString('ko-KR') }}원
        </p>

        <p class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717] ml-1">
          {{ combineShipping }}
        </p>
        <p
          v-if="cart.islandPrice > 0"
          class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717] ml-2"
        >
          + 도서산간지역 {{ cart.islandPrice.toLocaleString('ko-KR') }}원
        </p>
        <p
          class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717] ml-1 hidden lg:block"
        >
          = 총 {{ payPrice.toLocaleString('ko-KR') }}원
        </p>
      </div>
      <p
        class="text-[11px] lg:text-[15px] font-notosansCJKmd text-[#171717] ml-1 lg:hidden text-right"
      >
        = 총 {{ payPrice.toLocaleString('ko-KR') }}원
      </p>
    </div>
    <div class="hidden lg:flex justify-end mt-[2px]">
      <p class="text-[12px] font-notosansCJKmd text-[#6D6D6D]">
        {{ shippingPrice.toLocaleString('ko-KR') }}원 이상 구매시 무료배송
      </p>
    </div>
    <!-- {{ pdatas }} -->
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect, onBeforeUnmount } from 'vue'
import { useCartStore } from '../../stores/useCart'

const cart = useCartStore()
const props = defineProps({
  price: Object,
  itemCount: Number,
  company: String
})

const counterRef = ref(null)

const shippingPrice = ref(0)

const totalPrice = computed(() => {
  let result = props.price.totalPrice + props.price.totalDiscountAmmount
  return result.toLocaleString('ko-KR')
})

const payPrice = computed(() => {
  let result = props.price.totalPrice + props.price.resultShipAmmount
  return result.toLocaleString('ko-KR')
})

const combineShipping = computed(() => {
  if (props.price.resultShipAmmount !== 0 && props.itemCount > 1) {
    return '(묶음배송)'
  } else if (props.price.resultShipAmmount === 0) {
    return '(무료배송)'
  } else {
    return ''
  }
})

const pdatas = ref([])
let observer

const updatePdatas = () => {
  if (counterRef.value) {
    const paragraphs = counterRef.value.querySelectorAll('p')
    const paragraphTexts = Array.from(paragraphs).map((p) => p.textContent.trim())
    pdatas.value = paragraphTexts.slice(0, -1) // 마지막 요소 제외
  }
}

const emit = defineEmits(['updateData'])
const sendData = () => {
  emit('updateData', pdatas.value) // 위에 정의된 updateData에 날려 보낼 데이터를 지정한다
}

watchEffect(() => {
  if (pdatas.value.length) {
    // pdatas가 비어있지 않을 때만
    sendData()
  }
})

onMounted(() => {
  if (props.company === '연들') {
    shippingPrice.value = cart.freeShipAmmount.yeondeul
  } else {
    shippingPrice.value = cart.freeShipAmmount.jagidaum
  }
  // counterRef로 참조된 상위 div 내 모든 <p> 태그의 텍스트를 수집
  updatePdatas()

  observer = new MutationObserver(() => {
    updatePdatas() // DOM 변경 시 데이터 업데이트
  })

  if (counterRef.value) {
    observer.observe(counterRef.value, {
      childList: true,
      subtree: true,
      characterData: true
    })
  }
})

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<style lang="scss" scoped></style>
