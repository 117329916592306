<template>
  <div v-if="item" ref="conttainers">
    <div>
      <button
        class="h-[45px] w-full mb-[10px] border-[1px] border-solid border-[#C1C1C1] rounded-lg text-[16px] text-left pl-[26px] flex items-center justify-between"
        @click="toggleOptions('type')"
      >
        <p v-if="!selectedType" class="text-[16px] font-notosansCJR text-[#6D6D6D]">상품 유형</p>
        <p v-else class="text-[16px] font-notosansCJR text-[#6D6D6D]">
          {{ selectedType.optionName }}
        </p>
        <div class="mr-[14.5px]">
          <i v-if="!options1" class="pi pi-chevron-down text-[#707070] text-[11px] font-bold"></i>
          <i v-else class="pi pi-chevron-up text-[#707070] text-[11px] font-bold"></i>
        </div>
      </button>
      <ul
        v-if="options1"
        class="border-1 border-[#C1C1C1]"
        :class="`${optionListClass} `"
        :style="`width: ${width}px`"
      >
        <li
          v-for="(type, index) in optionParent"
          :key="`type-${index}`"
          class="h-[45px] flex items-center"
        >
          <button @click="selectOption('type', type)">
            <span class="ml-[22px] text-[16px] font-notosansCJR text-[#6D6D6D]">
              {{ type.optionName }} + {{ type.extraCoast.toLocaleString('ko-KR') }} 원
            </span>
          </button>
        </li>
      </ul>
    </div>
    <!-- 컬러 선택 -->
    <div>
      <button
        class="h-[45px] w-full mb-[10px] border-[1px] border-solid border-[#C1C1C1] rounded-lg text-[16px] text-left pl-[26px] flex items-center justify-between"
        @click="toggleOptions('color')"
      >
        <p v-if="!selectedColor" class="text-[16px] font-notosansCJR text-[#6D6D6D]">컬러 선택</p>
        <p v-else class="text-[16px]">{{ selectedColor.optionName }}</p>
        <div class="mr-[14.5px]">
          <i v-if="!options2" class="pi pi-chevron-down text-[#707070] text-[11px] font-bold"></i>
          <i v-else class="pi pi-chevron-up text-[#707070] text-[11px] font-bold"></i>
        </div>
      </button>
      <ul
        v-if="options2"
        class="border-1 border-[#C1C1C1]"
        :class="`${optionListClass} `"
        :style="`width: ${width}px`"
      >
        <li
          v-for="(color, index) in optionChild"
          :key="`color-${index}`"
          class="h-[45px] flex items-center"
        >
          <button @click="selectOption('color', color)">
            <span class="ml-[22px] text-[16px] font-notosansCJR text-[#6D6D6D]">
              {{ color.optionName }} + {{ color.extraCoast.toLocaleString('ko-KR') }} 원
            </span>
          </button>
        </li>
      </ul>
    </div>

    <!-- 추가 상품 선택 -->
    <div v-if="extraList.length > 0">
      <button
        class="h-[45px] w-full mb-[10px] border-[1px] border-solid border-[#C1C1C1] rounded-lg text-[16px] text-left pl-[26px] flex items-center justify-between"
        @click="toggleOptions('extra')"
      >
        <p v-if="!selectedExtra" class="text-[16px] font-notosansCJR text-[#A5A5A5]">추가 상품</p>
        <p v-else class="text-[16px] font-notosansCJR text-[#A5A5A5]">{{ selectedExtra.title }}</p>
        <div class="mr-[14.5px]">
          <i v-if="!options3" class="pi pi-chevron-down text-[#707070] text-[11px] font-bold"></i>
          <i v-else class="pi pi-chevron-up text-[#707070] text-[11px] font-bold"></i>
        </div>
      </button>
      <ul
        v-if="options3"
        class="border-1 border-[#C1C1C1]"
        :class="`${optionListClass}`"
        :style="`width: ${width}px`"
      >
        <li
          v-for="(extra, index) in extraList"
          :key="`extra-${index}`"
          class="h-[45px] flex items-center"
        >
          <button @click="selectOption('extra', extra)">
            <span class="ml-[22px] text-[16px] font-notosansCJR text-[#A5A5A5]">
              {{ extra.title }} + {{ extra.price.toLocaleString('ko-KR') }} 원
            </span>
          </button>
        </li>
      </ul>
    </div>

    <!-- 선택된 상품 목록 -->
    <div
      v-for="(selected, index) in product.product_option_selected.done"
      :key="`selected-${index}`"
    >
      <div
        class="h-[113px] border border-solid-1 border-[#C1C1C1] bg-[#FBFBFB] rounded-md mt-[5px]"
      >
        <div class="flex justify-between items-center mt-[9px]">
          <p class="ml-[16px] text-[15px] text-black font-notosansCJKmd">
            상품유형 : {{ selected.types }}
          </p>
          <i
            class="pi pi-times text-[12px] text-[#232323] mr-[14px] cursor-pointer"
            @click="deleteSelected(index)"
          >
          </i>
        </div>
        <p class="ml-[22px] text-[15px] mt-[2px] text-[#6D6D6D] font-notosansCJKmd">
          └ 컬러: {{ selected.color }}
        </p>
        <section class="mt-[7.5px]">
          <div class="flex justify-between items-center">
            <div class="flex h-[36px] w-[84px] ml-[11px] border border-[#C1C1C1] rounded-xl">
              <button
                class="w-[30px] bg-white rounded-l-xl ml-1 text-[16px] font-bold"
                @click="adjustQuantity(index, 'minus')"
              >
                -
              </button>
              <p class="w-[24px] text-center bg-white mt-[5px] font-notosansCJKmd">
                {{ selected.ea }}
              </p>
              <button
                class="w-[30px] bg-white rounded-r-xl text-[16px] font-bold mr-1"
                @click="adjustQuantity(index, 'plus')"
              >
                +
              </button>
            </div>
            <p class="mr-[16.5px] text-[20px] text-black font-notosansCJKmd">
              {{ selected.price.toLocaleString('ko-KR') }} 원
            </p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, onMounted, onUnmounted } from 'vue'
import { useUserStore } from '../../../stores/useUser'
import { useProductStore } from '../../../stores/useProduct'
import { useElementSize } from '@vueuse/core'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useCartStore } from '../../../stores/useCart'

const conttainers = ref(null)
const { width, height } = useElementSize(conttainers)

const cart = useCartStore()
const user = useUserStore()
const product = useProductStore()
const props = defineProps({ item: Object })

const pageReady = ref(false)
const options1 = ref(false)
const options2 = ref(false)
const options3 = ref(false)
const selectedType = ref(null)
const selectedColor = ref(null)
const selectedExtra = ref(null)
const optionParent = ref([])
const optionChild = ref([])
const extraList = ref([])

const alertTime = ref(1200)
const toastLock = ref(false)

const toastLockCounter = () => {
  setTimeout(() => {
    toastLock.value = false
  }, alertTime.value)
}

const toatsInOven = () => {
  if (toastLock.value) {
    return
  }
  toastLock.value = true
  toast(`구매 가능한 수량을 초과 하였어요`, {
    theme: 'auto',
    type: 'error',
    autoClose: alertTime.value,
    transition: 'flip',
    dangerouslyHTMLString: true
  })
  toastLockCounter()
}

onMounted(() => {
  pageReady.value = true
  optionParent.value = props.item.options.filter((option) => option.parentOption === '')
  product.product_option_selected.parnet = optionParent.value
  extraList.value = props.item.extra
})

const toggleOptions = (optionType) => {
  if (optionType === 'type') {
    options1.value = !options1.value
    options2.value = false
    options3.value = false
  } else if (optionType === 'color') {
    if (selectedType.value) {
      // 상품 유형이 선택된 경우에만 색상 선택 열기
      options1.value = false
      options2.value = !options2.value
      options3.value = false
    }
  } else if (optionType === 'extra') {
    options1.value = false
    options2.value = false
    options3.value = !options3.value
  }
}

const selectOption = (optionType, optionValue) => {
  if (optionType === 'type') {
    selectedType.value = optionValue
    optionChild.value = props.item.options.filter(
      (option) => option.parentOption === optionValue.optionName
    )
    options1.value = false // 옵션 선택 후 드롭다운 닫기
  } else if (optionType === 'color') {
    selectedColor.value = optionValue
    options2.value = false // 옵션 선택 후 드롭다운 닫기
  } else if (optionType === 'extra') {
    selectedExtra.value = optionValue
    options3.value = false // 옵션 선택 후 드롭다운 닫기
  }
}

const resetSelected = () => {
  selectedType.value = null
  selectedColor.value = null
  selectedExtra.value = null
  optionChild.value = [] // 선택 초기화 시 optionChild도 초기화
}

const addProduct = () => {
  const newProduct = {
    name: `${selectedType.value.optionName} - ${selectedColor.value.optionName}`,
    types: selectedType.value.optionName,
    color: selectedColor.value.optionName,
    _id: props.item._id,
    ea: 1,
    price:
      props.item.price +
      selectedType.value.extraCoast +
      selectedColor.value.extraCoast -
      props.item.sale_of_per,
    fixPrice:
      props.item.price +
      selectedType.value.extraCoast +
      selectedColor.value.extraCoast -
      props.item.sale_of_per
  }
  if (product.product_option_selected.done.some((product) => product.name === newProduct.name)) {
    alert('이미 선택하신 상품입니다.')
    resetSelected()
    return
  }
  // console.log('현재 선택된 옵션 토탈 값: ', quantityCheckerIsOver('newOption'))

  if (quantityCheckerIsOver('newOption') === true) {
    toatsInOven()
    resetSelected()
    return
  }

  product.product_option_selected.done.push(newProduct)
  product.product_option_selected.done = product.product_option_selected.done
  user.mySelected = product.product_option_selected.done
  resetSelected()
}

const quantityCheckerIsOver = (cmd) => {
  if (!product.product_option_selected.done) {
    console.log('현재 설정된 옵션값 없음')
    return false
  }

  let maxSelectedQuantity
  let types

  switch (cmd) {
    case 'newOption':
      // console.log('newOptions selected')
      maxSelectedQuantity = selectedType.value.quantity
      types = selectedType.value.optionName
      break

    default:
      maxSelectedQuantity = selectedType.value.quantity
      types = selectedType.value.optionName
      break
  }

  // console.log('선택값', types)
  let totalEa = product.product_option_selected.done
    .filter((item) => item.types === types)
    .reduce((sum, item) => sum + item.ea, 0)

  let totalCartEa = cart.cartData
    .filter((cartItem) => cartItem.options.types === types)
    .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

  return totalEa + totalCartEa >= maxSelectedQuantity
}

const addExtraProduct = () => {
  const newExtra = {
    name: selectedExtra.value.title,
    types: '부가상품',
    color: selectedExtra.value.title,
    _id: props.item._id,
    ea: 1,
    price: selectedExtra.value.price,
    fixPrice: selectedExtra.value.price
  }

  if (product.product_option_selected.done.some((extra) => extra.name === newExtra.name)) {
    alert('이미 선택하신 추가 상품입니다.')
    return
  }

  product.product_option_selected.done.push(newExtra)
  selectedExtra.value = null // 추가 상품 선택 후 초기화
}

const extraProductquantityCheck = (quantity, titles) => {
  let findQuantity = quantity

  const names = titles

  let cartEaCounter = cart.cartData
    .filter((cartItem) => cartItem.options.name === names)
    .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

  let eaCounter = product.product_option_selected.done
    .filter((item) => item.name === names)
    .reduce((sum, item) => sum + item.ea, 0)

  const totalEaCounter = eaCounter + cartEaCounter
  if (findQuantity === totalEaCounter || findQuantity < totalEaCounter) {
    selectedExtra.value = null
    return true
  }
  return false
}

const adjustQuantity = (oi, command) => {
  if (command === 'minus') {
    if (product.product_option_selected.done[oi].ea < 2) {
      return
    }
    product.product_option_selected.done[oi].ea -= 1
  } else if (command === 'plus') {
    // 초과 체크

    // 부가상품 핸들링
    const types = product.product_option_selected.done[oi].types
    if (types === '부가상품') {
      let findQuantity = product.productItem.extra.find((item) => {
        return item.title === product.product_option_selected.done[oi].name
      })

      const names = product.product_option_selected.done[oi].name
      // 카트에 짱박은 부가상품도 찾아야됨
      let cartEaCounter = cart.cartData
        .filter((cartItem) => cartItem.options.name === names)
        .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

      let eaCounter = product.product_option_selected.done
        .filter((item) => item.name === names)
        .reduce((sum, item) => sum + item.ea, 0)

      const totalEaCounter = eaCounter + cartEaCounter
      console.log(
        types,
        '의 ',
        '설정된 최대 갯수: ',
        findQuantity.quantity,
        '중 ',
        totalEaCounter,
        ' 선택됨'
      )
      if (findQuantity.quantity === totalEaCounter || findQuantity.quantity < totalEaCounter) {
        toatsInOven()
        return
      }

      product.product_option_selected.done[oi].ea += 1
      return
    }

    // 수정

    let findCart = product.product_option_selected.parnet.find((item) => {
      return item.optionName === types
    })

    // 장바구니에서 동일한 타입의 상품을 찾아 수량을 합산
    let cartEaCounter = cart.cartData
      .filter((cartItem) => cartItem.options.types === types)
      .reduce((sum, cartItem) => sum + cartItem.options.ea, 0)

    let eaCounter = product.product_option_selected.done
      .filter((item) => item.types === types)
      .reduce((sum, item) => sum + item.ea, 0)

    const totalEaCounter = eaCounter + cartEaCounter
    // console.log('뭘 찾은거야', eaCounter, cartEaCounter)
    // console.log('totalEaCounter:', totalEaCounter)
    // console.log(
    //   types,
    //   '의 ',
    //   '설정된 최대 갯수: ',
    //   findCart.quantity,
    //   '중 ',
    //   totalEaCounter,
    //   ' 선택됨'
    // )

    if (findCart.quantity === totalEaCounter || findCart.quantity < totalEaCounter) {
      // console.log('재고 초과 리턴함')
      toatsInOven()
      return
    }

    // 초과 체크 done
    product.product_option_selected.done[oi].ea += 1
  }
}

const deleteSelected = (index) => {
  product.product_option_selected.done.splice(index, 1)
  // user.mySelected = product.product_option_selected.done
}

const emit = defineEmits(['updateData'])
const sendData = () => {
  emit('updateData', {
    done: product.product_option_selected.done,
    parent: optionParent.value
  })
}

watchEffect(() => {
  if (selectedType.value && selectedColor.value) {
    addProduct()
  }
})

watchEffect(() => {
  if (selectedExtra.value) {
    const checkResult = extraProductquantityCheck(
      selectedExtra.value.quantity,
      selectedExtra.value.title
    )
    if (checkResult) {
      toatsInOven()
      return
    } else {
      addExtraProduct()
    }
  }
})

watchEffect(() => {
  if (product.product_option_selected.done) {
    sendData()
  }
})

onUnmounted(() => {
  product.product_option_selected = {
    done: [],
    parnet: []
  }
})

const optionListClass =
  'h-auto border border-solid-[1.4px] border-gray-300 bg-white rounded-md absolute z-25 -mt-[5px]'
</script>

<style scoped></style>
