<template>
  <div class="">
    <div class="">
      <div class="ml-[10.8px]">
        <p v-if="!isRecomnd" class="text-[#5A5656] font-bold text-[23px] hidden lg:block">
          {{ data.tag }}
        </p>
        <div v-if="!isRecomnd" class="flex items-center">
          <p class="text-[18px] font-bold text-[#5A5656] ml-3 hidden lg:block">{{ data.title }}</p>
          <p
            v-if="data.tag !== 'NEW ARRIVAL' && data.tag !== 'Pick'"
            class="text-[17px] font-notosansCJKmd text-[#404040] lg:hidden"
          >
            {{ data.tag }}
          </p>
          <p
            v-if="data.tag === 'NEW ARRIVAL'"
            class="text-[17px] font-notosansCJKmd text-[#404040] lg:hidden w-[220px] md:w-[180px]"
          >
            {{ data.tag }}
          </p>
          <p
            v-if="data.tag === 'Pick'"
            class="text-[17px] font-notosansCJKmd text-[#404040] lg:hidden"
          >
            TREND
          </p>
          <hr
            class="w-full mr-[36px] md:mr-0 lg:w-[167.5px] ml-[26px] lg:ml-[16px] border-1 h-[1px] border-[#707070]"
          />
        </div>
        <div class="lg:flex items-center mt-[21px] hidden">
          <div v-for="(tendItems, ii) in data.item" :key="`trendCard` + ii" class="cursor-pointer">
            <router-link v-if="ii < 4" :to="{ path: `/product/${tendItems._id}` }">
              <shopCard :item="tendItems" class="mr-[45px]" />
            </router-link>
          </div>
        </div>
        <div class="swiper-container lg:hidden mt-[11.5px]">
          <swiper
            :slides-per-view="'auto'"
            :space-between="spaces"
            :loop="false"
            class="swiper-wrapper"
          >
            <swiper-slide
              v-for="(trendSwiper, index) in data.item"
              :key="'trendsSwiper' + index"
              class="mb-[44px] cursor-pointer"
            >
              <shopCard :item="trendSwiper" class="" @click="goToDetailProduct(trendSwiper._id)" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { sendRequest } from '../../api/serviceApi'

import productCardHome from '../prodcut/productCardHome.vue'
import shopCard from '../shop/shopCard.vue'
import { useRouter } from 'vue-router'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
const props = defineProps({
  data: Object,
  isRecomnd: Boolean
})

const spaces = ref(10)
const router = useRouter()

const goToDetailProduct = (id) => {
  router.push(`/product/${id}`)
}
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
}
.swiper-wrapper {
  display: flex;
  position: relative;
}

.swiper-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 42px; /* 그라디언트의 너비, 필요에 따라 조절 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgb(255, 255, 255)
  ); /* 왼쪽에서 오른쪽으로 투명에서 완전 
    불투명으로 */
  z-index: 2;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.slide-content {
  display: inline-block;
  padding: 0 15px; /* 슬라이드 내용의 좌우 여백 조정 */
}
</style>
