<template>
  <div v-if="dataReady" class="w-full" ref="postFrame">
    <div>
      <postHeader class="" />
    </div>
    <section class="mb-[8px] lg:-mt-[10px] lg:mb-0 lg:ml-[5px]">
      <!-- flex wrapper -->
      <div class="w-full flex">
        <!-- counter -->
        <div class="mt-[11px] ml-[15px] flex items-center">
          <p class="text-[11px] md:text-[16px] font-notosansCJKbold">전체</p>
          <p
            class="ml-[4px] md:ml-[9px] text-[11px] md:text-[16px] text-[#389033] font-notosansCJKbold"
          >
            {{ pageData.length }}건
          </p>
        </div>
        <!-- blogs -->
      </div>
    </section>
    <section>
      <postGrid
        class="lg:ml-[15px]"
        :data="isMobile ? pageData : childData"
        :isMobile="isMobile"
        :key="isMobile"
      />
      <div v-if="width > 1179" class="h-[84.5px] lg:h-[122px] hidden lg:block">
        <pagination :numberOfData="9" :data="pageData" @update-data="handleData" />
      </div>
    </section>
  </div>
</template>

<script setup>
import postHeader from '../../components/post/postHeader.vue'
import { ref, onMounted, watch, watchEffect } from 'vue'
import postGrid from './postGrid/postGrid.vue'
import { sendRequest } from '../../api/serviceApi'
import { useRouter, useRoute } from 'vue-router'
import pagination from '../util/pagination.vue'

import { useElementSize } from '@vueuse/core'

const postFrame = ref(null)
const { width } = useElementSize(postFrame)

const isMobile = ref(false)

watch(width, () => {
  if (width._rawValue > 1179) {
    isMobile.value = false
  } else if (width._rawValue < 1179) {
    isMobile.value = true
    childData.value = imsiData
  }
})

const imsiData = []

const navList = ref([
  {
    name: '시공사례',
    class: '',
    color: 'text-[#707070]',
    router: 'case'
    // ref: newPostArea
  },
  {
    name: '인테리어',
    class: '',
    color: 'text-[#707070]',
    router: 'interior'
    // ref: caseArea
  },
  {
    name: '연들소식',
    class: '',
    color: 'text-[#707070]',
    router: 'blog'
    // ref: interiorArea
  }
])

const childData = ref('')
const handleData = (data) => {
  childData.value = data
}

const typeName = ref('')

const props = defineProps({
  params: String
})

const postTitle = (params) => {
  switch (params) {
    case 'blog':
      return 2
    case 'interior':
      return 1
    case 'case':
      return 0
    default:
      return 0
  }
}

watchEffect(() => {
  if (props.params) {
    typeName.value = postTitle(props.params)
  }
})

const router = useRouter()
const route = useRoute()

const pageRady = ref(false)
const dataReady = ref(false)

const pageData = ref(null)

const getAllBlogs = async () => {
  const type = {
    type: route.params.id
  }
  const getPost = await sendRequest({ method: 'post', path: '/post/getPostByTypes', data: type })
  const result = getPost.data
  if (result) {
    pageData.value = result
    dataReady.value = true
  } else {
    // console.error(result.message)
    return
  }
}

const postRouter = (link) => {
  router.push(`/post/${link}`)
}
const goTodetails = (id, type) => {
  router.push({
    path: `/post/details/${id}`,
    query: { type: type }
  })
}

onMounted(() => {
  pageRady.value = true
})

watchEffect(() => {
  if (pageRady.value === true) {
    getAllBlogs()
  }
})
</script>

<style lang="scss" scoped></style>
