<template>
  <div class="hidden lg:block">
    <section>
      <div class="flex justify-end w-[169px]">
        <div class="h-[82px] flex items-center">
          <div class="text-right">
            <p class="text-[28px] font-notosansCJKlight text-[#232323]">이달의,</p>
            <p class="text-[28px] font-notosansCJKlight text-[#D23F3F]">신상품</p>
          </div>
        </div>
      </div>
    </section>
    <section class="mt-[31px]">
      <div class="flex justify-end">
        <hr class="w-[105px] border-1 border-[#E2E2E2]" />
      </div>
      <section class="ml-[7px] mt-[3px] text-right mr-[5px]">
        <div class="h-[24px] items-center justify-center">
          <p class="text-[16px] font-notosansCJKmd text-[#232323] mt-[7px]">NEW</p>
        </div>
      </section>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
