<template>
  <div class="w-full h-full" ref="frame">
    <div class="z-20 relative w-full h-full">
      <img
        :src="data"
        alt=""
        class="w-full h-full aspect-375/300 md:aspect-900/580 object-cover object-center"
        @click="calculatePercentage($event)"
      />
      <div
        v-for="(badge, index) in batchList"
        :key="badge._id"
        @click="badge.show = !badge.show"
        class="absolute flex justify-center items-center"
        :style="{ left: `${badge.left}%`, top: `${badge.top}%` }"
      >
        <div class="shadow-sm relative">
          <postBadger :data="badge" class="cursor-pointer" />
          <postBadgeDropdown
            v-if="badge.show && badge.item === undefined"
            class=""
            @click.stop
            @update-data="handleData"
            @update-index="updateIndex"
            :index="index"
          />
          <!-- 설정된 뱃지 데이터 -->
          <postBadgerSetted
            v-if="badge.item && badge.show"
            :item="badge.item"
            :index="index"
            :main="props.main"
            @click.stop
            @update-index="updateIndex"
            @update-close="closeIndex"
            class="animate-dropDown absolute -right-[12rem] hidden md:block"
          />
        </div>
        <!-- 모바일 모달 -->
        <div
          v-if="badge.item && badge.show"
          class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 md:hidden"
        >
          <postBadgerSetted
            class="mx-3"
            :item="badge.item"
            :index="index"
            :main="props.main"
            @update-index="updateIndex"
            @update-close="closeIndex"
          />
        </div>
      </div>
    </div>
    <button @click="deleteAll()" v-if="!props.main">초기화</button>
  </div>
</template>

<script setup>
// batchList는 여기서만 생성되고
// img가 업데이트 되면 batch데이터 초기화, 새 배치 데이터는 프롭으로 받는다

import { ref, onMounted, onUnmounted, watchEffect, watch, nextTick, computed } from 'vue'
import postBadger from './postImgUtilCompo/postBadger.vue'
import postBadgeDropdown from './postImgUtilCompo/postBadgeDropdown.vue'
import postBadgerSetted from './postImgUtilCompo/postBadgeSetted.vue'
import { useRouter } from 'vue-router'
import { useElementSize } from '@vueuse/core'

const isDeleting = ref(false)
const activeIndex = ref(null)
const deleteStatus = ref(false)
const compoIndex = ref(0)
const frame = ref(null)
const { width, height } = useElementSize(frame)

const router = useRouter()
const props = defineProps({
  badgeList: Array,
  data: Object,
  index: Number,
  main: Boolean, // false 이면 에디트 모드 임
  isEdit: Boolean
})

onMounted(() => {
  compoIndex.value = props.data.index
  // console.log('현재 이미지 유틸의 인덴스 넘버는', compoIndex.value)
  // console.log('가져온 뱃지리스트', props.badgeList)
})

const batchList = ref([]) // 메인 데이터
batchList.value = props.badgeList
const batchIsEdit = ref(true)
const clickPosition = ref(null)

// !! 데이터 이밋 및 머징 !! //

// 데이터를 이밋 받으면
const childData = ref(null)

const deleteAll = () => {
  batchList.value = []
}
const handleData = (data) => {
  childData.value = data
}

const updateIndex = (data) => {
  // console.log('index emit done,', data.index)
  activeIndex.value = data.index
  deleteStatus.value = data.isDelete
  // console.log('gonna delete : ', data.isDelete)
}

const closeIndex = (data) => {
  console.log('closeIndex called with data:', data) // 로그 추가
  batchList.value[data.index].show = data.show
}

// 해당번 열의 뱃지와 데이터를 머징합니다
const mergingWithBadge = (data) => {
  console.log('merging', data)
  const order = data.index
  const itemData = { ...data.data }
  if (batchList.value[order]) {
    // console.log('order item', batchList.value[order])
    batchList.value[order] = {
      ...batchList.value[order],
      item: itemData,
      index: order,
      show: false
    } // show 상태를 false로 설정
    childData.value = null
  }
  // 뱃지 하나가 완성 되었으므로 다시 뱃지를 만들 수 있게 에디터를 풀어 준다
  batchIsEdit.value = true
}

// 그리고 show = false, batchIsEdit = true로 지정합니다
const randomKey = () => {
  const randomKeys = Math.random() * 100
  return randomKeys
}

// !! 데이터 이밋 및 머징 !! //

const deleteBadgeList = (index) => {
  batchList.value.splice(index, 1)
  batchIsEdit.value = true
  deleteStatus.value = false
}

// !!  뱃지 생성 로직 !! //

// 3. 리스트 생성 호출
const addBatch = (a, b, randomKey) => {
  batchList.value.push({
    show: true,
    key: randomKey,
    left: a,
    top: b,
    setLeft: 0,
    setTop: 0
  })
}

// 1. 좌표를 계산
function calculatePercentage(event) {
  // 에디트가 이니면 생성 x
  if (props.main === true) {
    return
  }
  if (isDeleting.value === true) {
    isDeleting.value = false // 삭제중 watchEffct 제어
    // console.log('is delte', isDeleting.value)
  }
  if (!batchIsEdit.value) {
    // return console.log('에디터 비활성화')
  }
  nextTick(() => {
    const { width, height, top, left } = event.target.getBoundingClientRect()
    const x = event.clientX - left
    const y = event.clientY - top

    const xPercent = ((x / width) * 100).toFixed(2)
    const yPercent = ((y / height) * 100).toFixed(2)

    clickPosition.value = { top: yPercent, left: xPercent }
  })
}

// 2.좌표가 들어오면 리스트 생성호출
watchEffect(() => {
  if (clickPosition.value && isDeleting.value === false) {
    batchIsEdit.value = false
    addBatch(clickPosition.value.left, clickPosition.value.top, randomKey())
  }
})

// 상품 선택이 완료 되면 이루어지는 모든 로직
watchEffect(() => {
  if (childData.value) {
    mergingWithBadge(childData.value)
  }
})

// 뱃지 생성 로직 끝 //

// 초기 생성 취소 삭제
watchEffect(() => {
  if (deleteStatus.value) {
    deleteBadgeList(activeIndex.value)
  }
})

const results = ref(null)

// 최종 데이터를 상위 컴포넌트에 전달
const emit = defineEmits(['updateData', 'updateProduct'])

//이벤트 실행
const sendData = () => {
  emit('updateData', results.value)
  // console.log('imgUtil : 이밋을 보냅니다')
}

watch(batchList.value, (newBatchList) => {
  if (newBatchList) {
    results.value = {
      index: props.index,
      batchList: batchList.value
    }
    sendData()
  }
})

watchEffect(() => {
  if (props.data.index !== compoIndex.value) {
    // console.log('imgUtil, 이미지를 바깠쓰예')
    batchList.value = props.badgeList
  }
})

// related데이터 생성

const relatedProduct = computed(() => {
  try {
    // batchList가 배열인지 확인하고 안전하게 처리
    if (!batchList.value || !Array.isArray(batchList.value)) {
      throw new Error('batchList가 배열 형식이 아니거나 존재하지 않습니다.')
    }

    // item이 존재하고 _id가 있을 때만 _id를 추출하여 relatedProduct 배열에 저장
    return batchList.value
      .filter((batch) => batch.item && batch.item._id) // item이 있고 _id가 있을 때만 필터링
      .map((batch) => batch.item._id) // _id 값만 추출
  } catch (error) {
    console.error('Error in relatedProduct computed:', error.message)
    return [] // 예외 발생 시 빈 배열 반환
  }
})
const relateProuct = relatedProduct.value

const sendRelatedProduct = () => {
  if (props.index !== undefined) {
    emit('updateProduct', {
      datas: relatedProduct.value
    })
    // console.log(props.index) // 인덱스 로그 확인 0
  }
}

watchEffect(() => {
  if (relatedProduct.value) {
    sendRelatedProduct()
  }
})

// console.log(relateProuct)
</script>

<style lang="scss" scoped></style>
