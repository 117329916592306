<template>
  <div class="">
    <div class="flex justify-center mt-[15.5px] md:mt-[46px]">
      <div>
        <section>
          <div class="flex justify-center">
            <p class="text-[16px] md:text-[26px] font-notosansCJKmd text-[#171717]">
              회원 정보 입력
            </p>
          </div>
        </section>
      </div>
    </div>
    <section class="mt-[36.5px] md:mt-[17px]">
      <div class="flex justify-center">
        <naverRegisterInfoBox />
      </div>
    </section>
    <div class="flex justify-center mt-[21px] md:mt-[41px]">
      <registerAgreement />
    </div>
    <div class="mt-[23.5px] md:mt-[42px] mb-0 md:mb-[191px]">
      <section class="md:hidden">
        <div class="flex justify-center">
          <button
            class="border border-[#389033] md:border-[#0C370A] w-[320px] md:w-[356px] h-[43px] md:h-[50px] rounded-lg md:rounded-md text-[15px] md:text-[18px] font-notosansCJKmd text-[#389033] md:text-white bg-transparent md:bg-[#389033]"
            @click="stepToStage()"
          >
            다음 단계
          </button>
        </div>
      </section>
      <section class="hidden md:block">
        <div class="mt-[23.5px] mb-[191px]">
          <section class="">
            <div class="flex justify-center">
              <button
                class="border border-[#D5D5D5] w-[226px] h-[51px] rounded-md text-[18px] font-notosansCJKmd text-[#6D6D6D] bg-transparent"
                @click="goBack()"
              >
                이전단계
              </button>
              <button
                class="border border-[#0C370A] bg-[#389033] w-[226px] h-[51px] rounded-md text-[18px] font-notosansCJKmd text-white ml-[40px]"
                @click="naverRegisterToRedirect()"
              >
                다음단계
              </button>
            </div>
          </section>
        </div>
      </section>
    </div>
    <p
      class="md:hidden mt-[32px] mb-[60px] text-center text-[10px] md:text-[14px] text-[#6D6D6D] font-notosansCJR"
    >
      ⓒ ruruci.inc All rights reserved.
    </p>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useRegisterStore } from '../../../stores/useRegister'
import registerAgreement from '../registerAgreement.vue'
import naverRegisterInfoBox from './naverRegisterInfoBox.vue'

const router = useRouter()
const register = useRegisterStore()

const goBack = () => {
  register.registStage = 0
  router.push('/register')
}
const naverRegisterToRedirect = () => {
  if (!register.smsAuth) {
    alert('휴대폰 인증을 완료해 주세요.')
    return
  }
  if (!register.registerStage.stage0.required) {
    alert('서비스 이용을 위해 모든 필수 동의 항목을 체크해 주세요. ')
    return
  }
  register.registStage = 2
  router.push('/register')
}
</script>

<style lang="scss" scoped></style>
