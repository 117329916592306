<template>
  <div class="w-full lg:h-auto lg:w-[872px] flex justify-between items-center">
    <router-link :to="{ path: '/' }" class="hidden md:block">
      <img
        class="h-[59px] w-auto object-center object-cover"
        src="/img/logo/logo2.webp"
        alt="logo"
      />
    </router-link>

    <!-- 임시로고 -->
    <router-link :to="{ path: '/' }" class="md:hidden">
      <div class="md:hidden">
        <img src="/img/logo/logoMobile.webp" alt="yeondeul_mobile_logo" class="h-[42px] w-[70px]" />
      </div>
    </router-link>

    <div v-if="!system.mobileMenu" class="ml-[22px] lg:ml-0">
      <headerSearchBar />
    </div>
    <div class="h-[18px] mr-[12.5px] md:hidden flex items-center mt-1">
      <!-- 모바일장바구니 -->
      <div class="w-[25px] h-[25px] mr-[15px] mb-1">
        <router-link to="/cart" @click="system.mobileMenu = false">
          <i class="pi pi-shopping-cart text-[25px]"></i>
        </router-link>
      </div>
      <!-- 모바일 버거메뉴 -->
      <!-- <div class="burger-menu"></div> -->
      <div
        class="w-[32px] h-[32px] flex items-center justify-center cursor-pointer"
        @click="toggleMenu()"
      >
        <div :class="`burger-menu ${system.mobileMenu ? 'open' : ''} ${textcolor}`"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import headerSearchBar from '../../layouts/headerUtil/headerSearchBar.vue'
import { useSystemStore } from '../../stores/useSystem'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import buggerMenuVue from '../../layouts/headerUtil/buggerMenu.vue'

const router = useRouter()
const isMenuOpen = ref(false)
const textcolor = ref('white')
const system = useSystemStore()

const toggleMenu = () => {
  system.mobileMenu = !system.mobileMenu
}

const goToMain = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.burger-menu {
  width: 21.8px; /* 넓이 변경 */
  height: 2px;
  background-color: currentColor; /* Use the current text color */
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: currentColor; /* Use the current text color */
    transition: transform 0.3s ease-in-out;
  }

  &:before {
    top: -7px; /* 위치 변경 (기존 10px 비율로 줄임) */
  }

  &:after {
    top: 7px; /* 위치 변경 */
  }
}

.burger-menu.open {
  background-color: transparent;

  &:before {
    transform: translateY(7px) rotate(45deg); /* translateY 값 수정 */
  }

  &:after {
    transform: translateY(-7px) rotate(-45deg); /* translateY 값 수정 */
  }
}
</style>
