<template>
  <div v-if="pageReady" class="w-full">
    <div class="grid grid-cols-1 gap-0 lg:grid-cols-3 lg:mx-auto h-auto" :key="isMobile">
      <div v-for="(item, index) in sortedData" :key="index" class="mt-[px]">
        <!-- <div class="w-[240px] h-[540px] bg-red-200" > {{ idnex }}  </div> -->
        <router-link :to="{ path: `/post/details/${item._id}` }">
          <postGridCardVue
            v-if="isMobile ? index < limiter : data.length"
            :key="'iex' + index"
            :data="item"
            class="hd:pb-0 mb-[29px] lg:mt-[8px] opacity-0 animate-fadeInBottom z-0 lg:mx-0"
          />
        </router-link>
      </div>
    </div>
    <div
      v-if="isMobile && loadDone === false"
      class="flex justify-center items-center mt-[35px] mb-[150px]"
      ref="target"
    >
      <i class="pi pi-spin pi-spinner text-[24px]"></i>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, watchEffect, onMounted } from 'vue'
import postGridCardVue from './postGridCard.vue'

import { useIntersectionObserver } from '@vueuse/core'
import { useRoute } from 'vue-router'
const route = useRoute()

const pageReady = ref(true)
const limiter = ref(3)
const loadLimit = ref(0)
const loadDone = ref(false)

const props = defineProps({
  data: Array,
  isMobile: Boolean
})

const target = ref(null)
const targetIsVisible = ref(false)
const { stop } = useIntersectionObserver(target, ([{ isIntersecting }], observerElement) => {
  targetIsVisible.value = isIntersecting
})

const loadingTimer = () => {
  if (limiter.value === loadLimit.value) {
    loadDone.value = true
  }
  setTimeout(() => {
    let nowLimit = limiter.value
    limiter.value = nowLimit + 3
    if (limiter.value > props.data.length) {
      limiter.value = props.data.length
    }
    targetIsVisible.value = false
  }, 650)
}

watchEffect(() => {
  if (targetIsVisible.value) {
    loadingTimer()
  }
})

const sortedData = ref([])

onMounted(() => {
  if (props && props.isMobile === true) {
    // props.data의 date 값을 기준으로 최신 순으로 정렬 (내림차순)
    sortedData.value = [...props.data].sort((a, b) => new Date(b.date) - new Date(a.date))

    loadLimit.value = props.data.length
    if (limiter.value > props.data.length) {
      limiter.value = props.data.length
    }
  }
})

watch(
  () => props.data, // props.data를 감시
  (newData) => {
    if (Array.isArray(newData) && newData.length > 0) {
      // props.data를 복사한 후 날짜 기준으로 정렬
      sortedData.value = [...newData].sort((a, b) => new Date(b.date) - new Date(a.date))
    }
  },
  { immediate: true } // 컴포넌트가 마운트될 때도 즉시 실행
)
</script>

<style lang="scss" scoped></style>
