<template>
  <div class="md:flex items-center text-[18px]">
    <!-- 1. postList  -->
    <div class="flex items-start mx-auto md:mx-0">
      <div
        v-for="item in menuDatas"
        :key="item"
        class="text-[16px] text-[#6D6D6D] font-notosansCJKmd mr-[31px]"
      >
        <router-link :to="{ path: `${item.route}` }">
          {{ item.title }}
        </router-link>
      </div>
      <p class="hidden md:block -ml-[6.2px] text-[#707070]">|</p>
    </div>
    <!-- 2. cat list -->
    <div class="md:flex items-center mt-4 md:-mt-1 md:ml-[46.4px]">
      <div
        v-for="cats in categoryDatas"
        :key="cats.title"
        class="text-[16px] text-[#6D6D6D] font-notosansCJKmd mr-[33px]"
      >
        <router-link :to="{ path: `/shop/${cats.link}`, query: { type: cats.title } }">
          {{ cats.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import mainCatData from '../../data/mainCatData'
const router = useRouter()

const routerPush = (cats) => {
  router.push(`/shop/${cats.link}`)
}

const postPush = (path) => {
  router.push(`${path}`)
}

const categoryDatas = {
  ...mainCatData
}

const menuDatas = [
  {
    title: '브랜드소개',
    route: '/about'
  },
  {
    title: '매거진',
    route: '/post'
  },
  {
    title: '기획전',
    route: '/'
  }
]
</script>

<style lang="scss" scoped></style>
